import React from 'react'
import './button.scss'

const Button = ({
    id,
    btnText,
    btnType,
    btnClass,
    isDisabled,
    onHandleClick,
    iconRight,
    iconLeft,
    iconSize,
    iconClass
}) => {

    return (
        <button
            type={btnType || 'button'}
            id={id}
            onClick={onHandleClick}
            className={`btn ${btnClass}`}
            disabled={isDisabled ?? false}
            data-tooltip="tooltip"
        >
            {
                iconLeft ? (
                    <div className={`icon-left-wrapper `} >
                        <i className={`pi ${iconClass}`} style={{fontSize: iconSize}} />
                    </div>
                ) : null
            }
            {btnText}
            {
                iconRight ? (
                    <div className={`icon-right-wrapper `} >
                        <i className={`pi ${iconClass}`} style={{fontSize: iconSize}} />
                    </div>
                ) : null
            }
        </button>
    );
}

export default Button