import React from "react"
import { validators } from "../../../utils/helpers/validators"
import SaveButton from "../../common/buttons/SaveButton"
import LabeledMultiSelect from "../../common/form-components/labeled-dropdown/LabeledMultiSelect"
import LabeledText from "../../common/form-components/labeled-inputs/LabeledText"
import LabeledTextArea from "../../common/form-components/labeled-inputs/LabeledTextArea"
import LabeledDropdown from "../../common/form-components/labeled-dropdown/LabeledDropdown"

const BalanceForm = ({
    inputs,
    handleChange,
    handleSubmit,
    accounts,
    types
}) => {
    const validateForm = validators.isEntityValues([inputs?.name]) && inputs?.accounts?.length > 0

    return (
        <>
            <div>
                <LabeledText
                    idLabel='name'
                    required
                    name='name'
                    value={inputs?.name}
                    onChange={(e) => handleChange(e)} 
                />
                <LabeledMultiSelect 
                    idLabel='accounts'
                    name='accounts'
                    value={inputs?.accounts}
                    onChange={(e) => handleChange(e)}
                    options={accounts}
                    optionLabel="account"
                    optionValue="_id"
                />
                <LabeledTextArea
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={(e) => handleChange(e)} 
                />
                <LabeledDropdown 
                    idLabel='type'
                    name='type'
                    value={inputs?.type}
                    onChange={(e) => handleChange(e)}
                    options={types}
                    optionLabel="name"
                    optionValue="_id"
                />
            </div>
            <div className="modal-btn-container">
                <SaveButton
                    onClick={() => handleSubmit()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal" 
                />
            </div>
        </>
    )
}

export default BalanceForm