import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import './distributionCriteriaForm.scss'

const DistributionCriteriaForm = ({ 
    inputs, 
    handleChange,
    handleSave 
}) => {

    const validateForm = validators.isEntityValues([inputs?.name])

    return (
        <div className="distribution-criteria-form">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledText 
                        idLabel="name"
                        required
                        name="name"
                        value={inputs?.name}
                        onChange={handleChange}
                    />
                    <LabeledTextArea 
                        idLabel="description"
                        name="description"
                        value={inputs?.description}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div className="modal-btn-container">
                <SaveButton 
                    onClick={() => handleSave(inputs?._id)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default DistributionCriteriaForm
