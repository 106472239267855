import React from 'react'
import { FormattedMessage } from 'react-intl'
import { validators } from '../../../../utils/helpers/validators'
import Button from '../../../common/buttons'
import StaffProfileTabs from '../staff-profile-tabs/StaffProfileTabs'
import './staffEditForm.scss'

const StaffEditForm = ({ 
    inputs, 
    handleChange, 
    handleSave,
    activeTabIndex
}) => {

    const validateForm = validators.hasValue(inputs?.infoBasic?.daDni) 
                    && validators.hasValue(inputs?.infoBasic?.apellidosYNombre)

    return (
        <div className='staff-basic-edit-form'>
            <div className='modal-content-wrapper'>
                <StaffProfileTabs 
                    infoBasic={inputs?.infoBasic}
                    infoWork={inputs?.infoWork}
                    infoSs={inputs?.infoSs}
                    handleInputsChange={handleChange}
                    isReadOnly={false}
                    activeTabIndex={activeTabIndex}
                />
            </div>
            
            <div className='modal-btn-container '>
                <Button
                    id="saveNewCenter"
                    btnText={<FormattedMessage id="button.save"/>}
                    btnType='button'
                    btnClass="btn-save-modal"
                    isDisabled={!validateForm}
                    onHandleClick={handleSave}
                />
            </div>
        </div>
    )
}

export default StaffEditForm