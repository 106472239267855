import React from "react"
import MaestrosListView from '../../../../../components/common/MaestrosListView'
import { MAESTROS_SUBVENTIONS } from "../../../../../utils/constants/maestros/maestrosCenters"

const MaestrosSubventions = () => (
    <MaestrosListView 
        title='maestros.subventions' 
        maestros={MAESTROS_SUBVENTIONS} 
    />
)

export default MaestrosSubventions