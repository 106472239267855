import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const DeleteButton = ({
    id,
    onClick, 
    isDisabled,  
    ...propsAddButton
}) => {
    const { formatMessage: msg } = useIntl()

    return (
        <Button
            id={id || "deleteCourse"}
            btnText={msg({id: "button.delete" })}
            btnClass="button-white button-white__padding__8_15 action-cours-btn btn-delete"
            isDisabled={false || isDisabled}
            onHandleClick={onClick}
            iconRight
            iconSize='1rem'
            iconClass='pi-trash'
            {...propsAddButton}
        />
    )
}

export default DeleteButton