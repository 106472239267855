import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import StatisticsService from "../../../../services/centers/courses/statistics/statistics.service"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import BreadcrumbLink from "../../../../components/common/breadcrumb/BreadcrumbLink"
import ResumeStatistics from "../../../../components/center-courses/statistics/resume/ResumeStatistics"
import useLoading from "../../../../utils/hooks/useLoading"

const StatisticsSublistAgroupation = () => {
    const { formatMessage: msg } = useIntl()

    const navigate = useNavigate()

    const { centerId, courseId, agroupationId } = useParams()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const [agroupations, setAgroupations] = useState()
    const [summary, setSummary] = useState()

    useEffect(() => {
        getTeams()
    }, [agroupationId])

    const getTeams = async () => {
        startLoading()
        if (!courseId || !agroupationId) return
        const resp = (await StatisticsService.getTeamsByCourseIdAndAssociation(courseId, agroupationId))
            ?.resp
        if (resp) {
            setAgroupations(resp)
        }
        stopLoading()
    }

    const getSummary = async () => {
        if (!courseId || !agroupationId) return
        const resp = (await StatisticsService.getAgroupationsSummary(courseId, agroupationId))?.resp
        if (resp) {
            setSummary(resp)
        }
    }

    const cards = {
        list: agroupations?.teams?.map(t => ({
            _id: t?.centerTeam,
            name: t?.name, 
            confirmation: t?.confirmation,
        })),
        gotToLink: (teamId) => 
            CENTER_COURSE_DETAILS.STATISTICS_BY_TEAM_ID(centerId, courseId, agroupationId, teamId),
    }

    const contentSummary = (
        <ResumeStatistics 
            summaryData={summary}
            onlyTables
        />
    )

    const breadcrumb = [
        {
            label: msg({ id: 'work.teams' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.STATISTICS(centerId, courseId)} 
                text={msg({ id: 'work.teams' })} /> 
        },
        {
            label: agroupations?.associationName,
        }
    ]

    const paramsGenerateExcel = {
        fileName: msg({ id: 'work.teams'}),
        cols: [
            { title: msg({ id: 'name' }), dataKey: 'name' },
            { title: msg({ id: 'year' }), dataKey: 'year', className: "p-datatype-number" },
            { title: msg({ id: 'hours' }), dataKey: 'hours', className: "p-datatype-number" }
        ],
        resp: async () => {
            const resp = await StatisticsService.getAgroupationsSummary(courseId, agroupationId)
            const teams = resp?.resp?.teams || []
            const data = teams.flatMap(team => [
                { name: '', year: '', hours: '', style: false },
                { name: team.name, year: '', hours: '', style: true },
                ...team.positions.map(position => ({
                    name: position.person ? position.person.basic.apellidosYNombre : '',
                    year: position.yearAppointmentOrExtension || '',
                    hours: position.hours,
                    style: false
                }))
            ])
            return data
        }
    }

    const handlePrevious = () => {
        navigate(CENTER_COURSE_DETAILS.STATISTICS(centerId, courseId))
    }

    return (
        <CardsOverview 
            cardsInfo={cards} 
            breadcrumb={breadcrumb}
            handlePrevious={handlePrevious}
            handleSummary={getSummary}
            contentTableSummary={contentSummary}
            paramsGenerateExcel={paramsGenerateExcel}
            loading={loading}
        />
    )
}
    
export default StatisticsSublistAgroupation
