import React from "react"
import DeleteButtonImg from "../../../../components/common/buttons/DeleteButtonImg"

const rowDelete = ({
    handleDelete, 
    deleteItem = false
}) => {

    const buttonDelete = (rowData) => {
        const valueDeleted = deleteItem ? rowData : rowData?._id
        return (
            <DeleteButtonImg onClick={() => handleDelete(valueDeleted)}/>
        )
    }

    return ({
        key: Math.random(), 
        className: 'row-delete',
        rowEditor: 'rowEditor', 
        body: (rowData) => buttonDelete(rowData),
        align: 'right',
        exportable: false 
    })

}

export default rowDelete