import React from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import CardsOverview from "../../../../../components/common-courses/view-content/CardsOverview"

const Results = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId} = useParams()
    
    const goToList = (id, item) => {
        if (item?.source === 'vertical') {
            return CENTER_COURSE_DETAILS.BUDGET_RESULTS_VERTICAL(centerId, courseId)
        }
        return CENTER_COURSE_DETAILS.BUDGET_RESULTS_HORIZONTAL(centerId, courseId)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'result' })
        }
    ]

    const resultCards = [
        {
            name: msg({ id: 'vertical.result' }),
            source: 'vertical'
        },
        {
            name: msg({ id: 'horizontal.result' }),
            source: 'horizontal'
        }
    ]

    const cardList = {
        list: resultCards,
        gotToLink: goToList
    }

    return (
        <CardsOverview
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default Results