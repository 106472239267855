import { POST, GET, DELETE, PATCH } from "../common/commonRequest.service"
import { endpointsFoundationAccountPlan } from "./endpoints"

const FoundationAccountPlanService = {
    create: async (body) => POST(endpointsFoundationAccountPlan.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsFoundationAccountPlan.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsFoundationAccountPlan.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsFoundationAccountPlan.BY_ID(id))
}

export default FoundationAccountPlanService