import React from "react"
import InputChipsComp from "../../form-components/inputs/InputChipComp"
import { onCellEditComplete } from "../../../../utils/helpers/data-table/common"

const editChipsRowColumn = () => {

    const chipsEditor = (options) => (
        <InputChipsComp
            value={options?.value}
            onChange={({ target: { value } }) => options.editorCallback(value)}
            separator=","
        />
    )

    return {
        editor: (options) => chipsEditor(options),
        onCellEditComplete, 
        key: Math.random()
    }
}

export default editChipsRowColumn