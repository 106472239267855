import React from "react"
import MaestrosListView from '../../../components/common/MaestrosListView'
import { maestrosKeys } from "../../../utils/constants/maestros/maestros"
import { MAESTROS_FOUNDATION } from "../../../utils/constants/maestros/maestrosFoundation"

const MaestrosFoundation = () => (
    <MaestrosListView 
        title='foundationMaster' 
        maestros={MAESTROS_FOUNDATION} 
        masterKeyConfirm={maestrosKeys.FOUNDATION}
    />
)

export default MaestrosFoundation