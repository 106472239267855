import React from "react"
import { SALARY_TABLE, maestrosLaboral, STAFF_PROFILE, SALARY_TABLE_DATA } from "./maestrosLaboral.pathname"
import ContractTypes from '../../../views/maestros/maestros-labor/contract-types/ContractTypes'
import SalaryTables from "../../../views/maestros/maestros-labor/salary-tables/SalaryTables"
import CollectiveAgreements from "../../../views/maestros/maestros-labor/collective-agreements/CollectiveAgreements"
import ManagementPositions from "../../../views/maestros/maestros-labor/management-positions/ManagementPositions"
import Stadiums from "../../../views/maestros/maestros-labor/stadiums/Stadiums"
import SalaryTable from "../../../views/maestros/maestros-labor/salary-tables/SalaryTable"
import Staff from "../../../views/maestros/maestros-labor/staff/Staff"
import StaffProfile from "../../../views/maestros/maestros-labor/staff/StaffProfile"
import Pas from '../../../views/maestros/maestros-labor/pas/Pas'
import SalaryTableData from "../../../views/maestros/maestros-labor/salary-tables/SalaryTableData"
import PasId from "../../../views/maestros/maestros-labor/pas/PasId"

export const maestrosLaboralRoutes = [
    {
        path: maestrosLaboral.CONTRACT_TYPES,
        name: 'contract types',
        element: <ContractTypes />,
    },
    {
        path: maestrosLaboral.COLLECTIVE_AGREEMENTS,
        name: 'colective agreements',
        element: <CollectiveAgreements />,
    },
    {
        path: maestrosLaboral.SALARY_TABLES,
        name: 'Salary Tables',
        element: <SalaryTables />,
    },
    {
        path: maestrosLaboral.MANAGEMENT_POSITIONS,
        name: 'managementPositions',
        element: <ManagementPositions />,
    },
    {
        path: maestrosLaboral.STADIUMS,
        name: 'stadiums',
        element: <Stadiums />,
    },
    {
        path: SALARY_TABLE((':id')),
        name: 'salaryTable',
        element: <SalaryTable />,
    },
    {
        path: SALARY_TABLE_DATA(':agreementId', ':id'),
        name: 'salaryTable',
        element: <SalaryTableData />,
    },
    {
        path: maestrosLaboral.STAFF,
        name: 'staff',
        element: <Staff />,
    },
    {
        path: STAFF_PROFILE(':id'),
        name: 'stuff masters',
        element: <StaffProfile />,
    },
    {
        path: maestrosLaboral.PAS,
        name: 'pas',
        element: <Pas />,
    },
    {
        path: maestrosLaboral.PAS_ID + ':pasId',
        name: 'pasId',
        element: <PasId />,
    },
]