import React from "react"
import { ControlLabel } from "../label/Label"
import InputTextAreaComp from "../inputs/InputTextAreaComp"
import "./labeledInput.scss"

const LabeledTextArea = ({
    idLabel,
    required,
    name,
    value,
    onChange,
    numRows,
    numColumns,
    disabled
}) => {
    return (
        <div className='flex-container textarea'>
            <ControlLabel id={idLabel} required={required} />
            <div className='control-input'> 
                <InputTextAreaComp
                    name={name}
                    value={value} 
                    disabled={disabled}
                    onChange={onChange} 
                    rows={numRows} 
                    cols={numColumns} 
                />
            </div>
        </div>
    )
}

export default LabeledTextArea