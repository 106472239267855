import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const NextButton = ({
    onClick,
    ...props
}) => {
    const { formatMessage: msg } = useIntl()
    return (
        <Button
            id="next"
            btnText={msg({id: "button.next"})}
            btnClass="button-white button-white__padding__8_15"
            onHandleClick={() => onClick()}
            iconRight
            iconSize="1rem"
            iconClass="pi-arrow-right"
            {...props}
        />
    )
}

export default NextButton