import React from 'react'
import { useIntl } from 'react-intl'
import { MAESTROS } from '../../../utils/constants/maestros/maestros'
import {
    MAESTROS_CENTER,
    MAESTROS_ACCOUNTING_CENTER,
    MAESTROS_ACCOUNTING_PLAN_CENTER,
    MAESTROS_SUBVENTIONS
} from '../../../utils/constants/maestros/maestrosCenters'
import { MAESTROS_ECOFIN } from '../../../utils/constants/maestros/maestrosEcoFin'
import {
    MAESTROS_FOUNDATION,
    MAESTROS_ACCOUNTING_FOUNDATION
} from '../../../utils/constants/maestros/maestrosFoundation'
import { MAESTROS_LABORAL } from '../../../utils/constants/maestros/maestrosLaboral'
import { MAESTROS_TEMPLATES } from '../../../utils/constants/maestros/maestrosTemplate'
import MaestrosList from '../../../components/maestros/MaestrosList'
import useBasicLocalData from '../../../utils/hooks/base-adapter/useBasicLocalData'
import MainCommonView from '../../../components/common/MainCommonView'

const ALL_MASTERS = [
    ...MAESTROS,
    ...MAESTROS_CENTER,
    ...MAESTROS_ACCOUNTING_CENTER,
    ...MAESTROS_ACCOUNTING_PLAN_CENTER,
    ...MAESTROS_SUBVENTIONS,
    ...MAESTROS_ECOFIN,
    ...MAESTROS_FOUNDATION,
    ...MAESTROS_ACCOUNTING_FOUNDATION,
    ...MAESTROS_LABORAL,
    ...MAESTROS_TEMPLATES
]

const SearchMasters = () => {

    const { formatMessage: msg } = useIntl()

    const dataFacade = useBasicLocalData({
        allData: ALL_MASTERS,
        rowsPerPageInitial: 28
    })

    const headerOptions = {
        title: msg({id: 'all.maestros'}),
        searchOptions: dataFacade?.searchOptions
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        rowsPerPageOptions: [10, 15, 28],
        totalRecords: ALL_MASTERS.length
    }

    return (
        <div className='search-masters'>
            <MainCommonView
                componentData={(
                    <MaestrosList 
                        maestroslist={dataFacade.data}
                    />
                )}
                headerOptions={headerOptions}
                pagerOptions={pagerOptions}
            />
        </div>
    )
}
export default SearchMasters
