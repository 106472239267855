
import React from 'react'
import MaestrosListView from '../../components/common/MaestrosListView'
import { MAESTROS } from '../../utils/constants/maestros/maestros'


const Maestros = () => (
    <MaestrosListView
        title='maestros' 
        maestros={MAESTROS} 
    />
)

export default Maestros