import React from 'react'
import { FormattedMessage } from 'react-intl'
import { validators } from '../../../utils/helpers/validators'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledNumber from '../../common/form-components/labeled-inputs/LabeledNumber'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledCheckbox from '../../common/form-components/labeled-checkbox/LabeledCheckbox'
import './formEtapa.scss'

const FormEtapa = (
    { 
        inputs,
        handleChange, 
        handleSave
    }
) => {

    const validateYearsInput = () => {
        if (inputs?.yearTo !== '' && inputs?.yearTo !== undefined) {
            return Number(inputs?.yearFrom) <= Number(inputs.yearTo)
        }
        return true
    }

    const validateForm = validators.hasValue(inputs?.name) 
        && validators.hasValue(inputs?.order) 
        && validators.isNumeric(inputs?.order)
        && validators.hasValue(inputs?.yearFrom)
        && validators.hasValue(inputs?.numMonths)
        && validateYearsInput()

    return (
        <div className='form-etapa'>
            <div className='modal-content-wrapper'> 
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                    
                    <LabeledNumber
                        idLabel='num.months'
                        required
                        name='numMonths'
                        value={inputs?.numMonths}
                        onChange={(e) => handleChange(e)}
                    />

                </div>

                <div className='control-wrapper'>
                    <LabeledNumber 
                        idLabel='order'
                        required
                        name='order'
                        value={inputs?.order}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledCheckbox 
                        idLabel='stage.baxiback'
                        name='hasBatxibac'
                        onChange={(e) => handleChange(e)}
                        checked={inputs?.hasBatxibac}
                    />
                </div>

                <div className='control-wrapper-combo'>
                    <div className='control-label' role="heading">
                        <h2 className='title__red-subtitle'>
                            <FormattedMessage id='students.ages' /> :
                        </h2>
                    </div>
                    <div className='control-wrapper'>
                        <LabeledNumber 
                            idLabel='from'
                            required
                            name='yearFrom'
                            value={inputs?.yearFrom}
                            onChange={(e) => handleChange(e)}
                        />

                        <LabeledNumber 
                            idLabel='to'
                            name='yearTo'
                            value={inputs?.yearTo}
                            onChange={(e) => handleChange(e)}
                        />

                    </div>
                </div>
            </div>
            <div className='modal-btn-container'>
                <SaveButton 
                    btnClass="btn-save-modal"
                    isDisabled={!validateForm}
                    onClick={() => handleSave(inputs?._id)}
                />
            </div>
        </div>
    )
}

export default FormEtapa