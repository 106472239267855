import React from 'react'
import { useIntl } from "react-intl"
import AppInfo from '../../components/common/appinfo/AppInfo'

const Home = () => {

    const { formatMessage: msg } = useIntl()

    return (
        <div className='container container__with-background'>
            {msg({id: "home"})}
            <AppInfo />
        </div>
    )
}
export default Home
