import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import useLoading from "../../../../../../utils/hooks/useLoading"
import CardsOverview from "../../../../../../components/common-courses/view-content/CardsOverview"
import BudgetService from "../../../../../../services/centers/courses/budgets/budgets.service"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"

const StaffExpenses = () => {

    const { formatMessage: msg } = useIntl()
    const {centerId, courseId} = useParams()

    const [staffs, setStaffs] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getStaffExpenses()
    }, [])

    const getStaffExpenses = async () => {
        startLoading()
        const resp = await BudgetService.getStaffExpenses(courseId)
        if (resp) {
            setStaffs(resp?.resp)
        }
        stopLoading()
    }

    const goToList = (id, item) => {
        if (item?.etapa) {
            return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_STAFFS_ETAPA_TABLE(centerId, courseId, item?.etapa)
        }
        return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES(centerId, courseId, item?.balanceTemplate)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({id: 'expenses'}),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET_EXPENSES(centerId, courseId)} 
                text={msg({ id: 'expenses' })} /> 
        },
        {
            label: msg({ id: 'staff.expenses' })
        }
    ]
    const cardList = {
        list: staffs?.etapas,
        gotToLink: goToList
    }

    return (
        <CardsOverview 
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default StaffExpenses
