import React from "react"
import { TreeSelect } from "primereact/treeselect"
import { useIntl } from "react-intl"
import './dropdownComp.scss'

const TreeSelectComp = ({
    name,
    value,
    options,
    optionLabel,
    optionValue,
    onChange, 
    ...propsTreeSelect
}) => {

    const { formatMessage: msg } = useIntl()

    return (
        <TreeSelect
            value={value}
            options={options || []}
            name={name}
            optionLabel={optionLabel}
            optionValue={optionValue}
            onChange={onChange}
            placeholder={msg({ id: (propsTreeSelect?.idPlaceHolder || 'placeholder.select') })}
            emptyMessage={propsTreeSelect?.emptyMessage || msg({id: 'placeholder.not.options'})}
            emptyFilterMessage={propsTreeSelect?.emptyFilterMessage || msg({id: 'placeholder.not.options'})}
            {...propsTreeSelect}
            pt={{
                item: ({ context }) => ({
                    className: context.selected ? 'item-active' : undefined
                })
            }}
        />
    )
}

export default TreeSelectComp