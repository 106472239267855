import React, { useState, useEffect } from "react"
import CentersService from "../../../services/centers/centers.service"
import logo from '../../../assets/imgs/logos/logo.svg'
import "./center-logo.scss"

const CenterLogo = ({
    centerId,
    centerImgURL
}) => {

    const [logoCenter, setLogoCenter] = useState(null)

    useEffect(() => {
        handleGetCenterLogo()
    }, [])

    const handleGetCenterLogo = async () => {
        if (centerImgURL) {
            const image = await CentersService.getImage(centerId)
            const base64 = btoa(String.fromCharCode(...new Uint8Array(image.data)))
            const imageFromBase64 = `data:image/png;base64,${base64}`
            setLogoCenter(imageFromBase64)
        }
    }
    
    const handleSaveCenterLogo = async (e) => {
        
        const filePicker = e.target
        const file = filePicker.files[0]
        const buffer = await file.arrayBuffer()

        const { name, type } = file
        const extension = name.split('.')[1]
            
        const base64 = btoa(String.fromCharCode(...new Uint8Array(buffer)))

        await CentersService.updateImage(centerId, { doc: base64, extension })

        const imageFromBase64 = `data:${type};base64,${base64}`;
        setLogoCenter(imageFromBase64)
        e.target.value = ""
    }

    return (
        <div className='center-logo-wrapper'>
            <div className='upload-btn-wrapper'>
                <label htmlFor='logoCenter' role="button" className='btn-label'>
                    <i className="pi pi-upload" />
                </label>
                <input
                    id="logoCenter" 
                    className='inputfile'
                    type="file" 
                    name="logo" 
                    onChange={handleSaveCenterLogo}
                />
            </div> 
            {
                logoCenter !== null
                    ? <img className="logo-image" src={logo} alt="logo-center" />
                    : <img className="logo-image" src={logo} alt="logo-center" />
            }
        </div>
    )

}

export default CenterLogo