import React from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import CardsOverview from "../../../../../../components/common-courses/view-content/CardsOverview"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"

const VerticalResults = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId} = useParams()

    const goToList = (id, item) => {
        if (item?.source === 'expenses') {
            return CENTER_COURSE_DETAILS.BUDGET_RESULTS_VERTICAL_EXPENSES(centerId, courseId)
        }
        if (item?.source === 'income') {
            return CENTER_COURSE_DETAILS.BUDGET_RESULTS_VERTICAL_INCOMES(centerId, courseId)
        }
        return CENTER_COURSE_DETAILS.BUDGET_RESULTS_VERTICAL_TOTALS(centerId, courseId)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'result' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_RESULTS(centerId, courseId)} 
                text={msg({ id: 'result' })} /> 
        },
        {
            label: msg({ id: 'vertical.result' })
        }
    ]

    const verticalCards = [
        {
            name: msg({ id: 'expenses' }),
            source: 'expenses',
        },
        {
            name: msg({ id: 'income' }),
            source: 'income',
        },
        {
            name: msg({ id: 'total' }),
            source: 'total',
        }
    ]

    const cardList = {
        list: verticalCards,
        gotToLink: goToList
    }

    return (
        <CardsOverview
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default VerticalResults