import { DELETE, GET, PATCH, POST } from '../../common/commonRequest.service'
import { endpointsDistributionCriteria } from './endpoints'

const DistributionCriteriaService = {
    create: async (body) => POST(endpointsDistributionCriteria.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsDistributionCriteria.LOAD({filter, limit, skip, sort })),
    update: async ({ _id, body }) => PATCH(endpointsDistributionCriteria.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsDistributionCriteria.BY_ID(id)),
}

export default DistributionCriteriaService
