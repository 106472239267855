import React from "react"
import { useSelector } from 'react-redux'
import { PaymentTypeItems } from '../../../utils/constants/paymentTypes'
import { validators } from "../../../utils/helpers/validators"
import SaveButton from "../../common/buttons/SaveButton"
import LabeledMultiSelect from "../../common/form-components/labeled-dropdown/LabeledMultiSelect"
import LabeledText from "../../common/form-components/labeled-inputs/LabeledText"
import LabeledDropdown from "../../common/form-components/labeled-dropdown/LabeledDropdown"
import './centerTeamCharges.scss'

const CenterTeamCharges = ({
    inputs, 
    handleChange, 
    handleSave,
    isManagerial
}) => {

    const alletapas = useSelector(state => state?.etapas)

    const validateForm = validators.isEntityValues([inputs?.post])
        && (isManagerial ? validators.isEntityValues([inputs?.paymentType]) : true)
    
    return (
        <div className='new-post-centerteam-modal'>
            <div className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='post'
                        value={inputs?.post}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledMultiSelect 
                        key={Math.random()}
                        idLabel='charge.stages'
                        name='etapas'
                        value={inputs?.etapas}
                        optionLabel='name'
                        options={alletapas?.resp}
                        onChange={handleChange}
                    />
                </div>
                { isManagerial 
                    && (
                        <div className='flex-container_ middle_ control-wrapper'>
                            <LabeledDropdown
                                idLabel='payment.type'
                                required={isManagerial}
                                name='paymentType'
                                optionLabel='name'
                                optionValue='_id'
                                options={PaymentTypeItems}
                                value={inputs?.paymentType}
                                onChange={(e) => handleChange(e)}
                            />
                        </div>
                    )
                }
            </div>
            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default CenterTeamCharges