import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import CardsOverview from "../../../../../components/common-courses/view-content/CardsOverview"
import BudgetService from "../../../../../services/centers/courses/budgets/budgets.service"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import useLoading from "../../../../../utils/hooks/useLoading"

const Expenses = () => {

    const { formatMessage: msg } = useIntl()
    const {centerId, courseId} = useParams()
    
    const [expenses, setExpenses] = useState([])
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        startLoading()
        getExpenses()
        stopLoading()
    }, [])

    const getExpenses = async () => {
        const resp = await BudgetService.getExpenses(courseId)
        if (resp) {
            setExpenses(resp?.resp)
        }
    }

    const goToList = (id, item) => {
        if (item?.source === "staffs") {
            return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_STAFFS(centerId, courseId)
        }
        return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES(centerId, courseId, item?.balanceTemplate)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'expenses' })
        }
    ]

    const cardList = {
        list: [
            {
                name: msg({id: 'staff.expenses'}),
                source: 'staffs'
            },
            ...(expenses?.supplies || []).map(item => ({
                ...item,
                source: 'supplies',
            })),
        ],
        gotToLink: goToList
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default Expenses