import { FileUpload } from "primereact/fileupload"
import React from "react"
import { FormattedMessage } from 'react-intl'
import { onCellEditComplete } from "../../../../utils/helpers/data-table/common"

const dropDownEditor = (
    inputId,
    accept,
    labelButton,
    multiple, 
    maxFileSize,
    optionsEditor
) => {
    return (
        <FileUpload
            name={inputId}
            mode="basic"
            accept={accept}
            multiple
            maxFileSize={maxFileSize || 10000000}
            chooseLabel={<FormattedMessage id={labelButton || 'upload'}/>}
            onSelect={(e) => optionsEditor.editorCallback(multiple ? e?.files : e?.files[0])}
        />
    )
}

const editUploadFileRowColumn = ({ 
    inputId, 
    accept, 
    labelButton,
    multiple = false,
    maxFileSize
}) => {
    
    return {
        editor: (optionsEditor) => dropDownEditor(
            inputId,
            accept,
            labelButton,
            multiple, 
            maxFileSize,
            optionsEditor
        ),
        onCellEditComplete, 
    }
}

export default editUploadFileRowColumn