import React from 'react'
import { validators } from '../../utils/helpers/validators'
import LabeledCalendar from '../common/form-components/labeled-calendar/LabeledCalendar'
import LabeledTextArea from '../common/form-components/labeled-inputs/LabeledTextArea'
import SaveButton from '../common/buttons/SaveButton'
import './FromSchoolCourse.scss'

const FormSchoolCourse = ({
    inputs, 
    handleChange,
    handleSubmit
}) => {

    const validateForm = validators.isEntityValues([inputs?.yearFrom, inputs?.yearTo])
    
    const minDateFinish = new Date(inputs.yearFrom)
    minDateFinish.setFullYear(minDateFinish.getFullYear() + 1)
    
    return (
        <div className='school-course-form'>
            <div className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <LabeledCalendar 
                        idLabel='year.start'
                        required
                        name='yearFrom'
                        value={inputs?.yearFrom}
                        onChange={(e) => handleChange(e)}
                        dateFormat='yy'
                        view='year'
                        showIcon
                    />
                    <LabeledCalendar 
                        idLabel='year.finish'
                        required
                        name='yearTo'
                        value={inputs?.yearTo}
                        onChange={(e) => handleChange(e)}
                        dateFormat='yy'
                        view='year'
                        showIcon
                    />                   
                </div>
                <LabeledTextArea 
                    idLabel='description'
                    name='description'
                    value={inputs?.values}
                    onChange={(e) => handleChange(e)}
                    numRows={3}
                    numColumns={15}
                />
            </div>

            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={() => handleSubmit()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormSchoolCourse