import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const PreviusButton = ({
    onClick,
    ...props
}) => {
    const { formatMessage: msg } = useIntl()
    return (
        <Button
            id="previus"
            btnText={msg({id: "button.back"})}
            btnClass="button-white button-white__padding__8_15"
            isDisabled={false}
            onHandleClick={() => onClick()}
            iconLeft
            iconSize="1rem"
            iconClass="pi-arrow-left"
            {...props}
        />
    )
}

export default PreviusButton