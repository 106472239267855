import { DELETE, GET, PATCH, POST } from '../../common/commonRequest.service'
import { endpointsCenterCostCenter } from './endpoints'

const CentersCostCenterService = {
    create: async (body) => POST(endpointsCenterCostCenter.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsCenterCostCenter.LOAD({ filter, limit, skip, sort })),
    update: async ({ _id, body }) => PATCH(endpointsCenterCostCenter.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsCenterCostCenter.BY_ID(id)),
}

export default CentersCostCenterService
