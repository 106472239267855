import { createSlice } from '@reduxjs/toolkit'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import { TerritorialServicesService } from './territorialServices.service'

export const getAll = createAsyncThunkMiddleware('territorialService/get', TerritorialServicesService.getAll)

const initialState = []

const territorialServicesSlice = createSlice({
    name: 'territorialService',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAll.fulfilled, (state, action) => {
                return action.payload
            })
    }
})

export default territorialServicesSlice.reducer
