import React, { useContext, useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { LayoutCenterContext } from "../../../../../components/common-courses/context/LayoutCenterContex"
import Dashboard from "../../../../../components/common-courses/view-content/Dashboard"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import EditableTotalsTable from "../../../../../components/common/dataTable/EditableTotalsTable"
import InputNumberComp from "../../../../../components/common/form-components/inputs/InputNumberComp"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import EducationOfferService from "../../../../../services/centers/courses/education-offer-and-service/educationOffer.service"
import useLoading from "../../../../../utils/hooks/useLoading"
import "./classRoomAndStudents.scss"

const ClassRoomAndStudents = () => {

    const {centerId, courseId, educationOfferId} = useParams()
    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const [ classAndStudents, setClassAndStudents ] = useState({})

    const [columns, setColumns] = useState([])
    const [footer, setFooter] = useState([
        { field: 'name', name: msg({ id: 'total' }) },
        { field: 'classroomAndStudents.concertedUnits', footer: 'sum', value: null, recalculate: true },
        { field: 'classroomAndStudents.units', footer: 'sum', value: null, recalculate: true },
        { field: 'classroomAndStudents.students', footer: 'sum', value: null, recalculate: true },
        { field: 'classroomAndStudents.studentsChildrenCenterWorkers', footer: 'sum', value: null, recalculate: true },
    ])

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getClassAndStudents()
    }, [educationOfferId])

    useEffect(() => {
        getColumnsClassroomAndStudents()
    }, [classAndStudents?.classroomAndStudents])

    const getClassAndStudents = async () => {
        startLoading()
        const resp = await EducationOfferService.getClassAndStudents(courseId, educationOfferId)
        if (resp) {
            const calculateFooterTotals = !resp?.resp.hasUnits
            setClassAndStudents(resp?.resp)

            setFooter(prev => prev?.map(f =>
                // eslint-disable-next-line no-nested-ternary
                f?.field === 'classroomAndStudents.units' ? { ...f, recalculate: calculateFooterTotals }
                    : (f?.field === 'classroomAndStudents.concertedUnits' ? { ...f, recalculate: calculateFooterTotals }
                        : f)))
        }
        stopLoading()
    }

    const getColumnsClassroomAndStudents = () => { 
        const cols = getColumns(COLUMNS_KEYS)
        setColumns([COLUMNS_KEYS[0], ...cols])
    }

    const getColumns = (columnsKeys) => {
        if (classAndStudents?.classroomAndStudents) {
            const columnsToShow = columnsKeys.filter(column => {
                const fieldParts = column?.field?.split('.')
                if (fieldParts?.length > 1 && fieldParts[0] === 'classroomAndStudents') {
                    return fieldParts[1] in classAndStudents.classroomAndStudents
                }
                return false
            })
            return columnsToShow
        }
        return []
    }

    const getTableValues = () => {
        let tableValues = []

        if (classAndStudents?.specialties?.length > 0) {
            tableValues = [...classAndStudents.specialties, classAndStudents]
        } else if (classAndStudents?.subetapas?.length > 0) {
            tableValues = [...classAndStudents.subetapas, classAndStudents]
        } else {
            tableValues = [classAndStudents]
        }

        return [...tableValues]
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const body = {
                ...classAndStudents,
                classroomAndStudents: {
                    ...classAndStudents.classroomAndStudents
                },
                specialties: classAndStudents?.specialties 
            }
            const resp = confirm===true
                ? await EducationOfferService.confirmEtapaOffer(courseId, educationOfferId, body)
                : await EducationOfferService.updateClassAndStudents(courseId, educationOfferId, body)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_NEE(centerId, courseId, educationOfferId))
            }
        }, 0)
    }

    const handleNext = () => {
        navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_NEE(centerId, courseId, educationOfferId))
    }

    const handlePrevious = () => {
        navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, educationOfferId))
    }

    const isCellOfFooter = (options) => options?.props?.value?.length - 1 === options?.rowIndex

    const isCellEditable = (options) => {
        if (options.field === 'name') return false

        const isStudentsField = options.field === "classroomAndStudents.studentsChildrenCenterWorkers" || options.field === 'classroomAndStudents.students'
        const isFooter = isCellOfFooter(options)

        if (isStudentsField) return !isFooter
        if (isFooter) return classAndStudents.hasUnits

        return !classAndStudents.hasUnits
    }

    const getCellClassName = (rowData, options) => {
        const isFooter = isCellOfFooter(options)
        const editable = isCellEditable(options)
        const isNumber = options?.field !== 'name'

        let footerClass = `${isFooter ? 'cell-footer' : ''}`
        footerClass = `${footerClass} ${isNumber ? 'p-datatype-number' : ''}`

        const className = `${footerClass} ${(editable) ? 'editRow' : ''}`

        return className
    }

    const numberEditor = ({ options, propsInput }) => (
        <InputNumberComp
            value={options?.value}
            onChange={({ target: { value } }) => options.editorCallback(value ?? 0)}
            {...propsInput}
        />
    )


    const editableCellParms = {
        bodyClassName: getCellClassName,
        onBeforeCellEditShow: isCellEditable,
        editor: (options) => numberEditor({ options, propsInput: {} }),
    }

    const COLUMNS_KEYS = [
        {
            field: 'name',
            header: 'name',
            style: { width: '50%' },
            bodyClassName: getCellClassName,
        },
        {
            field: 'classroomAndStudents.concertedUnits',
            headerClassName: "p-align-end",
            header: 'concerted.units', 
            style: { width: '20%'},
            ...editableCellParms
        },
        {
            field: 'classroomAndStudents.units',
            headerClassName: "p-align-end",
            header: 'units',
            style: { width: '10%'},
            ...editableCellParms
        },
        {
            field: 'classroomAndStudents.students',
            headerClassName: "p-align-end",
            header: 'students',
            style: { width: '10%'},
            ...editableCellParms
        },
        {
            field: 'classroomAndStudents.studentsChildrenCenterWorkers',
            headerClassName: "p-align-end",
            header: 'students.children.workers',
            style: { width: '10%'},
            ...editableCellParms
        },
    ]

    const breadcrumb = [
        {
            label: msg({ id: 'educative.offer.and.services' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId)} 
                text={msg({ id: 'educative.offer.and.services' })} /> 
        },
        ...(classAndStudents?.path || [])
            ?.map(p => ({
                label: p?.name,
                template: <BreadcrumbLink
                    path={CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, p?._id)}
                    text={p?.name} />
            })),
        {
            label: msg({ id: 'studentsclassrooms'}).toUpperCase()
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const table = (
        <div className="class-room-and-students">
            <EditableTotalsTable
                tableOptions={{
                    loading,
                    value: getTableValues(),
                }}
                columns={columns}
                columnsFooter={footer}
            />
        </div>
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
            footerOptions={{
                handleNext,
                handlePrevious
            }}
        />
    )
}

export default ClassRoomAndStudents