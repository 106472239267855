import { createSlice } from '@reduxjs/toolkit'
import SchoolYearService from './schoolYears.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const createSchoolYear = createAsyncThunkMiddleware('schoolYear/create', SchoolYearService.createSchoolYear)

export const getAllSchoolYear = createAsyncThunkMiddleware('schoolYear/getAll', SchoolYearService.getAll)

export const updateSchoolYear = createAsyncThunkMiddleware('schoolYear/update', SchoolYearService.updateSchoolYear)

export const removeSchoolYear = createAsyncThunkMiddleware('schoolYear/delete', SchoolYearService.removeSchoolYear)

export const updateSchoolYearMaster = createAsyncThunkMiddleware('schoolYear/update', SchoolYearService.updateSchoolYearMaster)

export const removeSchoolYearMaster = createAsyncThunkMiddleware('schoolYear/update', SchoolYearService.removeSchoolYearMaster)


const initialState = []

const schoolYearSlice = createSlice({
    name: 'schoolYears',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(createSchoolYear, updateSchoolYear, getAllSchoolYear, removeSchoolYear)
})

export default schoolYearSlice.reducer