import React from "react"
import LabeledText from "../../../../common/form-components/labeled-inputs/LabeledText"
import LabeledCalendar from "../../../../common/form-components/labeled-calendar/LabeledCalendar"
import "./staffProfileWorking.scss"

const StaffProfileWorking = ({
    info,
    index,
    handleInputsChange,
    isReadOnly
}) => {
    const changeOptions = {
        isReadOnly,
        disabled: isReadOnly,
        onChange: handleInputsChange
    }

    return (
        <div className="staff-profile-working">
            <LabeledText {...changeOptions} idLabel="code" name={`infoWork.${index}.coIdenNumero`} value={info?.coIdenNumero} />
            <LabeledText {...changeOptions} idLabel="center.code" name={`infoWork.${index}.coCentro`} value={info?.coCentro} />
            <LabeledText {...changeOptions} idLabel="center.description" name={`infoWork.${index}.dcCentro`} value={info?.dcCentro} />
            <LabeledText {...changeOptions} idLabel="division.code" name={`infoWork.${index}.coDivision`} value={info?.coDivision} />
            <LabeledText {...changeOptions} idLabel="division.description" name={`infoWork.${index}.dcDivision`} value={info?.dcDivision} />
            <LabeledText {...changeOptions} idLabel="production.unit.code" name={`infoWork.${index}.coUniProd`} value={info?.coUniProd} />
            <LabeledText {...changeOptions} idLabel="production.unit.description" name={`infoWork.${index}.dcUniProd`} value={info?.dcUniProd} />

            <LabeledCalendar {...changeOptions} idLabel="date.start.employee" name={`infoWork.${index}.feCoNalEmp`} value={info?.feCoNalEmp} dateFormat="yy" view="year"/>

            <LabeledText {...changeOptions} idLabel="contract.code" name={`infoWork.${index}.coIdenContrato`} value={info?.coIdenContrato} />
            <LabeledText {...changeOptions} idLabel="contract.type.code" name={`infoWork.${index}.coTipoContrato`} value={info?.coTipoContrato} />
            <LabeledText {...changeOptions} idLabel="contract.type.description" name={`infoWork.${index}.dcTipoContrato`} value={info?.dcTipoContrato} />
            <LabeledText {...changeOptions} idLabel="date.start.contract" name={`infoWork.${index}.feCoNalCon`} value={info?.feCoNalCon} />
            <LabeledText {...changeOptions} idLabel="category.code" name={`infoWork.${index}.coCategoria`} value={info?.coCategoria} />
            <LabeledText {...changeOptions} idLabel="category.description" name={`infoWork.${index}.dcCategoria`} value={info?.dcCategoria} />
            <LabeledText {...changeOptions} idLabel="salary.group.code" name={`infoWork.${index}.coGrupoTarifa`} value={info?.coGrupoTarifa} />
            <LabeledText {...changeOptions} idLabel="salary.group.description" name={`infoWork.${index}.dcGrupoTarifa`} value={info?.dcGrupoTarifa} />
            <LabeledText {...changeOptions} idLabel="tc2.code" name={`infoWork.${index}.coTc2`} value={info?.coTc2} />
            <LabeledText {...changeOptions} idLabel="tc2.description" name={`infoWork.${index}.dcTc2`} value={info?.dcTc2} />
            <LabeledText {...changeOptions} idLabel="coeficientFTP" name={`infoWork.${index}.coeficientFTP`} value={info?.coeficientFTP} />
            <LabeledText {...changeOptions} idLabel="tc2Ccc1.code" name={`infoWork.${index}.coTc2Ccc1`} value={info?.coTc2Ccc1} />
            <LabeledText {...changeOptions} idLabel="tc2Ccc2.code" name={`infoWork.${index}.coTc2Ccc2`} value={info?.coTc2Ccc2} />
            <LabeledText {...changeOptions} idLabel="ftp" name={`infoWork.${index}.daCoEfTp`} value={info?.daCoEfTp} />

            <LabeledCalendar {...changeOptions} idLabel="antiquity.date" name={`infoWork.${index}.feAntiguedad`} value={info?.feAntiguedad} dateFormat="yy" view="year"/>
            <LabeledCalendar {...changeOptions} idLabel="antiquity.date.1" name={`infoWork.${index}.feAntiguedad1`} value={info?.feAntiguedad1} dateFormat="yy" view="year"/>
        </div>
    )
}

export default StaffProfileWorking