import React, { useState, useEffect } from "react"
import { useIntl } from 'react-intl'
import { useParams } from "react-router-dom"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import StatisticsService from "../../../../services/centers/courses/statistics/statistics.service"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import ResumeStatistics from "../../../../components/center-courses/statistics/resume/ResumeStatistics"
import useLoading from "../../../../utils/hooks/useLoading"

const Statistics = () => {
    const { formatMessage: msg } = useIntl()

    const { centerId, courseId } = useParams()
    const { loading, startLoading, stopLoading } = useLoading(true)
    const [ agroupations, setAgroupations ] = useState()
    const [summary, setSummary] = useState()

    useEffect(() => {
        getAgroupations()
    }, [courseId])

    const getAgroupations = async () => {
        if (!courseId) return
        startLoading()
        const resp = (await StatisticsService.getAgroupations(courseId))?.resp
        if (resp) {
            setAgroupations(resp?.associations?.map(a => (
                { _id: a?.associationTeam, ...a }
            )))
        }
        stopLoading()
    }

    const getStatisticsSummary = async () => {
        if (!courseId) return
        const resp = await StatisticsService.getSummary(courseId)
        if (resp) {
            setSummary(resp?.resp)
        }
    }

    const contentSummary = (
        <ResumeStatistics 
            summaryData={summary}
            onlyTables={false}
        />
    )

    const paramsGenerateExcel = {
        fileName: msg({ id: 'work.teams'}),
        cols: [
            { title: msg({ id: 'name' }), dataKey: 'name' },
            { title: msg({ id: 'hours' }), dataKey: 'hours', className: "p-datatype-number" }
        ],
        resp: async () => {
            const resp = await StatisticsService.getSummary(courseId)
            const associations = resp?.resp?.associations || []
            const data = associations.flatMap(association =>
                association.teams.flatMap(team => [
                    { name: '', year: '', hours: '', style: false },
                    { name: team?.name, year: '', hours: '', style: true },
                    ...team.positions.map(position => ({
                        name: position.person ? position.person.basic.apellidosYNombre : '',
                        year: position.yearAppointmentOrExtension || '',
                        hours: position.hours,
                        style: false
                    }))
                ]))
            return data
        }
    }

    const cards = {
        list: agroupations,
        gotToLink: (agroupationId) => 
            CENTER_COURSE_DETAILS.STATISTICS_TEAMS_BY_AGROUPATION(centerId, courseId, agroupationId)
    }

    return (
        <CardsOverview 
            title='work.teams'
            cardsInfo={cards} 
            handleSummary={getStatisticsSummary}
            contentTableSummary={contentSummary}
            paramsGenerateExcel={paramsGenerateExcel}
            loading={loading}
        />
    )
}

export default Statistics