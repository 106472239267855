import _ from 'lodash'
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import auxFunction from "../../../utils/helpers/auxFun"
import { DataTableFun } from "../../../utils/helpers/dataTableFun"
import "./editableTotalsTable.scss"
import useSums from "./hooks/useSum"


const EditableTotalsTable = ({
    loading = false,
    tableOptions = {},
    columns = [],
    columnsFooter = [],
}) => {

    const { formatMessage: msg } = useIntl()

    useEffect(() => {
        // BEGIN: DataTable Responsive Pattern
        window.addEventListener("resize", DataTableFun.responsiveResize)
        return () => {
            window.removeEventListener("resize", DataTableFun.responsiveResize)
        }
        // END: DataTable Responsive Pattern
    }, [])

    const allRows = tableOptions?.value
    const rowsWithoutTotals = allRows?.slice(0, -1)
    const totalRow = allRows[allRows?.length - 1]

    const { sums, updateSums } = useSums(rowsWithoutTotals, columnsFooter)

    const tableRows = [
        ...rowsWithoutTotals,
        {
            ...totalRow,
            ...columnsFooter.reduce((acc, column) => ({
                ...acc,
                [column?.field]: (column?.recalculate || typeof column?.recalculate === 'undefined')
                    ? sums[column?.field]
                    : _.get(totalRow, column?.field)

            }))
        }
    ]

    const onCellEditComplete = (e) => {
        const { rowData, field, originalEvent: event } = e
        const newValue = auxFunction.getObjectDeepPropertyValue(e?.newRowData, field)

        if (newValue !== null && newValue !== undefined && !Number.isNaN(newValue)) {
            // TODO: use / move to utils
            const fieldParts = field.split('.')

            if (rowData[fieldParts[0]]) {
                rowData[fieldParts[0]][fieldParts[1]] = newValue
            } else {
                rowData[fieldParts[0]] = {
                    [fieldParts[1]]: newValue
                }
            }

            updateSums()
        }
        else event.preventDefault()
    }

    return (
        <DataTable
            loading={loading}
            className="editable-totals-table"
            scrollable={tableOptions?.scrollable || true}
            scrollHeight={DataTableFun.responsiveHeight()}
            emptyMessage={msg({ id: 'placeholder.not.data' })}
            editMode="cell"
            {...tableOptions}
            value={tableRows}
        >
            {columns?.map((column) => (
                <Column
                    key={column.field}
                    {...column}
                    className={column.className || undefined}
                    header={column?.noTraslateHeader ? column?.header : msg({ id: column?.header })}
                    onCellEditComplete={onCellEditComplete}
                />
            ))}
        </DataTable>
    )
}

export default EditableTotalsTable