import React from 'react'
import MaestrosListView from '../../../components/common/MaestrosListView'
import { MAESTROS_TEMPLATES } from '../../../utils/constants/maestros/maestrosTemplate'

const MaestrosTemplates = () => (
    <MaestrosListView 
        title='templates.distribution.hours.school' 
        maestros={MAESTROS_TEMPLATES} 
    />
)

export default MaestrosTemplates
