import { createSlice } from '@reduxjs/toolkit'
import createAsyncThunkMiddleware from '../../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../../common/extraReducers'
import DistributionCriteriaService from './distributionCriteria.service'

export const create = createAsyncThunkMiddleware('distributionCriteria/create', DistributionCriteriaService.create)

export const getAll = createAsyncThunkMiddleware('distributionCriteria/getAll', DistributionCriteriaService.getAll)

export const update = createAsyncThunkMiddleware('distributionCriteria/update', DistributionCriteriaService.update)

export const remove = createAsyncThunkMiddleware('distributionCriteria/delete', DistributionCriteriaService.remove)

const initialState = []

const distributionCriteriaSlice = createSlice({
    name: 'distributionCriteria',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default distributionCriteriaSlice.reducer
