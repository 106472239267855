import React from 'react'
import PreviusButton from '../../common/buttons/PreviusButton'
import NextButton from '../../common/buttons/NextButton'
import Legend from '../../common/legend/Legend'
import "./footer.scss"

const Footer = ({
    isLegend = false,
    handlePrevious, 
    handleNext, 
    isDisabledNext
}) => {


    return (isLegend || handlePrevious || handleNext) && (
        <div className='footer-buttons'>
            { isLegend && <Legend />}
            {(handlePrevious || handleNext) && (
                <div className='footer-buttons__actions'>
                    <div className='only-left'>
                        { handlePrevious && <PreviusButton onClick={handlePrevious} /> }
                    </div>
                    <div className='only-right'>
                        { handleNext && <NextButton onClick={handleNext} isDisabled={isDisabledNext}/> }
                    </div>
                </div>
            )}
        </div>
    )
}

export default Footer