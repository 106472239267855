import { useState } from "react"

const useFilter = () => {

    const [ filter, setFilter] = useState('')

    const updateFilter = ({ target: { value } }) => {
        setFilter(value)
    }

    return {
        filter, updateFilter
    }

}

export default useFilter