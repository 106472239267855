import React from "react"
import { FormattedMessage } from "react-intl"
import "./label.scss"

export const ControlLabel = ({
    id,
    labelValues,
    required,
    translate = true
}) => {

    const isRequired = required ? '*' : ''

    return (
        <div className="control-label">
            {translate ? <FormattedMessage id={id} values={labelValues} defaultMessage={id}/> : id} { isRequired }
        </div>
    )
}

export const LabelCheck = ({
    id,
    specificClass
}) => {
    return (
        <label className={`control-label label-check ` + specificClass} >
            <FormattedMessage id={id} />
        </label>
    )
}

export const SubTitle = ({
    id
}) => {
    return (
        <div className="title__red-subtitle">
            <FormattedMessage id={id} />
        </div>
    )
}
