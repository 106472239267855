import React from 'react'
import { Calendar } from 'primereact/calendar'
import { addLocale } from 'primereact/api' 
import './calendarComp.scss'

const CalendarComp = ({
    name,
    value, 
    onChange,
    disabled,
    dateFormat,
    showTime,
    hourFormat, 
    maxDate, 
    minDate,
    view
}) => {

    //const { formatMessage: msg } = useIntl()

    //TODO; translate 
    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        weekHeader: 'SEM',
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Limpiar'
    })

    return (
        <Calendar 
            name={name}
            value={value ? new Date(value): ''} 
            onChange={(e) => onChange(e)} 
            showWeek
            locale="es"
            disabled={disabled}
            dateFormat={dateFormat}  
            showIcon 
            showTime={showTime} 
            hourFormat={hourFormat}
            maxDate={maxDate} 
            minDate={minDate}
            view={view}
        />
    )
}
export default CalendarComp