import swalAlert from "../../utils/helpers/swalAlert"
import API from "./commonAPI"

const POST = async (endpoint, body, msgSucces, showSuccessAlert = true, catchErrors = true) => {
    if (catchErrors) {
        try {
            return await POST_NO_CATCH_ERRORS(endpoint, body, msgSucces, showSuccessAlert)
        } catch (error) {
            await swalAlert.error(error.response.data?.message || error.response.data)
            return null
        }
    } else {
        return POST_NO_CATCH_ERRORS(endpoint, body, msgSucces, showSuccessAlert)
    }    
}

const POST_NO_CATCH_ERRORS = async (endpoint, body, msgSucces, showSuccessAlert = true) => {
    const response = await API.post(endpoint, body)

    if (showSuccessAlert) {
        await swalAlert.succesWithTimer(msgSucces)
    }
    
    return response.data
}

const GET = async (endpoint) => {
    try {
        const response = await API.get(endpoint)
        return response.data
    } catch (error) {
        await swalAlert.error(error.response.data?.message || error.response.data)
        return null
    }
}

const PATCH = async (endpoint, body, msgSucces, showSuccessAlert = true) => {
    try {
        const response = await API.patch(endpoint, body)

        if (showSuccessAlert) {
            await swalAlert.succesWithTimer(msgSucces)
        }
        
        return response.data
    } catch (error) {
        await swalAlert.error(error.response.data?.message || error.response.data)
        return null
    }
}

const PUT = async (endpoint, body, msgSucces, showSuccessAlert = true) => {
    try {
        const response = await API.put(endpoint, body)

        if (showSuccessAlert) {
            await swalAlert.succesWithTimer(msgSucces)
        }

        return response.data
    } catch (error) {
        await swalAlert.error(error.response.data?.message || error.response.data)
        return null
    }
}

const DELETE = async (endpoint, msgSucces, showSuccessAlert = true) => {
    try {
        const response = await API.delete(endpoint)
        if (showSuccessAlert) {
            await swalAlert.succesWithTimer(msgSucces)
        }

        return response.data
    } catch (error) {
        await swalAlert.error(error.response.data?.message || error.response.data)
        return null
    }
}

export { POST, GET, PATCH, DELETE, PUT }