import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"
import { LayoutCenterContext } from "../common-courses/context/LayoutCenterContex"
import CenterData from "../centers/center-data/CenterData"
import "./layoutWithoutCourse.scss"

const LayoutWithoutCourse = () => {

    const { center } = useContext(LayoutCenterContext) || []

    return (
        <div className="center-course-routes">
            <div className="menu-aside">
                <h4>
                    <FormattedMessage id="center.info.without.cours"/>
                </h4>
            </div>
            <div className="section-routes">
                <CenterData center={center}/>
            </div>
        </div>

    // <div className="center-without-course">
    //     <aside className="center-without-course__aside">
    //         <div className="aside-no-courses-illustration">
    //             <h2>
    //                 <FormattedMessage id="center.info.without.cours"/>
    //             </h2>
    //         </div>
    //     </aside> 
    //     <section className='center-without-course__section'>
    //         <CenterData center={center}/>
    //     </section>
    // </div>
    )
}

export default LayoutWithoutCourse