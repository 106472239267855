import React, { useState, useEffect, useRef } from "react"
import { FormattedMessage } from 'react-intl'
import { Document, Page, pdfjs } from "react-pdf"
import { useReactToPrint } from 'react-to-print'
import Button from '../common/buttons'
import './pdfView.scss'

// TODO: borrar
const PDF_WORKER_URL = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PdfView = ({ url }) => {
    const [numPages, setNumPages] = useState(null)
    const componentRef = useRef()

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = PDF_WORKER_URL
    }, [])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const onDocumentLoadSuccess = (options) => {
        setNumPages(options?.numPages)
    }

    return (
        <div className="pdf-modal">
            <div className="preview-file">
                <div ref={componentRef}>
                    <Document 
                        file={url} 
                        onLoadSuccess={onDocumentLoadSuccess} 
                        loading={<LoadingMessage />}
                    >
                        {Array.from(
                            new Array(numPages || 0),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    width={890}
                                />
                            ),
                        )}
                    </Document>
                </div>
            </div>
            <div className="modal-btn-container actions-wrapper">
                <PrintButton handlePrint={handlePrint} isDisabled={numPages === null} />
                <DownloadButton url={url} />
            </div>
        </div>
    )
}

const LoadingMessage = () => (
    <div className="loading">
        <FormattedMessage id="swal.title.loading" />
    </div>
)

const PrintButton = ({ handlePrint, isDisabled }) => (
    <Button
        btnText={<FormattedMessage id="print" />}
        btnType="button"
        btnClass="button-white button-white__padding__8_15"
        iconRight
        iconSize='1rem'
        isDisabled={isDisabled}
        iconClass='pi-file'
        onHandleClick={handlePrint}
    />
)

const DownloadButton = ({ url }) => (
    <a href={url} download={url} title="download" className="btn-save-modal">
        <FormattedMessage id="button.save" />  <i className='pi pi-download' />
    </a>
)

export default PdfView
