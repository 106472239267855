import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const AddFileButton = ({
    onClick, 
    isDisabled, 
    btnAdd, 
    btnClass
}) => {
    const { formatMessage: msg } = useIntl()

    return (
        <Button 
            btnText={msg({id: btnAdd || "button.add"})}
            btnType="button"
            btnClass={btnClass}
            isDisabled={!isDisabled}
            onHandleClick={onClick}
        />
    )
}

export default AddFileButton