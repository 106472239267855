import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import ImportSalaryTableModal from "../../../../components/import-files/ImportSalaryTableModal"
import Modal from '../../../../components/modal/Modal'
import { SALARY_TABLE_DATA } from "../../../../routes/maestros/maestros-laboral/maestrosLaboral.pathname"
import CollectiveAgreementsService from '../../../../services/collective-aggreements/collectiveAggreements.service'
import SalaryTablesService from "../../../../services/salary-tables/salaryTables.service"
import { getAllSchoolYear } from "../../../../services/schoolYears/schoolYears.slice"
import { uploadFileWithLoading } from '../../../../utils/helpers/uploadFiles'
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from "../../../../utils/hooks/useInputs"
import useModal from '../../../../utils/hooks/useModal'
import rowSeeWithTooltip from "../../../../components/common/dataTable/body/rowSeeWithTooltip"
import "./salaryTable.scss"

const SalaryTable = () => {
    const { id: agreementId } = useParams()
    
    const { formatMessage: msg } = useIntl()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isShowing: isShowingImport, toggle: toggleImport } = useModal()
    
    const [ agreement, setAgreement ] = useState([])
    const { inputs, onChangeInputs } = useInputs()
    
    const getSalaryTableByAgreementId = async ({filter, limit, skip, sort}) => SalaryTablesService.getAll({agreementId, filter, limit, skip, sort})

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'schoolYear.name',
        getAll: getSalaryTableByAgreementId,
    })

    const getYears = () => { dispatch(getAllSchoolYear({})) }

    useEffect(() => {
        getYears()
        getAgreementByAgreementId()
    }, [])

    const getAgreementByAgreementId = async () => setAgreement((await CollectiveAgreementsService.getById(agreementId))?.resp)    

    const handleImportFile = async (file) => uploadFileWithLoading({
        file, 
        uploadService: (formData) => SalaryTablesService.upload(inputs.schoolYear, agreementId, formData),
        onSuccess: dataFacade.reload,
        onFinally: toggleImport,
        loadingMessage: msg({ id: 'swal.title.loading' })
    })


    const headerOptions = {
        title: msg({ id: 'salary.tables' }) + ' - ' + agreement?.name,
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'accounts.list',
        columns: [
            { title: msg({ id: 'year' }), dataKey: 'schoolYear.name' },
            { title: msg({ id: 'code' }), dataKey: 'categoryCode' },
            { title: msg({ id: 'category' }), dataKey: 'category' },
            { title: msg({ id: 'grossTotalWithoutAntiquity' }), dataKey: 'calculations.montlyGrossTotalWithoutAntiquity' },
            { title: msg({ id: 'annualCostSS' }), dataKey: 'calculations.annualCostWithSS' },
            { title: msg({ id: 'hours.year' }), dataKey: 'calculations.annualHours' },
            { title: msg({ id: 'hourlyCostNoSS' }), dataKey: 'calculations.priceHourWithoutSS' }
        ],
        exportService: () => SalaryTablesService.getAll({agreementId}),
        importService: toggleImport
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: "row",
                ...dataFacade?.sortOptions
            }}
            columns={([
                { field: "schoolYear.name", sortable: true, header: 'year' },
                { field: "categoryCode", sortable: true, header: 'code' },
                { field: "category", sortable: true, header: 'category' },
                { field: "concepts.weeklyHours", header: 'hours.week' },
                { field: "calculations.montlyGrossTotalWithoutAntiquity", header: 'grossTotalWithoutAntiquity'},
                { field: "calculations.annualCostWithSS", header: 'annualCostSS' },
                { field: "calculations.annualHours", header: 'hours.year' },
                { field: "calculations.priceHourWithoutSS", header: 'hourlyCostNoSS' },
                { className: 'row-delete column-tooltip',
                    field: "masters",
                    ...rowSeeWithTooltip({
                        textTooltip: msg({id: 'info.salary.table'}),
                        onClick: (rowData) => navigate(SALARY_TABLE_DATA(agreementId, rowData?._id))
                    }) }    
            ])}
        />
    )

    return (
        <div className="salary-table">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowingImport}
                hide={toggleImport}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id="salary.tables.new" />}
                context={(
                    <ImportSalaryTableModal
                        handleSubmit={handleImportFile}
                        inputs={inputs}
                        onChangeInputs={onChangeInputs}
                    />
                )}
            />
        </div>
    )
}

export default SalaryTable
 