import { isNumber, isObject, isString } from 'lodash'

export const getFullYear = (date) => (
    date instanceof Date ? date.getFullYear() : date
)

export const parseStrToISO8601String = (string) => {
    const date = new Date(string)
    const iso8601String = date.toISOString()
    return iso8601String
}

export const convertStringToDate = (sringYear) => {
    const yearNumber = parseInt(sringYear, 10)
    return new Date(yearNumber, 0, 1)
}

export const parseYear = (value) => {  
    if (isNumber(value)) {
        return convertStringToDate(value)
    }
    if (isObject(value) && value instanceof Date) {
        return value
    }
    if (isString(value)) {
        return new Date(value)
    }
    return value ? new Date(value, 0, 1) : ''
}

export const parseDateYearStr = (year) => {
    let valueYear = year
    if (isString(valueYear)) {
        valueYear = new Date(valueYear)
        return valueYear.getFullYear()
    }
    if (!valueYear) {
        return ''
    }  

    return valueYear
}