import React from "react"
import { useIntl } from 'react-intl'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import ImputationService from "../../../../services/imputation/imputation.service"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import { IMPUTATION_GROUPING } from "../../../../utils/constants/grouping"

const Imputation = () => {

    const { formatMessage: msg } = useIntl()

    const dataFacade = useHandlerBasicActions({
        rowsPerPageInitial: 10,
        sortFieldInitial: 'name',
        getAll: ImputationService.getAll, 
        createAction: ImputationService.create,
        removeAction: ImputationService.remove, 
        editAction: ImputationService.update
    })

    const headerOptions = {
        title: msg({id: 'imputation'}),
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'accounts.list',
        columns: [
            { title: msg({ id: 'name' }), dataKey: 'name' },
            { title: msg({ id: 'imputation.by' }), dataKey: 'grouping' },
            { title: msg({ id: 'description' }), dataKey: 'description' }
        ],
        exportService: async () => {
            const resp = await ImputationService.getAll({})
            let dataResp = []
            if (resp) {
                dataResp = (resp?.resp || []).map(r => ({
                    ...r,
                    grouping: IMPUTATION_GROUPING.find(option => option.value === r?.grouping)?.name
                }))
            }
            return {resp: dataResp} 
        }
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                ...dataFacade?.sortOptions
            }}
            columns={[
                { field: "name",
                    sortable: true,
                    header: 'name',
                },
                {
                    field: 'grouping',
                    header: 'imputation.by',
                    body: (rowData) => rowData?.grouping ? msg({id: rowData?.grouping }) : '',
                },
                { 
                    field: "description",
                    header: 'description',
                }
            ]}
        />
    )

    return (
        <div className='imputation'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />
        </div>
    )
}
export default Imputation 