import { createSlice } from '@reduxjs/toolkit'
import CenterTeamsService from './centerTeams.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'

export const getAllCenterTeamsAgroupations = createAsyncThunkMiddleware(
    'centerTeamsAgroupations/getAll', 
    CenterTeamsService.getAgroupations
)

const initialState = {}

const centerTeamsAgroupationsSlice = createSlice({
    name: 'centerTeamsAgroupations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCenterTeamsAgroupations.fulfilled, (state, action) => {
                return action.payload
            })
    }
})

export default centerTeamsAgroupationsSlice.reducer
