import React from "react"
import { MAESTROS_CENTER } from "../../../utils/constants/maestros/maestrosCenters"
import { maestrosKeys } from "../../../utils/constants/maestros/maestros"
import MaestrosListView from "../../../components/common/MaestrosListView"

import './maestrosCenter.scss'

const MaestrosCenter = () => {
    return (
        <MaestrosListView 
            title='schoolsMaster'
            maestros={MAESTROS_CENTER}
            masterKeyConfirm={maestrosKeys.CENTER}
            showNumbers
        />
    )
}

export default MaestrosCenter