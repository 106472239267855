import React from "react"
import { FormattedMessage } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import FormEditFoundation from "../../../components/foundation/forms/FormEditFoundation"
import IconButtonEdit from "../../../components/common/buttons/IconButtonEdit"
import Modal from "../../../components/modal/Modal"
import { updateFoundation } from "../../../services/foundation/foundation.slice"
import useInputs from "../../../utils/hooks/useInputs"
import useModal from "../../../utils/hooks/useModal"
import "./foundationData.scss"


const FoundationData = () => {

    const {inputs, onChangeInputs, updateInputs} = useInputs({})
    const {isShowingEdit, toggleEdit} = useModal()

    const dispatch = useDispatch()
    const foundation = useSelector(state => state.foundation)?.resp


    const handleUpdateCenter = async () => {
        await dispatch(updateFoundation(inputs))
        toggleEdit()
    }

    const openEditModal = () => {
        updateInputs(foundation)
        toggleEdit()
    }
    
    return (
        <div className="foundation-data">

            {/* <div className='foundation-data__container'>
                <IconButtonEdit onEdit={openEditModal} />
                <div className="foundation-data__container--data">
                    <div>{foundation?.name}</div>
                    <div>{foundation?.address}</div>
                    <div>{foundation?.email}</div>
                    <div>{foundation?.population}</div>
                    <div>{foundation?.province}</div>
                    <div>{foundation?.telf}</div>
                    <div>{foundation?.web}</div>
                </div>
                
            </div>

            <Modal 
                isShowing={isShowingEdit}
                hide={toggleEdit}
                width='100%'
                maxWidth='750px'
                title={<FormattedMessage id='center.edit'/>}
                context={(
                    <FormEditFoundation
                        inputs={inputs}
                        handleChange={onChangeInputs}
                        handleSave={handleUpdateCenter}
                    /> 
                )}
            /> */}

        </div>
    )
}

export default FoundationData