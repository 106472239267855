import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import EtapasList from '../../../../components/maestros-center/etapas/EtapasList'
import EtapasService from '../../../../services/stages/etapas.service'
import useModal from '../../../../utils/hooks/useModal'
import Modal from '../../../../components/modal/Modal'
import FormEtapa from '../../../../components/maestros-center/etapas/FormEtapa'

const Etapas = () => {

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    
    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'order',
        getAll: EtapasService.getAllEtapas,
        createAction: EtapasService.createEtapa,
        removeAction: EtapasService.removeEtapa, 
        editAction: EtapasService.updateEtapa
    })

    const nextOrder = () => {
        let lastOrder = 0
        if (dataFacade?.data?.resp) {
            lastOrder = dataFacade?.data?.total > 0 
                ? Math.max(...dataFacade?.data?.resp?.map((o) => o?.order)) 
                : 0
        }
        return lastOrder + 1
    }

    const initialState = {
        name: '',
        order: nextOrder(),
        yearFrom: '',
        yearTo: '',
    }

    const [inputs, setInputs] = useState(initialState)

    useEffect(() => {
        setInputs(initialState)
    }, [dataFacade?.data])

    const onInputChange = ({ target: { value, name } }) => {
        setInputs({ ...inputs, [name]: value })
    }

    const handleCreateEtapa = () => dataFacade.create(inputs)

    const handleUpdateEtapa = async (id) => dataFacade.edit({_id: id, body: inputs})

    const handleSave = async (_id) => {
        const resp = _id 
            ? await handleUpdateEtapa(_id) 
            : await handleCreateEtapa()
        if (resp) {
            toggleModal()
        }
    }

    const openEditModal = (etapa) => {
        setInputs(etapa)
        toggle()
    }

    const toggleModal = () => {
        toggle()
        setInputs(initialState)
    }

    const headerOptions = {
        title: msg({id: 'stages'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'stages.list',
        columns: [
            { title: msg({ id: 'name' }), dataKey: 'name' }
        ],
        exportService: () => EtapasService.getAllEtapas({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataControl = (
        <EtapasList 
            etapas={_.orderBy(dataFacade?.data?.resp, 'order')} 
            handleDelete={dataFacade.remove} 
            openEditModal={openEditModal} 
        />
    )

    const defineModalTitle = () => msg({ id: inputs._id ? "stage.edit" : "stage.new" })

    return (
        <div className="etapas-maestros-container">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
                showLegend
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="450px"
                title={defineModalTitle()}
                context={(
                    <FormEtapa 
                        inputs={inputs} 
                        handleChange={onInputChange} 
                        handleSave={handleSave} 
                    />
                )}
            />
        </div>
    )
}
export default Etapas
