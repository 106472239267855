import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from 'react-intl'
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import FormStadium from "../../../../components/maestros-labor/stadiums/FormStadium"
import Modal from "../../../../components/modal/Modal"
import CollectiveAgreementsService from "../../../../services/collective-aggreements/collectiveAggreements.service"
import StadiumsService from "../../../../services/stadiums/stadiums.service"
import auxFunction from '../../../../utils/helpers/auxFun'
import editNumberRowColumn from "../../../../components/common/dataTable/editors/editNumberRowColumn"
import editTextRowColumn from "../../../../components/common/dataTable/editors/editTextRowColumn"
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../utils/helpers/data-table/column-types/rowEditor"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'

const Stadiums = () => {
    
    const { formatMessage: msg } = useIntl()

    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'year',
        getAll: StadiumsService.getAll,
        createAction: StadiumsService.create,
        removeAction: StadiumsService.remove, 
        editAction: StadiumsService.update
    })

    const getAllCollectiveAgreements = () => CollectiveAgreementsService.getAll({ })

    useEffect(() => {
        getAllCollectiveAgreements()
    }, [])

    const handleCreate = async () => {
        inputs.schoolyear = auxFunction.returnObjectId(inputs.schoolyear)
        await dataFacade.create(inputs)
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({ newData }) => {
        dataFacade.edit({_id: newData?._id, body: newData})
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const headerOptions = {
        title: msg({id: 'stadiums'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'stadiums',
        columns: [
            { title: msg({id: 'year'}), dataKey: 'year' },
            { title: msg({id: 'agreement'}), dataKey: 'agreement' },
            { title: msg({id: 'number'}), dataKey: 'stadiumNum' },
            { title: msg({id: 'unitary'}), dataKey: 'unitary' },
            { title: msg({id: 'accumulated'}), dataKey: 'accumulated' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: async () => {
            const resp = (await StadiumsService.getAll({}))?.resp
            return { resp: auxFunction.flattenArrayProperty(resp, 'agreement', 'name')}
        }
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={([
                { field: 'year',
                    sortable: true,
                    header: 'year' 
                },
                { field: "agreement.name",
                    header: 'agreement'
                },
                { field: "stadiumNum",
                    sortable: true, 
                    header: 'number'   
                },
                { field: "unitary",
                    sortable: true,
                    header: 'unitary',
                    ...editNumberRowColumn({})
                },
                { field: "accumulated",
                    sortable: true,
                    header: 'accumulated'
                },
                { field: "description",
                    header: 'description',
                    ...editTextRowColumn
                },
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ])}
        />
    )

    return (
        <div className="stadiums">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="550px"
                title={<FormattedMessage id="stadium.new" />}
                context={(
                    <FormStadium 
                        inputs={inputs} 
                        handleSave={handleCreate}
                        handleChange={onChangeInputs} 
                    />
                )}
            />

        </div>
    )

}

export default Stadiums