import React from 'react'
import { maestrosTemplates, TEMPLATES_ETAPA_HOURS } from './maestrosTemplates.pathname'
import TemplateEtapasHours from '../../../views/maestros/template-distribution-hour-school/template-etapa-hours/TemplateEtapasHours'
import ExceededHoursDistribution from '../../../views/maestros/template-distribution-hour-school/exceeded-hours-distribution/ExceededHoursDistribution'
import TemplateEtapaHours from '../../../views/maestros/template-distribution-hour-school/template-etapa-hours/TemplateEtapaHours'

export const maestrosTemplatesRoutes = [
    {
        path: maestrosTemplates.TEMPLATES_ETAPAS_HOURS,
        name: 'Horas de plantilla etapas',
        element: <TemplateEtapasHours />,
    },
    {
        path: TEMPLATES_ETAPA_HOURS(':id'),
        name: 'Horas de plantilla etapas',
        element: <TemplateEtapaHours />,
    },
    {
        path: maestrosTemplates.TEMPLATE_EXCEEDED_HOURS_DISTRIBUTION,
        name: 'Categorias Horas de plantilla',
        element: <ExceededHoursDistribution />,
    },
]
