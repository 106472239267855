import { haveFilterQuery, paginator, haveSort } from "../common/auxFun"

export const endpointsCenterServices = {
    GENERIC: '/v1/maestros/school/services',
    SERVICES: {
        LOAD: ({filter, limit, skip, sort}) => `/v1/maestros/school/services${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
        BY_SERVICE: (service) => `/v1/maestros/school/services/${service}`,
        CONFIRM: (service) => `/v1/maestros/school/services/${service}/confirm`
    },
    SERVICES_TYPE: {
        BY_SERVICE_TYPE: (id) => `/v1/maestros/school/services/types/${id}`,
    }
}