import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from "../../../../../../utils/hooks/useLoading"
import BreadcrumbLink from '../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../routes/centers/center-course/pathname'
import BugetTable from '../../../../../../components/common/dataTable/BugetTable'
import BudgetService from '../../../../../../services/centers/courses/budgets/budgets.service'
import Dashboard from '../../../../../../components/common-courses/view-content/Dashboard'

const TotalsTable = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId} = useParams()

    const [resultsTable, setResultsTable] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getResultsData()
    }, [])

    const getResultsData = async () => {
        startLoading()
        const resp = await BudgetService.getResults(courseId)
        if (resp) {
            setResultsTable(resp?.resp)
        }
        stopLoading()
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'result' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_RESULTS(centerId, courseId)} 
                text={msg({ id: 'result' })} /> 
        },
        {
            label: msg({ id: 'vertical.result' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_RESULTS_VERTICAL(centerId, courseId)} 
                text={msg({ id: 'vertical.result' })} /> 
        },
        {
            label: msg({ id: 'expenses' })
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: resultsTable
            }}
            columns={[
                { field: 'name', header: 'course.result' },
                { field: 'income', header: 'income' },
                { field: 'expense', header: 'expenses' },
                { field: 'result', header: 'result' },
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 1, footerStyle: { textAlign: 'left' } },
                { footer: 'sum', field: 'income'},
                { footer: 'sum', field: 'expense'},
                { footer: 'sum', field: 'result'},
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default TotalsTable