import React from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import Button from "../buttons"
import "./headerActions.scss"

const HeaderActions = ({
    firstHandlerAction,
    textFirstAction, 
    firstIconClass,
    firstActionDisabled,
    secondHandlerAction,
    textSecondAction,
    secondIconClass, 
    secondActionDisabled,
    confirmHandlerAction,
    isConfirmed,
    textConfirmAction
}) => {

    const {formatMessage: msg} = useIntl()
    const navigate = useNavigate()

    return (
        <div className="header-actions">
            {/* Botton back */}                    
            <Button
                id="gobackBtn"
                btnText={msg({id: 'button.back'})}
                btnType='button'
                btnClass="button-white button-white__padding__8_15"
                onHandleClick={() => navigate(-1)}
                iconRight
                iconSize='0.9rem'
                iconClass='pi-replay'
            />
            { /* others bottons*/ }
            {
                firstHandlerAction && (
                    <Button
                        id="addNew"
                        btnText={(textFirstAction && msg({ id: textFirstAction }))|| msg({id: 'button.add'})}
                        btnType='button'
                        btnClass="button-white button-white__padding__8_15"
                        onHandleClick={() => firstHandlerAction()}
                        iconRight
                        iconSize='0.9rem'
                        iconClass={firstIconClass || 'pi-plus'}
                        isDisabled={firstActionDisabled}
                    />
                )
                        
            }
            {
                secondHandlerAction && (
                    <Button
                        id="addNew"
                        btnText={(textSecondAction && msg({id: textSecondAction})) || msg({id: 'button.add'})}
                        btnType='button'
                        btnClass="button-white button-white__padding__8_10"
                        onHandleClick={() => secondHandlerAction()}
                        iconRight
                        iconSize='0.9rem'
                        iconClass={secondIconClass || 'pi-plus'}
                        isDisabled={secondActionDisabled}
                    />
                )
            }
            {
                !isConfirmed && confirmHandlerAction && (
                    <Button
                        id="confirmBtn"
                        btnText={textConfirmAction || msg({id: 'button.confirm'})}
                        btnType='button'
                        btnClass="button-red-border btn__with-bg-type-1 btn__padding__8_15"
                        onHandleClick={() => confirmHandlerAction()} 
                        iconRight
                        iconSize="0.8rem"
                        iconClass="pi-check"
                    />
                )
            }
        </div>
    )
}

export default HeaderActions