import React from "react"
import { ControlLabel } from "../label/Label"
import DropdownComp from "../dropdown/DropdownComp"

const LabeledDropdown = ({
    idLabel,
    required,
    name,
    value,
    ...propsDropdown
}) => {

    return (
        <div className="flex-container">
            <ControlLabel id={idLabel} required={required}/>
            <div className="control-input">
                <DropdownComp 
                    name={name}
                    value={value}
                    {...propsDropdown}
                />
            </div>
        </div>
    )
}

export default LabeledDropdown