import React from "react"
import ButtonWithTooltip from "../../buttons/ButtonWithTooltip"
import ViewButton from "../../buttons/ViewButton"

const seeWithTooltip = (onClick, textTooltip, rowData) => (
    <ButtonWithTooltip 
        textTooltip={textTooltip}
        button={(
            <ViewButton 
                onClick={() => onClick(rowData)}
            />
        )}
    />
)

const rowSeeWithTooltip = ({
    onClick, 
    textTooltip,
}) => {
    return ({
        key: Math.random(), 
        className: 'row-delete column-tooltip',
        body: (rowData) => seeWithTooltip(onClick, textTooltip, rowData),
        align: 'right',
        exportable: false 
    })
}

export default rowSeeWithTooltip