import React from 'react'
import { useIntl } from 'react-intl'
import MainCommonView from "../../components/common/MainCommonView"
import useHandlerBasicActions from "../../utils/hooks/base-adapter/useHandlerBasicActions"
import CentersService from '../../services/centers/centers.service'
import useModal from '../../utils/hooks/useModal'
import CardList from '../../components/card-list/CardList'
import Modal from '../../components/modal/Modal'
import CenterFrom from '../../components/centers/form-edit-center/CenterForm'
import useInputs from '../../utils/hooks/useInputs'
import { CENTER } from '../../routes/centers/pathname'
import './centers.scss'

const Centers = () => {

    const {formatMessage: msg} = useIntl()

    const { isShowing, toggle} = useModal()
    const { inputs, onChangeInputs, updateInputs } = useInputs({})
    
    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        rowsPerPageInitial: 20,
        getAll: CentersService.getAll,
        createAction: CentersService.create,
        removeAction: CentersService.remove, 
        editAction: CentersService.update
    })

    const optionsSort = [
        { value: 'name', label: msg({id: 'name'})}, 
        { value: 'reference', label: msg({id: 'reference'}) }
    ]

    const goToCardLink = (id) => CENTER(id)

    const handleSave = async (_id) => {
        if (_id) {
            await dataFacade.edit({_id, body: inputs })
        } else {
            await dataFacade.create(inputs)
        }
        closeModal()
    }

    const openModal = (centerTeam) => {
        updateInputs(centerTeam)
        toggle()
    }

    const closeModal = () => {
        updateInputs({})
        toggle()
    }

    const defineModalTitle = () => msg({ id: `center.${inputs._id ? 'edit' : 'new'}`})

    const headerOptions = {
        title: msg({id: 'centers'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions,
        dropdownOptions: {
            options: optionsSort,
            value: dataFacade?.sortOptions?.sortField,
            onChange: (e) => dataFacade?.sortOptions?.onSort({ sortField: e?.value })
        }
    }

    const importExportFilesOptions = {
        title: 'backoffice.centres',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'reference'}), dataKey: 'reference' },
            { title: msg({id: 'address'}), dataKey: 'address' },
            { title: msg({id: 'population'}), dataKey: 'population' },
            { title: msg({id: 'telf'}), dataKey: 'telf' },
            { title: msg({id: 'email'}), dataKey: 'email' },
            { title: msg({id: 'WEB'}), dataKey: 'web' }
        ],
        exportService: () => CentersService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataControl = (
        <CardList
            list={dataFacade?.data?.resp}
            gotToLink={goToCardLink}
            openEditModal={openModal}
            handleDelete={dataFacade.remove}
        />
    )

    return (
        <>
            <MainCommonView
                loading={dataFacade?.loading}
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
            />
            <Modal 
                isShowing={isShowing}
                hide={closeModal}
                width='90%'
                maxWidth='750px'
                title={defineModalTitle()}
                context={(
                    <CenterFrom
                        inputs={inputs}
                        handleChange={onChangeInputs}
                        handleSave={handleSave}
                    />
                )}
            />
        </>
    )
} 
export default Centers
