import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import useLoading from "../../../../../../utils/hooks/useLoading"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"
import CardsOverview from "../../../../../../components/common-courses/view-content/CardsOverview"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"
import BudgetService from "../../../../../../services/centers/courses/budgets/budgets.service"

const SuppliesData = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, suppliesId, balanceId} = useParams()

    const [data, setData] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        startLoading()
        const resp = await BudgetService.getExpensesConcepts(courseId, balanceId)
        if (resp) {
            setData(resp?.resp)
        }
        stopLoading()
    }
 
    const goToTotals = (id, item) => {
        if (item?.agroupation === 'totals') return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES_DATA_TOTALS(centerId, courseId, suppliesId, balanceId)
        return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES_DATA_AGROUPATIONS(centerId, courseId, suppliesId, balanceId, item?.etapa || item?.otherService)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'expenses' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_EXPENSES(centerId, courseId)} 
                text={msg({ id: 'expenses' })} />
        },
        {
            label: data?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES(centerId, courseId, data?.balanceTemplate)} 
                text={data?.templateName} /> 
        },
        {
            label: data?.conceptName,
        }
    ]

    const cardListTotals = {
        list: [
            {
                agroupation: 'totals',
                name: msg({ id: 'totals.anual' }).toUpperCase()
            }
        ],
        gotToLink: goToTotals
    }

    const cardListEtapaServices = {
        list: [
            ...(data?.etapas || []),
            ...(data?.otherServices || [])
        ],
        gotToLink: goToTotals
    }

    const sections = [
        {
            title: msg({ id: 'indicate.total.anual'}) + '*',
            cardsInfo: cardListTotals
        },
        ...(
            !data?.confirmation?.isConfirm ? [{
                title: msg({ id: 'total.distribution'}) + '*',
                cardsInfo: cardListEtapaServices
            }] : []
        )
    ]

    return (
        <CardsOverview
            loading={loading}
            breadcrumb={breadcrumb}
            sections={sections}
        />
    )
}

export default SuppliesData
