import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import MainCommonView from '../../../../components/common/MainCommonView'
import MaestrosDataTable from '../../../../components/common/dataTable/MaestrosDataTable'
import CollectiveAgreementForm from '../../../../components/maestros-labor/collectiveAgreements/CollectiveAgreementForm'
import FileData from '../../../../components/maestros-labor/collectiveAgreements/FileData'
import Modal from '../../../../components/modal/Modal'
import CollectiveAgreementsService from '../../../../services/collective-aggreements/collectiveAggreements.service'
import env from '../../../../utils/constants/env-vars'
import rowDelete from '../../../../utils/helpers/data-table/column-types/rowDelete'
import UPLOAD, { objectBodyAppendFormData } from '../../../../utils/helpers/uploadFiles'
import useFilter from '../../../../utils/hooks/get-list-scheme/useFilter'
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'
import usePreviewPdf from '../../../../utils/hooks/usePreviewPdf'
import swalAlert from '../../../../utils/helpers/swalAlert'
import EditButton from '../../../../components/common/buttons/EditButton'
import useSort from '../../../../utils/hooks/get-list-scheme/useSort'
import usePaginator from '../../../../utils/hooks/get-list-scheme/usePaginator'
import './collective-agreements.scss'

// TO DO: refactorizar este componente
const CollectiveAgreements = () => {

    const { formatMessage: msg } = useIntl()

    const { filter, updateFilter } = useFilter()
    const { sortOrder, onSort, sort } = useSort()
    const { firstRow, skip, limit, onPageChange } = usePaginator()

    const { inputs, updateInputs, onChangeInputs } = useInputs()
    const { isShowing, toggle } = useModal()
    const { onclickPreviewPdf, modalPdfView } = usePreviewPdf()
    const [ data, setData ] = useState({})

    useEffect(() => {
        getAllItems()
    }, [filter, limit, skip, sortOrder])

    const getAllItems = async () => {
        const resp = await CollectiveAgreementsService.getAll({filter, limit, skip, sort})
        if (resp) setData(resp)
    }

    const handleCreateOrUpdateAgreement = async (filesData) => {
        if (inputs?._id) {
            await updateCollectiveAgreement({ ...inputs })
        } else {
            await saveCollectiveAgreement({...inputs, files: filesData })
        }
        updateInputs({})
        toggle()
    }

    const saveCollectiveAgreement = async (body) => {
        const collectiveAgreement = await CollectiveAgreementsService.create({ 
            body: _.omit(body, 'files'),
            showSuccessAlert: false 
        })

        if (collectiveAgreement) {
            if (body?.files?.length > 0) {
                const agreementConFiles = await UPLOAD.multiFiles({ 
                    id: collectiveAgreement?.resp?._id,
                    files: body?.files.map(file => ({
                        file: file?.files,
                        year: file?.year instanceof Date ? file?.year.getFullYear() : file?.year,
                        description: file?.description ?? '' 
                    })),
                    uploadService: ({_id, body: body_}) => CollectiveAgreementsService.addFile({_id, body: body_, showSuccessAlert: false, catchErrors: false}), 
                    errorMsgTitle: msg({ id: 'swall.error.uploading.files' })
                })
                updateTableOnCreateAgreement(agreementConFiles)
            } else {
                updateTableOnCreateAgreement(collectiveAgreement)
            }
        }
    }

    const handleDeleteCollectiveAgreement = async (id) => {

        const confirm = await swalAlert.warning( 
            msg({id: "swal.title.delete"}),
            msg({id: "button.cancel"}),
            msg({id: "button.delete"})
        )

        if (confirm?.isConfirmed) {
            const resp = await CollectiveAgreementsService.remove(id)
            if (resp) {
                setData({...data, 
                    resp: [...data?.resp?.filter(agreement => agreement._id !== id)], 
                    total: data.total -=1 
                })
            }
        }
    }

    const updateCollectiveAgreement = async (body) => {
        const resp = await CollectiveAgreementsService.update({ 
            _id: body._id,
            body: _.omit(body, 'files')
        })
        if (resp) updateTableOnEditAgreement(resp?.resp)
    }

    const handleAddFile = async (fileWithData) => {
        const body = {
            file: fileWithData?.files,
            year: fileWithData?.year instanceof Date ? fileWithData?.year.getFullYear() : fileWithData?.year,
            description: fileWithData?.description ?? '' 
        }
        const resp = await CollectiveAgreementsService.addFile({
            _id: inputs?._id, 
            body: objectBodyAppendFormData(body)
        })
        if (resp) updateTableOnEditAgreement(resp?.resp)
        return resp
    }

    const handleEditFile = async (fileWithData) => {
        const body = {
            year: fileWithData?.year instanceof Date ? fileWithData?.year.getFullYear() : fileWithData?.year,
            description: fileWithData?.description ?? '' 
        }
        if (fileWithData?.files) body.file = fileWithData?.files
        const resp = await CollectiveAgreementsService.updateFile({
            _id: inputs?._id, 
            fileId: fileWithData?._id, 
            body: objectBodyAppendFormData(body)
        })
        if (resp) updateTableOnEditAgreement(resp?.resp)
        return resp

    }

    const handleDeleteFile = async ({_id, fileId}) => {
        const confirm = await swalAlert.warning( 
            msg({id: "swal.title.delete"}),
            msg({id: "button.cancel"}),
            msg({id: "button.delete"})
        )

        if (confirm?.isConfirmed) {
            const resp = await CollectiveAgreementsService.removeFile({ _id, fileId })

            if (resp) {
                updateTableOnEditAgreement(resp?.resp)
            }
        }
    }

    const updateTableOnEditAgreement = (agreement) => {
        setData({
            ...data,
            resp: data.resp.map((s) => {
                if (s._id === agreement?._id) return agreement 
                return s
            }),
        })
    }

    const updateTableOnCreateAgreement = (agreement) => {
        setData({...data, 
            resp: [...data.resp, agreement.resp ], 
            total: data.total +=1 })
    }

    const toggleModal = (item) => {
        toggle()
        updateInputs(item || {})
    }

    const buttonEdit = (rowData) => (<EditButton onClick={() => toggleModal(rowData)}/>)

    const getFileColumnBody = (rowData) => (
        <FileData 
            filesWithData={rowData?.files}
            onHandleClick={(fileInfo) => onclickPreviewPdf({
                name: fileInfo?.name,
                env: env.URL_DOWNLOAD_AGREEMENTS, 
                path: fileInfo?.path 
            })}
        />
    )

    const headerOptions = {
        title: msg({id: 'collectiveAgreements'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: {
            updateFilter,
            filter
        },
    }

    const exportService = async () => {
        const resp = await CollectiveAgreementsService.getAllItems({})
        let dataResp = []
        if (resp) {
            const filesToString = (files) => files?.map(file => file?.year + ' \t ' + file?.description || file?.name).join('\n\n\n')
            dataResp = (resp?.resp || []).map(r => ({
                ...r,
                files: filesToString(r?.files)
            }))
        }
        return {resp: dataResp}     
    }

    const importExportFilesOptions = {
        title: 'collectiveAgreements',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'file'}), dataKey: 'files' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService
    }

    const pagerOptions = {
        firstRow,
        limit,
        onPageChange,
        totalRecords: data?.total
    }

    const sortOptions = {
        sortField: 'name',
        sortOrder,
        onSort
    }


    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: data?.resp,
                editMode: 'row',
                ...sortOptions
            }}
            columns={([
                {
                    field: 'name',
                    sortable: true,
                    header: 'name',
                },
                {
                    field: "description",
                    header: 'description' 
                },
                {
                    field: 'name',
                    header: 'files',
                    body: getFileColumnBody
                },
                {...rowDelete({ handleDelete: handleDeleteCollectiveAgreement })},
                {
                    className: 'row-edit',
                    body: buttonEdit,
                    exportable: false
                }
            ])}
        />
    )

    return (
        <div className="collective-agreements">

            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="1100px"
                title={inputs?._id ? msg({ id: 'collectiveAgreement.edit'}) : msg({ id: 'collectiveAgreement.new'})}
                context={(
                    <CollectiveAgreementForm 
                        inputs={inputs} 
                        handleChangeInputs={onChangeInputs} 
                        handleSaveAgreement={handleCreateOrUpdateAgreement}
                        handleAddFile={handleAddFile}
                        handleEditFile={handleEditFile}
                        handleRemoveFile={handleDeleteFile}
                    />
                )}
            />
            {/** MODAL PREVIEW PDF */}
            { modalPdfView }

        </div>
    )

}

export default CollectiveAgreements