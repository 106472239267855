import React from "react"
import { useIntl } from 'react-intl'
import { Checkbox } from "primereact/checkbox"

const CheckboxCom = ({
    id,
    name, 
    onChange, 
    checked, 
    disabled, 
    tooltip
}) => {
    const {formatMessage: msg} = useIntl()


    return ( 
        <Checkbox 
            id={id}
            name={name}
            onChange={(e) => onChange({ target: { value: e.checked, name: e.target.name } })}
            checked={checked}
            disabled={disabled}
            tooltip={tooltip ? msg({ id: tooltip}) : undefined}
        />
    )
}

export default CheckboxCom