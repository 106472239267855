import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledCheckbox from '../../common/form-components/labeled-checkbox/LabeledCheckbox'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import './formSchoolServices.scss'
import LabeledNumber from '../../common/form-components/labeled-inputs/LabeledNumber'

const FormSchoolService = ({ inputs, handleChange, handleSave }) => {

    const validateForm = validators.hasValue(inputs?.name) && validators.hasValue(inputs?.numMonths)

    return (
        <div className='form-service'>
            <div className='modal-content-wrapper'>  
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />

                    <LabeledNumber 
                        idLabel='num.months'
                        required
                        name='numMonths'
                        value={inputs?.numMonths}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper checkbox'>
                    <LabeledCheckbox 
                        idLabel='isByEtapa'
                        name='isByEtapa'
                        onChange={(e) => handleChange(e)}
                        checked={inputs?.isByEtapa}
                        tooltip="info.isByEtapa"
                    />      
                </div>
            </div>       
            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={() => handleSave(inputs?._id)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormSchoolService