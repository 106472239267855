import swal from 'sweetalert2'
import '../../assets/styles/swal-alert.scss'

const swalWithCustomClass = swal.mixin({
    customClass: {
        container: 'swal-overlay',
        popup: 'swal-modal',
        title: 'swal-text',
        footer: 'swal-footer',
        icon: 'swal2-icon ',
        actions: 'swal2-actions',
    },
})

const swalAlert = {
    error: (text) => {
        return swalWithCustomClass.fire({
            title: '',
            text,
            icon: 'error',
        })
    },
    errorReach: (html) => {
        return swalWithCustomClass.fire({
            title: '',
            html,
            icon: 'error',
        })
    },
    succes: (text) => {
        return swalWithCustomClass.fire({
            title: '',
            text,
            icon: 'success',
        })
    },
    succesWithTimer: (text) => {
        return swalWithCustomClass.fire({
            title: '',
            text,
            icon: 'success',
            showConfirmButton: false,
            timer: 1000,
        })
    },
    info: (text) => {
        return swalWithCustomClass.fire({
            title: '',
            text,
            icon: 'info',
        })
    },
    warning: async (text, cancel, confirm) => {
        return swalWithCustomClass.fire({
            title: '',
            text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: confirm,
            cancelButtonText: cancel,
            reverseButtons: true,
        })
    },
    warningReach: async (html) => {
        return swalWithCustomClass.fire({
            title: '',
            html,
            icon: 'warning'
        })
    },
    loadinng: (text) => {
        return swalWithCustomClass.fire({
            text,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            allowEnterKey: false,
            didOpen: () => {
                swal.showLoading()
            },
        })
    },
}

export default swalAlert
