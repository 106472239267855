import React from "react"
import { ControlLabel } from "../label/Label"
import InputNumberComp from "../inputs/InputNumberComp"
import "./labeledInput.scss"

const LabeledNumber = ({
    classNameBox,
    idLabel, 
    required,
    onChange,
    ...propsInput
}) => {

    return (
        <div className={`flex-container ${classNameBox}`}>
            <ControlLabel id={idLabel} required={required} />
            <div className="control-input">
                <InputNumberComp onChange={onChange} {...propsInput}/>
            </div>
        </div>
    )

}

export default LabeledNumber
