import React, { useEffect, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { LayoutCenterContext } from "../../../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../../../utils/hooks/useLoading"
import BudgetService from "../../../../../../services/centers/courses/budgets/budgets.service"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"
import Dashboard from "../../../../../../components/common-courses/view-content/Dashboard"
import BugetTable from "../../../../../../components/common/dataTable/BugetTable"
import editNumberRowColumn from "../../../../../../components/common/dataTable/editors/editNumberRowColumn"

const StaffExpensesEtapaTable = () => {

    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, etapaId} = useParams()

    const [dataTable, setDataTable] = useState([])
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getStaffExpensesEtapa()
    }, [])

    const getStaffExpensesEtapa = async () => {
        startLoading()
        const resp = await BudgetService.getStaffExpensesEtapa(courseId, etapaId)
        if (resp) {
            setDataTable(resp)
        }
        stopLoading()
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await BudgetService.confirmStaffExpensesEtapa(courseId, etapaId, dataTable)
                : await BudgetService.saveStaffExpensesEtapa(courseId, etapaId, dataTable)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setDataTable(resp?.resp)
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({id: 'expenses'}),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET_EXPENSES(centerId, courseId)} 
                text={msg({ id: 'expenses' })} /> 
        },
        {
            label: msg({id: 'staff.expenses'}),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUDGET_EXPENSES_STAFFS(centerId, courseId)} 
                text={msg({ id: 'staff.expenses' })} /> 
        },
        {
            label: dataTable?.path?.[0]?.name
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleSave,
        handleConfirm
    }

    const table = (
        <BugetTable
            tableOptions={{
                loading,
                value: dataTable?.resp
            }}
            columns={[
                { field: 'staff', header: 'first.last.name', style: { minWidth: '200px' } },
                { field: 'category', header: 'category', style: { minWidth: '200px' }},
                { field: 'totalHours', header: 'totalHours', headerClassName: 'p-align-end', className: 'p-datatype-number', style: { minWidth: '200px' } },
                { field: 'numStudents', header: 'line.distribution.concerted.payment.hours', headerClassName: 'p-align-end', className: 'p-datatype-number', style: { minWidth: '200px' } },
                { field: 'numStudents', header: 'school.payment.hours', headerClassName: 'p-align-end', className: 'p-datatype-number', style: { minWidth: '200px' } },
                { field: 'numStudents', header: 'complet.journey.hours', headerClassName: 'p-align-end', className: 'p-datatype-number', style: { minWidth: '200px' } },
                { field: 'triennial',
                    header: 'triennis',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    ...editNumberRowColumn({})
                },
                { field: 'schoolStadiums',
                    header: 'school.stadiums',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    style: { minWidth: '200px' },
                    ...editNumberRowColumn({})
                },
                { field: 'delegatedPaymentStadium',
                    header: 'delegated.payment.stadiums',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    style: { minWidth: '200px' },
                    ...editNumberRowColumn({})
                },
                { field: 'supplementsBonusesProportionalToWorkday',
                    header: 'supplementsBonusesProportionalToWorkday',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    style: { minWidth: '200px' },
                    ...editNumberRowColumn({})
                },
                { field: 'supplementsBonusesNotProportionalToWorkday',
                    header: 'supplementsBonusesNotProportionalToWorkday',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    style: { minWidth: '200px' },
                    ...editNumberRowColumn({})
                },
                { field: 'schoolManagementSupplements',
                    header: 'schoolManagementSupplements',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    style: { minWidth: '200px' },
                    ...editNumberRowColumn({})
                },
                { field: 'delegatedPaymentManagementSupplements',
                    header: 'delegatedPaymentManagementSupplements',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    style: { minWidth: '200px' },
                    ...editNumberRowColumn({})
                },
                { field: 'payments',
                    header: 'payments',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow',
                    style: { minWidth: '200px' },
                    ...editNumberRowColumn({})
                },
                { field: 'salaryIncrease', header: 'salaryIncrease', headerClassName: 'p-align-end', className: 'p-datatype-number', style: { minWidth: '200px' } },
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 2, footerStyle: { textAlign: 'left' } },
                { footer: 'sum', field: 'totalHours', className: 'p-datatype-number'},
                { footer: 'sum', field: 'totalIncome', className: 'p-datatype-number'},
                { footer: 'sum', field: 'totalIncome', className: 'p-datatype-number'},
                { footer: 'sum', field: 'totalIncome', className: 'p-datatype-number'},
                { footer: 'sum', field: 'triennial', className: 'p-datatype-number'},
                { footer: 'sum', field: 'schoolStadiums', className: 'p-datatype-number'},
                { footer: 'sum', field: 'delegatedPaymentStadium', className: 'p-datatype-number'},
                { footer: 'sum', field: 'supplementsBonusesProportionalToWorkday', className: 'p-datatype-number'},
                { footer: 'sum', field: 'supplementsBonusesNotProportionalToWorkday', className: 'p-datatype-number'},
                { footer: 'sum', field: 'schoolManagementSupplements', className: 'p-datatype-number'},
                { footer: 'sum', field: 'delegatedPaymentManagementSupplements', className: 'p-datatype-number'},
                { footer: 'sum', field: 'payments', className: 'p-datatype-number'}
            ]}
        />
    )

    return (
        <Dashboard
            content={table} 
            headerOptions={headerOptions}
        />
    )
}

export default StaffExpensesEtapaTable
