import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CollectiveAgreementsService from '../../../services/collective-aggreements/collectiveAggreements.service'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledNumber from '../../common/form-components/labeled-inputs/LabeledNumber'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import './formStadium.scss'
import auxFunction from '../../../utils/helpers/auxFun'


const FormStadium = ({ inputs, handleChange, handleSave }) => {

    const [ collectiveAgreements, setCollectiveAgreements ] = useState([])

    const schoolYears = useSelector(state => state.schoolYears)?.resp

    useEffect(() => {
        getAllCollectiveAgreements()
    }, [])

    const getAllCollectiveAgreements = async () => {
        const resp = await CollectiveAgreementsService.getAll({})
        setCollectiveAgreements(resp?.resp)
    }

    const validateForm = validators.isEntityValues([
        inputs?.schoolyear, inputs?.agreement, inputs?.unitary]) 

    return (
        <div className="categories-form ">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                   
                    <LabeledDropdown 
                        idLabel="schoolYear" 
                        name="schoolyear"
                        required
                        filter
                        options={schoolYears}
                        value={auxFunction.returnObjectId(inputs?.schoolyear)}
                        optionValue="_id"
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledDropdown 
                        idLabel='agreement'
                        required
                        name='agreement'
                        value={inputs?.agreement}
                        optionLabel='name'
                        optionValue='_id'
                        options={collectiveAgreements || []}
                        onChange={(e) => handleChange(e)}
                    />
                </div> 
                <LabeledNumber 
                    idLabel='unitary'
                    required
                    name='unitary'
                    value={inputs?.unitary}
                    onChange={(e) => handleChange(e)}
                    maxFractionDigits={2}
                />
                <LabeledTextArea 
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={(e) => handleChange(e)}
                    numRows={3}
                    numColumns={15}
                />
            </div>

            <div className="modal-btn-container actions-wrapper">
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormStadium
