import React from "react"
import SchoolYearMasters from "../../../views/maestros/school-courses/school-year-masters/SchoolYearMasters"
import schoolYear from "./schoolYear.pathname"

export const schoolYearRoutes = [
    {
        path: schoolYear.MASTERS(':schoolYearId'),
        name: 'school year masters',
        element: <SchoolYearMasters />,
    }
]
