import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import MainCommonView from "../../../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import Modal from '../../../../../../components/modal/Modal'
import useModal from '../../../../../../utils/hooks/useModal'
import useInputs from '../../../../../../utils/hooks/useInputs'
import SubsidyQuantificationForm from '../../../../../../components/maestros-center/subsidy-quantification/SubsidyQuantificationForm'
import SubsidyQuantificationService from '../../../../../../services/center-subsidy-quantification/subsidyQuantification.service'
import MaestrosDataTable from '../../../../../../components/common/dataTable/MaestrosDataTable'
import editTextRowColumn from '../../../../../../components/common/dataTable/editors/editTextRowColumn'

const SubsidyQuantification = () => {

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: SubsidyQuantificationService.getAll, 
        createAction: SubsidyQuantificationService.create,
        removeAction: SubsidyQuantificationService.remove, 
        editAction: SubsidyQuantificationService.update
    })

    const handleCreate = async () => {
        await dataFacade.create(inputs)
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({ newData }) => {
        const { _id } = newData
        const body = {
            name: newData?.name,
            description: newData?.description
        }
        dataFacade.edit({_id, body})
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const headerOptions = {
        title: msg({id: 'subsidy.criteria'}),
        searchOptions: dataFacade?.searchOptions
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }
    
    const dataTable = (
        <MaestrosDataTable
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={[
                { field: "name",
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn,
                },
                { field: "description",
                    header: 'description',
                    ...editTextRowColumn,
                }
            ]}
        />
    )

    return (
        <div className='subsidy-quantification'>
            <MainCommonView 
                headerOptions={headerOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="500px"
                title={<FormattedMessage id="subsidy.criteria.new" />}
                context={(
                    <SubsidyQuantificationForm
                        inputs={inputs} 
                        handleSubmit={handleCreate} 
                        handleChange={onChangeInputs} 
                    />
                )}
            />
            
        </div>
    )
}

export default SubsidyQuantification
