import React from 'react'
import { FormattedMessage } from "react-intl"

export const COST_CENTER_GROUPING =[
    {name: <FormattedMessage id='services' />, value: 'services'},
    {name: <FormattedMessage id='stages' />, value: 'stages'},
    {name: <FormattedMessage id='stages.services' />, value: 'stages and services'}
]

export const IMPUTATION_GROUPING =[
    {name: <FormattedMessage id='center.and.foundation' />, value: 'all'},
    {name: <FormattedMessage id='center' />, value: 'center'},
    {name: <FormattedMessage id='foundation' />, value: 'foundation'}
]