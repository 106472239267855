import React, { useContext, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { LayoutCenterContext } from "../../../../components/common-courses/context/LayoutCenterContex"
import useInputs from "../../../../utils/hooks/useInputs"
import GeneralStructure from "../../../../components/center-courses/structure/GeneralStructure"
import StageInformation from "../../../../components/center-courses/structure/StageInformation"
import CoursesService from "../../../../services/centers/courses/courses.service"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import { validators } from "../../../../utils/helpers/validators"
import SubstageSelection from "../../../../components/center-courses/structure/SubstageSelection"
import SubserviceSelection from "../../../../components/center-courses/structure/SubserviceSelection"
import "./structure.scss"
import auxFunction from "../../../../utils/helpers/auxFun"

const Structure = () => {
    const { centerId } = useParams()
    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()
    const { courses, setFlagCreateCourse, setCourseSeletedDropdown } = useContext(LayoutCenterContext) 
    /**
     * To DO: 
     * Implementar en caso de que se quiero obtner los datos de la estrucutra del curso anterior 
     * Implementar en caso de que se quiera copiar todos los datos del curso anterior antes de guardar
     */

    const { inputs, onChangeInputs, updateInputs } = useInputs()
    const [currentIndex, setCurrentIndex] = useState(0)

    useEffect(() => {
        if (courses.length > 0 && inputs?.schoolYear?._id) getLastCourse()
    }, [])

    const getLastCourse = async () => {
        const resp = await CoursesService.getLastCourseCreated(centerId)
        if (resp) {
            updateInputs(resp?.resp) // TO DO: revisar que es lo que devuelve
        }
    }

    const onChangeServiceTypes = (subservices, service) => {
        const updatedServices = inputs?.educativeOffer?.services?.map(s =>
            s?._id === service?._id
                ? { ...s, ...subservices}
                : s)
        
        updateInputs({
            ...inputs,
            educativeOffer: {
                ...inputs.educativeOffer,
                services: updatedServices
            }
        })
    }

    const onChangeSubstages = (e, stage) => {
        if (e?.target?.name === 'activatedBatxibac') {
            const updatedStages = inputs?.educativeOffer?.etapas?.map(s =>
                s?.etapa === stage?.etapa
                    ? { ...s, activatedBatxibac: e.target.value }
                    : s)
            updateInputs({
                ...inputs,
                educativeOffer: {
                    ...inputs.educativeOffer,
                    etapas: updatedStages
                }
            })
            return
        }
        const updatedStages = inputs.educativeOffer.etapas.map(s =>
            s?.etapa === stage?.etapa
                ? { ...s, selectedSubEtapas: e.target.value }
                : s)
        updateInputs({
            ...inputs,
            educativeOffer: {
                ...inputs.educativeOffer,
                etapas: updatedStages
            }
        })
    }

    const handleNext = () => {
        setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, viewForms.length - 1))
    }

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    }

    const handleSave = async () => {
        setTimeout(async () => {
            const resp = await CoursesService.create(parseInputs(inputs))
            if (resp) {
                const courseId = resp?.resp?._id
                setFlagCreateCourse(true)
                setCourseSeletedDropdown(courseId)
                navigate(CENTER_COURSE_DETAILS.STATISTICS(centerId, courseId))
            }
        }, 0)
    }

    const parseInputs = (data) => {
        const services = data?.educativeOffer?.services?.length > 0 
            ? data?.educativeOffer?.services?.map(service => {
                return {
                    service: service?._id,
                    subservices: service?.subservices?.length > 0 
                        ? service?.subservices.map(x => 
                            ({ subservice: x?.subservice?._id, isSubventioned: x?.isSubventioned })) 
                        : []
                }
            }) : []
        const etapas = data?.educativeOffer?.etapas?.length > 0 
            ? data?.educativeOffer?.etapas.map(stage => {
                return {
                    subsidizedSubetapas: stage?.isSubventioned 
                    && stage?.subsidizedSubetapas?.length > 0 
                        ? stage?.subsidizedSubetapas.map(x => x?._id)
                        : [],
                    activatedBatxibac: stage?.activatedBatxibac,
                    activatedConcerted: stage?.activatedConcerted,
                    etapa: stage?.etapa,
                    subetapas: stage?.selectedSubEtapas?.length > 0 
                        ? stage?.selectedSubEtapas?.map(x => x?._id) 
                        : [],
                    numberLines: stage?.numberLines,
                }
            
            }) : []
        return {
            schoolYear: auxFunction.returnObjectId(data?.schoolYear?._id),
            typology: auxFunction.returnObjectId(data?.typology?._id),
            center: centerId,
            educativeOffer: {
                services,
                etapas
            }
        }
    }

    const propsButtons = {
        handleNext,
        handlePrevious,
        handleSave
    }

    const selectedServices = useMemo(() => {
        return inputs?.educativeOffer?.services?.filter(
            service => service?.serviceTypes?.length > 0
        )?.map(service => (
            <SubserviceSelection 
                key={service._id}
                title={msg({ id: 'service.type.choose' })}
                data={service}
                onChangeInputs={(e) => onChangeServiceTypes(e, service)}
                propsButtons={propsButtons}
            />
        )) || []
    }, [inputs])

    const selectedStages = useMemo(() => {
        return (inputs?.educativeOffer?.etapas?.filter(stage =>
            stage?.numberLines > 0 && stage?.subetapas?.length > 0 
            && stage?.yearFrom > 15).map(stage => (
            <SubstageSelection 
                key={stage.etapa}
                title={msg({ id: 'stage.type.choose' })}
                data={stage}
                optionLabel='label'
                onChangeInputs={(e) => onChangeSubstages(e, stage)}
                propsButtons={propsButtons}
            />
        ))) || []
    }, [inputs])

    const viewForms = useMemo(() => {
        const forms = [
            <GeneralStructure
                inputs={inputs}
                onChangeInputs={onChangeInputs}
                updateInputs={updateInputs}
                propsButtons={propsButtons}
            />,
            <StageInformation
                inputs={inputs}
                updateInputs={updateInputs}
                propsButtons={propsButtons}
            />,
            ...selectedServices,
            ...selectedStages
        ]
        return forms
    }, [inputs])

    const isLastScreen = currentIndex === viewForms.length - 1
    const isFirstScreen = currentIndex === 0

    const etapas = inputs?.educativeOffer?.etapas
    const services = inputs?.educativeOffer?.services

    const disabledSaveButton = !(
        validators.isEntityValues([inputs?.schoolYear, inputs?.typology])
        && etapas?.some(e => e?.numberLines > 0)
        && (
            (services
                && services?.every(service => service?.subservices?.length > 0)
                && services.every(service => service?.personalType))
            || !services
        )
    )

    return (
        <div className="section-structure">
            {
                // Hay un StructureLayout que se encarga de renderizar el contenido de cada pantalla 
                // y manejo de los botones
                React.cloneElement(
                    viewForms[currentIndex], 
                    { isLastScreen, isFirstScreen, disabledSaveButton }
                )
            }
        </div>
    )
}

export default Structure
