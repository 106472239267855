import React from 'react'
import { useIntl } from 'react-intl'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import FoundationTeamsService from '../../../../services/foundation-teams/foundationTeams.service'
import useModal from '../../../../utils/hooks/useModal'
import Modal from '../../../../components/modal/Modal'
import FoundationTeamForm from '../../../../components/maestros-foundation/foundation-teams/FoundationTeamForm'
import CardList from '../../../../components/card-list/CardList'
import useInputs from '../../../../utils/hooks/useInputs'
import { maestrosFoundation } from '../../../../routes/maestros/maestros-foundation/maestrosFoundation.pathname'


const FoundationTeams = () => {
    const {formatMessage: msg} = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: FoundationTeamsService.getAll,
        createAction: FoundationTeamsService.create,
        removeAction: FoundationTeamsService.remove, 
        editAction: FoundationTeamsService.update
    })

    const goToCardLink = (id) => maestrosFoundation.FOUNDATION_TEAM(id)

    const handleCreate = () => dataFacade.create(inputs)

    const handleUpdate = (id) => {
        const data = {
            name: inputs?.name,
            description: inputs?.description
        }
        
        return dataFacade.edit({ _id: id, body: data })
    }

    const handleSave = async (_id) => {
        const resp = _id ? await handleUpdate(_id) : await handleCreate()
        closeModal()
        return resp
    }

    const openEditModal = (data) => {
        updateInputs(data)
        toggle()
    }

    const closeModal = () => {
        updateInputs({})
        toggle()
    }

    const headerOptions = {
        title: msg({id: 'foundation.teams'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataControl = (
        <CardList 
            list={dataFacade?.data?.resp} 
            gotToLink={goToCardLink}
            openEditModal={openEditModal}
            handleDelete={dataFacade.remove}
        />
    )

    const defineModalTitle = () => msg({ id: `foundation.teams.${inputs._id ? 'edit' : 'new'}`})

    return (
        <div className='foundation-teams-container'>
            <MainCommonView 
                headerOptions={headerOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
                showLegend
            />
            
            <Modal
                isShowing={isShowing}
                hide={closeModal}
                width='90%'
                maxWidth='450px'
                title={defineModalTitle()}
                context={(
                    <FoundationTeamForm
                        inputs={inputs}
                        handleChange={onChangeInputs}
                        handleSave={handleSave}
                    />
                )}
            />
        </div>
    )
}
export default FoundationTeams