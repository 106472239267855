import React from "react"
import StrucutreLayout from "./StrucutreLayout"
import LabeledMultiSelect from "../../common/form-components/labeled-dropdown/LabeledMultiSelect"
import { validators } from "../../../utils/helpers/validators"
import LabeledCheckbox from "../../common/form-components/labeled-checkbox/LabeledCheckbox"

const SubstageSelection = ({
    title,
    data,
    optionLabel,
    onChangeInputs, 
    propsButtons, 
    isLastScreen,
    isFirstScreen,
    disabledSaveButton
}) => {

    const validate = validators.isEntityValues([data?.selectedSubEtapas])

    return (
        <StrucutreLayout 
            title={title}
            {...propsButtons}
            isLastScreen={isLastScreen}
            isFirstScreen={isFirstScreen}
            disabledNextButton={!validate}
            disabledSaveButton={disabledSaveButton}
            context={(
                <div className="bg-structure-box"> 
                    <div className="inline-items">
                        <LabeledMultiSelect
                            idLabel={data?.name}
                            translate={false}
                            required
                            onChange={onChangeInputs}
                            options={data?.serviceTypes ?? data?.subetapas}
                            optionLabel={optionLabel ?? 'name'}
                            value={data?.subservices ?? data?.selectedSubEtapas}
                        />
                        { data?.hasBatxibac && (
                            <LabeledCheckbox idLabel='stage.baxiback'
                                name='activatedBatxibac'
                                onChange={onChangeInputs}
                                checked={data.activatedBatxibac}/>
                        ) }
                    </div>
                    
                    
                </div>
                
            )}
        />
    )
}

export default SubstageSelection