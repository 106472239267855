import { endpointsCollectiveAgreements } from "./endpoints"
import { POST, GET, DELETE, PATCH } from '../common/commonRequest.service'

const CollectiveAgreementsService = {
    create: async ({ body, showSuccessAlert}) => POST(endpointsCollectiveAgreements.GENERIC, body, null, showSuccessAlert),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsCollectiveAgreements.LOAD({filter, limit, skip, sort})),
    getById: async (id) => GET(endpointsCollectiveAgreements.BY_ID(id)),
    update: async ({_id, body, showSuccessAlert}) => PATCH(endpointsCollectiveAgreements.BY_ID(_id), body, null, showSuccessAlert),
    remove: async (id) => DELETE(endpointsCollectiveAgreements.BY_ID(id)),
    addFile: async ({_id, body, showSuccessAlert, catchErrors}) => POST(endpointsCollectiveAgreements.FILE_BY_AGREEMENT_ID(_id), body, null, showSuccessAlert, catchErrors),
    updateFile: async ({_id, fileId, body, showSuccessAlert, catchErrors}) => PATCH(endpointsCollectiveAgreements.FILE_BY_AGREEMENT_ID_FILE_ID(_id, fileId), body, null, showSuccessAlert, catchErrors),
    removeFile: async ({_id, fileId}) => DELETE(endpointsCollectiveAgreements.FILE_BY_AGREEMENT_ID_FILE_ID(_id, fileId))
}

export default CollectiveAgreementsService