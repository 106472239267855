import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import MainCommonView from "../../../../components/common/MainCommonView"
import FormPas from '../../../../components/maestros-labor/pas/FormPas'
import Modal from "../../../../components/modal/Modal"
import PasService from "../../../../services/pas/pas.service"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from "../../../../utils/hooks/useInputs"
import useModal from "../../../../utils/hooks/useModal"
import CardList from "../../../../components/card-list/CardList"
import { maestrosLaboral } from "../../../../routes/maestros/maestros-laboral/maestrosLaboral.pathname"
import swalAlert from "../../../../utils/helpers/swalAlert"

const PasId = () => {

    const { pasId } = useParams()

    const {formatMessage: msg} = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const [pas, setPas] = useState()

    const dataFacade = useHandlerBasicActions({
        getAll: ({filter, limit, skip}) => PasService.getById({ pasId, filter, limit, skip }),
        createAction: PasService.create,
        removeAction: PasService.remove,
        editAction: PasService.update
    })

    useEffect(() => {
        getStagePas()
    }, [pasId])

    const getStagePas = async () => {
        const resp = await PasService.getById({pasId})
        if (resp) {
            setPas(resp?.resp)
        }
    }

    const handleCreatePas = async () => {
        const resp = await PasService.create({parent: pasId, ...inputs})
        if (resp) {
            setPas({...pas, childrenPas: [...pas?.childrenPas, resp?.resp]})
            updateInputs({})
            toggle()
        }
    }

    const handleUpdatePas = async (id) => {
        const resp = await PasService.update({_id: id, body: inputs})
        if (resp) {
            setPas({
                ...pas,
                childrenPas: pas?.childrenPas.map(p => p?._id === id ? resp?.resp : p)
            })
        } 
    }

    const handleDeletePas = async (id) => {
        const confirm = await swalAlert.warning(
            msg({id: "swal.title.delete"}),
            msg({id: "button.cancel"}),
            msg({id: "button.delete"})
        )

        if (confirm?.isConfirmed) {
            const resp = await PasService.remove(id)
            if (resp) {
                setPas({
                    ...pas,
                    childrenPas: pas?.childrenPas.filter(p => p?._id !== id)
                })
            }
        }
    }

    const handleSave = async (_id) => {
        const resp = _id ? await handleUpdatePas(_id) : await handleCreatePas()
        toggleModal()
        return resp
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const openEditModal = (p) => {
        updateInputs(p)
        toggle()
    }

    const headerOptions = {
        title: msg({id: 'pas'}),
        titleSuffix: `- ${pas?.pas?.name}`,
        handlerHeaderActions: {
            firstHandlerAction: toggle,   
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'linked.categories',
        titleSuffix: `- ${pas?.pas?.name}`,
        columns: [{ title: msg({id: 'name'}), dataKey: 'name' }],
        exportService: () => PasService.getAll({pasId})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const goToLink = (id) => maestrosLaboral.PAS_ID + id

    const dataControl = (
        <CardList 
            list={pas?.childrenPas}
            handleDelete={handleDeletePas}
            openEditModal={openEditModal}
            gotToLink={goToLink}
        />
    )

    return (
        <>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
                showLegend
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width='90%'
                maxWidth='450px'
                title={<FormattedMessage id="pas.new" />}
                context={(
                    <FormPas
                        inputs={inputs} 
                        handleSubmit={handleSave}
                        handleChange={onChangeInputs}
                    />
                )}
            />
        </>
    )

}

export default PasId