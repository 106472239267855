import { useState, useCallback, useEffect } from 'react'
import _ from 'lodash'

// columnsFooter - array of { footer, field }
const useSums = (values, columnsFooter) => {
    const [sums, setSums] = useState({})

    const calculateSums = useCallback((data, columnsF) => {
        const newSums = {}

        columnsF.forEach(column => {
            if (column.footer === "sum") {
                // Fix for nested attribs
                newSums[column.field] = data?.reduce((total, item) => total + (_.get(item, column.field) || 0), 0)
            }
        })
        return newSums
    }, [])

    useEffect(() => {
        updateSums()
    }, [columnsFooter, calculateSums])

    const updateSums = () => {
        setSums(calculateSums(values, columnsFooter))
    }

    return {
        sums,
        updateSums
    }
}

export default useSums
