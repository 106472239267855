import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsSchoolYear } from "./endpoints"

const SchoolYearService = {
    createSchoolYear: async (body) => POST(endpointsSchoolYear.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsSchoolYear.LOAD({filter, limit, skip, sort})),
    getById: async (id) => GET(endpointsSchoolYear.BY_ID(id)),
    updateSchoolYear: async ({ _id, body }) => PATCH(endpointsSchoolYear.BY_SCHOOL_YEAR(_id), body),
    removeSchoolYear: async (schoolYearId) => DELETE(endpointsSchoolYear.BY_SCHOOL_YEAR(schoolYearId)),
    updateSchoolYearMaster: async ({ schoolYearId, master }) => POST(endpointsSchoolYear.BY_SCHOOL_YEAR_AND_MASTER(schoolYearId, master)),
}

export default SchoolYearService 