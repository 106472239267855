import { Column } from "primereact/column"
import { ColumnGroup } from "primereact/columngroup"
import { DataTable } from "primereact/datatable"
import { Row } from "primereact/row"
import React, { useEffect, useMemo } from "react"
import { useIntl } from "react-intl"
import { DataTableFun } from "../../../utils/helpers/dataTableFun"
import "./bugetDataTable.scss"
import useSums from "./hooks/useSum"

const BugetTable = ({
    loading=false,
    tableOptions={},
    columns=[],
    columnsFooter=[],
}) => {

    const { formatMessage: msg } = useIntl()

    useEffect(() => {
        // BEGIN: DataTable Responsive Pattern
        window.addEventListener("resize", DataTableFun.responsiveResize)
        return () => {
            window.removeEventListener("resize", DataTableFun.responsiveResize)
        }
        // END: DataTable Responsive Pattern
    }, [])

    const { sums, updateSums } = useSums(tableOptions?.value, columnsFooter)

    const onCellEditComplete = (e) => {
        const { rowData, newValue, field, originalEvent: event } = e

        // Workaround de primereact bug
        const newRowData = e.newRowData
        const fieldParts = field?.split('.')
        const newCorrectValue = fieldParts?.length === 2 ? newRowData?.[fieldParts?.[0]]?.[fieldParts?.[1]] : newRowData?.[fieldParts?.[0]]

        if (newValue !== null && newValue !== undefined && !Number.isNaN(newValue)) {
            rowData[field] = newCorrectValue
            updateSums()
        }
        else event.preventDefault()
    }

    const footerStyleAlert = (min, max, total) => {
        // TODO: don't hardcode, use css classes
        if (min && max) {
            if (max && total > max) return { backgroundColor: '#F5E2E2' }
            if (min && total < min) return { backgroundColor: '#F5E2E2' }
            return { backgroundColor: '#A5FFE7' }
        }
        return null
    }
    
    const footerGroup = useMemo(() => (
        <ColumnGroup>
            <Row>
                {columnsFooter?.map((column) => (
                    column?.footer === 'sum' ? (
                        <Column
                            className={column.className || undefined}
                            key={'footer-' + column.field}
                            {...column}
                            footer={sums[column.field]}
                            footerStyle={footerStyleAlert(column?.min, column?.max, sums[column.field])}
                        />
                    ) : (
                        <Column key={'footer-' + column.field} className={column.className || undefined} {...column} />
                    )
                ))}
            </Row>
        </ColumnGroup>
    ), [sums, columnsFooter])

    return (
        <DataTable
            loading={loading}
            className="buget-data-table"
            scrollable={tableOptions?.scrollable || true}
            scrollHeight={DataTableFun.responsiveHeight()}
            emptyMessage={msg({ id: 'placeholder.not.data' })}
            editMode="cell"
            {...tableOptions}
            {...(columnsFooter.length > 0 && { footerColumnGroup: footerGroup })}
        >
            { columns?.map((column) => (
                <Column 
                    key={column?.field || Math.random()} 
                    {...column}
                    className={column.className || undefined}
                    header={column?.noTraslateHeader ? column?.header : msg({id: column?.header})}
                    onCellEditComplete={onCellEditComplete}
                />
            ))}
        </DataTable>
    )
}

export default BugetTable