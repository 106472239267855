import React from "react"
import { isNumber, isObject } from 'lodash'
import CalendarComp from "../../../../components/common/calendar/CalendarComp"
import { onCellEditComplete } from "../common"
import auxFunction from "../../auxFun"

const editCalendarRowColumn = ({ 
    sortable = false,
    dateFormat,
    view, 
    minDate,
    maxDate 
}) => {

    const calendarEditor = (options) => {
        // TODO: adaptar para distintos tipos de parse, no solo year
        return (
            <CalendarComp 
                value={parseYear(options?.value)}
                onChange={(e) => options.editorCallback(e.value)}
                view={view}
                dateFormat={dateFormat}
                minDate={parseYear(minDate)}
                maxDate={parseYear(maxDate)}
                showIcon
                required
            />
        )
    }


    const parseYear = (value) => {
        if (isNumber(value)) {
            return auxFunction.convertStringToDate(value)
        }
        if (isObject(value)) {
            return new Date(value?.getFullYear().toString(), 0, 1)
        }
        return value ? new Date(value, 0, 1) : ''
    }

    return {
        sortable,
        editor: (options) => calendarEditor(options),
        onCellEditComplete, 
        // TODO: give key with sentido
        key: Math.random()
    }
}

export default editCalendarRowColumn