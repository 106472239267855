import React, { useEffect, useState, useContext } from "react"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import { LayoutCenterContext } from "../../../../components/common-courses/context/LayoutCenterContex"
import Dashboard from "../../../../components/common-courses/view-content/Dashboard"
import StatisticsService from "../../../../services/centers/courses/statistics/statistics.service"
import BreadcrumbLink from "../../../../components/common/breadcrumb/BreadcrumbLink"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import useTeamNavigation from "../../../../components/center-courses/statistics/hooks/useTeamNavigation"
import StaffTable from "../../../../components/common-courses/staff/StaffTable"
import EditorComp from "../../../../components/common/form-components/inputs/EditorComp"
import editNumberRowColumn from "../../../../components/common/dataTable/editors/editNumberRowColumn"
import editCalendarRowColumn from "../../../../components/common/dataTable/editors/editCalendarRowColumn"
import { parseDateYearStr } from "../../../../utils/helpers/date"
import useLoading from "../../../../utils/hooks/useLoading"
import "./statisticalData.scss"
import swalAlert from "../../../../utils/helpers/swalAlert"

const StatisticalData = () => {
    
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, agroupationId, teamId } = useParams()

    const { handleNext, handlePrevious } = useTeamNavigation()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const [ data, setData ] = useState()

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getTeamData()
    }, [centerId, courseId, agroupationId, teamId])

    const getTeamData = async () => {
        if (!courseId || !agroupationId || !teamId) return
        startLoading()
        const resp = (await StatisticsService.getTeamByCourseId(courseId, teamId))?.resp
        if (resp) {
            setData(resp)
        } 
        stopLoading()  
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        // WARNING: An extra render cycle is necessary to consolidate data-
        setTimeout(async () => {
            const hoursLimit = data?.team?.category?.hoursLimit
            const totalHours = data?.team?.positions.reduce((sum, position) => sum + (position.hours || 0), 0)
            
            if (hoursLimit && (totalHours < hoursLimit.minHours || totalHours > hoursLimit.maxHours)) {
                swalAlert.error(
                    msg({ id: "swal.message.hoursOutOfRange" })
                )
                return
            }
            // Attrib assign mode
            const body = {
                positions: data?.team?.positions.map(position => {
                    const patchedPosition = position
                    patchedPosition.hours = position.hours
                    patchedPosition.person = position?.person?._id
                    return patchedPosition
                }),
                remarks: data?.remarks
            }
            const resp = confirm===true
                ? await StatisticsService.confirmTeamByCourseId(courseId, teamId, body)
                : await StatisticsService.updateTeamByCourseId(courseId, teamId, body)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                handleNext()
            }
        }, 0)
        
    }

    const breadcrumb = [
        {
            label: msg({ id: 'work.teams' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.STATISTICS(centerId, courseId)} 
                text={msg({ id: 'work.teams' })} /> 
        },
        {
            label: data?.associationName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.STATISTICS_TEAMS_BY_AGROUPATION(centerId, courseId, agroupationId)} 
                text={data?.associationName} /> 
        }, 
        {
            label: data?.team?.name,
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const hoursLimit = data?.team?.category?.hoursLimit 
    const getHoursLimit = hoursLimit
        ? (
            msg({id: 'min'}) + ': ' + hoursLimit?.minHours + ' - '
            + msg({id: 'max'}) + ': ' + hoursLimit?.maxHours 
        )
        : ''

    const contentData = (
        <div className="statistical-data-container">
            <StaffTable 
                loading={loading}
                data={data?.team?.positions || []}
                nameFieldPerson='person'
                columns={[
                    { field: 'jobTitle', header: 'jobTitle', style: {width: "50%"} },
                    { field: 'person', header: 'staff', style: {width: "30%"} },
                    { field: 'yearAppointmentOrExtension',
                        className: "p-datatype-number",
                        headerClassName: 'p-align-end',
                        style: {width: "10%"}, 
                        header: 'yearAppointmentOrExtension', 
                        ...editCalendarRowColumn({dateFormat: 'yy', view: "year"}), 
                        body: (rowData) => parseDateYearStr(rowData?.yearAppointmentOrExtension)
                    },
                    { field: 'hours', className: "p-datatype-number", headerClassName: 'p-align-end', style: {width: "10%"}, header: 'hours', ...editNumberRowColumn({}) },
                ]}
                columnsFooter={[
                    { footer: msg({id: 'total'}) + ' ' + getHoursLimit, colSpan: 3, footerStyle: { textAlign: 'right' }},
                    { footer: 'sum', field: 'hours', className: "p-datatype-number", min: hoursLimit?.minHours, max: hoursLimit?.maxHours }
                ]}
            />
            <EditorComp 
                value={data?.remarks}
                onChange={(e) => setData({...data, remarks: e?.hrmlValue})}
            />
        </div>
    )

    return (
        <Dashboard 
            breadcrumb={breadcrumb}
            headerOptions={headerOptions}
            content={contentData}
            footerOptions={{
                handleNext,
                handlePrevious
            }}
        />
    )
}

export default StatisticalData