import React from "react"
import _ from "lodash"
import { useIntl } from "react-intl"
import DataTableCenter from "../../common/dataTable/DataTableCenter"
import { bodyRenderBoolean, bodyRenderTypes } from "../../common/dataTable/body/bodyColumnsTypes"
import editNumberRowColumn from "../../common/dataTable/editors/editNumberRowColumn"
import editBooleanRowColumn from "../../common/dataTable/editors/editBooleanRowColumn"
import MultiSelectComp from "../../common/form-components/dropdown/MultiSelectComp"

const StageInformationTable = ({
    loading,
    stages,
    updateInputs
}) => {
    const { formatMessage: msg } = useIntl()

    const onCellEditComplete = (e) => {
        const { rowData, newValue, field, originalEvent: event, rowIndex} = e
        if (newValue !== null) {
            rowData[field] = newValue
            const newParseData = [...stages]
            newParseData[rowIndex]=rowData
            updateInputs(newParseData)
        }
        else event.preventDefault()
    }

    const dataSubsidized = (options) => {  
        const { rowData } = options
        const sortedSubetapas = _.orderBy(rowData?.subetapas, ['label'], ['asc'])
        return (
            <div className="subsidized">
                {rowData.isSubventioned && (
                    <MultiSelectComp 
                        filter
                        name="subsidizedSubetapas"
                        options={sortedSubetapas}
                        //optionLabel="label"
                        value={rowData?.subsidizedSubetapas}
                        onChange={(e) => options?.editorCallback(e?.value)}
                    />
                )}
            </div>
        )
    } 

    const editSubsidized = {
        editor: (options) => dataSubsidized(options),
        onCellEditComplete
    }

    const bodySubsidized = (rowData) => rowData?.isSubventioned 
        ? bodyRenderTypes(rowData?.subsidizedSubetapas) 
        : msg({ id: 'subsidized.marks' }) 
        
    return (
        <DataTableCenter 
            tableOptions={{
                loading,
                value: stages,
                editMode: 'cell'
            }}
            columns={[
                { field: 'name', bodyClassName: ' roll-title'},
                { field: 'numberLines',
                    header: msg({ id: 'number.lines' }) + '*',
                    bodyClassName: ' editRow',
                    ...editNumberRowColumn({}), 
                    onCellEditComplete
                },
                { field: 'activatedConcerted',
                    header: msg({ id: 'isConcerted' }) + '*', 
                    bodyClassName: ' editRow',
                    body: (rowData) => bodyRenderBoolean({ value: rowData?.activatedConcerted }),
                    ...editBooleanRowColumn,
                    onCellEditComplete,
                },
                { 
                    field: 'isSubventioned',
                    header: msg({ id: 'isSubventioned' }), 
                    bodyClassName: 'editRow',
                    body: (rowData) => bodyRenderBoolean({ value: rowData?.isSubventioned }),
                    ...editBooleanRowColumn,
                    onCellEditComplete,
                },
                { 
                    field: 'subsidizedSubetapas',
                    header: msg({ id: 'subsidized.courses' }), 
                    bodyClassName: 'editRow',
                    body: (rowData) => bodySubsidized(rowData),
                    ...editSubsidized,
                },
            ]}
        />
    )
}

export default StageInformationTable