import { DELETE, GET, PATCH, POST } from '../../common/commonRequest.service'
import { endpointsSubtypes } from './endpoints'

const SubtypesService = {
    create: async (body) => POST(endpointsSubtypes.GENERIC, body),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsSubtypes.LOAD({filter, limit, skip, sort })),
    update: async ({ _id, body }) => PATCH(endpointsSubtypes.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsSubtypes.BY_ID(id)),
}

export default SubtypesService
