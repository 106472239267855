import React, { useRef, useEffect } from 'react'
import { InputNumber } from 'primereact/inputnumber'
import "./inputComp.scss"

const InputNumberComp = ({
    name,
    value, 
    onChange,
    useGrouping=false,
    ...propsInput
}) => {

    const inputRef = useRef()

    useEffect(() => {
        if (inputRef && inputRef?.current && inputRef?.current?.getInput()) {
            inputRef.current.getInput().focus()
            inputRef.current.getInput().select()
        }

        inputRef?.current?.getInput().addEventListener('click', () => {
            // console.log('click', e)
            inputRef.current.getInput().focus()
            inputRef.current.getInput().select()
        })

    }, [])

    return (
        <InputNumber 
            ref={inputRef}
            name={name}
            value={value}
            onChange={(e) => {
                if (onChange) onChange({target: { name: e.originalEvent.target.name, value: e.value}});
            }}
            useGrouping={useGrouping}
            {...propsInput}
        />
    )

}

export default InputNumberComp