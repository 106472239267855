import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Route, Routes, useParams, useNavigate } from "react-router-dom"
import { centerCourseRoutes } from "../../../routes/centers/center-course/centerCourse.routes"
import { LayoutCenterContext } from "../../common-courses/context/LayoutCenterContex"
import MenuCourse from "../../common-courses/menu/MenuCourse"
import CoursesService from "../../../services/centers/courses/courses.service"
import { CENTER_COURSE } from "../../../routes/centers/center-course/pathname"
import CenterCourseSummary from "../../../views/centers/center-course/CenterCourseSummary"
import useLoading from "../../../utils/hooks/useLoading"
import "./centerCourseRoutes.scss"

const OPTIONS_MENU_COURSE = {
    structure: true,
    center: false,
    statisticsCompleted: false,
    educativeOfferCompleted: false,
    distributionHoursCompleted: false,
    budgetsCompleted: false
}

// Rutas que hacer referencia a al curso del centro
const CenterCourseRoutes = () => {
    const { centerId, courseId } = useParams()
    const navigate = useNavigate()

    const { loading, startLoading, stopLoading } = useLoading(false)

    const { 
        courses,
        courseSeletedDropdawn,
        flagCreateCourse,
        setFlagCreateCourse, 
        setCourseSeletedDropdown,
        flagConfirm,
        setFlagConfirm
    } = useContext(LayoutCenterContext)
    
    const [ activeItemMenu, setActiveItemMenu ] = useState(OPTIONS_MENU_COURSE)
    const [ refresh, setRefresh ] = useState()

    useEffect(() => {
        if (flagConfirm) {
            // console.log('flagConfirm', flagConfirm)
            getInfoCourse()
            setFlagConfirm(false)
        }
    }, [flagConfirm])

    useEffect(() => {
        if (flagCreateCourse) {
            getInfoCourse()
            setCourseSeletedDropdown("")
        }
    }, [flagCreateCourse])

    useEffect(() => {
        setCourseSeletedDropdown(getCurrentCourseId())
    }, [courseSeletedDropdawn, courseId])

    useEffect(() => {
        getInfoCourse()
    }, [courseSeletedDropdawn])

    const getInfoCourse = useCallback(async () => {
        const haveCourseId = getCurrentCourseId()
        if (haveCourseId && haveCourseId !== "" && haveCourseId !== "undefined" && haveCourseId !== undefined) { 
            const resp = (await CoursesService.getCourseByCourseId(haveCourseId))?.resp
            if (resp) {
                updateActiveItemMenu(resp)
            }
        } else {
            updateActiveItemMenu(OPTIONS_MENU_COURSE)
        }
    }, [courseSeletedDropdawn])

    const getCurrentCourseId = () => {
        if (courseSeletedDropdawn !== undefined && courseSeletedDropdawn !== "undefined" && courseSeletedDropdawn !== "") return courseSeletedDropdawn
        if (courseId !== undefined && courseId !== "undefined" && courseId !== "") return courseId
        return "undefined"
    }

    const menuCreateNewCourese = (resp, isCourseCreated) => ({
        ...OPTIONS_MENU_COURSE,
        structure: isCourseCreated,
        center: !isCourseCreated,
        statisticsCompleted: !isCourseCreated,
        educativeOfferCompleted: resp?.statisticsCompleted,
        distributionHoursCompleted: resp?.educativeOfferCompleted,
        budgetsCompleted: resp?.distributionHoursCompleted
    })

    const menuCourse = (resp) => ({
        ...OPTIONS_MENU_COURSE,
        structure: false,
        center: true,
        statisticsCompleted: true,
        educativeOfferCompleted: resp?.statisticsCompleted,
        distributionHoursCompleted: resp?.educativeOfferCompleted,
        budgetsCompleted: resp?.distributionHoursCompleted
    })

    const updateActiveItemMenu = (resp) => {
        // TODO: Verificar si es un curso nuevo o no
        console.log('updateActiveItemMenu')
        startLoading()
        const createNewCourese = (getCurrentCourseId() === "undefined")
        if (createNewCourese) {
            const menu = menuCreateNewCourese(resp, createNewCourese)
            setActiveItemMenu(() => menu)
            setFlagCreateCourse(true)
            navigate(CENTER_COURSE(centerId, getCurrentCourseId()) + `/${lastActiveItem(menu)}`)
            // navigate(CENTER_COURSE(centerId) + `/${lastActiveItem(menu)}`)
            return
        } 
        const menu = menuCourse(resp)
        setRefresh(new Date().toLocaleString())
        setActiveItemMenu(() => menu)
        stopLoading()
    }

    const lastActiveItem = (confirmationModule) => {
        const getLastActivedItem = () => 
            Object.keys(confirmationModule).reduceRight((acc, key) => 
                acc || (confirmationModule[key] ? key : null), null)
        const lastActiveItemPath = centerCourseRoutes.find(route => 
            route.key === getLastActivedItem())?.path
        return lastActiveItemPath
    }

    const updateMenu = useCallback(() => {
        return (courses.length === 0 && !flagCreateCourse)
            ? []
            : <MenuCourse loading={loading} activeItemMenu={activeItemMenu} newCourse={flagCreateCourse && !activeItemMenu.distributionHoursCompleted} />
    }, [courses, flagCreateCourse, courseSeletedDropdawn, activeItemMenu])

    const menu = useMemo(updateMenu, [updateMenu])

    return (
        <div className="center-course-routes">
            <div className="menu-aside">
                {/* DEBUG: Check if menu is refreshed */}
                {/* <span style={{fontSize: "11px", position: "absolute", color: "silver"}}>{refresh}</span> */}
                {menu}

            </div>
            <div className="section-routes">
                <Routes>
                    {/** Cargar aquellas rutas que estan confirmationModule a true */}
                    {centerCourseRoutes.map(({ path, element, key }) => (
                        (activeItemMenu[key] || (key === 'center' && !activeItemMenu.structure))
                        && <Route key={Math.random()} path={path} element={element} />
                    ))}
                    <Route path="*" element={<CenterCourseSummary/>} />
                </Routes>
            </div>
        </div>
    )

}

export default CenterCourseRoutes