import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'reduxjs-toolkit-persist'
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1'
import storage from 'reduxjs-toolkit-persist/lib/storage' // defaults to localStorage for web
import auth, { logout } from '../services/auth/auth.slice'
import contractTypes from '../services/contract-types/contractTypes.slice'
import typologies from '../services/typologies/typologies.slice'
import profile from '../services/users/profile.slice'
import services from '../services/center-services/centerServices.slice'
import etapas from '../services/stages/etapas.slice'
import subtypes from '../services/center-accounting/subtypes/subtypes.slice'
import schoolYears from '../services/schoolYears/schoolYears.slice'
import foundationTeams from '../services/foundation-teams/foundationTeams.slice'
import territorialServices from '../services/territorial-services/territorialServices.slice'
import centersTeams from '../services/center-teams/centerTeams.slice'
import centerTeamsAgroupations from '../services/center-teams/centerTeamsAgroupations.slice'
import centersCostCenter from '../services/center-accounting/center-cost-center/centersCostCenter.slice'
import nee from '../services/nee/nee.slice'
import foundation from '../services/foundation/foundation.slice'
import foundationAccountPlan from '../services/foundation-account-plan/foundationAccountPlan.slice'
import imputations from '../services/imputation/imputation.slice'
import subsidyQuantification from '../services/center-subsidy-quantification/subsidyQuantification.slice'
import subventions from '../services/center-subvention/subvention.slice'
import collectiveAgreements from '../services/collective-aggreements/collectiveAggreements.slice'
import stadiums from '../services/stadiums/stadiums.slice'
import distributionCriteria from '../services/center-accounting/distribution-criteria/distributionCriteria.slice'

const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel1,
}

const appReducer = combineReducers({
    collectiveAgreements,
    auth,
    centersTeams,
    centerTeamsAgroupations,
    centersCostCenter,
    contractTypes,
    etapas,
    subtypes,
    imputations,
    foundationAccountPlan,
    foundationTeams,
    foundation,
    nee,
    profile,
    services,
    schoolYears,
    subventions,
    subsidyQuantification,
    territorialServices,
    typologies,
    stadiums,
    distributionCriteria
})

const rootReducer = (state, action) => {
    if (action.type === logout.fulfilled.toString() || action.type === logout.rejected.toString()) {
        storage.removeItem('persist:root')
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const pReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: pReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export const persistor = persistStore(store)
