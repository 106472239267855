import React, { useEffect, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { LayoutCenterContext } from '../../../../../components/common-courses/context/LayoutCenterContex'
import useLoading from "../../../../../utils/hooks/useLoading"
import Dashboard from "../../../../../components/common-courses/view-content/Dashboard"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import BugetTable from "../../../../../components/common/dataTable/BugetTable"
import editNumberRowColumn from "../../../../../components/common/dataTable/editors/editNumberRowColumn"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import DistributionHoursService from "../../../../../services/centers/courses/distribution-hours/ditributionHours.service"

const FoundationTeamsHours = () => {
    
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, etapaId } = useParams({})

    const navigate = useNavigate()

    const [foundationHours, setFoundationHours] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getFoundationTemasHours()
    }, [])

    const getFoundationTemasHours = async () => {
        startLoading()
        const resp = await DistributionHoursService.getFoundation(courseId, etapaId)
        if (resp) {
            setFoundationHours(resp?.resp)
        }
        stopLoading()
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await DistributionHoursService.confirmFoundation(courseId, etapaId, {categories: foundationHours?.team?.categories})
                : await DistributionHoursService.updateFoundation(courseId, etapaId, {categories: foundationHours?.team?.categories})
            if (confirm) setFlagConfirm(true)
            if (resp) {
                navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION(centerId, courseId, etapaId))
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'hours.and.staff' }),
            template: <BreadcrumbLink
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({ id: 'hours.and.staff' })} /> 
        },
        {
            label: foundationHours?.etapaName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, foundationHours?.etapa)} 
                text={foundationHours?.etapaName} /> 
        },
        {
            label: msg({id: 'distribution.hours'}),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION(centerId, courseId, etapaId)} 
                text={msg({id: 'distribution.hours'})} /> 
        },
        {
            label: msg({id: 'foundation.teams.hours'})
        }
    ]

    const handlePrevious = () => { navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION(centerId, courseId, etapaId)) }

    const handleNext = () => { navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION_SCHOOL(centerId, courseId, etapaId)) }

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const footerOptions = {
        handlePrevious,
        handleNext
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: foundationHours?.team?.categories,
                editMode: 'cell'
            }}
            columns={[
                {field: 'name', bodyClassName: 'roll-title', header: msg({id: 'name'})},
                {
                    field: 'hours',
                    className: 'p-datatype-number',
                    headerClassName: "p-align-end",
                    style: { width: "10%"},
                    bodyClassName: 'editRow',
                    header: msg({id: 'hours'}),
                    ...editNumberRowColumn({})
                }
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 1, footerStyle: { textAlign: 'left' } },
                { footer: 'sum', className: 'p-datatype-number', field: 'hours' }
            ]}
        />
    )

    return (
        <Dashboard 
            headerOptions={headerOptions}
            content={table}
            footerOptions={footerOptions}
        />
    )
}

export default FoundationTeamsHours