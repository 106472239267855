import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsCenterTeams } from "./endpoints"

const CenterTeamsService = {
    createCenterTeam: async (body) => POST(endpointsCenterTeams.GENERIC, body),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsCenterTeams.LOAD({filter, limit, skip, sort})),
    getAllManagerial: async () => GET(endpointsCenterTeams.LOAD_ALL_MANAGERS),
    getDataByTeamId: async ({centerTeamId}) => GET(endpointsCenterTeams.BY_TEAM(centerTeamId)),
    updateCenterTeam: async ({_id, body}) => PATCH(endpointsCenterTeams.BY_TEAM(_id), body),
    confirmCenterTeam: async (centerTeamId) => PATCH(endpointsCenterTeams.CONFIRM(centerTeamId)),
    removeCenterTeam: async (centerTeamId) => DELETE(endpointsCenterTeams.BY_TEAM(centerTeamId)),
    createCenterTeamCharge: async ({ centerTeamId, body }) => POST(endpointsCenterTeams.TEAM_POST(centerTeamId), body),
    deleteCenterTeamCharge: async (postId) => DELETE(endpointsCenterTeams.TEAM_BY_POST(postId)),
    updateCenterTeamCharge: async ({ _id, body }) => PATCH(endpointsCenterTeams.TEAM_POST(_id), body),
    getAgroupations: async () => GET(endpointsCenterTeams.AGROUPATIONS)
}

export default CenterTeamsService