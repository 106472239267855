import _ from 'lodash'
import React from "react"
import { useIntl } from "react-intl"
import MainCommonView from "../../../components/common/MainCommonView"
import MaestrosDataTable from "../../../components/common/dataTable/MaestrosDataTable"
import MaestrosTemplateImportForm, { FILE_TYPE } from "../../../components/maestros-template-import/MaestrosTemplateImportForm"
import Modal from "../../../components/modal/Modal"
import TemplateService from "../../../services/template/template.service"
import editTextRowColumn from "../../../components/common/dataTable/editors/editTextRowColumn"
import editUploadFileRowColumn from "../../../components/common/dataTable/editors/editUploadFileRowColumn"
import rowDelete from "../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../utils/helpers/data-table/column-types/rowEditor"
import swalAlert from '../../../utils/helpers/swalAlert'
import UPLOAD from '../../../utils/helpers/uploadFiles'
import useHandlerBasicActions from "../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../utils/hooks/useInputs'
import useModal from "../../../utils/hooks/useModal"

const MaestroTemplateImport = () => {

    const {formatMessage: msg} = useIntl()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: TemplateService.getAll,
        createAction: (body) => TemplateService.create(body, false),
        removeAction: TemplateService.remove, 
        editAction: ({_id, body}) => TemplateService.update({_id, body, showSuccessAlert: false})
    })

    const { inputs, onChangeInputs } = useInputs()
    const { isShowing, toggle } = useModal()

    const handleCreateTemplate = async () => {
        await handleSaveTemplate({...inputs})
        toggle()
    }

    const handleSaveTemplate = async (body) => {
        const template = await dataFacade.create(_.omit(body, 'files'))

        if (template && body?.files) {
            const templateWithFile = await uploadFiles(template?.resp?._id, body?.files)

            dataFacade.updateStateOnCreate(templateWithFile)
        }
    }

    const handleUpdateTemplate = async (body) => {
        const template = await dataFacade.edit({
            _id: body?._id, 
            body: _.omit(body, 'files', 'createdAt', 'updatedAt', 'path', '_id'), 
            showSuccessAlert: false})

        if (template) {
            if (body?.files) {
                const templateWithFile = await uploadFiles(template?.resp?._id, body?.files)
                dataFacade.updateStateOnEdit(templateWithFile)
            } else {
                await swalAlert.succesWithTimer()
            }
        }
    }

    const uploadFiles = (templateId, files) => UPLOAD.multiFiles({ 
        id: templateId,
        files: [{file: files}],
        uploadService: ({_id, body: body_}) => TemplateService.uploadFile(_id, body_, false),
        errorMsgTitle: msg({ id: 'swall.error.uploading.files' })
    })

    const onRowEditComplete = ({ newData }) => handleUpdateTemplate(newData)

    const headerOptions = {
        title: msg({id: 'templates'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'templates',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: () => TemplateService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={([
                { field: 'name',
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                { field: 'files',
                    header: 'file',
                    ...editUploadFileRowColumn({
                        labelButton: 'change',
                        accept: FILE_TYPE
                    })
                },
                { field: 'description',
                    header: 'description',
                    ...editTextRowColumn
                },
                { ...rowDelete({ handleDelete: dataFacade?.remove })},
                { ...rowEditor()},
            ])}
        />
    )

    return (
        <div className="staff">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="550px"
                title={msg({id: 'import.template' })}
                context={(
                    <MaestrosTemplateImportForm
                        inputs={inputs}
                        handleChange={onChangeInputs}
                        handleSave={handleCreateTemplate} 
                        typefile='.xlsx,.csv'
                    />
                )}
            />

        </div>
    )

}

export default MaestroTemplateImport