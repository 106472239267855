import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import useLoading from "../../../../../../../utils/hooks/useLoading"
import CardsOverview from "../../../../../../../components/common-courses/view-content/CardsOverview"
import BudgetService from "../../../../../../../services/centers/courses/budgets/budgets.service"
import { CENTER_COURSE_DETAILS } from "../../../../../../../routes/centers/center-course/pathname"
import BreadcrumbLink from "../../../../../../../components/common/breadcrumb/BreadcrumbLink"

const SuppliesTotals = () => {

    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, suppliesId, balanceId} = useParams()
    
    const [accounts, setAccounts] = useState([])
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getAccounts()
    }, [])

    const getAccounts = async () => {
        startLoading()
        const resp = await BudgetService.getExpensesTotals(courseId, balanceId)
        if (resp) {
            setAccounts(resp?.resp)
        }
        stopLoading()
    }

    const goToList = (id, item) => {
        return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES_TOTALS_TABLE(centerId, courseId, suppliesId, balanceId, item?.account)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'expenses' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET_EXPENSES(centerId, courseId)} 
                text={msg({ id: 'expenses' })} /> 
        },
        {
            label: accounts?.templateName,
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES(centerId, courseId, suppliesId)} 
                text={accounts?.templateName} /> 
        },
        {
            label: accounts?.conceptName,
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES_DATA(centerId, courseId, suppliesId, balanceId)}
                text={accounts?.conceptName} /> 
        },
        {
            label: msg({ id: 'totals' })
        }
    ]

    const cardList = {
        list: [
            ...(accounts?.accounts || []).map(item => ({
                ...item,
                source: 'accounts',
            })),
        ],
        gotToLink: goToList
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default SuppliesTotals