import { createSlice } from '@reduxjs/toolkit'
import StadiumsService from './stadiums.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('stadiums/create', StadiumsService.create)

export const getAll = createAsyncThunkMiddleware('stadiums/getAll', StadiumsService.getAll)

export const update = createAsyncThunkMiddleware('stadiums/update', StadiumsService.update)

export const remove = createAsyncThunkMiddleware('stadiums/delete', StadiumsService.remove)

const initialState = []

const stadiumsSlice = createSlice({
    name: 'stadiums',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove),
})

export default stadiumsSlice.reducer