import React, { useEffect } from "react"
import { useIntl } from 'react-intl'
import DropdownComp from "../form-components/dropdown/DropdownComp"
import HeaderTitle from "./HeaderTitle"
import HeaderActions from "./HeaderActions"
import Search from "../search/Search"
import useConfirmLastCourse from "../../../utils/hooks/useConfirmLastCourse"
import './commonHeader.scss'

const CommonHeader = ({
    title,
    searchOptions,
    titleSuffix,
    // props for select
    dropdownOptions,
    handlerHeaderActions, 
    auxInfoText,
    masterKeyConfirm
}) => {

    const { formatMessage: msg } = useIntl()

    const confirmLastCourse = useConfirmLastCourse({ masterKey: masterKeyConfirm })
    
    useEffect(() => {
        if (masterKeyConfirm) {
            confirmLastCourse.defineLastCourseConfirmed()
        }
    }, [])

    const titleRight = masterKeyConfirm ? confirmLastCourse.auxInfoText : auxInfoText

    const confirmationOptions = masterKeyConfirm && confirmLastCourse?.handlerHeaderActions

    return (
        <div className='common-component-header'> 
            <div className="header-wrapper-content">
                
                <HeaderTitle title={title} titleSuffix={titleSuffix}/>

                <div className="right-aligned-part-of-header">
                    { titleRight 
                        && (
                            <h3 className="title__subtitle">
                                { titleRight }
                            </h3>
                        ) 
                    }
                    
                    {searchOptions
                    && (
                        <div className="search">
                            <Search
                                updateFilter={searchOptions?.updateFilter}
                                filter={searchOptions?.filter}
                                placeholder={searchOptions?.placeholderSearchId}
                            />
                        </div>
                    )
                    }

                    {/** Select */}
                    {
                        dropdownOptions?.value
                        && (
                            <div className="order-by-dropDown">
                                {msg({id: dropdownOptions?.label || "order.by"})}
                                <DropdownComp 
                                    id='selectOptions'
                                    name='options'
                                    value={dropdownOptions?.value}
                                    options={dropdownOptions?.options}
                                    optionLabel='label'
                                    // filter
                                    emptyMessage={msg({id: 'placeholder.not.options'})}
                                    emptyFilterMessage={msg({id: 'placeholder.not.options'})}
                                    onChange={(e) => dropdownOptions?.onChange(e)}
                                    placeholder={msg({id: 'placeholder.select'})}
                                />
                            </div>
                        ) 
                    }
                    <HeaderActions 
                        {...handlerHeaderActions}
                        {...confirmationOptions}
                    />
                </div>  
            </div>
        </div>
    )
}

export default CommonHeader