import { useState } from 'react'
import auxFunction from '../helpers/auxFun'

const useInputs = (initial) => {

    const [inputs, setInputs] = useState(initial || {})

    const onChangeInputs = ({ target, files }) => {
        if (target) {
            const name = target?.name
            const value = target?.value

            if (name) {
                const updatedInputs = auxFunction.updateInputs(name, value, inputs)
                setInputs(updatedInputs)
            }
        }

        if (files) {
            setInputs({...inputs, files})
        }
    }
    
    const updateInputs = (data) => {
        setInputs(data)
    }

    return { inputs, onChangeInputs, updateInputs }
}

export default useInputs
