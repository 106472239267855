import React from "react"
import { onCellEditComplete } from "./common"
import InputNumberComp from "../../form-components/inputs/InputNumberComp"

const numberEditor = ({ options, propsInput}) => (
    <InputNumberComp
        value={options?.value}
        onChange={({ target: { value } }) => options.editorCallback(value ?? 0)}
        {...propsInput}
    />
)


const editNumberRowColumn = ({ propsInput }) => {
    return {
        editor: (options) => numberEditor({ options, propsInput}),
        onCellEditComplete, 
    }
}


export default editNumberRowColumn