import React from "react"
import { ControlLabel } from "../label/Label"
import InputChipComp from "../inputs/InputChipComp"
import "./labeledInput.scss"

const LabeledChip = ({
    classNameBox,
    idLabel, 
    required, 
    name,
    value,
    onChange,
    ...propsChips
}) => {

    return (
        <div className={`flex-container ${classNameBox}`}>
            <ControlLabel id={idLabel} required={required}/>
            <div className="control-input">
                <InputChipComp
                    name={name}
                    value={value}
                    onChange={onChange}
                    {...propsChips}
                />
            </div>
        </div>
    )

}

export default LabeledChip
