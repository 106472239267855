import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import useLoading from "../../../../utils/hooks/useLoading"
import DistributionHoursService from "../../../../services/centers/courses/distribution-hours/ditributionHours.service"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import BreadcrumbLink from "../../../../components/common/breadcrumb/BreadcrumbLink"

const DistributionHours = () => {
    
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, etapaId } = useParams({})

    const navigate = useNavigate()

    const [etapa, setEtapa] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getLineConfirmation()
    }, [])

    const getLineConfirmation = async () => {
        startLoading()
        const resp = await DistributionHoursService.getEtapa(courseId, etapaId)
        if (resp) {
            setEtapa(resp?.resp)
        }
        stopLoading()
    }

    const distributioHoursCards = [
        {   
            _id: etapaId,
            source: 'foundationTeamsHours',
            confirmation: etapa?.teams?.find(team => team?.name === 'FUNDACIÓ')?.confirmation,
            name: msg({id: 'foundation.teams.hours'}),
            key: 'foundation-' + etapaId,
        },
        {   
            _id: etapaId,
            source: 'schoolTeamsHours',
            confirmation: etapa?.teams?.find(team => team?.name === 'ESCOLA')?.confirmation,
            name: msg({id: 'hours.teams.school'}),
            key: 'school-' + etapaId,
        },
        ...(etapa?.lineDistribution?.confirmation?.isConfirm ? [{   
            _id: etapaId,
            source: 'staffHours',
            confirmation: etapa?.staffDistribution?.confirmation,
            name: msg({id: 'staff'}),
            key: 'staff-' + etapaId,
        }] : [])
    ]

    const goToList = (id, item) => {
        if (item?.source === "foundationTeamsHours") {
            return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION_FOUNDATION(centerId, courseId, id)
        }
        if (item?.source === "schoolTeamsHours") {
            return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION_SCHOOL(centerId, courseId, id)
        }
        return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_STAFF(centerId, courseId, id)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'hours.and.staff' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({ id: 'hours.and.staff' })} /> 
        },
        {
            label: etapa?.etapaName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, etapa?.etapa)} 
                text={etapa?.etapaName} /> 
        },
        {
            label: msg({id: 'distribution.hours'})
        }
    ]

    const handlePrevious = () => { navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, etapaId)) }

    const cardList = {
        list: distributioHoursCards,
        gotToLink: goToList
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
            handlePrevious={handlePrevious}
        />
    )
}

export default DistributionHours