import { CENTER_COURSE_DETAILS } from "../../routes/centers/center-course/pathname"

export const menuOptionsType = Object.freeze({
    CENTER: 'center', 
    FOUNDATION: 'foundation'
})

export const typeOptionsCourse = Object.freeze({
    NEW: 'new',
    CREATED: 'created'
})

export const menuCenteCommon = [
    {
        id: 'work.teams', 
        path: (centerId, courseId) => CENTER_COURSE_DETAILS.STATISTICS(centerId, courseId), 
        key: 'statisticsCompleted'
    }, 
    {
        id: 'educative.offer.and.services', 
        path: (centerId, courseId) => CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId),
        key: 'educativeOfferCompleted'
    },
    {
        id: 'hours.and.staff', 
        path: (centerId, courseId) => CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId),
        key: 'distributionHoursCompleted'
    }, 
    {
        id: 'budget', 
        path: (centerId, courseId) => CENTER_COURSE_DETAILS.BUGET(centerId, courseId),
        key: 'budgetsCompleted'
    }, 
]

export const newCenterCourseMenu = [
    {
        id: 'center.strucutre', 
        path: (centerId, courseId) => CENTER_COURSE_DETAILS.STRUCTURE(centerId, courseId),
        key: 'structure'
    }, 
    ...menuCenteCommon

]

export const centerCourseMenu = [
    {
        id: 'center', 
        path: (centerId, courseId) => CENTER_COURSE_DETAILS.DATA(centerId, courseId),
        key: 'center'
    },
    ...menuCenteCommon
]
