/* eslint-disable fp/no-mutating-methods */
export const parseToTableFormat = (data) => {
    return Object.keys(data).map(key => {
        const item = data[key]
        const parsedItem = {
            concept: key,
            total: item.total,
        }

        item.data.forEach(subItem => {
            const id = subItem._id
            parsedItem[id] = {
                value: subItem.value,
                name: subItem.name
            }
        })
        return parsedItem
    })
}

export const parseToBackendFormat = (tableData) => {
    const backendData = {}

    tableData.forEach(tableItem => {
        const { concept, total, ...details } = tableItem
        const data = []

        details?.map(key => (
            data.push({
                etapa: key,
                service: key,
                pas: null,
                value: details[key]
            })
        ))
        backendData[concept] = { data, total }
    })

    return backendData
}