import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from "../../../../../../utils/hooks/useLoading"
import BreadcrumbLink from '../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../routes/centers/center-course/pathname'
import BugetTable from '../../../../../../components/common/dataTable/BugetTable'
import BudgetService from '../../../../../../services/centers/courses/budgets/budgets.service'
import Dashboard from '../../../../../../components/common-courses/view-content/Dashboard'

const IncomesTable = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId} = useParams()

    const [incomesTable, setIncomesTable] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getIncomesData()
    }, [])

    const getIncomesData = async () => {
        startLoading()
        const resp = await BudgetService.getIncomesResults(courseId)
        if (resp) {
            setIncomesTable(resp?.resp)
        }
        stopLoading()
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'result' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_RESULTS(centerId, courseId)} 
                text={msg({ id: 'result' })} /> 
        },
        {
            label: msg({ id: 'vertical.result' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_RESULTS_VERTICAL(centerId, courseId)} 
                text={msg({ id: 'vertical.result' })} /> 
        },
        {
            label: msg({ id: 'income' })
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: incomesTable
            }}
            columns={[
                { field: 'name', header: 'concept' },
                { field: 'personal', header: 'staff' },
                { field: 'general', header: 'general' },
                { field: 'annualTotalCost', header: 'annualTotalCost' },
                { field: 'anualStudents', header: 'anual.students' },
                { field: 'monthDayStudents', header: 'month.day.students' },
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 1, footerStyle: { textAlign: 'left' } },
                { footer: 'sum', field: 'staff'},
                { footer: 'sum', field: 'general'},
                { footer: 'sum', field: 'annualTotalCost'},
                { footer: 'sum', field: 'anualStudents'},
                { footer: 'sum', field: 'monthDayStudents'}
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default IncomesTable