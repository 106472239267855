import React from "react"
import { useIntl } from 'react-intl'
import MainCommonView from './MainCommonView'
import useBasicLocalData from '../../utils/hooks/base-adapter/useBasicLocalData'
import MaestrosList from "../maestros/MaestrosList"

const MaestrosListView = ({ 
    title,
    maestros,
    masterKeyConfirm, 
    showNumbers
}) => {
    const { formatMessage: msg } = useIntl()

    const dataFacade = useBasicLocalData({
        allData: maestros,
        rowsPerPageInitial: 10,
        sortFieldInitial: 'position'
    })

    const headerOptions = {
        title: msg({id: title}),
        searchOptions: dataFacade?.searchOptions,
        masterKeyConfirm
    }

    const listComponent = <MaestrosList maestroslist={dataFacade.data} showNumbers={showNumbers}/>
    
    return (
        <MainCommonView 
            componentData={listComponent}
            headerOptions={headerOptions}
            pagerOptions={dataFacade?.pagerOptions}
        />
    )
}

export default MaestrosListView