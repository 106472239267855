import React from "react"
import InputTextComp from "../../form-components/inputs/InputTextComp"
import { onCellEditComplete } from "./common"

const textEditor = (options) => (
    <InputTextComp
        value={options?.value}
        onChange={({ target: { value } }) => options.editorCallback(value)}
    />
)

const editTextRowColumn = {
    editor: (options) => textEditor(options),
    onCellEditComplete, 
}


export default editTextRowColumn