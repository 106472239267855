import React from "react"
import MaestrosListView from '../../../components/common/MaestrosListView'
import { MAESTROS_LABORAL } from "../../../utils/constants/maestros/maestrosLaboral"
import { maestrosKeys } from "../../../utils/constants/maestros/maestros"

const MaestrosLabor = () => (
    <MaestrosListView 
        title='workingMaster' 
        maestros={MAESTROS_LABORAL} 
        masterKeyConfirm={maestrosKeys.LABORAL}
    />
)

export default MaestrosLabor
