import React, { useEffect, useState } from "react"
import DropdownComp from "../../common/form-components/dropdown/DropdownComp"
import StaffService from "../../../services/staff/staff.service"

const LaborDropdown = ({options}) => {
    const [laborOptions, setLaborOptions] = useState([])

    useEffect(() => {
        if (options?.rowData?.person) {
            getLaborByStaff()
        }
    }, [])

    const getLaborByStaff = async () => {
        const resp = (await StaffService.getLaboralById(options?.rowData?.person?._id))?.resp
        if (resp) {
            setLaborOptions(resp)
        }
    }

    const itemTemplateStaff = (item) => {
        return (
            <div className={item?.isPersonal ? '' : 'deactivated'}> 
                {item?.dcCategoria} 
            </div>
        )
    }

    return (
        <DropdownComp 
            options={laborOptions}
            value={options?.value}
            onChange={({ target: { value } }) => options.editorCallback(value)} 
            optionLabel='dcCategoria'
            itemTemplate={itemTemplateStaff}
        />
    )
}

export default LaborDropdown
