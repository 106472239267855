import _ from "lodash"
import { parseStrToISO8601String } from "../../utils/helpers/date"

const encode = encodeURIComponent

export const haveFilter = (filter) => {
    const hasValueFilter = filter && !_.values(filter).some(x => x === "")
    return hasValueFilter ? `?filter=${encode(filter)}` : `?` 
}

export const haveFilterQuery = (filter) => {
    const hasValueFilter = filter && !_.values(filter).some(x => x === "")
    const stringifiedFilter = typeof filter === "object" ? JSON.stringify(filter) : filter

    return hasValueFilter ? `?filter=${encode(stringifiedFilter)}` : `?` 
}

export const haveSort = (sort) => {
    return sort ? `&sort=${sort}` : ''
}

export const paginator = (limit, skip) => {
    let result = ''
    if (limit) result = `&limit=${limit}`
    if (skip !== undefined) result = `${result}&skip=${skip*limit}`
    return result
}

export const buildQueryParams = (options) => {
    let queryParams = ''

    // Filtrar
    if (options?.filter) {
        const hasValueFilter = !_.isEmpty(options?.filter) && !_.values(options?.filter).some((x) => x === '')
        if (hasValueFilter) {
            const filterString = encode(JSON.stringify(options?.filter))
            queryParams += `?filter=${filterString}`
        }
    }

    // Ordenar
    if (options?.sort) {
        queryParams += queryParams ? '&' : '?'
        queryParams += `sort=${options?.sort}`
    }

    // Paginación
    if (options?.limit) {
        queryParams += queryParams ? '&' : '?'
        queryParams += `limit=${options?.limit}`
    }
    if (options?.skip !== undefined && options?.limit !== undefined) {
        queryParams += queryParams ? '&' : '?'
        queryParams += `skip=${options.skip * options.limit}`;
    }
    
    if (options.initDate && options.finishDate) {
        const iDate = parseStrToISO8601String(options.initDate)
        const fDate = parseStrToISO8601String(options.finishDate)
        queryParams += queryParams ? '&' : '?'
        queryParams += `initDate=${iDate}&finishDate=${fDate}`
    }

    return queryParams
}