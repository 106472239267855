import React from "react"
import "./progressSpinnerComp.scss"

const ProgressSpinnerComp = ({loading}) => {

    return (
        loading && (
            <div className="spinner"/>
        )
    )
}

export default ProgressSpinnerComp