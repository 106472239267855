import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import './formContractType.scss'

const FormContractType = ({ inputs, handleChange, handleSave }) => {

    const validateForm = validators.isEntityValues([inputs?.code, inputs?.contract]) 
    
    return (
        <div className="form-contractType">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledText 
                        idLabel='code'
                        required
                        name='code'
                        value={inputs?.code}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledText 
                        idLabel='contract'
                        required
                        name='contract'
                        value={inputs?.contract}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <LabeledTextArea 
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={(e) => handleChange(e)}
                    numRows={3}
                    numColumns={15}
                />
            </div>

            <div className="modal-btn-container">
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormContractType
