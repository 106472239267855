import { maestros } from "../maestros.pathname"
    
export const maestrosLaboral = {
    CONTRACT_TYPES: maestros.MAESTROS_LABOR + '/contracttypes',
    COLLECTIVE_AGREEMENTS: maestros.MAESTROS_LABOR + '/collectiveAgreements',
    SALARY_TABLES: maestros.MAESTROS_LABOR + '/salaryTables',
    MANAGEMENT_POSITIONS: maestros.MAESTROS_LABOR + '/managementPositions',
    STADIUMS: maestros.MAESTROS_LABOR + '/stadiums',
    STAFF: maestros.MAESTROS_LABOR + '/staff',
    PAS: maestros.MAESTROS_LABOR + '/pas',
    PAS_ID: maestros.MAESTROS_LABOR + `/pas/pasId/`
}

export const SALARY_TABLE = (id) => maestrosLaboral.SALARY_TABLES + `/salaryTable/${id}`
export const SALARY_TABLE_DATA = (agreementId, id) => maestrosLaboral.SALARY_TABLES + `/salaryTable/${agreementId}/resume/${id}`
export const STAFF_PROFILE = (id) => maestrosLaboral.STAFF + `/${id}/basic`