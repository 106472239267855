import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import MainCommonView from "../../../../components/common/MainCommonView"
import FormSalary from "../../../../components/maestros-labor/salary-table/FormSalary"
import SalaryTablesService from "../../../../services/salary-tables/salaryTables.service"

const SalaryTableData = () => {

    const { formatMessage: msg } = useIntl()
    const { id } = useParams()

    const [agreement, setAgreement] = useState([])

    useEffect(() => {
        getAgreementByAgreementId()
    }, [])

    const getAgreementByAgreementId = async () => setAgreement((await SalaryTablesService.getById(id))?.resp)

    const headerOptions = {
        title: msg({ id: 'salary.tables' }) + ' - ' + agreement?.categoryCode,
    } 

    const formDalary = (
        <FormSalary 
            inputs={agreement}
        />
    )

    return (
        <MainCommonView
            headerOptions={headerOptions}
            componentData={formDalary}
        />
    )
}

export default SalaryTableData