import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams, useNavigate } from "react-router-dom"
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import swalAlert from '../../../../utils/helpers/swalAlert'
import EtapasService from '../../../../services/stages/etapas.service'
import useModal from '../../../../utils/hooks/useModal'
import EtapaList from '../../../../components/maestros-center/etapas/EtapasList'
import Modal from '../../../../components/modal/Modal'
import FormSubetapa from '../../../../components/maestros-center/etapas/FormSubetapa'

const Etapa = () => {

    const { etapaId } = useParams()
    const navigate = useNavigate()
    const { formatMessage: msg } = useIntl()

    const { isShowing, toggle } = useModal()

    const initialState = {
        parent: etapaId,
        name: '',
        order: 1,
        treatAsTimePeriodApplicable: true,
        treatAsTimePeriod: false
    }

    const [ inputs, setInputs ]= useState(initialState)
    const [ etapa, setEtapa ] = useState()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'order',
        getAll: ({filter, limit, skip}) => EtapasService.getAllSubetapas({ etapaId, filter, limit, skip }),
        createAction: (body) => EtapasService.createEtapa(body),
        removeAction: EtapasService.removeEtapa, 
        editAction: EtapasService.updateEtapa,
        additionalDependency: etapaId
    })

    useEffect(() => {
        getStageData()
        setInputs(initialState)
    }, [etapaId])

    useEffect(() => {
        setInputs({
            ...initialState,
            order: dataFacade?.data?.total + 1}) 
    }, [dataFacade?.data])

    const getStageData = async () => {
        const resp = await EtapasService.getDataByEtapaId(etapaId)
        if (resp) {
            setEtapa(resp?.resp)
        }
    }

    const handleCreateSubetapa = () => dataFacade.create({
        ...inputs,
        isLevelOfDistribution: inputs?.isLevelOfDistribution || false
    })

    const handleEditSubetapa = async (_id) => {
        const body = {
            parent: etapaId,
            name: inputs?.name,
            order: inputs?.order,
            isLevelOfDistribution: inputs?.isLevelOfDistribution || false
        }
        return dataFacade.edit({_id, body })
    }

    const handleSaveSubetapa = async (_id) => {
        const resp = _id ? await handleEditSubetapa(_id) : await handleCreateSubetapa()
        if (resp) {
            closeModal()
            dataFacade.reload()
        }
    }

    const handleConfirmEtapa = async () => {
        const confirm = await swalAlert.warning( 
            (msg({id: "swal.title.confirm"}) + "?"),
            msg({id: "button.cancel"}),
            msg({id: "button.confirm"})
        )
        if (confirm.isConfirmed) {
            await EtapasService.confirmEtapa(etapaId)
            navigate(-1)
        }
    }

    const onInputChange = ({target: {value, name}}) => { 
        setInputs({...inputs, [name]: value}) 
    }

    const openModal = (subetapa) => {
        setInputs({ 
            ...subetapa, 
            isLevelOfDistribution: subetapa?.isLevelOfDistribution
        })
        toggle()
    }

    const closeModal = () => {
        setInputs({...initialState, order: dataFacade?.data?.total + 1})
        toggle()
    }

    const headerOptions = {
        title: msg({id: 'groups'}),
        titleSuffix: `- ${etapa?.name}`,
        handlerHeaderActions: {
            firstHandlerAction: toggle,
            confirmHandlerAction: handleConfirmEtapa,
            isConfirmed: etapa?.confirmation?.isConfirm //dataFacade?.data?.resp?.confirmation?.isConfirm
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'linked.categories',
        titleSuffix: `- ${etapa?.name}`,
        columns: [{ title: msg({id: 'name'}), dataKey: 'name' }],
        exportService: () => EtapasService.getAllSubetapas({etapaId})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataControl = (
        <EtapaList
            etapas={dataFacade?.data?.resp} // subetapas
            openEditModal={openModal}
            handleDelete={dataFacade.remove}
            canEtapaBeEntered={e => !e?.isLevelOfDistribution}
        />
    )

    const defineModalTitle = msg({ id: `group.${inputs._id ? 'edit' : 'new'}`})

    return (
        <div className='etapa-maestros-container'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
                showLegend
            />

            <Modal
                isShowing={isShowing}
                hide={closeModal}
                width='90%'
                maxWidth='450px'
                title={defineModalTitle}
                context={(
                    <FormSubetapa
                        inputs={inputs}
                        handleChange={onInputChange}
                        handleSave={handleSaveSubetapa}
                    />
                )}
            />
        </div>
        
    )
}

export default Etapa