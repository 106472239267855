import { saveAs } from 'file-saver'
import { utils, write } from 'xlsx-js-style'
import JsPDF from 'jspdf'
import 'jspdf-autotable'

const useExport = (params = {}) => {
    const { title = '', columns = [] } = params

    const saveAsExcelFile = (buffer, fileName) => {
        const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const EXCEL_EXTENSION = '.xlsx'
        const data = new Blob([buffer], {
            type: EXCEL_TYPE,
        })
        saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION)
    }

    const fitToColumn = (cols, data) => {
        const res = cols?.map((c) => [c.title].concat(data?.map((d) => d[c?.dataKey])))
        return res.map((col) => ({ wch: Math.max(...col.map((cell) => (cell ? cell.toString().length + 5 : 0))) }))
    }

    const generateExcel = ({ cols, data, fileName }) => {
        const finalData = data?.map((d) => {
            const res = {}
            cols?.forEach((col) => {
                let value = d[col.dataKey]
                if (col?.dataKey.includes('.')) {
                    const keys = col?.dataKey.split('.')
                    value = d
            
                    keys?.forEach((key) => {
                        value = value[key]
                    }) 
                }
                res[col.title] = value
            })
            return res
        })
        const worksheet = utils.json_to_sheet(finalData)

        worksheet['!cols'] = fitToColumn(cols, data)

        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
        const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array', cellStyles: true })
        saveAsExcelFile(excelBuffer, fileName)
    }

    const generatePdf = ({cols, data, fileName}) => {
        const doc = new JsPDF()
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth()
        doc.setFontSize(20)
        doc.text(fileName, pageWidth / 2, 15, 'center')
        const styles = {
            theme: 'grid',
            headStyles: { fillColor: [141, 36, 49], fontSize: 10 },
            startY: 25,
            bodyStyles: { fontSize: 7.5, minCellWidth: 20 },
        }
        const processedData = data?.map(d => {
            const res = {}
            cols?.forEach(col => {
                let value = d[col?.dataKey]
    
                if (typeof col?.dataKey === 'string' && col?.dataKey?.includes('.')) {
                    const keys = col.dataKey.split('.');
                    value = d
                    keys?.forEach(key => {
                        value = value[key]
                    })
                }
                res[col.dataKey] = value
            })
    
            return res
        })
        doc.autoTable(cols, processedData, styles)
        doc.save(fileName + '.pdf')
    }

    const exportXLS = async (service) => {
        const resp = await service()
        const response = Array.isArray(resp?.resp) ? resp?.resp : []
        generateExcel({ cols: columns, data: response, fileName: title})
    }

    const exportPDF = async (service) => {
        const resp = await service()
        generatePdf({ cols: columns, data: resp?.resp || [], fileName: title})
    }

    return {
        exportXLS,
        exportPDF,
        generateExcel
    }

}

export default useExport