import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from '../../../../../../utils/hooks/useLoading'
import CardsOverview from '../../../../../../components/common-courses/view-content/CardsOverview'
import BreadcrumbLink from '../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../routes/centers/center-course/pathname'
import BudgetService from '../../../../../../services/centers/courses/budgets/budgets.service'

const EtapaFeesIncomes = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId, feesId, etapaId} = useParams()

    const [feeEtapa, setFeeEtapa] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getActivityIncomes()
    }, [])

    const getActivityIncomes = async () => {
        startLoading()
        const resp = await BudgetService.getActivitiesDataEtapa(courseId, feesId, etapaId)
        if (resp) {
            setFeeEtapa(resp?.resp)
        }
        stopLoading()
    }

    const goToLink = (id, item) => {
        if (item?.source === 'general') return CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES_SERVICES_GENERAL_TABLE(centerId, courseId, balanceId, feesId, etapaId)
        return CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES_SERVICES_TABLE(centerId, courseId, balanceId, feesId, etapaId, item?.service)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: feeEtapa?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES(centerId, courseId, feeEtapa?.balanceTemplate)} 
                text={feeEtapa?.templateName} /> 
        },
        {
            label: feeEtapa?.conceptName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES(centerId, courseId, balanceId, feeEtapa?.concept)} 
                text={feeEtapa?.conceptName} /> 
        },
        {
            label: feeEtapa?.etapaName
        }
    ]

    const cardList = {
        list: [
            {
                _id: 'etapa',
                name: msg({id: 'general.cuote'}),
                source: 'general',
            },
            ...(feeEtapa?.services || []).map(item => ({
                ...item,
                source: 'service'
            })),
        ],
        gotToLink: goToLink,
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default EtapaFeesIncomes