import React, { useState, useEffect } from 'react'
import { useIntl, FormattedMessage} from 'react-intl'
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useBasicLocalData from "../../../../utils/hooks/base-adapter/useBasicLocalData"
import { getAllEtapas } from '../../../../services/stages/etapas.slice'
import { CenterServices, CenterServicesTypes } from '../../../../services/center-services/centerServices.service'
import useModal from '../../../../utils/hooks/useModal'
import Modal from '../../../../components/modal/Modal'
import FormServiceTypes from '../../../../components/maestros-center/school-services/FormServiceTypes'
import editTextRowColumn from '../../../../components/common/dataTable/editors/editTextRowColumn'
import rowDelete from '../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../utils/helpers/data-table/column-types/rowEditor'
import useInputs from '../../../../utils/hooks/useInputs'
import { bodyRenderTypes } from "../../../../components/common/dataTable/body/bodyColumnsTypes"
import editMultiSelectRowColumn from "../../../../components/common/dataTable/editors/editMultiSelectRowColumn"
import MaestrosDataTable from '../../../../components/common/dataTable/MaestrosDataTable'
import auxFunction from '../../../../utils/helpers/auxFun'
import './schoolService.scss'

const SchoolService = () => {
    
    const { serviceId } = useParams()
    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const stages = useSelector(state => state?.etapas)

    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs} = useInputs()
    const [ serviceInfo, setServiceInfo ] = useState()

    useEffect(() => {
        dispatch(getAllEtapas({}))
    }, [])

    const getServiceTypes = async () => {
        const service = (await CenterServices.getServiceById({ serviceId }))?.resp
        setServiceInfo({
            name: service?.name,
            isByEtapa: service?.isByEtapa,
            confirmed: service?.confirmation?.isConfirm
        })
        return { resp: service?.serviceTypes }
    }

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: getServiceTypes,
        createAction: CenterServicesTypes.createServiceType,
        removeAction: CenterServicesTypes.removeServiceType, 
        editAction: CenterServicesTypes.updateServiceType
    })

    const localPagingAndFiltering = useBasicLocalData({
        allData: dataFacade?.data?.resp,
        rowsPerPageInitial: 10,
        sortFieldInitial: 'name',
        fieldFilter: 'name',
        translate: false
    })

    const handleConfirmSchoolService = async () => {
        await CenterServices.confirmService(serviceId)
        navigate(-1)
    }

    const handleCreateServiceType = async () => {
        const body = {
            ...inputs,
            etapas: auxFunction.returnIds(inputs?.etapas)
        }
        dataFacade.create({serviceId, body})
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({newData}) => {
        newData.etapas = auxFunction.returnIds(newData?.etapas)
        const {_id, updatedAt, createdAt, active, __v, ...body} = newData
        dataFacade.edit({_id, body})
    }

    const headerOptions = {
        title: msg({id: 'groups'}),
        titleSuffix: `- ${serviceInfo?.name}`,
        handlerHeaderActions: {
            firstHandlerAction: toggle,
            confirmHandlerAction: handleConfirmSchoolService,
            isConfirmed: serviceInfo?.confirmed
        },
        searchOptions: localPagingAndFiltering.searchOptions
    }

    const importExportFilesOptions = {
        title: 'services',
        titleSuffix: serviceInfo?.name,
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
        ],
        exportService: getServiceTypes
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: localPagingAndFiltering?.data,
                editMode: 'row',
                onRowEditComplete,
                ...localPagingAndFiltering?.sortOptions,
            }}
            columns={[
                {
                    field: 'name',
                    header: 'name',
                    sortable: true,
                    ...editTextRowColumn
                },
                serviceInfo?.isByEtapa 
                && ({
                    className: 'items-column',
                    header: 'stages',
                    field: 'etapas',
                    body: (rowData) => bodyRenderTypes(rowData?.etapas || []),
                    ...editMultiSelectRowColumn({options: stages?.resp || []})
                }),
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ]}
        />
    )

    return (
        <div className='school-service'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={localPagingAndFiltering?.pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id='group.new'/>}
                context={(
                    <FormServiceTypes
                        inputs={inputs} 
                        isByEtapa={serviceInfo?.isByEtapa}
                        handleSave={handleCreateServiceType}
                        handleChange={onChangeInputs}
                    />
                )}
            />
        </div>
    ) 
}
export default SchoolService