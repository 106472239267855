import React from 'react'
import { roles } from '../../utils/constants/roles'
import { validators } from '../../utils/helpers/validators'
import SaveButton from '../common/buttons/SaveButton'
import LabeledDropdown from '../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledText from '../common/form-components/labeled-inputs/LabeledText'
import './formUser.scss'

const FormUser = ({ 
    inputs,
    handleChange,
    handleSave 
}) => {

    const validateForm = validators.isEntityValues([
        inputs?.name,
        inputs?.email,
        inputs?.role
    ]) && validators.emailFormat(inputs?.email)

    return (
        <div className='form-user'>
            <div className='modal-content-wrapper'> 
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={handleChange}
                    />
                    <LabeledText 
                        idLabel='email'
                        required
                        name='email'
                        value={inputs?.email}
                        onChange={handleChange}
                    />
                    <LabeledDropdown 
                        idLabel='role'
                        required
                        name='role'
                        value={inputs?.role}
                        optionLabel='name'
                        optionValue='_id'
                        options={roles}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>
            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormUser