import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import MainCommonView from "../../../../components/common/MainCommonView"
import EditButton from "../../../../components/common/buttons/EditButton"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import ExceededHoursDistributionForm from "../../../../components/maestros-template/exceeded-hours-distribution-form/ExceededHoursDistributionForm"
import Modal from "../../../../components/modal/Modal"
import { ExceededHoursDistributionService } from "../../../../services/templates-distribution-hours-school/templateDistributionHoursSchool"
import { getAll } from '../../../../services/typologies/typologies.slice'
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useModal from "../../../../utils/hooks/useModal"
import "./exceededHoursDistribution.scss"

const ExceededHoursDistribution = () => {

    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()

    const typologies = (useSelector(state => state?.typologies))?.resp

    const { isShowing, toggle } = useModal()
    const { isShowing: isShowingEdit, toggle: toggleEdit } = useModal()

    const [ dataRowLine, setDataRowLine ] = useState({})
    const [ expandedRows, setExpandedRows ] = useState(null)

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: ExceededHoursDistributionService.getAll,
        createAction: ExceededHoursDistributionService.create,
        removeAction: ExceededHoursDistributionService.remove,
        editAction: ExceededHoursDistributionService.update
    })

    useEffect(() => { 
        dispatch(getAll({}))
    }, [])

    const handleEdit = async (inputs) => {
        const resp = await dataFacade.edit({_id: inputs?._id, body: inputs})
        if (resp) toggleEdit()
    }

    const handleCreate = async (inputs) => {
        const resp = await dataFacade.create(inputs)
        if (resp) toggle()
    }

    const buttonEdit = (rowData) => (
        <EditButton onClick={() => toggleEdit() ?? setDataRowLine(rowData)}/>
    )

    const headerOptions = {
        title: msg({id: 'distribution.hours.foundation'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions,
    }

    const rowExpansionTemplate = (data) => {
        const expandedData = typologies.reduce((acc, typology) => {
            const typ = data?.tipologies?.find(t => t.typologyId === typology._id)
            return [
                {...acc[0], [typology?._id]: typology?.name},
                {...acc[1], [typology?._id]: typ?.minHours},
                {...acc[2], [typology?._id]: typ?.maxHours}
            ]
        }, [{ subject: 'typology' }, { subject: 'min' }, { subject: 'max' }])

        const typeTypologies = typologies.map((typology) => ({field: typology?._id}))

        return (
            <MaestrosDataTable 
                tableOptions={{
                    value: expandedData
                }}
                columns={[
                    {
                        field: "subject",
                        body: (rowData) => msg({id: rowData.subject}) 
                    },
                    ...typeTypologies
                ]}
            />
        )
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                responsiveLayout: "scroll",
                scrollHeight: "auto",
                ...dataFacade?.sortOptions,
                expandedRows,
                sortField: 'categoryName',
                rowExpansionTemplate,
                onRowToggle: (e) => { setExpandedRows(e?.data) },
                dataKey: "_id" 
            }}
            columns={([
                { expander: true,
                    key: "expander"
                },
                { field: "categoryName",
                    sortable: true,
                    header: 'category',
                },
                { field: "centerTeam",
                    sortable: true,
                    header: 'center.team',
                    body: (rowData) => rowData?.centerTeam?.name
                },
                { field: "description",
                    header: 'description'
                },
                { ...rowDelete({ handleDelete: dataFacade?.remove })},
                { className: 'row-edit',
                    body: buttonEdit,
                    exportable: false
                }
            ])}
        />
    )

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    return (
        <div>
            <MainCommonView
                headerOptions={headerOptions}
                componentData={dataTable} 
                pagerOptions={pagerOptions}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="900px"
                title={msg({id: 'distribution.hours.foundation.new'})}
                context={(
                    <ExceededHoursDistributionForm
                        handleSave={handleCreate}
                    />
                )} 
            />
            
            <Modal
                isShowing={isShowingEdit}
                hide={toggleEdit}
                width="90%"
                maxWidth="900px"
                title={msg({id: 'distribution.hours.foundation.edit'})}
                context={(
                    <ExceededHoursDistributionForm
                        handleSave={handleEdit}
                        dateEdit={dataRowLine}
                    />
                )} 
            />
        </div>
    )
}

export default ExceededHoursDistribution