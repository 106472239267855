
export const endpointsCenterPas = {
    PAS: (id) => `/v1/centers/courses/distributionHours/staffs/pas/course/${id}`,
    PAS_SUMARY: (id) => `/v1/centers/courses/distributionHours/staffs/pas/course/${id}/summary`,

    PAS_ID: (courseId, pasId) => `/v1/centers/courses/distributionHours/staffs/pas/course/${courseId}/pas/${pasId}`,
    PAS_ID_SUMARY: (id, pasId) => `/v1/centers/courses/distributionHours/staffs/pas/course/${id}/pas/${pasId}/summary`,

    CONFIRM: (id, pasId) => `/v1/centers/courses/distributionHours/staffs/pas/course/${id}/pas/${pasId}/confirm`,
    DISCONFIRM: (id, pasId) => `/v1/centers/courses/distributionHours/staffs/pas/course/${id}/pas/${pasId}/disconfirm`,
}