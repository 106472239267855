import { haveFilterQuery, paginator, haveSort } from "../common/auxFun"

export const endpointsStages = {
    GENERIC: '/v1/maestros/school/etapas',
    LOAD: ({filter, limit, skip, sort}) => `/v1/maestros/school/etapas${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
    BY_ETAPA: (etapaId) => `/v1/maestros/school/etapas/${etapaId}`,
    FIRST_LEVEL: ({filter, limit, skip, sort}) => `/v1/maestros/school/etapas/firstLvl/${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
    CONFIRM: (etapaId) => `/v1/maestros/school/etapas/${etapaId}/confirm`,
    LOAD_SUBETAPAS: ({ etapaId, filter, limit, skip, sort }) =>
        `/v1/maestros/school/etapas/${etapaId}/subetapas/${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
}