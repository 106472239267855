import React from 'react'
import { FormattedMessage } from 'react-intl'
import PasswordCom from '../common/form-components/password/PasswordComp'
import { validators } from '../../utils/helpers/validators'
import { ControlLabel } from '../common/form-components/label/Label'
import Button from '../common/buttons'
import DropdownComp from '../common/form-components/dropdown/DropdownComp'
import { Languages } from '../../utils/constants/languages'
import ProfileImg from './ProfileImg'
import useInputs from '../../utils/hooks/useInputs'
import "./profile-form.scss"
import InputTextComp from '../common/form-components/inputs/InputTextComp'


//TODO: Refactor
const ProfileForm = ({ 
    data,
    handleUpdateProfile,
}) => {

    const { inputs, onChangeInputs } = useInputs(data)

    const validateForm = () => {

        const { name, email, newPassword, confirmedPassword, currentPassword } = inputs
    
        const isEmailValid = validators.hasValue(email) && validators.emailFormat(email)

        const isPasswordValid = (
            validators.hasValue(newPassword) 
            && validators.equals(newPassword, confirmedPassword) 
            && validators.hasValue(currentPassword)
        ) || (
            !validators.hasValue(newPassword) 
            && !validators.hasValue(confirmedPassword)
        )
    
        return validators.hasValue(name) && isEmailValid && isPasswordValid
    }

    return (
        <div className='profile-form'>
            <form className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <div className='flex-container'>
                        <div className='profile-img'>
                            <ProfileImg />
                        </div>
                    </div>
                
                    <div className='flex-container'>
                        <ControlLabel id='name' required/>
                        <div className='control-input '>
                            <InputTextComp 
                                name='name'
                                value={inputs?.name}
                                onChange={(e) => onChangeInputs(e)}
                            />
                        </div> 

                        <ControlLabel id='email' required/>
                        <div className='control-input '>
                            <InputTextComp 
                                name='email'
                                value={inputs?.email}
                                onChange={(e) => onChangeInputs(e)}
                            />
                        </div>

                        <ControlLabel id='lang' required/>
                        <div className='control-input '>
                            <DropdownComp
                                name="lang"
                                value={inputs?.lang}
                                optionLabel="name"
                                optionValue="id"
                                options={Languages}
                                onChange={(e) => onChangeInputs(e)}
                            />
                        </div>
                    </div>
                </div> 
                
                <div className='control-wrapper'>
                    <div className='flex-container'>
                        <ControlLabel id='password.current' />
                        <div className='control-input '>
                            <PasswordCom 
                                name="currentPassword"
                                value={inputs?.currentPassword} 
                                onChange={(e) => onChangeInputs(e)} 
                                toggleMask
                            />
                        </div>
                    </div>
                    
                    <div className='flex-container'>
                        <ControlLabel id='password.new' />
                        <div className='control-input '>
                            <PasswordCom 
                                name="newPassword"
                                value={inputs?.newPassword} 
                                onChange={(e) => onChangeInputs(e)} 
                                toggleMask
                                feedback
                            />
                        </div>
                    </div>

                    <div className='flex-container'>
                        <ControlLabel id='password.confirm' />
                        <div className='control-input '>
                            <PasswordCom 
                                name="confirmedPassword"
                                value={inputs?.confirmedPassword} 
                                onChange={(e) => onChangeInputs(e)} 
                                toggleMask
                            />
                        </div>
                    </div>
                </div>
            </form>
            
            <div className='modal-btn-container '>
                <Button
                    id="saveUserProfile"
                    btnText={<FormattedMessage id="button.save"/>}
                    btnType='button'
                    btnClass="btn-save-modal"
                    isDisabled={!validateForm()}
                    onHandleClick={() => handleUpdateProfile(inputs)}
                />
            </div>
        </div>
    )
}

export default ProfileForm