import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../components/common/dataTable/MaestrosDataTable'
import FormAccount from '../../../../components/maestros-ecofin/accounts/FormAccount'
import Modal from '../../../../components/modal/Modal'
import { AccountsService } from '../../../../services/accounts/accounts.service'
import { bodyParseDate } from '../../../../components/common/dataTable/body/bodyColumnsTypes'
import editTextRowColumn from '../../../../components/common/dataTable/editors/editTextRowColumn'
import editBooleanRowColumn from '../../../../components/common/dataTable/editors/editBooleanRowColumn'
import rowDelete from '../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'
import CheckboxCom from '../../../../components/common/form-components/checkbox/CheckboxComp'

const Account = () => {
    const { accountId } = useParams()

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs} = useInputs()

    const [ account, setAccount ] = useState({})

    const getAllSubAccounts = async ({filter, limit, skip, sort}) => 
        AccountsService.getAllSubaccountsById({ id: accountId, filter, limit, skip, sort})
    const removeSubAccount = (subaccountId) => AccountsService.removeSubaccount(accountId, subaccountId)

    const dataFacade = useHandlerBasicActions({
        getAll: getAllSubAccounts, 
        rowsPerPageInitial: 15,
        sortFieldInitial: 'subaccount',
        createAction: AccountsService.createSubaccount,
        removeAction: removeSubAccount, 
        editAction: AccountsService.updateSubaccount
    })

    useEffect(() => {
        getAccountById()
    }, [accountId])

    const getAccountById = async () => {
        const resp = await AccountsService.getDataById(accountId) 
        if (resp) {
            setAccount(resp?.resp)
        }
    }

    const handleCreate = async () => {
        const resp = await dataFacade.create({_id: accountId, body: inputs})
        if (resp) {
            updateInputs({})
            toggle()
        }
    }

    const onRowEditComplete = async ({newData}) => {
        const body = {
            subaccount: newData?.subaccount,
            description: newData?.description,
            enabled: newData?.enabled
        }
        await dataFacade.edit({ _id: newData?._id, body})
    }

    const headerOptions = {
        title: msg({id: 'account'}),
        titleSuffix: account?.account,
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'subaccounts.list',
        titleSuffix: account?.account,
        columns: [
            { title: msg({ id: 'subaccount' }), dataKey: 'subaccount' },
            { title: msg({ id: 'description' }), dataKey: 'description' }
        ],
        exportService: () => AccountsService.getAllSubaccountsById({ id: accountId })
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const booleanEditor = (options) => (
        <CheckboxCom 
            checked={options?.value === false}
            onChange={({ target: { value } }) => options.editorCallback(!value)}
        />
    )

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,           
                rowClassName: (rowData) => 
                    rowData?.enabled === false ? 'disabled-text' : '',
                ...dataFacade?.sortOptions
            }}
            columns={([
                { field: 'subaccount',
                    sortable: true,
                    header: 'subaccount',
                    ...editTextRowColumn
                },
                { field: 'description',
                    header: 'description',
                    sortable: true,
                    ...editTextRowColumn
                },
                { field: 'createdAt',
                    header: 'created',
                    body: (rowData) => bodyParseDate({date: rowData?.createdAt})
                },
                { field: 'enabled',
                    header: 'enabled',
                    body: (rowData) => !rowData?.enabled && rowData?.enabled !== undefined 
                        ? bodyParseDate({date: rowData.updatedAt}) : '',
                    editor: booleanEditor,
                    ...editBooleanRowColumn.onCellEditComplete
                },
                { ...rowDelete({ handleDelete: dataFacade.remove })},
                { ...rowEditor()},
            ])}
        />
    )

    return (
        <div className='account'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id='subaccount.new'/>}
                context={(
                    <FormAccount
                        isSubaccount
                        inputs={inputs} 
                        handleSubmit={handleCreate}
                        handleChange={onChangeInputs}
                    />
                )}
            />
        </div>
    )
}
export default Account
