const getLocalRefreshToken = () => {
    const jwt = JSON.parse(localStorage.getItem("jwt"))
    return jwt.refreshToken
}

const getLocalAccessToken = () => {
    const jwt = JSON.parse(localStorage.getItem("jwt"))
    if (!jwt) return ''
    return jwt.accessToken
}

const updateLocalAccessToken = (token) => {
    const jwt = JSON.parse(localStorage.getItem("jwt"))
    jwt.accessToken = token
    localStorage.setItem("jwt", JSON.stringify(jwt))
}

const getJWT = () => {
    return JSON.parse(localStorage.getItem("jwt"))
}

const setJWT = (jwt) => {
    if (jwt) {
        localStorage.setItem("jwt", JSON.stringify(jwt)) 
    }
}

const removesetJWT = () => {
    localStorage.removeItem("jwt")
}

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getJWT,
    setJWT,
    removesetJWT
}
export default TokenService