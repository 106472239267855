import React from "react"
import LabeledText from "../../../../common/form-components/labeled-inputs/LabeledText"
import "./staffProfileSs.scss"

const StaffProfileSs = ({
    info,
    handleInputsChange,
    isReadOnly,
}) => {
    const changeOptions = {
        isReadOnly,
        disabled: isReadOnly,
        onChange: handleInputsChange
    }

    return (
        <div className="staff-profile-Ss">
            <LabeledText {...changeOptions} idLabel="affiliation.number.1" name="infoSs.naf1" value={info?.naf1} />
            <LabeledText {...changeOptions} idLabel="affiliation.number.2" name="infoSs.naf2" value={info?.naf2} />
            <LabeledText {...changeOptions} idLabel="affiliation.number.3" name="infoSs.naf3" value={info?.naf3} />
        </div>
    ) }

export default StaffProfileSs