import React from "react"
import { ControlLabel } from "../label/Label"
import CalendarComp from "../calendar/CalendarComp"

const LabeledCalendar = ({
    idLabel,
    required,
    name,
    value,
    onChange,
    dateFormat,
    hourFormat,
    disabled, 
    maxDate,
    minDate,
    view,
    showTime
}) => {

    return (
        <div className="flex-container">
            <ControlLabel id={idLabel} required={required}/>
            <div className="control-input">
                <CalendarComp
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e)}
                    showTime={showTime}
                    hourFormat={hourFormat}
                    dateFormat={dateFormat}
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={disabled}
                    view={view}
                />
            </div>
        </div>
    )
}

export default LabeledCalendar