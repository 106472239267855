import React, { useMemo } from "react"
import StaffProfileWorking from "./StaffProfileWorking"
import { isIterable } from "../../../../../utils/helpers/common"
import "./staffProfileWorking.scss"

const StaffProfileWorkingList = ({
    info,
    handleInputsChange,
    isReadOnly
}) => {
    const parseInfoData = (infoData) => isIterable(infoData) ? infoData : [infoData]

    const parsedInfo = useMemo(() => parseInfoData(info), info)

    return parsedInfo.map((infoData, index) => (
        <StaffProfileWorking 
            info={infoData} 
            index={index}
            isReadOnly={isReadOnly} 
            handleInputsChange={handleInputsChange} 
            key={Math.random()} />
    ))
}

export default StaffProfileWorkingList