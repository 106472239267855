
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import FormSchoolCourse from '../../../components/school-courses/FormSchoolCourse'
import swalAlert from '../../../utils/helpers/swalAlert'
import { getAllSchoolYear, removeSchoolYear, updateSchoolYear, createSchoolYear} from '../../../services/schoolYears/schoolYears.slice'
import SchoolYearService from '../../../services/schoolYears/schoolYears.service'
import useModal from '../../../utils/hooks/useModal'
import Modal from '../../../components/modal/Modal'
import useInputs from '../../../utils/hooks/useInputs'
import schoolYear from '../../../routes/maestros/school-year/schoolYear.pathname'
import useHandlerBasicActions from '../../../utils/hooks/base-adapter/useHandlerBasicActions'
import MainCommonView from '../../../components/common/MainCommonView'
import MaestrosDataTable from '../../../components/common/dataTable/MaestrosDataTable'
import editTextRowColumn from '../../../utils/helpers/data-table/column-types/editTextRowColumn'
import editCalendarRowColumn from '../../../components/common/dataTable/editors/editCalendarRowColumn'
import rowEditor from '../../../utils/helpers/data-table/column-types/rowEditor'
import rowDelete from '../../../utils/helpers/data-table/column-types/rowDelete'
import rowSeeWithTooltip from '../../../components/common/dataTable/body/rowSeeWithTooltip'
import { getFullYear } from '../../../utils/helpers/date'

const SchoolCourses = () => {

    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()

    const schoolYears = useSelector(state => state.schoolYears)
    
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs({})

    const dataFacade = useHandlerBasicActions({
        isUseDispatch: true,
        getAll: getAllSchoolYear, 
        sortFieldInitial: 'yearFrom',
        sortInit: -1,
        createAction: createSchoolYear,
        removeAction: removeSchoolYear, 
        editAction: updateSchoolYear
    })

    const handleSave = async () => {
        const data = {
            ...inputs,
            name: getFullYear(inputs?.yearFrom) + '-' + getFullYear(inputs?.yearTo),
            yearFrom: getFullYear(inputs?.yearFrom),
            yearTo: getFullYear(inputs?.yearTo) }
 
        const arrLastCourses = schoolYears.resp.map((x) => +x?.yearFrom)
        const existCours = _.includes(arrLastCourses, data?.yearTo)

        if (existCours) {
            swalAlert.error(msg({id: 'swal.exist.year.cours'})) 
            return
        } 

        await dataFacade.create(data)
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({ newData }) => {
        const data = _.omit(newData, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const body = {
            ...data, 
            name: getFullYear(data?.yearFrom) + '-' + getFullYear(data?.yearTo),
            yearFrom: getFullYear(data?.yearFrom),
            yearTo: getFullYear(data?.yearTo),
        }

        if (data?.yearFrom > data?.yearTo) {
            swalAlert.error(msg({id: 'swal.compare.years'}))
            return
        }
        const { _id } = newData
        dataFacade.edit({_id, body})
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const headerOptions = {
        title: msg({id: 'school.courses'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'school.courses',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'description'}), dataKey: 'description' }, 
            { title: msg({id: "year.start"}), dataKey: 'yearFrom' },
            { title: msg({id: "year.finish"}), dataKey: 'yearTo' }
        ],
        exportService: () => SchoolYearService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: schoolYears?.total
    }

    const data = (
        <MaestrosDataTable 
            tableOptions={{
                value: schoolYears?.resp,
                editMode: "row",
                onRowEditComplete, 
                ...dataFacade?.sortOptions
            }}
            columns={[
                { field: "name", header: 'name' },
                { field: 'yearFrom',
                    header: 'year.start',
                    sortable: true,
                    ...editCalendarRowColumn({ 
                        view: 'year', dateFormat: 'yy' })
                },
                { field: "yearTo",
                    header: 'year.finish',
                    ...editCalendarRowColumn({ 
                        view: 'year', dateFormat: 'yy' })
                },
                {field: "description",
                    header: 'description', 
                    ...editTextRowColumn({})
                },
                { ...rowDelete({ handleDelete: dataFacade.remove })},
                { ...rowEditor()},
                { field: "masters",
                    ...rowSeeWithTooltip({ 
                        textTooltip: msg({id: 'info.config'}),
                        onClick: (rowData) => navigate(schoolYear.MASTERS(rowData?._id))})
                }

            ]}
        />
    )

    return (
        <div className='school-courses'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                componentData={data}
                pagerOptions={pagerOptions}
            />        

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="550px"
                title={<FormattedMessage id='school.course.new'/>}
                context={(
                    <FormSchoolCourse 
                        inputs={inputs} 
                        handleSubmit={handleSave}
                        handleChange={onChangeInputs}
                    />
                )}
            />
        </div>
    )
}
export default SchoolCourses