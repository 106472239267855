import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import StatisticsService from "../../../../services/centers/courses/statistics/statistics.service"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"

const useTeamNavigation = () => {
    const { centerId, courseId, agroupationId, teamId } = useParams()
    const navigate = useNavigate()

    const [nextTeam, setNextTeam] = useState(null)
    const [prevTeam, setPrevTeam] = useState(null)

    useEffect(() => {
        getAllTeams()
    }, [courseId, agroupationId, teamId])

    const getAllTeams = useCallback(async () => {
        if (!courseId || !agroupationId) return
        const resp = (await StatisticsService.getTeamsByCourseIdAndAssociation(courseId, agroupationId))?.resp

        if (resp && resp?.teams.length > 0) {
            assignPrevAndNextTeam(resp?.teams)
        }
    }, [courseId, agroupationId, teamId])

    const assignPrevAndNextTeam = (teams) => {
        const currentIndex = teams?.findIndex(t => t?.centerTeam === teamId)
        const nextTeamFound = currentIndex < teams.length - 1 
            ? teams[currentIndex + 1]?.centerTeam : null
        const prevTeamFound = currentIndex > 0 
            ? teams[currentIndex - 1]?.centerTeam : null
        setNextTeam(nextTeamFound)
        setPrevTeam(prevTeamFound)
    }

    const handleNext = () => {
        if (nextTeam) {
            navigate(CENTER_COURSE_DETAILS.STATISTICS_BY_TEAM_ID(
                centerId,
                courseId,
                agroupationId,
                nextTeam
            ))
            return
        }
        navigate(CENTER_COURSE_DETAILS.STATISTICS_TEAMS_BY_AGROUPATION(centerId, courseId, agroupationId))
    }

    const handlePrevious = () => {
        if (prevTeam) {
            navigate(CENTER_COURSE_DETAILS.STATISTICS_BY_TEAM_ID(
                centerId,
                courseId,
                agroupationId,
                prevTeam
            ))
            return
        }
        navigate(CENTER_COURSE_DETAILS.STATISTICS_TEAMS_BY_AGROUPATION(centerId, courseId, agroupationId))
    }

    return {
        handleNext,
        handlePrevious,
    }
}

export default useTeamNavigation
