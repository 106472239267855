import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import MainCommonView from "../../../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../../../components/common/dataTable/MaestrosDataTable'
import { bodyRenderTypes } from '../../../../../../components/common/dataTable/body/bodyColumnsTypes'
import FormSubvention from '../../../../../../components/maestros-center/subventions/FormSubventions'
import Modal from '../../../../../../components/modal/Modal'
import { getAll as getAllSubsidyQuantification } from '../../../../../../services/center-subsidy-quantification/subsidyQuantification.slice'
import SubventionService from '../../../../../../services/center-subvention/subvention.service'
import EtapasService from '../../../../../../services/stages/etapas.service'
import { getAllEtapas } from '../../../../../../services/stages/etapas.slice'
import auxFunction from '../../../../../../utils/helpers/auxFun'
import editDropdownRowColumn from '../../../../../../components/common/dataTable/editors/editDropDownRowColumn'
import editNumberRowColumn from '../../../../../../components/common/dataTable/editors/editNumberRowColumn'
import editTextRowColumn from '../../../../../../components/common/dataTable/editors/editTextRowColumn'
import editTreeSelectRowColumn from '../../../../../../components/common/dataTable/editors/editTreeSelectRowColumn'
import rowDelete from '../../../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from "../../../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../../../utils/hooks/useInputs'
import useModal from '../../../../../../utils/hooks/useModal'

const Subventions = () => {

    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const schoolYears = useSelector(state => state?.schoolYears)
    const subsidyQuantification = useSelector(state => state?.subsidyQuantification)
    const [stages, setStages] = useState([])  

    const getStages = () => dispatch(getAllEtapas({}))
    const getSubsidyQuantifications = () => dispatch(getAllSubsidyQuantification({}))

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: SubventionService.getAll,
        createAction: SubventionService.create,
        removeAction: SubventionService.remove, 
        editAction: SubventionService.update
    })

    useEffect(() => {
        getStages()
        getSubsidyQuantifications()
        getFirstLevelStage()
    }, [])

    const getFirstLevelStage = async () => {
        const resp = await EtapasService.getFirstLevel({})
        if (resp) {
            setStages(resp)
        }
    }

    const handleCreate = async () => {
        await dataFacade.create(parseSubvetion(inputs))
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({ newData }) => {
        const { _id } = newData
        dataFacade.edit({_id, body: parseSubvetion(newData)})
    }

    const parseSubvetion = (data = {}) => {
        const getStageSelected = () => {
            if (_.isArray(data?.etapas) && data?.etapas?.length > 0) {
                return data?.etapas?.map(etapa => etapa?._id)
            }
            return Object.keys(data?.etapas)?.map(etapa => etapa)
        }
        return {
            ...data,
            etapas: getStageSelected(),
            subsidyQuantification: auxFunction.returnObjectId(data?.subsidyQuantification),
            schoolYear: auxFunction.returnObjectId(data?.schoolYear),
        }
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const headerOptions = {
        title: msg({id: 'subventions'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const exportService = async () => {
        let resp = (await SubventionService.getAll({}))?.resp
        resp = auxFunction.flattenArrayProperty(resp, 'etapas', 'name')
        resp = auxFunction.flattenArrayProperty(resp, 'subsidyQuantification', 'name')
        resp = auxFunction.flattenArrayProperty(resp, 'schoolYear', 'name')
        return { resp }
    }

    const importExportFilesOptions = {
        title: 'subventions',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'schoolYear'}), dataKey: 'schoolYear' },
            { title: msg({id: 'stages.groups'}), dataKey: 'etapas' },
            { title: msg({id: 'quantification.criterion'}), dataKey: 'subsidyQuantification' },
            { title: msg({id: 'subsidyQuantification'}), dataKey: 'total' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: "row",
                onRowEditComplete, 
                ...dataFacade?.sortOptions
            }}
            columns={([
                { field: "name",
                    frozen: true,
                    header: 'name',
                    sortable: true, 
                    ...editTextRowColumn
                },
                { field: "schoolYear",
                    sortable: true,
                    body: (rowData) => rowData.schoolYear?.name,
                    header: 'schoolYear',
                    ...editDropdownRowColumn({
                        options: schoolYears?.resp,
                        name: 'schoolYears'
                    })
                },
                { className: 'items-column',
                    header: 'stages.groups',
                    field: 'etapas',
                    body: (rowData) => bodyRenderTypes(rowData?.etapas),
                    ...editTreeSelectRowColumn({options: stages?.resp})
                },
                { field: "subsidyQuantification",
                    sortable: true,
                    body: (rowData) => rowData.subsidyQuantification?.name,
                    header: 'quantification.criterion',
                    ...editDropdownRowColumn({
                        options: subsidyQuantification?.resp,
                        name: 'subsidyQuantification'
                    })
                },
                { field: "total",
                    sortable: true, 
                    header: 'subsidyQuantification',
                    ...editNumberRowColumn({})
                },
                { field: "description",
                    header: 'description',
                    ...editTextRowColumn
                },
                { ...rowDelete({ handleDelete: dataFacade.remove })},
                { ...rowEditor()},
            ])}
        />
    )

    return (
        <div className='subventions'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="650px"
                title={<FormattedMessage id="subvention.new" />}
                context={(
                    <FormSubvention 
                        inputs={inputs} 
                        handleSave={handleCreate} 
                        handleChange={onChangeInputs} 
                    />
                )}
            />
        </div>
    )
}

export default Subventions
