import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsTemplate } from "./endpoints"

const TemplateService = {
    create: async (body, showSuccessAlert) => POST(endpointsTemplate.GENERIC, body, '', showSuccessAlert),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsTemplate.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body, showSuccessAlert}) => PATCH(endpointsTemplate.BY_ID(_id), body, '', showSuccessAlert),
    remove: async (id) => DELETE(endpointsTemplate.BY_ID(id)),
    uploadFile: async (id, body, showSuccessAlert) => POST(endpointsTemplate.BY_ID_FILE(id), body, '', showSuccessAlert),
}

export default TemplateService