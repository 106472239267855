import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'


const FoundationTeamForm = (
    {
        inputs, 
        handleChange,
        handleSave
    }
) => {

    const validateForm = validators.hasValue(inputs?.name) 
    
    return (
        <div className='form-foundation-teams'> 
            <div className='modal-content-wrapper'> 
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledTextArea 
                        idLabel='description'
                        name='description'
                        value={inputs?.description}
                        onChange={(e) => handleChange(e)}
                        numRows={3}
                        numColumns={15}    
                    />
                </div>
                <div className='modal-btn-container'>
                    <SaveButton
                        onClick={() => handleSave(inputs?._id)}
                        isDisabled={!validateForm}
                        btnClass="btn-save-modal"    
                    />
                </div>
            </div>
        </div>
        
    )

}

export default FoundationTeamForm