import { POST, GET, DELETE, PATCH } from '../common/commonRequest.service'
import { endpointsAccounts } from './endpoints'

export const AccountsService = {
    create: async (body) => POST(endpointsAccounts.GENERIC, body),
    createSubaccount: async ({ _id, body }) => POST(endpointsAccounts.SUBACCOUNTS.BY_ACCOUNT_CREATE(_id), body),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsAccounts.ACCOUNTS.LOAD({filter, limit, skip, sort})),
    getDataById: async (id) => GET(endpointsAccounts.ACCOUNTS.BY_ACCOUNT(id)),
    getAllSubaccountsById: async ({ id, filter, limit, skip, sort }) => GET(endpointsAccounts.SUBACCOUNTS.BY_ACCOUNT({id, filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsAccounts.ACCOUNTS.BY_ACCOUNT(_id), body),
    updateSubaccount: async ({ _id, body }) => PATCH(endpointsAccounts.SUBACCOUNTS.BY_SUBACCOUNT(_id), body),
    enableSubaccount: async (_id) => PATCH(endpointsAccounts.SUBACCOUNTS.BY_SUBACCOUNT_ENABLE(_id)),
    disableSubaccount: async (_id) => PATCH(endpointsAccounts.SUBACCOUNTS.BY_SUBACCOUNT_DISABLE(_id)),
    uploadFile: async (body) => POST(endpointsAccounts.ACCOUNTS.UPLOAD_FILE, body),
    remove: async (id) => DELETE(endpointsAccounts.ACCOUNTS.BY_ACCOUNT(id)),
    removeSubaccount: async (id, subaccountId) => DELETE(endpointsAccounts.SUBACCOUNTS.BY_ACCOUNT_AND_SUBACCOUNT(id, subaccountId)),
}
