import { Checkbox } from "primereact/checkbox"
import React from "react"
import { FormattedDate, FormattedMessage } from "react-intl"

const BodyRenderItems = ({
    items
}) => {
    return items && items.length > 0 ? (
        <div className="items-column">
            {items.map((item) => (
                !Array.isArray(item) ? (
                    <span key={Math.random()} className="item">
                        {typeof item === 'string' ? item : (item?.name || item?.subaccount || item?.label || item?.account)}
                    </span>
                ) : null
            ))}
        </div>
    ) : (
        <span className="sense-items">
            <FormattedMessage id="empty" />
        </span>
    )

}

export const bodyRenderTypes = (items) => (<BodyRenderItems items={items} />)


export const bodyParseDate = ({ date, dateTimeFormat = false }) => {
    const formatDate = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
    }
    const formatDataAndHour = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    }
 
    const format = dateTimeFormat ? formatDataAndHour : formatDate
 
    return (date && <FormattedDate value={date} {...format}/>)
}

export const bodyRenderBoolean = ({ value }) => <Checkbox checked={value} disabled />
export const bodyRenderBooleanYesNo = ({ value }) => <FormattedMessage id={value ? 'yes' : 'no'} />