import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const AddButton = ({
    id,
    btnAdd,
    onClick, 
    isDisabled,  
    ...propsAddButton
}) => {
    const { formatMessage: msg } = useIntl()

    const btnText = msg({id: (btnAdd || "button.add")})

    return (
        <Button
            id={id || "newCourse"}
            btnText={btnText}
            btnClass="button-white button-white__padding__8_15 action-cours-btn"
            isDisabled={false || isDisabled}
            onHandleClick={onClick}
            iconRight
            iconSize="0.8rem"
            iconClass="pi-plus"
            {...propsAddButton}
        />
    )
}

export default AddButton