import { haveFilterQuery, paginator, haveSort } from "../common/auxFun"

export const endpointsUsers = {
    GENERIC: '/v1/users',
    LOAD: ({filter, limit, skip, sort}) => `/v1/users${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
    BY_USER: (id) => `/v1/users/${id}`,
    PROFILE: '/v1/users/profile',
    UPDATE_IMAGE: '/v1/users/profile/image',
    RESETPASSWORD: '/v1/users/resetpassword',
    UPDATE_PASSWORD: '/v1/users/updatepassword'
}