import React from 'react'
import LabeledNumber from '../../common/form-components/labeled-inputs/LabeledNumber'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import "./form-salary.scss"

const FormSalary = ({ inputs }) => {
    return (
        <div className='form-salary modal-content-wrapper'>
            <div className='wrapper-flex'>
                <LabeledText 
                    idLabel='year'
                    value={inputs?.schoolYear?.name}
                    disabled
                />
                <LabeledText 
                    idLabel='code.and.category'
                    value={inputs?.categoryCode + "-" + inputs?.category}
                    disabled
                />
                <LabeledNumber 
                    idLabel='hours.week'
                    value={inputs?.concepts?.weeklyHours}
                    disabled
                />
                <LabeledNumber
                    idLabel='hours.year'
                    value={inputs?.calculations?.annualHours}
                    disabled
                />
                {
                    inputs?.accessories
                        ?.filter((accessory) => accessory?.value !== undefined)
                        .map((accessory) => (
                            <div className='flex-container' key={accessory.code}>
                                <LabeledNumber
                                    idLabel={accessory.name}
                                    value={accessory.value}
                                    disabled
                                />
                            </div>
                        ))
                }
                <LabeledNumber
                    idLabel='payments.amount'
                    value={inputs?.calculations?.numberOfPAyments}
                    disabled
                />
                <LabeledNumber
                    idLabel='baseSalary'
                    value={inputs?.concepts?.baseSalary}
                    disabled
                />
                <LabeledNumber
                    idLabel='triennial'
                    value={inputs?.concepts?.triennial}
                    disabled
                />
                <LabeledNumber
                    idLabel='grossTotalWithoutAntiquity'
                    value={inputs?.calculations?.montlyGrossTotalWithoutAntiquity}
                    disabled
                />
                <LabeledNumber
                    idLabel='grossAnualWithoutAntiquity'
                    value={inputs?.calculations?.annualGrossTotalWithoutAntiquity}
                    disabled
                />
                <LabeledNumber
                    idLabel='annualCostSS'
                    value={inputs?.calculations?.annualCostWithSS}
                    disabled
                />
                <LabeledNumber
                    idLabel='hourlyCostNoSS'
                    value={inputs?.calculations?.priceHourWithoutSS}
                    disabled
                />
            </div>
        </div>
    )
}

export default FormSalary