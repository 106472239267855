import React from "react"
import "./headerTitle.scss"

const HeaderTitle = ({ title, titleSuffix }) => {

    return (
        <div className="header-title-container">
            <h2 className="title__container" >
                {title} {titleSuffix}
            </h2>
        </div>
    )
}

export default HeaderTitle