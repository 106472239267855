/* eslint-disable no-underscore-dangle */
import axios from 'axios'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import env from '../../utils/constants/env-vars'
import endpointsAuth from '../auth/endpoints'
import TokenService from './token.service'

const API = axios.create({
    baseURL: env.URL_BACK_API,
})

API.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken()
        if (token) {
            config.headers.Authorization = 'Bearer ' + token // for Spring Boot back-end
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

API.interceptors.response.use(
    (res) => {
        return res
    },
    async (err) => {
        const originalConfig = err.config

        if (originalConfig?.url !== endpointsAuth.LOGIN && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true
                try {
                    const refreshToken = TokenService.getLocalRefreshToken()
                    if (refreshToken === null) {
                        window.location.href = "/login"
                    }
                    const rs = await API.post(endpointsAuth.REFRESH_TOKEN, {
                        refreshToken,
                    })
                    const { accessToken } = rs.data.resp.tokens
                    TokenService.updateLocalAccessToken(accessToken)
                    return API(originalConfig)
                } catch (_error) {
                    TokenService.removesetJWT()
                    storage.removeItem('persist:root')
                    return Promise.reject(_error)
                }
            }
        }
        return Promise.reject(err)
    }
)
export default API
