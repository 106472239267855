import React from "react"
import { onCellEditComplete } from "../common"
import InputTextComp from "../../../../components/common/form-components/inputs/InputTextComp"

const editTextRowColumn = ({ sortable = false }) => {

    const textEditor = (options) => (
        <InputTextComp
            value={options?.value}
            onChange={({ target: { value } }) => options.editorCallback(value)}
        />
    )

    return {
        sortable,
        editor: (options) => textEditor(options),
        onCellEditComplete, 
        // TODO: give key with sentido
        key: Math.random()
    }
}


export default editTextRowColumn