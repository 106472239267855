import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import _ from 'lodash'
import useFilter from "../get-list-scheme/useFilter"
import usePaginator from "../get-list-scheme/usePaginator"
import useSort from "../get-list-scheme/useSort"
import auxFunction from "../../helpers/auxFun"

// paginar, filtrar y ordenar datos locales
// TODO: add possibility to filter by more than one field (fieldFilter is a string now, but add support of the array of strings)
const useBasicLocalData = ({ 
    allData, 
    rowsPerPageInitial,
    sortFieldInitial,
    fieldFilter,
    translate = true
}) => {

    const { formatMessage: msg } = useIntl()

    const { limit, skip, firstRow, onPageChange } = usePaginator(rowsPerPageInitial)
    const { sortField, sortOrder, onSort } = useSort(sortFieldInitial)
    const { filter, updateFilter } = useFilter()

    const [ data, setData ] = useState(allData)
    const [filteredDataLength, setFilteredDataLength] = useState(allData?.length || 0)

    useEffect(() => {
        getFilterData()
    }, [filter, skip, limit, allData])

    const getFilterData = () => {
        let filteredData = []

        if (allData && Array.isArray(allData)) {
            const id = fieldFilter || 'id'

            filteredData = allData.map(element => {
                const fieldValue = auxFunction.getObjectDeepPropertyValue(element, id)

                return {
                    ...element,
                    name: `${fieldValue && ((translate && msg({ id: fieldValue })) || (!translate && fieldValue))}`
                }
            })

            if (filter !== '') {
                const resultFilter = filteredData.filter(element => auxFunction.isSubstringPresent(element?.name, filter))
                filteredData = resultFilter
            }

        }

        const sortedData = _.sortBy([...filteredData], sortFieldInitial || 'name')
        const paginateData = getPagedData(sortedData)

        setFilteredDataLength(filteredData?.length)
        setData(paginateData)
    }

    const getPagedData = (items) => {
        const initialIndex = skip * limit
        const finalIndex = initialIndex + limit
        return items.slice(initialIndex, finalIndex)
    }

    return {
        data,
        pagerOptions: {
            firstRow,
            limit,
            onPageChange,
            totalRecords: filteredDataLength
        },
        searchOptions: {
            updateFilter,
            filter
        },
        sortOptions: {
            sortField,
            sortOrder,
            onSort
        }
    }
}

export default useBasicLocalData