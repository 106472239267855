import { Column } from 'primereact/column'
import { ColumnGroup } from "primereact/columngroup"
import { Row } from "primereact/row"
import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from 'react-intl'
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import FormManagementPositions from "../../../../components/maestros-labor/management-positions/FormManagementPositions"
import Modal from "../../../../components/modal/Modal"
import CenterTeamsService from "../../../../services/center-teams/centerTeams.service"
import CollectiveAgreementsService from '../../../../services/collective-aggreements/collectiveAggreements.service'
import ManagementPositionsService from "../../../../services/management-positions/managementPositions.service"
import TypologiesService from "../../../../services/typologies/typologies.service"
import { getAllAndSetState } from "../../../../utils/helpers/common"
import editCalendarRowColumn from "../../../../utils/helpers/data-table/column-types/editCalendarRowColumn"
import editDropdownRowColumn from "../../../../components/common/dataTable/editors/editDropDownRowColumn"
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../utils/helpers/data-table/column-types/rowEditor"
import { DataTableFun } from "../../../../utils/helpers/dataTableFun"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'
import "./management-positions.scss"

// TODO: refactor this component
const ManagementPositions = () => {
    const {formatMessage: msg} = useIntl()

    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()
    const [ managerialTeams, setManagerialTeams] = useState()
    
    const [collectiveAgreements, setCollectiveAgreements] = useState([])
    const [typologies, setTypologies] = useState([])

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'year',
        getAll: ManagementPositionsService.getAll,
        createAction: ManagementPositionsService.create,
        removeAction: ManagementPositionsService.remove, 
        editAction: ManagementPositionsService.update
    })

    useEffect(() => {
        getAllAndSetState({ request: CenterTeamsService.getAllManagerial, setState: setManagerialTeams})
        getAllAndSetState({request: CollectiveAgreementsService.getAll, setState: setCollectiveAgreements })
        getAllAndSetState({request: TypologiesService.getAll, setState: setTypologies })
    }, [])

    const paymentInputsToParams = (paymentInputs) => {
        const isPrice = (value) => value || value === 0
        const isNewPrice = (value) => isPrice(value) && !value?.position
        const isEditedPrice = (value) => isPrice(value) && !paymentInputs[value?.position]

        return paymentInputs
            ? Object
                .entries(paymentInputs)
                .filter(([ value ]) => isNewPrice(value) || isEditedPrice(value))
                .map(([ position, value ]) => { 
                    return (value?.position && !paymentInputs[value?.position]) 
                        ? value
                        : { position, value: value ? +value : 0 } 
                })
            :[]
    }

    const handleCreate = async () => {
        inputs.year = new Date(inputs?.year)?.getFullYear().toString()
        inputs.delegatedPayment = paymentInputsToParams(inputs?.delegatedPayment)
        inputs.directSchoolPayment = paymentInputsToParams(inputs?.directSchoolPayment)

        const resp = await dataFacade.create(inputs)
        
        if (resp) {
            toggleModal()
        }
    }

    const onRowEditComplete = ({ newData }) => {
        const { _id } = newData
        dataFacade.edit({_id, body: paymentInputsToParams(newData)})
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const headerGroup = (
        <ColumnGroup>
            <Row>
                {/* TODO: peta on frozen */}
                <Column header={msg({ id: 'year' })} sortable rowSpan={3} /> 
                <Column header={msg({ id: 'agreement' })} sortable rowSpan={3} />
                <Column header={msg({ id: 'typology' })} rowSpan={3} />
                <Column header={msg({ id: 'delegatedPayment' })} colSpan={managerialTeams?.resp?.delegatedPayment?.length || 0} />
                <Column header={msg({ id: 'directSchoolPayment' })} colSpan={managerialTeams?.resp?.directSchoolPayment?.length || 0} />
                <Column
                    rowEditor
                    rowSpan={3}
                />
                <Column
                    rowEditor
                    rowSpan={3}
                    style={{minWidth: "7em"}}
                />
            </Row>
            <Row> 
                { managerialTeams?.resp?.delegatedPayment?.map((managerialTeam) => <Column key={managerialTeam.name} header={managerialTeam.name} />) }
                { managerialTeams?.resp?.directSchoolPayment?.map((managerialTeam) => <Column key={managerialTeam.name} header={managerialTeam.name} />) }
            </Row>
        </ColumnGroup>
    )

    const headerOptions = {
        title: msg({id: 'management.positions'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const getPaymentValueForManagerialTeam = (payments, managerialTeam) => {
        const filteredPayments = payments?.filter(payment => payment?.position === managerialTeam?._id)
        return filteredPayments.length > 0 ? filteredPayments[0]?.value : ''
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    // TODO: no funciona el editor
    const managerialTeamsPagementDelegat = managerialTeams?.resp?.delegatedPayment
        ? managerialTeams.resp.delegatedPayment.map((managerialTeam) => ({
            field: `${managerialTeam?._id}`,
            header: managerialTeam?.header,
            body: (rowData) => getPaymentValueForManagerialTeam(rowData?.delegatedPayment, managerialTeam),
            editor: (options) => DataTableFun.doubleEditor({
                ...options,
                name: `delegatedPayment.${managerialTeam?._id}`,
                value: options?.value || getPaymentValueForManagerialTeam(options.rowData.delegatedPayment, managerialTeam)
            })
        }))
        : []

    const managerialTeamsPagementDirect = managerialTeams?.resp?.directSchoolPayment 
        ? managerialTeams?.resp?.directSchoolPayment?.map((managerialTeam) => ({
            field: `delegatedPayment.${managerialTeam?._id}`,
            header: managerialTeam?.header,
            body: (rowData) => getPaymentValueForManagerialTeam(rowData?.directSchoolPayment, managerialTeam),
            editor: (options) => DataTableFun.doubleEditor({
                ...options,
                name: `directSchoolPayment.${managerialTeam?._id}`,
                value: options?.value || getPaymentValueForManagerialTeam(options.rowData.directSchoolPayment, managerialTeam)
            })
        })) 
        : []

    const dataControl = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                headerColumnGroup: headerGroup,
                ...dataFacade?.sortOptions
            }}
            columns={([
                {
                    field: "year",
                    sortable: true,
                    header: 'year',
                    ...editCalendarRowColumn({ 
                        view: 'year', dateFormat: 'yy' })
                },
                {
                    field: "agreement",
                    sortable: true,
                    header: 'agreement',
                    body: (rowData) => rowData?.agreement?.name,
                    ...editDropdownRowColumn({
                        options: collectiveAgreements?.resp,
                        name: 'agreement'
                    })
                },
                {
                    field: "typology",
                    header: 'typology',
                    body: (rowData) => rowData?.typology?.name,
                    ...editDropdownRowColumn({
                        options: typologies?.resp,
                        name: 'typology'
                    })
                },
                ...managerialTeamsPagementDelegat,
                ...managerialTeamsPagementDirect,
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ])}
        />
    )

    return (
        <div className="management-positions">
            <MainCommonView 
                headerOptions={headerOptions}
                componentData={dataControl}
                pagerOptions={pagerOptions}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="700px"
                title={<FormattedMessage id="management.positions.new" />}
                context={(
                    <FormManagementPositions 
                        inputs={inputs} 
                        handleSave={handleCreate}
                        handleChange={onChangeInputs} 
                        managerialTeams={managerialTeams?.resp}
                        collectiveAgreements={collectiveAgreements?.resp}
                        typologies={typologies?.resp}
                    />
                )}
            />

        </div>
    )


}

export default ManagementPositions