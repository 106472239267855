import { GET, PATCH } from "../../../common/commonRequest.service"
import { endpointsStatistics } from "./endpoints"

const StatisticsService = {
    getById: (courseId) => GET(endpointsStatistics.BY_ID(courseId)), 
    getSummary: async (courseId) => GET(endpointsStatistics.SUMMARY_BY_ID(courseId)),
    getAgroupations: async (courseId) => GET(endpointsStatistics.AGROUPATIONS_BY_COURSE_ID(courseId)),
    getAgroupationsSummary: async (courseId, agroupationId) => GET(endpointsStatistics.SUMMARY_BY_ETAPA(courseId, agroupationId)),
    getTeamsByCourseIdAndAssociation: async (courseId, agroupationId) => GET(endpointsStatistics.BY_COURSE_ID_AND_AGROUPATION_ID(courseId, agroupationId)),
    getTeamsByCourseIdAndAssociationSummary: async (courseId, agroupationId) => GET(endpointsStatistics.BY_COURSE_ID_AND_AGROUPATION_ID_SUMMARY(courseId, agroupationId)),
    getTeamByCourseId: async (courseId, teamId) => GET(endpointsStatistics.BY_COURSE_ID_AND_TEAM_ID(courseId, teamId)),
    updateTeamByCourseId: async (courseId, teamId, body) => PATCH(endpointsStatistics.BY_COURSE_ID_AND_TEAM_ID(courseId, teamId), body),
    confirmTeamByCourseId: async (courseId, teamId) => PATCH(endpointsStatistics.CONFIRM(courseId, teamId)),
    disconfirmTeamByCourseId: async (courseId, teamId) => PATCH(endpointsStatistics.DISCONFIRM(courseId, teamId)),
}

export default StatisticsService
