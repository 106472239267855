import React from "react"
import { InputText } from 'primereact/inputtext'
import "./inputComp.scss"

const InputTextComp = ({
    name,
    value, 
    onChange,
    ...inputProps
}) => {
    return (
        <InputText 
            name={name}
            value={value || ''}
            onChange={onChange}
            {...inputProps}
        />
    )
}

export default InputTextComp