import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { foudationCourse, foundation as foundationPath } from "../../routes/foundation/pathname"
import { FoundationCourseService } from "../../services/foundation-courses/foundation-courses.service"
import { getAllSchoolYear } from "../../services/schoolYears/schoolYears.slice"
import swalAlert from "../../utils/helpers/swalAlert"
import useModal from "../../utils/hooks/useModal"
// TODO: no usar CenterCoursesHeader
import CenterCoursesHeader from "../center-courses/header/CenterCoursesHeader"
import MenuCourses from "../common-courses/menu/MenuCourse"
import FormFoundationCourse from "../foundation-courses/new-foundation-course/FormFoundationCourse"
import Modal from "../modal/Modal"
import { getFoundation } from "../../services/foundation/foundation.slice"
import "./foundationMainCourses.scss"

const FoundationMainCourses = () => {

    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()

    const { isShowing: isShowingEdit, toggle: toggleEdit } = useModal()
    const { isShowing: isNoFoundationCoursesNotificationShowing, toggle: toggleNoFoundationCourseNotification } = useModal()

    const [ courses, setCourses ] = useState([])
    const [selectedCourseId, setSelectedCourseId] = useState(null)

    const foundation = useSelector(state => state.foundation)

    const getSchoolYears = () => dispatch(getAllSchoolYear({}))

    useEffect(() => {
        getSchoolYears()
        dispatch(getFoundation())
        getAllCourses()
    }, [])

    useEffect(() => {
        getLastAddedCourse()
    }, [courses])

    const getAllCourses = async () => {
        const coursesResponse = (await FoundationCourseService.get())?.resp
        if (coursesResponse && coursesResponse.length > 0) {
            setCourses(coursesResponse)
            getLastAddedCourse()
            return
        }
        toggleNoFoundationCourseNotification()
    }

    const getLastAddedCourse = () => {
        if (courses.length === 1) {
            setSelectedCourseId(courses[0])
            return
        }
        const lastCourseCreated = courses.reduce((latest, item) => {
            return (new Date(item.createdAt) > new Date(latest.createdAt)) ? item : latest
        }, courses[0])
        setSelectedCourseId(lastCourseCreated)
    }

    const deleteCourse = async (course) => {
        const courseDeleted = await FoundationCourseService.delete(course?._id)
        if (courseDeleted?.resp) {
            const updatedCourses = courses.filter(c => c._id !== course?._id)
            setCourses(updatedCourses)
        }
    }

    const handleNewCourse = async (data) => {
        let copy = false
        if (courses && courses.length > 0) {
            copy = await courseCopy()
            const existingCourse = courses.find(c => (c?.schoolYear?._id || c?.schoolYear) === data?.schoolYear)
            if (existingCourse) {
                swalAlert.error(
                    msg({ id: 'foundation.course.creation.error' })
                )
                return
            }
        }
        createCourse(data, copy)
    }

    const createCourse = async (data, copy) => {
        const courseData = copy ? {...data, isLastYearCopy: true} : data

        const newCourseResponse = await FoundationCourseService.create(courseData)
        if (newCourseResponse?.resp) {
            setCourses([...courses, newCourseResponse?.resp])
            getLastAddedCourse()
            toggleEdit()
        }
    }

    const courseCopy = async () => {
        const confirm = await swalAlert.warning(
            msg({id: "swal.title.statististic.copy"}),
            msg({id: "button.not"}),
            msg({id: "button.yes"})
        )
        return confirm
    }

    const handleDeleteFoundationCourse = async () => {
        const confirm = await swalAlert.warning( 
            msg({id: "swal.title.delete"}),
            msg({id: "button.cancel"}),
            msg({id: "button.delete"})
        )
        if (confirm.isConfirmed) {
            await deleteCourse(selectedCourseId)
        }
    }
 
    const handleChangeSelectedCourse = (shcoolYear) => {
        const newCourse = courses.find(c => c.schoolYear?._id === shcoolYear.value)
        setSelectedCourseId(newCourse)

    }

    const openNewCourseModal = () => {
        setSelectedCourseId(null)
        toggleEdit()
    }

    const openEditCourseModal = (courseId) => {
        const course = courses.find(c => c._id === courseId?._id)
        setSelectedCourseId(course)
        toggleEdit()
    }

    const handleCloseModal = () => {
        toggleEdit()
        getLastAddedCourse()
    }

    const menuNav = [
        { id: 'center', path: foundationPath.FOUNDATION },
        { id: 'statistics', path: foudationCourse.STADISTICS }
    ]

    return (
        // <div className="foundacion-container container__with-background">
        <div className="container container__with-background">
            {msg({id: "foundation"})}


            {/* <CenterCoursesHeader 
                courses={courses?.map(c => c?.schoolYear)}
                currentCourse={selectedCourseId?.schoolYear}
                onChangeCourse={handleChangeSelectedCourse}
                editEnable
                name={foundation?.resp?.name}
                onAdd={openNewCourseModal}
                onEdit={() => openEditCourseModal(selectedCourseId)}
                onDelete={handleDeleteFoundationCourse}
            />

            <section className="course-tabs">
                <MenuCourses menuNav={menuNav} />
            </section>
            
            <Modal
                isShowing={isShowingEdit}
                hide={handleCloseModal}
                width='90%'
                maxWidth='550px'
                title={<FormattedMessage id={selectedCourseId ? 'course.edit' : 'course.new'} />}
                context={(
                    <FormFoundationCourse
                        course={selectedCourseId}
                        handleSubmit={handleNewCourse} 
                    />
                )}
            />

            <Modal
                isShowing={isNoFoundationCoursesNotificationShowing}
                hide={toggleNoFoundationCourseNotification}
                context={<div className="no-courses-illustration"><h2><FormattedMessage id="foundation.info.without.cours" /></h2></div>}
                className="no-courses-notification-modal"
            /> */}
            
        </div>
    
    )
}

export default FoundationMainCourses