import React from "react"
import { useIntl } from "react-intl"
import { validators } from "../../../utils/helpers/validators"
import StrucutreLayout from "./StrucutreLayout"
import LabeledMultiSelect from "../../common/form-components/labeled-dropdown/LabeledMultiSelect"
import LabeledDropdown from "../../common/form-components/labeled-dropdown/LabeledDropdown"

const SubserviceSelection = ({
    title,
    data,
    onChangeInputs, 
    propsButtons, 
    isLastScreen,
    isFirstScreen,
    disabledSaveButton

}) => {
    const { formatMessage: msg } = useIntl()

    const personalTypes = [
        { name: msg({id: 'intern'}), id: 'intern'},
        { name: msg({id: 'extern'}), id: 'extern'},
        { name: msg({id: 'mixed'}), id: 'mixed'}
    ]

    const onChange = (e) => {
        const { name, value } = e.target
        let updateData = data
        if (name === 'subservices') {
            updateData = {
                subservices: value?.map(x => ({subservice: x, isSubventioned: false}))
            }
        }
        if (name === 'personalType') {
            updateData = {
                ...data,
                personalType: value
            }
        }
        if (name === 'subsidized') {
            const updateSubservices = data?.subservices?.map(subservice => ({
                ...subservice,
                isSubventioned: !!value.find(x => x === subservice?.subservice)
            }))

            updateData = {
                ...data,
                subservices: updateSubservices
            }
        }

        onChangeInputs(updateData)
    }

    const subservicesValue = data?.subservices?.map(subservice => subservice?.subservice) 
    const subservicesSubsidized = data?.subservices?.filter(subservice => 
        subservice.isSubventioned)?.map(subservice => subservice?.subservice)

    const validate = validators.isEntityValues([data?.subservices, data?.personalType ])

    return (
        <StrucutreLayout 
            title={title}
            {...propsButtons}
            isLastScreen={isLastScreen}
            isFirstScreen={isFirstScreen}
            disabledNextButton={!validate}
            disabledSaveButton={disabledSaveButton}
            context={(
                <div className="bg-structure-box"> 
                    <div className="inline-items">
                        <LabeledMultiSelect
                            idLabel={data?.name}
                            translate={false}
                            required
                            name='subservices'
                            onChange={onChange}
                            options={data?.serviceTypes}
                            optionLabel='name'
                            value={subservicesValue}
                        />
                      
                        <LabeledDropdown 
                            idLabel='personal.type'
                            required
                            name='personalType'
                            onChange={onChange}
                            options={personalTypes}
                            optionLabel='name'
                            optionValue='id'
                            value={data?.personalType}
                        />
                    </div>
                    <LabeledMultiSelect
                        idLabel='services.subsidized'
                        name='subsidized'
                        onChange={onChange}
                        options={subservicesValue ?? []}
                        optionLabel='name'
                        value={subservicesSubsidized}
                    />
                </div>
            )}
        />
    )
}

export default SubserviceSelection