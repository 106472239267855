import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledNumber from '../../common/form-components/labeled-inputs/LabeledNumber'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledCheckbox from '../../common/form-components/labeled-checkbox/LabeledCheckbox'
import './formEtapa.scss'

const FormSubetapa = ({ 
    inputs,
    handleChange, 
    handleSave,
}) => {

    const validateForm = validators.isEntityValues([inputs?.name, inputs?.order ])

    return (
        <div className='form-etapa'>
            <div className='modal-content-wrapper'> 
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledNumber 
                        idLabel='order'
                        required
                        name='order'
                        value={inputs?.order}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
    
                <div className='control-wrapper'>
                    <LabeledCheckbox 
                        idLabel='stage.treat.as.time.period'
                        name='isLevelOfDistribution'
                        onChange={(e) => handleChange(e)}
                        checked={inputs?.isLevelOfDistribution}
                        tooltip="info.isLevelOfDistribution"
                    />
                </div>
            </div>
            <div className='modal-btn-container'>
                <SaveButton 
                    btnClass="btn-save-modal"
                    isDisabled={!validateForm}
                    onClick={() => handleSave(inputs?._id)}
                />
            </div>
        </div>
    )

}

export default FormSubetapa 