import React, { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import CardList from "../../../../components/card-list/CardList"
import MAESTROS_KEY_ROUTE_MAP from '../../../../utils/constants/maestros/maestros.keys'
import SchoolYearService from "../../../../services/schoolYears/schoolYears.service"
import { maestrosKeys } from '../../../../utils/constants/maestros/maestros'
import useBasicLocalData from "../../../../utils/hooks/base-adapter/useBasicLocalData"
import MainCommonView from "../../../../components/common/MainCommonView"
import "./schoolYearMaster.scss"

const SchoolYearMasters = () => {

    const { formatMessage: msg } = useIntl()
    const { schoolYearId } = useParams()
    const [ schoolYearData, setSchoolYearData ] = useState([])

    const dataFacade = useBasicLocalData({
        allData: schoolYearData?.masters,
        rowsPerPageInitial: 10,
        sortFieldInitial: 'position',
        fieldFilter: '_id'
    })

    useEffect(() => {
        getSchoolYearById()
    }, [])

    const goToCardLink = (id) => MAESTROS_KEY_ROUTE_MAP[id]

    const getSchoolYearById = async () => {
        const resp = await SchoolYearService.getById(schoolYearId)
        if (resp) {
            const mastersCards = toCartItems(resp?.resp?.masters)
            setSchoolYearData({...resp?.resp, masters: mastersCards})
        }
    }

    const toCartItems = (masterArray) => {
        const allMasters = Object.values(maestrosKeys)

        const cartItems = masterArray?.map((master) => {
            return allMasters.includes(master.master)
                ? {
                    confirmation: { isConfirm: master.status },
                    name: msg({ id: master.master }),
                    _id: master.master,
                    key: master.master
                }
                : null
        }).filter(Boolean)
    
        return cartItems
    }

    const headerOptions = {
        title: msg({ id: 'course.confirmed.masters' }) + ' ' + schoolYearData?.name,
        searchOptions: dataFacade?.searchOptions
    }

    return (
        <div className="school-year-master container__with-background ">
            <MainCommonView 
                componentData={(
                    <CardList 
                        list={dataFacade.data}
                        gotToLink={goToCardLink}
                    /> 
                )}
                headerOptions={headerOptions}
                pagerOptions={dataFacade?.pagerOptions}
                showLegend
            />
        </div>
    )
}

export default SchoolYearMasters