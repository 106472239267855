import { createSlice } from '@reduxjs/toolkit'
import ContractTypeService from './contractTypes.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('contractType/create', ContractTypeService.create)

export const getAll = createAsyncThunkMiddleware('contractType/getAll', ContractTypeService.getAll)

export const update = createAsyncThunkMiddleware('contractType/update', ContractTypeService.update)

export const remove = createAsyncThunkMiddleware('contractType/delete', ContractTypeService.remove)

const initialState = []

const contractTypeSlice = createSlice({
    name: 'contractTypes',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove),
})

export default contractTypeSlice.reducer
