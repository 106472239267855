import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from "react-redux"
import swalAlert from "../../helpers/swalAlert"
import useSort from "../get-list-scheme/useSort"
import usePaginator from "../get-list-scheme/usePaginator"
import useFilter from "../get-list-scheme/useFilter"
import { isIterable } from '../../helpers/common'
import useLoading from "../useLoading"

const useHandlerBasicActions = ({
    isUseDispatch,
    sortFieldInitial,
    sortInit,
    rowsPerPageInitial,
    getAll,
    createAction, 
    removeAction, 
    editAction,
    additionalDependency
}) => {

    const dispatch = useDispatch()
    const { formatMessage: msg } = useIntl()

    const { sortField, sortOrder, onSort, sort } = useSort(sortFieldInitial, sortInit)
    const { firstRow, skip, limit, onPageChange } = usePaginator(rowsPerPageInitial)
    const { filter, updateFilter } = useFilter()

    const [ data, setData ] = useState({})

    // TODO: Warning, this is global loading state for ALL DataTables
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getAllItems() 
    }, [filter, limit, skip, sort, additionalDependency])

    const getAllItems = async () => {
        startLoading()
        if (isUseDispatch) {
            dispatch(getAll({filter, limit, skip, sort }))
            return
        }
        const response = await getAll({filter, limit, skip, sort })
        if (response) setData(response)
        stopLoading()
    }

    const create = async (inputs) => {
        let response

        if (isUseDispatch) {
            response = (await dispatch(createAction(inputs))).payload
        } else {
            response = await createAction(inputs)

            updateStateOnCreate(response)
        }

        return response
    }

    const remove = async (id) => {
        const confirm = await swalAlert.warning( 
            msg({id: "swal.title.delete"}),
            msg({id: "button.cancel"}),
            msg({id: "button.delete"})
        )

        let response

        if (confirm.isConfirmed) {
            if (isUseDispatch) {
                response = await (dispatch(removeAction(id)))?.payload
            } else {
                response = await removeAction(id)

                updateStateOnRemove(response)
            }
            
        }

        return response
    }

    const edit = async ({_id, body}) => {
        let response

        if (isUseDispatch) {
            response = await (dispatch(editAction({_id, body})))?.payload
        } else {
            response = await editAction({_id, body})

            updateStateOnEdit(response)
        }

        return response
    } 

    // TODO: DRY updateStateOnCreate and updateStateOnEdit
    const updateStateOnCreate = (response) => {
        if (response) {
            const resp = isIterable(response?.resp) ? response?.resp : [response?.resp]

            setData(prev => {
                if (!isIterable(data?.resp)) return response

                const updatedExistingItems = { 
                    ...prev, 
                    resp: prev.resp.map((s) => {
                        const index = resp?.findIndex(item => item?._id === s._id)

                        return index === -1 ? s : resp?.splice(index, 1)?.[0]
                    })
                }

                const updatedAllItems = {
                    ...updatedExistingItems,
                    resp: [...updatedExistingItems?.resp, ...resp],
                    total: +prev.total + (resp?.length || 1)
                }

                return updatedAllItems

            })
                
        }
    }

    const updateStateOnRemove = (response) => {
        if (response) {
            setData(prev => isIterable(prev?.resp)
                ? {
                    ...prev, 
                    resp: prev?.resp.filter((d) => !(d._id === response?.resp?._id || d._id === response?.resp)),
                    total: +prev?.total - 1
                }
                : response)
        }
    }

    const updateStateOnEdit = (response) => {
        if (response) {
            const resp = isIterable(response?.resp) ? response?.resp : [response?.resp]

            setData(prev => {
                if (!isIterable(data?.resp)) return response
                
                const updatedExistingItems = { 
                    ...prev, 
                    resp: prev.resp.map((s) => {
                        const index = resp?.findIndex(item => item?._id === s._id)

                        return index === -1 ? s : resp?.splice(index, 1)?.[0]
                    })
                }

                const updatedAllItems = {
                    ...updatedExistingItems,
                    resp: [...updatedExistingItems?.resp, ...resp],
                    total: +prev.total + (resp?.length || 1)
                }

                return updatedAllItems
            })
        }
    }

    return {
        loading,
        create, 
        remove, 
        edit,
        data,
        updateStateOnCreate,
        updateStateOnEdit,
        reload: getAllItems,
        pagerOptions: {
            firstRow,
            limit,
            onPageChange
        },
        searchOptions: {
            updateFilter,
            filter
        },
        sortOptions: {
            sortField,
            sortOrder,
            onSort
        }
    }
}

export default useHandlerBasicActions