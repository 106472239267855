import _ from 'lodash'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import MainCommonView from "../../../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../../../components/common/dataTable/MaestrosDataTable'
import { bodyRenderTypes } from '../../../../../../components/common/dataTable/body/bodyColumnsTypes'
import SubtypeForm from '../../../../../../components/maestros-center/expense-type/SubtypeForm'
import Modal from '../../../../../../components/modal/Modal'
import SubtypesService from '../../../../../../services/center-accounting/subtypes/subtypes.service'
import editChipsRowColumn from '../../../../../../components/common/dataTable/editors/editChipsRowColumn'
import editTextRowColumn from '../../../../../../components/common/dataTable/editors/editTextRowColumn'
import rowDelete from '../../../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from "../../../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../../../utils/hooks/useInputs'
import useModal from '../../../../../../utils/hooks/useModal'
import InputChipsComp from '../../../../../../components/common/form-components/inputs/InputChipComp'

const Subtypes = () => {

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: SubtypesService.getAll,
        createAction: SubtypesService.create,
        removeAction: SubtypesService.remove, 
        editAction: SubtypesService.update
    })

    const handleCreate = async () => {
        await dataFacade.create(inputs)
        updateInputs({})
        toggle()
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const onRowEditComplete = async ({newData}) => {
        const body = _.omit(newData, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const { _id } = newData
        dataFacade.edit({_id, body})
    }

    const subtypeOptions = (options) => {
        return (
            <InputChipsComp
                name="group" 
                value={options.value} 
                onChange={({ target: { value } }) => options.editorCallback(value)} 
                separator="," 
            />
        )
    }

    const headerOptions = {
        title: msg({id: 'subtypes'}),
        auxInfoText: msg({id: 'info.subtypes' }),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'subtypes',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'options'}), dataKey: 'group' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: () => SubtypesService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={[
                { field: "name",
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                { className: 'items-column',
                    header: 'options',
                    field: 'group',
                    body: (rowData) => bodyRenderTypes(rowData?.group),
                    ...editChipsRowColumn({options: subtypeOptions?.value})
                },
                { field: "description",
                    header: 'description',
                    ortable: false,
                    ...editTextRowColumn
                },
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ]}
        />
    )

    return (
        <div className='subtypes-expense-types'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={() => {
                    toggleModal()
                    updateInputs({})
                }}
                width="90%"
                maxWidth="550px"
                title={<FormattedMessage id="subtypes.new" />}
                context={(
                    <SubtypeForm 
                        inputs={inputs} 
                        handleSave={handleCreate} 
                        handleChange={onChangeInputs} 
                    />
                )}
            />

        </div>
    )
}

export default Subtypes
