import React from "react"
import { Navigate, Outlet, } from 'react-router-dom'
import { pathname } from "../../../routes/pathname"

const ProtectedRoute = ({
    isAllowed,
    redirectPath = pathname.LOGIN,
    children,
}) => {
    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />
    }
    return children || <Outlet/>
}

export default ProtectedRoute 