import { useState } from "react"

const useLoading = (initState = false) => {
    const [ loading, setLoading ] = useState(initState)

    const startLoading = () => setLoading(true)

    const stopLoading = () => setLoading(false)

    // TODO: Refactor [ loading, startLoading, stopLoading ], as useState does.
    return { loading, startLoading, stopLoading }

}

export default useLoading