import React from "react"
import { useIntl } from "react-intl"
import useExport from "../../../utils/hooks/useExport"
import Button from "../buttons"
import DownloadButton from "../buttons/DownloadButton"
import './importExportFiles.scss'

const ImportExportFiles = ({
    title, 
    titleSuffix,
    columns,
    exportService,
    importService, 
}) => {
    
    const { formatMessage: msg } = useIntl()

    const tetleText = `${title && msg({id: title})} ${titleSuffix ?? ''}`

    const { exportPDF, exportXLS } = useExport({ title: tetleText, columns})

    return (
        (exportService || importService) && (
            <div className="main-exports-content">
                {
                    exportService && (
                        <div className="butons-exports">
                            <DownloadButton 
                                btnText="PDF"
                                onClickDownload={() => exportPDF(exportService)}
                            />
                            <DownloadButton 
                                btnText="XLS"
                                onClickDownload={() => exportXLS(exportService)}
                            />
                        </div> 
                    )
                }
                {
                    importService && (
                        <Button
                            id="importCsvBtn"
                            btnText={msg({ id: 'import' })}
                            btnType="button"
                            btnClass="button-white btn-export mr-0"
                            onHandleClick={() => importService()}
                            iconRight
                            iconSize="0.9rem"
                            iconClass="pi-upload"
                        />
                    )
                }
            </div>
        )
    )
}

export default ImportExportFiles