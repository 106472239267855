import React from "react"
import { FileUpload } from 'primereact/fileupload'
import { useIntl } from "react-intl"
import env from '../../utils/constants/env-vars'
import { endpointsUsers } from "../../services/users/endpoints"
import "./upload-img.scss"

const UploadImage = ({
    handleUpdateImage,
    
}) => {
    const intl = useIntl()

    const onUpload = async (e) => {
        const file = e.files[0]
        const formData = new FormData()
        formData.append("image", file)
        await handleUpdateImage(formData)
        e.options.clear()
    }

    return (
        <FileUpload
            url={env.URL_BACK_API + endpointsUsers.UPDATE_IMAGE}
            mode="basic"
            name="demo[]"
            accept="image/*"
            maxFileSize={1000000}
            customUpload
            uploadHandler={(e) => onUpload(e)}
            chooseLabel={intl.formatMessage({ id: 'button.choose.img' })} 
        />
    )
}

export default UploadImage