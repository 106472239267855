import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { getAll } from '../../../services/territorial-services/territorialServices.slice'
import Modal from '../../modal/Modal'
import useModal from '../../../utils/hooks/useModal'
import FormEditCenter from "../form-edit-center/CenterForm"
import IconButtonEdit from "../../common/buttons/IconButtonEdit"
import CentersService from '../../../services/centers/centers.service'
import InfoCard from '../../card/InfoCard'
import service from '../../../assets/imgs/center-panel-icons/servei-territorial.svg'
import codicentre from '../../../assets/imgs/center-panel-icons/codi-centre.svg'
import address from '../../../assets/imgs/center-panel-icons/direccio-centre.svg'
import telf from '../../../assets/imgs/center-panel-icons/telefon.svg'
import email from '../../../assets/imgs/center-panel-icons/mail.svg'
import web from '../../../assets/imgs/center-panel-icons/web.svg'
import useInputs from '../../../utils/hooks/useInputs'
import './centerData.scss'  

const CenterData = ({ center }) => {

    const dispatch = useDispatch()

    const {isShowing, toggle} = useModal()
    const {inputs, onChangeInputs, updateInputs} = useInputs({})

    const [ localCenter, setLocalCenter ] = useState(center)

    const getAllTerritorialsServices = () => dispatch(getAll({}))

    useEffect(() => {
        getAllTerritorialsServices()
    }, [])

    const handleUpdateCenter = async () => {
        console.log('handleUpdateCenter', inputs)
        const resp = await CentersService.update({_id: inputs?._id, body: inputs})
        if (resp) setLocalCenter(resp?.resp)
        toggle()
    }

    const openEditModal = () => {
        updateInputs(center)
        toggle()
    }

    const infoCards = [{
        src: service,
        id: 'territorial.service',
        subtitle: center?.territorialService?.name
    }, {
        src: codicentre,
        id: 'reference.concerted',
        subtitle: center?.concertedCenterCode
    }, {
        src: address,
        id: 'address',
        subtitle: center?.address + ', ' + center?.population + ', ' + center?.province
    }, {
        src: telf,
        id: 'telf',
        subtitle: center?.telf
    }, {
        src: email,
        id: 'email',
        subtitle: center?.email 
            ? <a href={`mailto:${center?.email}`} > {center?.email}</a> 
            : '-'
    }, {
        src: web,
        id: 'WEB',
        subtitle: (
            <a 
                id='web' 
                className='data-info__text' 
                href={center?.web} 
                target="_blank" 
                rel="noreferrer"> 
                { center?.web ? center?.web : '-'}
            </a>
        )
    }]

    const localInfoCards = [{
        src: service,
        id: 'territorial.service',
        subtitle: localCenter?.territorialService?.name
    }, {
        src: codicentre,
        id: 'reference.concerted',
        subtitle: localCenter?.concertedCenterCode
    }, {
        src: address,
        id: 'address',
        subtitle: localCenter?.address + ', ' + localCenter?.population + ', ' + localCenter?.province
    }, {
        src: telf,
        id: 'telf',
        subtitle: localCenter?.telf
    }, {
        src: email,
        id: 'email',
        subtitle: localCenter?.email 
            ? <a href={`mailto:${center?.email}`} > {localCenter?.email}</a> 
            : '-'
    }, {
        src: web,
        id: 'WEB',
        subtitle: (
            <a 
                id='web' 
                className='data-info__text' 
                href={localCenter?.web} 
                target="_blank" 
                rel="noreferrer"> 
                { localCenter?.web ? localCenter?.web : '-'}
            </a>
        )
    }]

    return (
        <>
            <div className='center-data__container'>
                <IconButtonEdit onEdit={openEditModal} />
                <div className='center-data__container--data' data-refresh={inputs}>
                    {
                        localInfoCards.map(card => (
                            <InfoCard
                                item={card} 
                                key={card?._id || Math.random()}
                            />
                        ))
                    }
                </div>
            </div>

            <Modal 
                isShowing={isShowing}
                hide={toggle}
                width='100%'
                maxWidth='750px'
                title={<FormattedMessage id='center.edit'/>}
                context={(
                    <FormEditCenter
                        updateInputs={updateInputs}
                        // inputs={inputs}
                        centerId={inputs?._id}
                        handleChange={onChangeInputs}
                        handleSave={handleUpdateCenter}
                    />
                )}
            />
        </>
    )
}

export default CenterData

