import React from "react"
import Statistics from "../../../views/centers/center-course/statistics/Statistics"
import StatisticsSublistAgroupation from "../../../views/centers/center-course/statistics/StatisticsSublistAgroupation"
import Structure from "../../../views/centers/center-course/structure/Structure"
import EducationOfferAndService from "../../../views/centers/center-course/education-offer-and-service/EducationOfferAndService"
import HoursAndStaff from "../../../views/centers/center-course/hours-and-staff/HoursAndStaff"
import EducationOffer from "../../../views/centers/center-course/education-offer-and-service/EducationOffer"
import ClassRoomAndStudents from "../../../views/centers/center-course/education-offer-and-service/education-offer-and-service-tables/ClassRoomAndStudents"
import Nee from "../../../views/centers/center-course/education-offer-and-service/education-offer-and-service-tables/Nee"
import Service from "../../../views/centers/center-course/education-offer-and-service/education-offer-and-service-tables/Service"
import EducationServices from "../../../views/centers/center-course/education-offer-and-service/EducationServices"
import CenterCourseSummary from "../../../views/centers/center-course/CenterCourseSummary"
import CalculHours from "../../../views/centers/center-course/hours-and-staff/CalculHours"
import HoursAndStaffView from "../../../views/centers/center-course/hours-and-staff/HoursAndStaffView"
import DistributionHours from "../../../views/centers/center-course/hours-and-staff/DistributionHours"
import FoundationTeamsHours from "../../../views/centers/center-course/hours-and-staff/tables/FoundationTeamsHours"
import SchoolTeamsHours from "../../../views/centers/center-course/hours-and-staff/tables/SchoolTeamsHours"
import Staff from "../../../views/centers/center-course/hours-and-staff/tables/Staff"
import StatisticalData from "../../../views/centers/center-course/statistics/StatisticalData"
import Budgets from "../../../views/centers/center-course/budgets/Budgets"
import Expenses from "../../../views/centers/center-course/budgets/expenses/Expenses"
import Incomes from "../../../views/centers/center-course/budgets/incomes/Incomes"
import Results from "../../../views/centers/center-course/budgets/results/Results"
import BudgetConcepts from "../../../views/centers/center-course/budgets/incomes/other-incomes/BudgetConcepts"
import ConceptAccounts from "../../../views/centers/center-course/budgets/incomes/other-incomes/ConceptAccounts"
import OtherIncomesData from "../../../views/centers/center-course/budgets/incomes/other-incomes/OtherIncomesData"
import OtherIncomesTable from "../../../views/centers/center-course/budgets/incomes/other-incomes/tables/OtherIncomesTable"
import Pas from "../../../views/centers/center-course/hours-and-staff/pas/Pas"
import PasChildrens from "../../../views/centers/center-course/hours-and-staff/pas/PasChildrens"
import PasTable from "../../../views/centers/center-course/hours-and-staff/pas/PasTable"
import AgroupationsTable from "../../../views/centers/center-course/budgets/incomes/other-incomes/tables/AgroupationsTable"
import ActivityIncomes from "../../../views/centers/center-course/budgets/incomes/activity-incomes/ActivityIncomes"
import FeesIncomes from "../../../views/centers/center-course/budgets/incomes/activity-incomes/FeesIncomes"
import EtapaFeesIncomes from "../../../views/centers/center-course/budgets/incomes/activity-incomes/EtapaFeesIncomes"
import GeneralFeesTable from "../../../views/centers/center-course/budgets/incomes/activity-incomes/tables/GeneralFeesTable"
import FeesTable from "../../../views/centers/center-course/budgets/incomes/activity-incomes/tables/FeesTable"
import SubventionsIncomes from "../../../views/centers/center-course/budgets/incomes/activity-incomes/SubventionsIncomes"
import SubventionsTable from "../../../views/centers/center-course/budgets/incomes/activity-incomes/tables/SubventionsTable"
import Supplies from "../../../views/centers/center-course/budgets/expenses/supplies/Supplies"
import EtapaFeesTable from "../../../views/centers/center-course/budgets/incomes/activity-incomes/tables/EtapaFeesTable"
import StaffExpenses from "../../../views/centers/center-course/budgets/expenses/staff-expenses/StaffExpenses"
import StaffExpensesEtapaTable from "../../../views/centers/center-course/budgets/expenses/staff-expenses/StaffExpensesEtapaTable"
import VerticalResults from "../../../views/centers/center-course/budgets/results/vertical/VerticalResult"
import ExpensesTable from "../../../views/centers/center-course/budgets/results/vertical/ExpensesTable"
import IncomesTable from "../../../views/centers/center-course/budgets/results/vertical/IncomesTable"
import SuppliesData from "../../../views/centers/center-course/budgets/expenses/supplies/SuppliesData"
import SuppliesTotals from "../../../views/centers/center-course/budgets/expenses/supplies/tables/SuppliesTotals"
import SuppliesTableTotals from "../../../views/centers/center-course/budgets/expenses/supplies/tables/SuppliesTableTotals"
import SuppliesEtapa from "../../../views/centers/center-course/budgets/expenses/supplies/tables/SuppliesEtapa"
import SuppliesTableEtapas from "../../../views/centers/center-course/budgets/expenses/supplies/tables/SuppliesTableEtapas"
import HorizontalResults from "../../../views/centers/center-course/budgets/results/horizontal/HorizontalResult"
import ExpensesTableH from "../../../views/centers/center-course/budgets/results/horizontal/ExpensesTableH"
import IncomesTableH from "../../../views/centers/center-course/budgets/results/horizontal/IncomesTableH"
import TotalsTable from "../../../views/centers/center-course/budgets/results/vertical/TotalsTable"
import TotalsTableH from "../../../views/centers/center-course/budgets/results/horizontal/TotalsTableH"

export const centerCourseRoutes = [
    {
        path: 'center',
        name: 'center',
        key: 'center',
        element: <CenterCourseSummary />,
    },
    {
        path: 'structure',
        name: 'Structure',
        key: 'structure',
        element: <Structure />,
    },
    {
        path: 'statistics',
        name: 'Statistics',
        key: 'statisticsCompleted',
        element: <Statistics />,
    },
    {
        path: 'statistics/:agroupationId',
        name: 'Statistics teams by agroupation',
        key: 'statisticsCompleted',
        element: <StatisticsSublistAgroupation />,
    },
    {
        path: 'statistics/:agroupationId/team/:teamId',
        name: 'Statistics data by team',
        key: 'statisticsCompleted',
        element: < StatisticalData/>,
    },
    {
        path: 'educationOffer',
        name: 'EducationOfferAndService',
        key: 'educativeOfferCompleted',
        element: <EducationOfferAndService />,
    },
    {
        path: 'educationOffer/:educationOfferId',
        name: 'EducationOfferAndService',
        key: 'educativeOfferCompleted',
        element: <EducationOffer />,
    },
    {
        path: 'educationOffer/:educationOfferId/service', 
        name: 'EducationOfferAndService',
        key: 'educativeOfferCompleted',
        element: <EducationServices />,
    },
    {
        path: '/educationOffer/:educationOfferId/classroomAndStudents',
        key: 'educativeOfferCompleted',
        element: <ClassRoomAndStudents />,
    },
    {
        path: '/educationOffer/:educationOfferId/nee',
        name: 'EducationOfferAndService',
        key: 'educativeOfferCompleted',
        element: <Nee />,
    },
    {
        path: '/educationOffer/:educationOfferId/service/:service',
        name: 'EducationOfferAndService',
        key: 'educativeOfferCompleted',
        element: <Service />,
    },
    {
        path: '/distributionHours',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <HoursAndStaff />,
    },
    {
        path: '/distributionHours/:etapaId',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <HoursAndStaffView />,
    },
    {
        path: '/distributionHours/:etapaId/calculHours',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <CalculHours />,
    },
    {
        path: '/distributionHours/:etapaId/distribution',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <DistributionHours />,
    },
    {
        path: '/distributionHours/:etapaId/distribution/foundation',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <FoundationTeamsHours />,
    },
    {
        path: '/distributionHours/:etapaId/distribution/school',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <SchoolTeamsHours />,
    },
    {
        path: '/distributionHours/:etapaId/distribution/:staffId',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <HoursAndStaff />,
    },
    {
        path: '/distributionHours/:etapaId/distribution/staff',
        name: 'HoursAndStaff',
        key: 'distributionHoursCompleted',
        element: <Staff />,
    },
    {
        path: '/budget',
        name: 'Budgets',
        key: 'budgetsCompleted',
        element: <Budgets />,
    },
    {
        path: '/budget/expenses',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <Expenses />,
    },
    {
        path: '/budget/expenses/supplies/:suppliesId',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <Supplies />,
    },
    {
        path: '/budget/expenses/supplies/:suppliesId/balance/:balanceId',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <SuppliesData />,
    },
    {
        path: '/budget/expenses/supplies/:suppliesId/balance/:balanceId/totals',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <SuppliesTotals />,
    },
    {
        path: '/budget/expenses/supplies/:suppliesId/balance/:balanceId/etapa/:etapaId',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <SuppliesEtapa />,
    },
    {
        path: '/budget/expenses/supplies/:suppliesId/balance/:balanceId/totals/:accountId',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <SuppliesTableTotals />,
    },
    {
        path: '/budget/expenses/supplies/:suppliesId/balance/:balanceId/etapa/:etapaId/:accountId',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <SuppliesTableEtapas />,
    },
    {
        path: '/budget/expenses/staffs',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <StaffExpenses />,
    },
    {
        path: '/budget/expenses/staffs/etapa/:etapaId',
        name: 'Expenses',
        key: 'budgetsCompleted',
        element: <StaffExpensesEtapaTable />,
    },
    {
        path: '/budget/incomes',
        name: 'Incomes',
        key: 'budgetsCompleted',
        element: <Incomes />,
    },
    {
        path: '/budget/incomes/balances/:balanceId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <BudgetConcepts />,
    },
    {
        path: '/budget/incomes/balances/:balanceId/concepts/:conceptId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <ConceptAccounts />,
    },
    {
        path: '/budget/incomes/balances/:balanceId/concepts/:conceptId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <ConceptAccounts />,
    },
    {
        path: '/budget/incomes/balances/:balanceId/concepts/:conceptId/accounts/:accountId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <OtherIncomesData />,
    },
    {
        path: '/budget/incomes/balances/:balanceId/concepts/:conceptId/accounts/:accountId/data',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <OtherIncomesTable />,
    },
    {
        path: '/budget/incomes/balances/:balanceId/concepts/:conceptId/accounts/:accountId/agroupations/:agroupationId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <AgroupationsTable />,
    },
    {
        path: '/budget/incomes/activities/:balanceId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <ActivityIncomes />,
    },
    {
        path: '/budget/incomes/activities/:balanceId/fees/:feesId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <FeesIncomes />,
    },
    {
        path: '/budget/incomes/activities/:balanceId/fees/:feesId/etapa/:etapaId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <EtapaFeesIncomes />,
    },
    {
        path: '/budget/incomes/activities/:balanceId/fees/:feesId/etapa/:etapaId/general',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <GeneralFeesTable />,
    },
    {
        path: '/budget/incomes/activities/:balanceId/fees/:feesId/service/:otherServiceId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <FeesTable />,
    },
    {
        path: '/budget/incomes/activities/:balanceId/fees/:feesId/etapa/:etapaId/services/:otherServiceId/data',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <EtapaFeesTable />,
    },
    {
        path: '/budget/incomes/activities/:balanceId/subventions/:subventionId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <SubventionsIncomes />,
    },
    {
        path: '/budget/incomes/activities/:balanceId/subventions/:subventionId/etapa/:etapaId',
        name: 'Concepts',
        key: 'budgetsCompleted',
        element: <SubventionsTable />,
    },
    {
        path: '/budget/results',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <Results />,
    },
    {
        path: '/budget/results/vertical',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <VerticalResults />,
    },
    {
        path: '/budget/results/vertical/expenses',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <ExpensesTable />,
    },
    {
        path: '/budget/results/vertical/incomes',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <IncomesTable />,
    },
    {
        path: '/budget/results/vertical/totals',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <TotalsTable />,
    },
    {
        path: '/budget/results/horizontal',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <HorizontalResults />,
    },
    {
        path: '/budget/results/horizontal/expenses',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <ExpensesTableH />,
    },
    {
        path: '/budget/results/horizontal/incomes',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <IncomesTableH />,
    },
    {
        path: '/budget/results/horizontal/totals',
        name: 'Results',
        key: 'budgetsCompleted',
        element: <TotalsTableH />,
    },
    {
        path: '/distributionHours/pas',
        name: 'DistributionPas',
        key: 'distributionHoursCompleted',
        element: <Pas />,
    },
    {
        path: '/distributionHours/pas/:pasId',
        name: 'DistributionPasId',
        key: 'distributionHoursCompleted',
        element: <PasChildrens />,
    },
    {
        path: '/distributionHours/pas/:pasId/table',
        name: 'DistributionPasTable',
        key: 'distributionHoursCompleted',
        element: <PasTable />,
    },
]


