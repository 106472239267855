import React from "react"
import Button from "./index"

const EditButton = ({
    iconClass,
    btnText,
    btnClass,
    onClick
}) => {
    return (
        <Button 
            type="button"
            btnClass={btnClass || "btn-icon"}
            btnText={btnText}
            iconRight
            iconSize="1rem"
            iconClass={iconClass || "pi-pencil"}
            onHandleClick={onClick}
        />
    )

}

export default EditButton