/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Route, Routes, useNavigate, useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import _ from "lodash"
import { LayoutCenterContext } from "../../common-courses/context/LayoutCenterContex"
import CentersService from "../../../services/centers/centers.service"
import CenterCoursesHeader from "../../center-courses/header/CenterCoursesHeader"
import CenterCourseRoutes from "./CenterCourseRoutes"
import swalAlert from "../../../utils/helpers/swalAlert"
import CoursesService from "../../../services/centers/courses/courses.service"
import LayoutWithoutCourse from "../../center-courses/LayoutWithoutCourse"
import { CENTER_COURSE_DETAILS } from "../../../routes/centers/center-course/pathname"
import "./centerRoutes.scss"
import CenterCourseSummary from "../../../views/centers/center-course/CenterCourseSummary"
import useLoading from "../../../utils/hooks/useLoading"

// CRUD CURSOS
const CenterRoutes = () => {
    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()

    const { centerId } = useParams()

    const { loading, startLoading, stopLoading } = useLoading(true)
    const [ center, setCenter ] = useState({})
    const [ courses, setCourses ] = useState([])
    const [ courseSeletedDropdawn, setCourseSeletedDropdown ] = useState('')
    const [ flagCreateCourse, setFlagCreateCourse ] = useState(false)
    const [ flagConfirm, setFlagConfirm ] = useState(false)

    useEffect(() => {
        getDataCenter()
    }, [centerId])

    useEffect(() => {}, [courseSeletedDropdawn])

    const contextValue = useMemo(() => ({
        courses,
        center,
        courseSeletedDropdawn, 
        setCourseSeletedDropdown,
        flagCreateCourse,
        setFlagCreateCourse,
        flagConfirm,
        setFlagConfirm
    }), [center, courseSeletedDropdawn, flagCreateCourse, flagConfirm, courses])

    const getDataCenter = async () => {
        if (!centerId) return
        if (!loading) startLoading()
        const resp = (await CentersService.getById(centerId))?.resp
        if (resp) {
            setDataCenter(resp)
        }
        stopLoading()
    }

    const handleCreateCourse = () => {
        navigateToCreateCourse()
    }

    const deleteCourse = async (courseId) => {
        const confirm = await swalAlert.warning(
            msg({id: "swal.title.delete"}),
            msg({id: "button.cancel"}),
            msg({id: "button.delete"})
        )
        if (confirm?.isConfirmed) {
            const resp = (await CoursesService.removeCourseByCourseId(courseId))?.resp
            if (resp) {
                const updateCourses = courses.filter(c => c?._id !== resp?._id)
                updateCenterCourse(updateCourses)
            }
        }
    }

    const setDataCenter = (resp) => {
        const { courses: centerCourses, ...centerData } = resp
        setCenter(centerData)
        if (centerCourses.length > 0) {
            updateCenterCourse(centerCourses)
        }
    }

    const updateCenterCourse = (courseOptions) => {
        setCourses([...courseOptions, {_id: "undefined", name: msg({id: "button.new.course"}).concat(' +')}] || [])
        if (courseOptions?.length > 0) {
            const getLastCourseId = courseOptions.slice(-1)[0]?._id
            setCourseSeletedDropdown(getLastCourseId)
            navigateToCourse(getLastCourseId)
            return
        }
        setCourseSeletedDropdown('')
        setFlagCreateCourse(false)
        navigateToCourse()
    }

    const onChangeCourse = useCallback((e) => {
        if (e?.target?.value?._id==="undefined") {
            navigateToCreateCourse()
        } else {
            setCourseSeletedDropdown(e?.target?.value?._id)
            navigateToCourse(e?.target?.value?._id)
        }
    }, [])

    const navigateToCourse = (courseId) => {
        if (courseId) {
            const lastPath = window.location.pathname.split(`/course/${courseId}`)[1]
            navigate(`/app/centers/center/${centerId}/course/${courseId}${lastPath ?? ''}`)
        }
    }

    const navigateToCreateCourse = () => {
        setFlagCreateCourse(true)
        setCourseSeletedDropdown("undefined")
        navigate(CENTER_COURSE_DETAILS.STRUCTURE(centerId, "undefined"))
    }

    const isRouteCenterCourse = !flagCreateCourse && courses.length === 0

    return (
        <LayoutCenterContext.Provider value={contextValue}>
            <main className="main-layout container__with-background">

                <CenterCoursesHeader 
                    onChangeCourse={onChangeCourse} 
                    onDelete={deleteCourse}
                    handleCreateCourse={handleCreateCourse}
                />

                {!loading && (
                    !isRouteCenterCourse
                        ? (
                            <Routes>
                                <Route path="/" element={<CenterCourseSummary />} />
                                <Route path="course/:courseId/*" element={<CenterCourseRoutes />} />
                            </Routes>
                        )
                        : <LayoutWithoutCourse />
                )}
                
            </main>
            
        </LayoutCenterContext.Provider>
    )
}

export default CenterRoutes