import React from "react"
import ReactModal from 'react-modal'
import close from "../../assets/imgs/cerrar.svg"
import favicon from "../../assets/imgs/logos/icono-logo-modal.svg"
import './modal.scss'

const Modal = ({
    isShowing,
    hide,
    maxWidth,
    width, 
    height,
    top,
    left,
    title,
    context,
    className,
    overlayClassName
}) => {

    const customStyles = {
        content: {
            maxWidth,
            width,
            height,
            top,
            left
        }
    }

    return (
        <div>
            {
                isShowing 
                && (
                    <ReactModal
                        isOpen={isShowing} 
                        ariaHideApp={false}
                        className={`${className || 'general-modal'} `}
                        style={customStyles}
                        overlayClassName={overlayClassName}
                    >
                        <span className="general-modal__favicon"> 
                            <img src={favicon} alt="favicon" /> 
                        </span> 

                        <span className="general-modal__close" onClick={() => hide()} aria-hidden="true" > 
                            <img src={close} alt="close" /> 
                        </span> 
                        
                        <h2 className='title__container'>
                            {title} 
                        </h2>
                        { context }
                    </ReactModal>
                )
            }
        </div>
    )
}

export default Modal