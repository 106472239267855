import { GET, PATCH} from "../../../common/commonRequest.service"
import { endpointsEducativeOfferService, endpointsEducativeOfferStage, endpointsEducativeOfferStageAndService } from "./endpoints"

const EducationOfferService = {
    getById: async (courseId) => GET(endpointsEducativeOfferStageAndService.BY_COURSE_ID(courseId)),
    getByEtapaId: async (courseId, etapaId) => GET(endpointsEducativeOfferStageAndService.BY_ETAPA(courseId, etapaId)),
    getOtherServices: async (courseId, etapaId) => GET(endpointsEducativeOfferStageAndService.OTHER_SERVICES(courseId, etapaId)),

    getEtapaServiceById: async (courseId, etapaId, serviceId) => GET(endpointsEducativeOfferService.BY_ETAPA(courseId, etapaId, serviceId)),
    getClassAndStudents: async (courseId, etapaId) => GET(endpointsEducativeOfferStage.CLASS_AND_STUDENTS(courseId, etapaId)),
    getNee: async (courseId, etapaId) => GET(endpointsEducativeOfferStage.NEE(courseId, etapaId)),

    updateClassAndStudents: async (id, etapaId, body) => PATCH(endpointsEducativeOfferStage.CLASS_AND_STUDENTS(id, etapaId), body),
    updateNee: async (id, etapaId, body) => PATCH(endpointsEducativeOfferStage.NEE(id, etapaId), body),
    updateEtapaService: async (id, etapaId, serviceId, body) => PATCH(endpointsEducativeOfferService.BY_ETAPA(id, etapaId, serviceId), body),
    updateService: async (id, serviceId, body) => PATCH(endpointsEducativeOfferStageAndService.OTHER_SERVICES(id, serviceId), body),

    confirmEtapaOffer: async (courseId, etapaId, body) => PATCH(endpointsEducativeOfferStage.CLASS_AND_STUDENTS_CONFIRM(courseId, etapaId), body),
    confirmEtapaNee: async (courseId, etapaId, body) => PATCH(endpointsEducativeOfferStage.NEE_CONFIRM(courseId, etapaId), body),
    confirmService: async (courseId, etapaId, serviceId, body) => PATCH(endpointsEducativeOfferService.CONFIRM(courseId, etapaId, serviceId), body),
    confirmOtherService: async (courseId, serviceId, body) => PATCH(endpointsEducativeOfferService.OTHER_SERVICE_CONFIRM(courseId, serviceId), body)
}

export default EducationOfferService