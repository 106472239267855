import React from "react"
import { useIntl } from "react-intl"
import auxFunction from "../../../utils/helpers/auxFun"
import { DataTableFun } from "../../../utils/helpers/dataTableFun"
import "./file-data.scss"


const FileData = ({ 
    filesWithData,
    onHandleClick
}) => {

    const { formatMessage: msg } = useIntl()

    return (
        <div className="dataFile"> 
            {
                filesWithData?.length > 0
                    ? filesWithData?.map(dataFile => (
                        <div className='row-data-file' key={Math.random()}>
                            <div> {dataFile?.year}</div>
                            <div>
                                {
                                    DataTableFun.linkFile({
                                        label: auxFunction.truncateWithEllipsis(dataFile?.name, 10), 
                                        onClick: () => onHandleClick(dataFile)
                                    })
                                }
                            </div>
                            
                            <div> {dataFile?.description} </div >
                        </div>
                    ))
                    : msg({id: 'placeholder.no.attachments'})
            }
        </div>
    )
}

export default FileData