import { GET, POST, PATCH, DELETE } from "../common/commonRequest.service"
import { endpointsStaff } from "./endpoints"

const StaffService = {
    uploadFile: async (body) => POST(endpointsStaff.GENERIC, body),
    createPending: async (body) => POST(endpointsStaff.PENDING, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsStaff.LOAD({filter, limit, skip, sort})),
    getBasicById: async (id) => GET(endpointsStaff.BASIC_BY_ID(id)),
    getLaboralById: async (id) => GET(endpointsStaff.LABORAL_BY_ID(id)),
    getSocialsById: async (id) => GET(endpointsStaff.SOCIALS_BY_ID(id)),

    updateBasicById: async (id, body) => PATCH(endpointsStaff.BASIC_BY_ID(id), body, null, false),
    updateLaboralById: async (id, body) => PATCH(endpointsStaff.LABORAL_BY_ID(id), body, null, false),
    updateSocialsById: async (id, body) => PATCH(endpointsStaff.SOCIALS_BY_ID(id), body, null, false),

    remove: async (id) => DELETE(endpointsStaff.BY_ID(id)),
}

export default StaffService 