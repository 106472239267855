import { GET, PATCH } from "../../../common/commonRequest.service"
import { endpointsDistributionHours } from "./endpoints"


const DistributionHoursService = {
    getById: async (courseId) => GET(endpointsDistributionHours.ETAPAS_BY_ID(courseId)),
    getEtapa: async (courseId, etapaId) => GET(endpointsDistributionHours.ETAPA(courseId, etapaId)),
    justify: async (courseId, body) => PATCH(endpointsDistributionHours.JUSTIFY(courseId), body),

    getLine: async (courseId, etapaId) => GET(endpointsDistributionHours.LINE(courseId, etapaId)),
    updateLine: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.LINE(courseId, etapaId), body),
    confirmLine: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.LINE_CONFIRM(courseId, etapaId), body),
    disconfirmLine: async (courseId, etapaId) => PATCH(endpointsDistributionHours.LINE_DISCONFIRM(courseId, etapaId)),

    getFoundation: async (courseId, etapaId) => GET(endpointsDistributionHours.FOUNDATION(courseId, etapaId)),
    updateFoundation: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.FOUNDATION(courseId, etapaId), body),
    confirmFoundation: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.FOUNDATION_CONFIRM(courseId, etapaId), body),
    disconfirmFoundation: async (courseId, etapaId) => PATCH(endpointsDistributionHours.FOUNDATION_DISCONFIRM(courseId, etapaId)),

    getSchool: async (courseId, etapaId) => GET(endpointsDistributionHours.SCHOOL(courseId, etapaId)),
    updateSchool: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.SCHOOL(courseId, etapaId), body),
    confirmSchool: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.SCHOOL_CONFIRM(courseId, etapaId), body),
    disconfirmSchool: async (courseId, etapaId) => PATCH(endpointsDistributionHours.SCHOOL_DISCONFIRM(courseId, etapaId)),

    getStaff: async (courseId, etapaId) => GET(endpointsDistributionHours.STAFF(courseId, etapaId)),
    updateStaff: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.STAFF(courseId, etapaId), body),
    confirmStaff: async (courseId, etapaId, body) => PATCH(endpointsDistributionHours.STAFF_CONFIRM(courseId, etapaId), body),
    disconfirmStaff: async (courseId, etapaId) => PATCH(endpointsDistributionHours.STAFF_DISCONFIRM(courseId, etapaId))

}

export default DistributionHoursService