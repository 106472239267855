import centers from "../../assets/imgs/centres.svg"
import maestros from "../../assets/imgs/mestres.svg"
import users from "../../assets/imgs/usuaris.svg"
import processmap from "../../assets/imgs/bx-circle.svg"
import { backoffice } from '../../routes/backoffice.pathname'

export const Config = [
    {
        id: 'centers',
        name: 'Centres',
        route: backoffice.CENTERS,
        src: centers
    },
    {
        id: 'maestros',
        name: 'Maestros',
        route: backoffice.MAESTROS,
        src: maestros
    },
    {
        id: 'users',
        name: 'Usuaris',
        route: backoffice.USERS,
        src: users
    },
    {
        id: 'processmap',
        name: 'ProcessMap',
        route: backoffice.PROCESSMAP,
        src: processmap
    },
]