import React from "react"
import { BreadCrumb } from 'primereact/breadcrumb'
import "./breadCrumbComp.scss"

const BreadcrumbComp = ({
    home, 
    model
}) => {
    return (
        <BreadCrumb model={model} home={home}/>
    )
}

export default BreadcrumbComp