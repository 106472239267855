import endpointsAuth from "./endpoints"
import { POST, GET } from '../common/commonRequest.service'

const AuthService = {
    login: async (body) => POST(endpointsAuth.LOGIN, body, '', false),
    logout: async () => GET(endpointsAuth.LOGOUT),
    recoverPassword: async (body, msgSucces) => POST(endpointsAuth.RECOVERPASSWORD, body, msgSucces),
    resetPassword: async (body, msgSucces) => POST(endpointsAuth.RESETPASSWORD, body, msgSucces)
}

export default AuthService