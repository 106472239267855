import React from "react"
import LabeledText from "../../common/form-components/labeled-inputs/LabeledText"
import SaveButton from "../../common/buttons/SaveButton"
import { validators } from "../../../utils/helpers/validators"
import LabeledTextArea from "../../common/form-components/labeled-inputs/LabeledTextArea"

const TemplateBalanceForm = ({
    inputs,
    handleChange,
    handleSubmit,
}) => {

    const validateForm = validators.isEntityValues([inputs?.name]) 

    return (
        <>
            <div>
                <LabeledText
                    idLabel='name'
                    required
                    name='name'
                    value={inputs?.name}
                    onChange={(e) => handleChange(e)} 
                />
                <LabeledTextArea
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={(e) => handleChange(e)} 
                />
            </div>
            <div className="modal-btn-container">
                <SaveButton
                    onClick={() => handleSubmit()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal" 
                />
            </div>
        </>
    )
}

export default TemplateBalanceForm