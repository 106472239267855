import React, { useEffect, useState, useContext } from "react"
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { LayoutCenterContext } from "../../../../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../../../../utils/hooks/useLoading"
import BugetTable from '../../../../../../../components/common/dataTable/BugetTable'
import Dashboard from '../../../../../../../components/common-courses/view-content/Dashboard'
import BudgetService from '../../../../../../../services/centers/courses/budgets/budgets.service'
import BreadcrumbLink from '../../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../../routes/centers/center-course/pathname'
import editNumberRowColumn from '../../../../../../../components/common/dataTable/editors/editNumberRowColumn'

const SuppliesTableTotals = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, suppliesId, balanceId, accountId } = useParams()
    
    const [dataTable, setDataTable] = useState([])
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getDataTable()
    }, [])

    const getDataTable = async () => {
        startLoading()
        const resp = await BudgetService.getExpensesTotalsData(courseId, balanceId, accountId)
        if (resp) {
            setDataTable(resp?.resp)
        }
        stopLoading()
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await BudgetService.confirmExpensesTotalsData(courseId, balanceId, accountId, dataTable)
                : await BudgetService.updateExpensesTotalsData(courseId, balanceId, accountId, dataTable)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setDataTable(resp?.resp)
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'expenses' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET_EXPENSES(centerId, courseId)} 
                text={msg({ id: 'expenses' })} /> 
        },
        {
            label: dataTable?.templateName,
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES(centerId, courseId, suppliesId)}
                text={dataTable?.templateName} /> 
        },
        {
            label: dataTable?.conceptName,
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES_DATA(centerId, courseId, suppliesId, balanceId)}
                text={dataTable?.conceptName} />
        },
        {
            label: msg({ id: 'totals' }),
            template: <BreadcrumbLink
                path={CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES_DATA_TOTALS(centerId, courseId, suppliesId, balanceId)}
                text={msg({ id: 'totals' })} />
        },
        {
            label: dataTable?.numAccount
        }
    ]

    const headerOptions = {
        breadcrumb,
        handleSave,
        handleConfirm
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: dataTable?.subaccounts,
            }}
            columns={[
                { field: 'numSubaccount', header: 'subaccount'},
                { field: 'descSubaccount', header: 'concept'},
                { field: 'costCenterName', header: 'school.cost.center'},
                { field: 'expense', header: 'groupings'},
                { field: 'imputationName', header: 'imputation'},
                { field: 'totalExpense', 
                    header: 'amount',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: { width: "10%"},
                    bodyClassName: 'editRow',
                    ...editNumberRowColumn({})
                },

            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 5, footerStyle: { textAlign: 'left' } },
                { footer: 'sum',
                    field: 'totalExpense',
                    className: "p-datatype-number"
                }
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default SuppliesTableTotals