import React, { useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import useModal from "../../../../utils/hooks/useModal"
import { create, getAll, remove, update } from "../../../../services/typologies/typologies.slice"
import { getAllEtapas } from "../../../../services/stages/etapas.slice"
import Modal from "../../../../components/modal/Modal"
import FormTipology from "../../../../components/maestros-center/tipologies/FormTipology"
import useInputs from "../../../../utils/hooks/useInputs"
import TypologiesService from "../../../../services/typologies/typologies.service"
import auxFunction from '../../../../utils/helpers/auxFun'
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../utils/helpers/data-table/column-types/rowEditor"
import { bodyRenderTypes } from "../../../../components/common/dataTable/body/bodyColumnsTypes"
import editMultiSelectRowColumn from "../../../../components/common/dataTable/editors/editMultiSelectRowColumn"
import editTextRowColumn from "../../../../components/common/dataTable/editors/editTextRowColumn"

const Typologies = () => {
    
    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs} = useInputs()

    const typologies = useSelector(state => state?.typologies)
    const stages = useSelector(state => state?.etapas)   

    const dataFacade = useHandlerBasicActions({
        isUseDispatch: true,
        getAll,
        sortFieldInitial: 'name',
        createAction: create,
        removeAction: remove, 
        editAction: update
    })

    useEffect(() => {
        dispatch(getAllEtapas({}))
    }, [])

    const handleCreate = async () => {
        const etapas = auxFunction.returnIds(inputs?.etapas)
        await dataFacade.create({ ...inputs, etapas })
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({newData}) => {
        newData.etapas = auxFunction.returnIds(newData?.etapas)
        const body = _.omit(newData, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const { _id } = newData
        dataFacade.edit({_id, body})
    }

    const headerOptions = {
        title: msg({id: 'tipologies'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions,
    }

    const importExportFilesOptions = {
        title: 'tipologies',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'stages'}), dataKey: 'etapas' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: async () => {
            const resp = (await TypologiesService.getAll({}))?.resp
            return { resp: auxFunction.flattenArrayProperty(resp, 'etapas', 'name')}
        }
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: typologies?.total
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: typologies?.resp,
                editMode: "row",
                onRowEditComplete, 
                ...dataFacade?.sortOptions
            }}
            columns={[
                {
                    field: 'name',
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                { 
                    className: 'items-column', 
                    header: 'stages',
                    field: 'etapas',
                    body: (rowData) => bodyRenderTypes(rowData?.etapas),
                    ...editMultiSelectRowColumn({options: stages?.resp})
                },
                {
                    field: "description", 
                    header: 'description',
                    sortable: true,
                    ...editTextRowColumn
                },
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ]}   
        />
    )

    return (
        <div className="tipologies">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="500px"
                title={<FormattedMessage id='typology.new'/>}
                context={(
                    <FormTipology 
                        inputs={inputs} 
                        handleSave={handleCreate}
                        handleChange={onChangeInputs}
                    />
                )}
            />
        </div>
    )
}

export default Typologies