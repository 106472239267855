import React from "react"
import { useIntl } from "react-intl"

import Button from "../../common/buttons"

const HeaderImportExportFiles = ({
    importService, 
    intermediateExportAction
}) => {
    
    const { formatMessage: msg } = useIntl()

    return (
        (intermediateExportAction || importService) && (
            <div className="main-exports-content">
                {
                    intermediateExportAction && (
                        <div className="butons-exports">
                            <Button
                                id="exportPdfBtn"
                                btnText="PDF"
                                btnType="button"
                                btnClass="button-white btn-export"
                                onHandleClick={() => intermediateExportAction({type: "pdf"})}
                                iconRight
                                iconSize="0.9rem"
                                iconClass="pi-download" />
                            <Button
                                id="exportXlsBtn"
                                btnText="XLS"
                                btnType="button"
                                btnClass="button-white btn-export"
                                onHandleClick={() => intermediateExportAction({type: "xls"})}
                                iconRight
                                iconSize="0.9rem"
                                iconClass="pi-download" />
                        </div> 
                    )
                }
                {
                    importService && (
                        <Button
                            id="importCsvBtn"
                            btnText={msg({ id: 'import' })}
                            btnType="button"
                            btnClass="button-white btn-export mr-0"
                            onHandleClick={() => importService()}
                            iconRight
                            iconSize="0.9rem"
                            iconClass="pi-upload"
                        />
                        
                    )
                }
            </div>
        )
    )
}

export default HeaderImportExportFiles