
import React from "react"
import env from "../../../utils/constants/env-vars"
import auxFunction from "../../../utils/helpers/auxFun"
import editCalendarRowColumn from "../../common/dataTable/editors/editCalendarRowColumn"
import editTextRowColumn from "../../common/dataTable/editors/editTextRowColumn"
import editUploadFileRowColumn from "../../common/dataTable/editors/editUploadFileRowColumn"
import editNumberRowColumn from "../../common/dataTable/editors/editNumberRowColumn"
import rowDelete from "../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../utils/helpers/data-table/column-types/rowEditor"
import { DataTableFun } from "../../../utils/helpers/dataTableFun"
import usePreviewPdf from "../../../utils/hooks/usePreviewPdf"
import MaestrosDataTable from "../../common/dataTable/MaestrosDataTable"
import { SubTitle } from "../../common/form-components/label/Label"

const CollectiveAgreementsFilesTable = ({
    data,
    removeItem,
    editItem
}) => {

    const { onclickPreviewPdf, modalPdfView } = usePreviewPdf()

    const bodyFile = (rowData) => {
        const { files } = rowData
        if (rowData?.path && !files) {
            return DataTableFun.linkFile({
                label: auxFunction.truncateWithEllipsis(rowData?.name, 10),
                onClick: () => onclickPreviewPdf({ 
                    name: rowData?.name,
                    env: env.URL_DOWNLOAD_AGREEMENTS,
                    path: rowData?.path })
            })
        }
        
        return DataTableFun.linkFile({
            label: auxFunction.truncateWithEllipsis(files?.name, 10), 
            onClick: () => onclickPreviewPdf({ name: files?.name, path: auxFunction.openPdfFileWithBlob(files)})
        })
    }

    return (
        <div>
            <SubTitle id='agreement.files'/>

            <MaestrosDataTable 
                tableOptions={{
                    value: data,
                    editMode: 'row',
                    onRowEditComplete: editItem,    
                }}
                columns={[
                    { 
                        field: 'year',
                        header: 'year',
                        ...editCalendarRowColumn({ 
                            view: 'year', dateFormat: 'yy' })
                    },
                    { 
                        field: 'files',
                        header: 'file',
                        body: bodyFile,
                        ...editUploadFileRowColumn({
                            labelButton: 'change',
                            accept: '.pdf'
                        })
                    },
                    { 
                        field: "description",
                        header: 'description',
                        ...editTextRowColumn
                    },
                    {
                        field: "salaryIncrease",
                        header: 'salaryIncrease',
                        ...editNumberRowColumn({propsInput: {
                            suffix: '%',
                            maxFractionDigits: 2
                        }})
                    },
                    removeItem && (
                        { ...rowDelete({ handleDelete: removeItem })}
                    ),
                    editItem && (
                        {...rowEditor()}
                    )
                ]}
            />
            { modalPdfView }
        </div>
    )
}

export default CollectiveAgreementsFilesTable