import React, { useEffect, useState, useContext } from "react"
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { LayoutCenterContext } from "../../../../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../../../../utils/hooks/useLoading"
import BugetTable from '../../../../../../../components/common/dataTable/BugetTable'
import Dashboard from '../../../../../../../components/common-courses/view-content/Dashboard'
import editNumberRowColumn from '../../../../../../../components/common/dataTable/editors/editNumberRowColumn'
import BudgetService from '../../../../../../../services/centers/courses/budgets/budgets.service'
import BreadcrumbLink from '../../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../../routes/centers/center-course/pathname'

const OtherIncomesTable = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, conceptId, accountId } = useParams()

    const [dataTableTotals, setDataTableTotals] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getDataTableTotals()
    }, [])

    const getDataTableTotals = async () => {
        startLoading()
        const resp = true //await BudgetService.getOtherIncomesTotals(courseId, conceptId, accountId)
        if (resp) {
            setDataTableTotals({
                course: "66aa038807310204f02d45c4",
                balanceTemplate: "668e6c9b19adb6be106c9a72",
                templateName: "1. Ingressos per les activitats",
                concept: "668e6ce319adb6be106c9a79",
                conceptName: " Prestacions de serveis",
                account: "657c09eec3ebe4abb90ce09c",
                numAccount: "724",
                totalIncome: 0,
                subaccounts: [
                    {
                        subaccount: "657c09eec3ebe4abb90ce0a0",
                        numSubaccount: "1022",
                        imputationName: "100%",
                        totalIncome: 0
                    },
                    {
                        subaccount: "657c09eec3ebe4abb90ce0ae",
                        numSubaccount: "1023",
                        imputationName: "100%",
                        totalIncome: 0
                    },
                    {
                        subaccount: "657c09eec3ebe4abb90ce0bc",
                        numSubaccount: "1024",
                        imputationName: "100%",
                        totalIncome: 0
                    },
                ]
            })
        }
        stopLoading()
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await BudgetService.totalIncomesConfirm(courseId, conceptId, accountId, dataTableTotals)
                : await BudgetService.totalIncomesSave(courseId, conceptId, accountId, dataTableTotals)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setDataTableTotals({
                    course: "66aa038807310204f02d45c4",
                    balanceTemplate: "668e6c9b19adb6be106c9a72",
                    templateName: "1. Ingressos per les activitats",
                    concept: "668e6ce319adb6be106c9a79",
                    conceptName: " Prestacions de serveis",
                    account: "657c09eec3ebe4abb90ce09c",
                    numAccount: "724",
                    totalIncome: 0,
                    subaccounts: [
                        {
                            subaccount: "657c09eec3ebe4abb90ce0a0",
                            numSubaccount: "1022",
                            imputationName: "100%",
                            totalIncome: 0
                        },
                        {
                            subaccount: "657c09eec3ebe4abb90ce0ae",
                            numSubaccount: "1023",
                            imputationName: "100%",
                            totalIncome: 0
                        },
                        {
                            subaccount: "657c09eec3ebe4abb90ce0bc",
                            numSubaccount: "1024",
                            imputationName: "100%",
                            totalIncome: 0
                        },
                    ]
                })
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: dataTableTotals?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_BALANCES(centerId, courseId, dataTableTotals?.balanceTemplate)} 
                text={dataTableTotals?.templateName} /> 
        },
        {
            label: dataTableTotals?.conceptName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_CONCEPTS(centerId, courseId, dataTableTotals?.balanceTemplate, dataTableTotals?.concept)} 
                text={dataTableTotals?.conceptName} /> 
        },
        {
            label: dataTableTotals?.numAccount,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_DATA(centerId, courseId, dataTableTotals?.balanceTemplate, dataTableTotals?.concept, accountId)} 
                text={dataTableTotals?.numAccount} /> 
        },
        {
            label: msg({ id: 'totals' })
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: dataTableTotals?.subaccounts
            }}
            columns={[
                { field: 'numSubaccount', header: 'subaccount'},
                { field: 'descSubaccount', header: 'concept' },
                { field: 'imputationName',
                    header: 'imputation',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: { width: "10%"},
                },
                { field: 'totalIncome', 
                    header: 'courseImport',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: { width: "10%"},
                    bodyClassName: 'editRow',
                    ...editNumberRowColumn({})
                }
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 3, footerStyle: { textAlign: 'left' } },
                { footer: 'sum', field: 'totalIncome', className: "p-datatype-number",}
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default OtherIncomesTable