import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../components/common/dataTable/MaestrosDataTable'
import FormContractType from '../../../../components/maestros-labor/contractTypes/FormContractType'
import Modal from '../../../../components/modal/Modal'
import ContractTypeService from '../../../../services/contract-types/contractTypes.service'
import editTextRowColumn from '../../../../components/common/dataTable/editors/editTextRowColumn'
import rowDelete from '../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'

const ContractTypes = () => {

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'code',
        getAll: ContractTypeService.getAll,
        createAction: ContractTypeService.create,
        removeAction: ContractTypeService.remove, 
        editAction: ContractTypeService.update
    })

    const handleCreate = async () => {
        await dataFacade.create(inputs)
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({ newData }) => {
        dataFacade.edit({ _id: newData?._id, body: newData })
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const headerOptions = {
        title: msg({id: 'contract.types'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'contract.types',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'code' },
            { title: msg({id: 'contract'}), dataKey: 'contract' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: () => ContractTypeService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                sortField: 'code',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={([
                { 
                    field: 'code',
                    sortable: true,
                    header: 'code',
                    ...editTextRowColumn
                },
                { 
                    field: "contract",
                    sortable: true,
                    header: 'contract',
                    ...editTextRowColumn
                },
                { 
                    field: "description",
                    header: 'description',
                    ...editTextRowColumn
                },
                { ...rowDelete({ handleDelete: dataFacade.remove })},
                { ...rowEditor()}
            ])}
        />
    )

    return (
        <div className="contract-types">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="500px"
                title={<FormattedMessage id="contractType.new" />}
                context={(
                    <FormContractType 
                        inputs={inputs} 
                        handleSave={handleCreate}
                        handleChange={onChangeInputs} 
                    />
                )}
            />
        </div>
    )
}

export default ContractTypes
