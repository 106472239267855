import React, {useState} from 'react'
import { useIntl } from 'react-intl'
import { TabView, TabPanel } from 'primereact/tabview'
import StaffProfileBasic from './panels/StaffProfileBasic'
import StaffProfileWorkingList from './panels/StaffProfileWorkingList'
import StaffProfileSs from './panels/StaffProfileSs'
import './staffProfileTabs.scss'

const StaffProfileTabs = ({ 
    infoBasic,
    infoWork,
    infoSs,
    onChangeTab,
    handleInputsChange,
    isReadOnly,
    activeTabIndex
}) => {
    const {formatMessage: msg} = useIntl()
    const [activeIndex, setActiveIndex] = useState(activeTabIndex || 0)

    return (
        <TabView 
            activeIndex={activeIndex} 
            onTabChange={(e) => setActiveIndex(e.index)}
            onBeforeTabChange={onChangeTab}
            className="container__without-padding-top" 
        >
            <TabPanel header={msg({id: "basic"})}>
                <StaffProfileBasic 
                    info={infoBasic} 
                    isReadOnly={isReadOnly} 
                    handleInputsChange={handleInputsChange} 
                />
            </TabPanel>
            <TabPanel header={msg({id: "working"})}>
                <StaffProfileWorkingList 
                    info={infoWork} 
                    handleInputsChange={handleInputsChange} 
                    isReadOnly={isReadOnly} 
                />
            </TabPanel>
            <TabPanel header={msg({id: "social.security"})}>
                <StaffProfileSs 
                    info={infoSs} 
                    handleInputsChange={handleInputsChange} 
                    isReadOnly={isReadOnly} 
                />
            </TabPanel>
        </TabView>
    )
}

export default StaffProfileTabs