import React from "react"
import { InputTextarea } from 'primereact/inputtextarea';
import "./inputComp.scss"

const InputTextAreaComp = ({
    name,
    value,
    onChange,
    ...inputProps
}) => {
    return (
        <InputTextarea 
            name={name}
            value={value}
            onChange={onChange}
            {...inputProps}
        />
    )
}

export default InputTextAreaComp