import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import useLoading from '../../../../../utils/hooks/useLoading'
import CardsOverview from '../../../../../components/common-courses/view-content/CardsOverview'
import BreadcrumbLink from '../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../routes/centers/center-course/pathname'
import { CenterPasService } from '../../../../../services/center-pas/centerServices.service'

const PasChildrens = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, pasId } = useParams()

    const navigate = useNavigate()

    const [pasChildren, setPasChildren] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getPasById()
    }, [pasId])

    const getPasById = async () => {
        startLoading()
        const resp = await CenterPasService.getPasById(courseId, pasId)
        if (resp) {
            setPasChildren(resp?.resp)
        }
        stopLoading()
    }

    const goToTable = (id, item) => {
        if (item?.isLastLevel) return CENTER_COURSE_DETAILS.DISITRIBUTION_HOURS_PAS_BY_ID_TABLE(centerId, courseId, item?.pasId)
        return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_PAS_BY_ID(centerId, courseId, item?.pasId)
    }

    const breadcrumb = [
        {
            label: msg({id: 'hours.and.staff'}),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({id: 'hours.and.staff'})} /> 
        },
        {
            label: 'PAS',
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_PAS(centerId, courseId)} 
                text='PAS' /> 
        },
        {
            label: pasChildren?.name
        }
    ]

    const contentSummary = (
        <h1>In progress...</h1>
    )

    const handlePrevious = () => navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_PAS(centerId, courseId))

    const cardList = {
        list: pasChildren?.pas,
        gotToLink: goToTable
    }

    return (
        <>
            {process.env.DEBUG===true ? <span>DEBUG MODE</span> : <span>NO DEBUG</span>}
            <CardsOverview
                loading={loading}
                breadcrumb={breadcrumb}
                cardsInfo={cardList}
                contentTableSummary={contentSummary}
                handlePrevious={handlePrevious}
            />
        </>
    )
}

export default PasChildrens