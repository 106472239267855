import React from "react"
import { Password } from 'primereact/password'
import { FormattedMessage, useIntl } from 'react-intl'
import "./passwordComp.scss"

const PasswordComp = ({
    className, 
    value, 
    onChange, 
    name, 
    placeholder, 
    feedback=false,
    toggleMask=true
}, propsPassword) => {

    const { formatMessage: msg } = useIntl()

    const footer = (
        <div className="info-password-panel">
            <hr />
            <p className=""><FormattedMessage id="suggestions"/>:</p>
            <ul className="pl-2 ml-2 mt-0 line-height-3">
                <li><FormattedMessage id="at.least.one.lowercase"/></li>
                <li><FormattedMessage id="at.least.one.uppercase"/></li>
                <li><FormattedMessage id="at.least.one.numeric"/></li>
                <li><FormattedMessage id="minimum.8.characters"/></li>
            </ul>
        </div>
    )

    return (
        <Password 
            className={`password  ${className}`}
            placeholder={placeholder ? msg({ id: placeholder}) : msg({id: "password"})}
            value={value} 
            onChange={onChange} 
            name={name}
            feedback={feedback} 
            toggleMask={toggleMask}
            footer={footer}
            promptLabel={msg({id: "password.choose"})} 
            weakLabel={msg({id: "insecure"})}  
            mediumLabel={msg({id: "average.complexity"})} 
            strongLabel={msg({id: "password.strong"})} 
            {...propsPassword}
        />
    )
}

export default PasswordComp