import { maestrosTemplates } from '../../../routes/maestros/maestros-templates/maestrosTemplates.pathname'

//MAESTROS PLANTILLAS
import schoolHoursAndDelegatePayment from '../../../assets/imgs/maestros/maestros-templates/plantilla-horas.svg'
import templateExceededHoursDistribution from '../../../assets/imgs/maestros/maestros-templates/distribucion-horas.svg'

export const MAESTROS_TEMPLATES = [
    {
        id: 'distribution.hours.foundation',
        name: 'Plantilla categories distribucion',
        route: maestrosTemplates.TEMPLATE_EXCEEDED_HOURS_DISTRIBUTION,
        src: templateExceededHoursDistribution,
    },
    {
        id: 'templates.and.hours',
        name: 'Horas de plantilla',
        route: maestrosTemplates.TEMPLATES_ETAPAS_HOURS,
        src: schoolHoursAndDelegatePayment,
    },
]
