import { maestros } from "../maestros.pathname"

export const maestrosCenters = {
    // MAESTROS CENTER
    TYPOLOGIE: maestros.MAESTROS_CENTER + '/tipologia',
    ETAPAS: maestros.MAESTROS_CENTER +'/etapas',
    ETAPA: maestros.MAESTROS_CENTER +'/etapas/etapa/',
    SERVICES: maestros.MAESTROS_CENTER +'/servicios/',
    CENTER_TEAMS: maestros.MAESTROS_CENTER + '/centerteams/',
    CENTER_TEAM: (idCenterTeam) => maestros.MAESTROS_CENTER +`/centerteams/${idCenterTeam}`,
    NEE: maestros.MAESTROS_CENTER +'/nee',
    MAESTROS_ACCOUNTING_CENTER: maestros.MAESTROS_CENTER + '/accounting',
    
    // Maestros contabilidad del centro
    MAESTROS_ACCOUNTING_PLAN: '/backoffice/maestroscenter/accounting/accountingplan',
    MAESTROS_SUBVENTIONS: '/backoffice/maestros/accounting/subventions',
    MAESTROS_FEES: '/backoffice/maestros/accounting/fees/',
    MAESTROS_FEES_ID: (etapaId) => maestrosCenters.MAESTROS_FEES + etapaId,

    // Maestros plan center
    ACCOUNTING_SCHOOL_COST_CENTER: '/backoffice/maestros/accounting/accountingplan/schoolcostcenter',
    ACCOUNTING_PLAN_CENTER: '/backoffice/maestros/accounting/plancenter',
    ACCOUNTING_PLAN_CENTER_BY_ID: (id) => `/backoffice/maestros/accounting/plancenter/${id}`,
    ACCOUNTING_SUBTYPES: '/backoffice/maestros/accounting/accountingplan/subtypes',
    ACCOUNTING_DISTRIBUTION_CRITERIA: '/backoffice/maestros/accounting/accountingplan/distributioncriteria',

    // Maestros subvenciones 
    SUBVENTIONS: '/backoffice/maestros/accounting/subventions/subventions',
    SUBSIDY_QUANTIFICATION: '/backoffice/maestros/accounting/subventions/subsidyquantification',
}