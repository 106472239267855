import React from "react"
import { MAESTROS_ECOFIN } from "../../../utils/constants/maestros/maestrosEcoFin"
import MaestrosListView from "../../../components/common/MaestrosListView"
import { maestrosKeys } from "../../../utils/constants/maestros/maestros"

import './maestrosData.scss'

const MaestrosEcoFin = () => {

    return (
        <MaestrosListView
            title='accountantMaster'
            maestros={MAESTROS_ECOFIN}
            masterKeyConfirm={maestrosKeys.ECOFIN}
        />
    )
}

export default MaestrosEcoFin