import React from "react"
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import './cardIcono.scss'

const CardIcono = ({
    item, 
    number
}) => {

    const { formatMessage: msg } = useIntl()

    return (
        <div className="box-item-icon">
            <div className="box-content">
                {number && <span className="order-card">{number}</span>}

                {item?.route && item?.src ? (
                    <Link className='red-text' to={item.route}>
                        <img className="card-img" src={item.src} alt={item.name} />
                        {item.id && msg({ id: item.id })}
                    </Link>
                ) : (
                    <div className='red-text'>
                        {item?.src && <img className="card-img" src={item.src} alt={item.name} />}
                        {item?.id && msg({ id: item.id })}
                    </div>
                )}
            </div>
        </div>
    )
}

export default CardIcono