import React, { useEffect } from "react"
import { isNumber, isObject, isString } from 'lodash'
import CalendarComp from "../../form-components/calendar/CalendarComp"
import { onCellEditComplete } from "./common"
import { convertStringToDate } from "../../../../utils/helpers/date"


const calendarEditor = ({ options, view, dateFormat, minDate, maxDate }) => {

    // TODO: adaptar para distintos tipos de parse, no solo year
    return (
        <CalendarComp 
            value={parseYear(options?.value)}
            onChange={(e) => e?.value?.getFullYear() ? options?.editorCallback(e.value.getFullYear()) : null}
            view={view}
            dateFormat={dateFormat}
            minDate={parseYear(minDate)}
            maxDate={parseYear(maxDate)}
            showIcon
            required
        />
    )
}

const parseYear = (value) => {
    if (isNumber(value)) {
        return convertStringToDate(value)
    }
    if (isObject(value) && value instanceof Date) {
        return value
    }
    if (isString(value)) {
        return new Date(value)
    }
    return value ? new Date(value, 0, 1) : ''
}

const editCalendarRowColumn = ({ 
    dateFormat,
    view, 
    minDate,
    maxDate 
}) => {
    return {
        editor: (options) => calendarEditor({ options, dateFormat, view, minDate, maxDate}),
        onCellEditComplete, 
    }
}

export default editCalendarRowColumn