import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import MainCommonView from "../../../../../components/common/MainCommonView"
import EtapasService from "../../../../../services/stages/etapas.service"
import FeeServices from "../../../../../services/fees/fees.service"
import Modal from "../../../../../components/modal/Modal"
import useModal from "../../../../../utils/hooks/useModal"
import useInputs from "../../../../../utils/hooks/useInputs"
import FeesForm from "../../../../../components/maestros-center/fees/FeesForm"
import MaestrosDataTable from "../../../../../components/common/dataTable/MaestrosDataTable"
import { bodyRenderTypes } from "../../../../../components/common/dataTable/body/bodyColumnsTypes"
import editTextRowColumn from "../../../../../components/common/dataTable/editors/editTextRowColumn"
import editMultiSelectRowColumn from "../../../../../components/common/dataTable/editors/editMultiSelectRowColumn"
import rowDelete from "../../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../../utils/helpers/data-table/column-types/rowEditor"
import swalAlert from "../../../../../utils/helpers/swalAlert"
import auxFunction from "../../../../../utils/helpers/auxFun"

const Fees = () => {
    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs} = useInputs()
    
    const {etapaId} = useParams()

    const [subetapas, setSubetapas] = useState()
    const [fees, setFees] = useState()

    useEffect(() => {
        getEtapaFees()
        getEtapa()
    }, [])

    const getEtapaFees = async () => {
        const resp = await FeeServices.getAll({id: etapaId})
        if (resp) {
            setFees(resp?.resp)
        }
    }

    const getEtapa = async () => {
        const resp = await EtapasService.getAllSubetapas({etapaId})
        if (resp) {
            setSubetapas(resp?.resp)
        }
    }
    
    const handleCreate = async () => {
        const feeSubetapas = auxFunction.returnIds(inputs?.subetapas)
        const data = {
            ...inputs,
            subetapas: feeSubetapas
        }
        const resp = await FeeServices.create(etapaId, data)
        if (resp) {
            updateInputs({})
            getEtapaFees()
            toggle()
        }
    }

    const handleDelete = async (id) => {
        const confirm = await swalAlert.warning( 
            msg({id: "swal.title.delete"}),
            msg({id: "button.cancel"}),
            msg({id: "button.delete"})
        )

        if (confirm?.isConfirmed) {
            const resp = await FeeServices.remove(id)
            if (resp) {
                getEtapaFees()
            }
        }
    }

    const onRowEditComplete = async (data) => {
        const feeSubetapas = auxFunction.returnIds(data?.newData?.subetapas)
        const feeData = {
            ...data?.data,
            subetapas: feeSubetapas
        }
        const resp = await FeeServices.update(data?.data?._id, feeData)
        if (resp) {
            getEtapaFees()
        }
    }

    const headerOptions = {
        title: msg({id: 'fees'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
    }

    const importExportFilesOptions = {
        title: 'fees',
        columns: [
            { title: msg({ id: 'name' }), dataKey: 'name' },
            { title: msg({ id: 'description' }), dataKey: 'description' },
            { title: msg({ id: 'subetapas' }), dataKey: 'subetapas' }
        ],
        exportService: async () => {
            const resp = await FeeServices.getAll(etapaId)
            return resp
        }
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: fees,
                editMode: 'row',
                onRowEditComplete
            }}
            columns={([
                {
                    field: 'name',
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                {
                    field: 'description',
                    sortable: true,
                    header: 'description',
                    ...editTextRowColumn
                },
                {
                    field: 'subetapas',
                    header: 'substage',
                    body: (rowData) => bodyRenderTypes(rowData?.subetapas),
                    ...editMultiSelectRowColumn({
                        options: subetapas,
                        optionLabel: 'name'
                    })
                },
                {...rowDelete({ handleDelete })},
                {...rowEditor()},
            ])}
        />
    )

    return (
        <div>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id='subaccount.new'/>}
                context={(
                    <FeesForm 
                        inputs={inputs} 
                        handleSubmit={handleCreate}
                        handleChange={onChangeInputs}
                        subetapas={subetapas || []}
                    />
                )}
            />
        </div>
    )
}

export default Fees