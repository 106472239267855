import swalAlert from './swalAlert'
import { execActionWithLoading } from './common'


// TO DO: REFACTORIZAR 
const UPLOAD = {
    multiFiles: async ({ id, files, uploadService, errorMsgTitle }) => {
        if (!id || files?.length === 0 || !files) return false
          
        const allFilesPromises = files
            .map((fileValue) => uploadService({ _id: id, body: objectBodyAppendFormData(fileValue)}))

        const allResponses = await Promise.allSettled(allFilesPromises)
        const errMsg = allResponses.reduce((allErr, resp, i) => {
            return allErr + (resp?.status === "rejected" ? files[i].file.name + ' - ' + resp?.reason?.message + '<br />' : '')
        }, '')                    
    
        if (errMsg !== '') { 
            await swalAlert.errorReach(errorMsgTitle + '<br />' + errMsg)
        } else {
            await swalAlert.succesWithTimer()
        }

        const mostFullSuccessfullResponse = allResponses
            .filter(r => r?.status === 'fulfilled')
            .reduce((acc, r) => r?.value?.resp?.files?.length < acc?.value?.resp?.files ? acc : r, null)

        return mostFullSuccessfullResponse?.value
    },

    file: async ({ _id, event, uploadService }) => {
        const fileUpload = event.files[0] 
        const formData = new FormData()
        formData.append('file', fileUpload)
        const resp = await uploadService({ _id, body: formData })
        event?.options?.clear()
        return resp
    }
}

export const objectBodyAppendFormData = (fileWithData) => {
    const formData = new FormData()
    Object
        .entries(fileWithData)
        .forEach(([key, value]) => {
            return formData.append(key, value)
        })
    
    return formData
}

export const defineUploadButtonLabelForFile = (fileValueObject, maxLabelLength, changeLabel, uploadLabel) => {
    let label = fileValueObject?.path ? changeLabel : uploadLabel
    const nameToShow = fileValueObject?.name?.length > maxLabelLength ? fileValueObject?.name?.substring(0, maxLabelLength) + '...' : fileValueObject?.name

    if (nameToShow) {
        label += ': ' + nameToShow
    }

    return label
}

export const uploadFileWithLoading = async ({file, uploadService, onSuccess, onFinally, loadingMessage}) => {
    return execActionWithLoading({
        action: () => uploadFile({file, uploadService}),
        onSuccess,
        onFinally,
        loadingMessage
    })
}

const uploadFile = async ({ uploadService, file }) => uploadFileWithData({
    uploadService,
    fileWithData: { file }
})

// TODO: don't duplicate code with objectBodyAppendFormData
async function uploadFileWithData({ uploadService, fileWithData }) {
    let resp = {}

    if (typeof uploadService === 'function') {
        const formData = new FormData()

        Object
            .entries(fileWithData)
            .filter(([key, value]) => (key === 'file' && mayFileBeSaved(value)) || key !== 'file')
            .forEach(([key, value]) => formData.append(key, value))

        resp = await uploadService(formData)
    }

    return resp
}

const mayFileBeSaved = (file) => {
    const wasFileEdited = file?.name && !file?.path

    return wasFileEdited && file instanceof Blob
}

export default UPLOAD 