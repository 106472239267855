import React from "react"
import PaginatorComp from "../paginator/PaginatorComp"
import CommonHeader from "./commonHeader/CommonHeader"
import ImportExportFiles from "./commonHeader/ImportExportFiles"
import Legend from "./legend/Legend"
import ProgressSpinnerComp from "./spinner/ProgressSpinnerComp"
import "./mainCommonView.scss"

const MainCommonView = ({
    loading = false,
    headerOptions,
    importExportFilesOptions,
    componentData,
    pagerOptions,
    showLegend, 
    totalRecords
}) => {

    const records = totalRecords || pagerOptions?.totalRecords

    return (

        <div className="main-common-view">
            <div className="box-container">
                <CommonHeader {...headerOptions} />

                <div className="component-data">
                    <ProgressSpinnerComp loading={loading}/>
                    { !loading && records > 0 && <ImportExportFiles {...importExportFilesOptions} />}
                    { !loading && componentData }
                </div>
                
                { showLegend && records > 0 && (<Legend/>) }
                <PaginatorComp {...pagerOptions}/>
            </div> 
        </div>
    )

}

export default MainCommonView