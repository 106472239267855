import React from "react"
import FoundationData from "../../views/foundation/foundation-data/FoundationData"
import FoundationStatistics from "../../views/foundation/foundation-statistics/FoundationStatistics"
import { foudationCourse, foundation } from "./pathname"

export const foundationRoutes = [
    {
        path: foundation.FOUNDATION,
        name: 'data',
        element: <FoundationData />,
    }, 
    {
        path: foudationCourse.STADISTICS,
        name: 'stadistics',
        element: <FoundationStatistics/>,
    }
]

