import React from "react"
import { ControlLabel } from "../label/Label"
import TreeSelectComp from "../dropdown/TreeSelectComp"

const LabeledTreeSelect = ({
    idLabel,
    required,
    name,
    value,
    options,
    onChange,
    ...propsTreeSelect
}) => {

    return (
        <div className="flex-container">
            <ControlLabel id={idLabel} required={required}/>
            <div className="control-input">
                <TreeSelectComp 
                    value={value}
                    options={options} 
                    name={name}
                    onChange={onChange}
                    {...propsTreeSelect}
                />
            </div>
        </div>
    )
}

export default LabeledTreeSelect