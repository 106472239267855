import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsCentros } from "./endpoints"

const CentersService = {
    getAll: async ({filter, sort, limit, skip}) => GET(endpointsCentros.LOAD({filter, limit, skip, sort})),
    
    getById: async (centerId) => GET(endpointsCentros.BY_CENTRO(centerId)),
    
    create: async (body) => POST(endpointsCentros.CREATE, body),
    
    update: async ({_id, body}) => PATCH(endpointsCentros.BY_CENTRO(_id), body),
    
    remove: async (id) => DELETE(endpointsCentros.BY_CENTRO(id)),
    
    updateImage: async (centerId, body) => PATCH(endpointsCentros.CENTER_LOGO(centerId), body),
    
    getImage: async (centerId) => GET(endpointsCentros.CENTER_LOGO(centerId))
}

export default CentersService