import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const ConfirmButton = ({
    onClick, 
    ...props
}) => {
    const { formatMessage: msg } = useIntl()

    return (
        <Button
            id='confirm'
            btnText={msg({id: 'button.confirm'})}
            btnClass='button-white button-white__padding__8_15'
            onHandleClick={onClick}
            iconClass="pi-arrow-right" // TODO: añadir el icono correcto
            {...props}
        />
    )
}

export default ConfirmButton