import { createSlice } from '@reduxjs/toolkit'
import CentersCostCenterService from './centersCostCenter.service'
import createAsyncThunkMiddleware from '../../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../../common/extraReducers'

export const create = createAsyncThunkMiddleware('centersCostCenter/create', CentersCostCenterService.create)

export const getAll = createAsyncThunkMiddleware('centersCostCenter/getAll', CentersCostCenterService.getAll)

export const update = createAsyncThunkMiddleware('centersCostCenter/update', CentersCostCenterService.update)

export const remove = createAsyncThunkMiddleware('centersCostCenter/delete', CentersCostCenterService.remove)

const initialState = []

const centersCostCenterSlice = createSlice({
    name: 'centersCostCenter',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default centersCostCenterSlice.reducer
