import { createSlice } from '@reduxjs/toolkit'
import SubventionService from './subvention.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('subvention/create', SubventionService.create)

export const getAll = createAsyncThunkMiddleware('subvention/getAll', SubventionService.getAll)

export const update = createAsyncThunkMiddleware('subvention/update', SubventionService.update)

export const remove = createAsyncThunkMiddleware('subvention/delete', SubventionService.remove)

const initialState = []

const subventionSlice = createSlice({
    name: 'subventions',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default subventionSlice.reducer
