
export const endpointsEducativeOfferStageAndService = {
    BY_COURSE_ID: (courseId) => `/v1/centers/courses/educativeOffer/course/${courseId}`,
    OTHER_SERVICES: (courseId, otherServicesId) => `/v1/centers/courses/educativeOffer/course/${courseId}/otherService/${otherServicesId}`,
    BY_ETAPA: (courseId, etapaId) => `/v1/centers/courses/educativeOffer/course/${courseId}/etapa/${etapaId}`
}

export const endpointsEducativeOfferStage = {
    BY_ID_AND_STAGE_ID: (id, teamId) => `/v1/centers/courses/educativeOffer/${id}/etapa/${teamId}`,

    CLASS_AND_STUDENTS: (id, etapaId) => `/v1/centers/courses/educativeOffer/course/${id}/etapa/${etapaId}/classroomAndStudents`,
    CLASS_AND_STUDENTS_CONFIRM: (id, etapaId) => `/v1/centers/courses/educativeOffer/course/${id}/etapa/${etapaId}/classroomAndStudents/confirm`,

    NEE: (id, etapaId) => `/v1/centers/courses/educativeOffer/course/${id}/etapa/${etapaId}/nee`,
    NEE_CONFIRM: (id, etapaId) => `/v1/centers/courses/educativeOffer/course/${id}/etapa/${etapaId}/nee/confirm`,
    
    DISCONFIRM: (id, etapaId) => `/v1/centers/courses/educativeOffer/${id}/etapa/${etapaId}/disconfirm`,
}

export const endpointsEducativeOfferService = {
    // Falta el GET en back
    BY_ETAPA: (id, etapaId, serviceId) => `/v1/centers/courses/educativeOffer/course/${id}/etapa/${etapaId}/service/${serviceId}`,
    OTHER_SERVICE_CONFIRM: (id, serviceId) => `/v1/centers/courses/educativeOffer/course/${id}/otherService/${serviceId}/confirm`,
    CONFIRM: (id, etapaId, serviceId) => `/v1/centers/courses/educativeOffer/course/${id}/etapa/${etapaId}/service/${serviceId}/confirm`,
    DISCONFIRM: (id, serviceId) => `/v1/centers/courses/educativeOffer/${id}/otherServices/${serviceId}/disconfirm`,
}
