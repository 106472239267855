import React from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"

const Budgets = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId} = useParams()

    const budgetCards = [
        {
            _id: 'expenses',
            name: msg({id: 'expenses'}),
        },
        {
            _id: 'incomes',
            name: msg({id: 'income'}),
        },
        {
            _id: 'results',
            name: msg({id: 'result'}),
            disabled: true
        }
    ]

    const goToList = (id, item) => {
        if (item?._id === "expenses") {
            return CENTER_COURSE_DETAILS.BUGET_EXPENSES(centerId, courseId)
        }
        if (item?._id === "incomes") {
            return CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)
        }
        return CENTER_COURSE_DETAILS.BUGET_RESULTS(centerId, courseId)
    }

    const cardList = {
        list: budgetCards,
        gotToLink: goToList
    }

    return (
        <CardsOverview 
            title='budget'
            cardsInfo={cardList}
        />
    )
}

export default Budgets