import { haveFilterQuery, paginator, haveSort } from '../common/auxFun'

export const endpointsLinesDistributionHoursExceeded = {
    GENERIC: '/v1/maestros/templateDistributionHourSchool/linesDistributionHoursExceeded',
    LOAD: ({filter, limit, skip, sort}) =>
        `/v1/maestros/templateDistributionHourSchool/linesDistributionHoursExceeded/${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(
            sort
        )}`,
    BY_ID: (id) => `/v1/maestros/templateDistributionHourSchool/linesDistributionHoursExceeded/${id}`

}

export const endpointsTemplateCalculateHours = {
    GENERIC: '/v1/maestros/templateDistributionHourSchool/templateEtapaHours',
    LOAD: ({filter, limit, skip, sort}) =>
        `/v1/maestros/templateDistributionHourSchool/templateEtapaHours/${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(
            sort
        )}`,
    BY_ID: (id) => `/v1/maestros/templateDistributionHourSchool/templateEtapaHours/${id}`,
    LIST: `/v1/maestros/templateDistributionHourSchool/templateEtapaHours/list`,
    LINE_POST: (id) => `/v1/maestros/templateDistributionHourSchool/templateEtapaHours/${id}/line`,
    BY_LINE: (id, lineId) => `/v1/maestros/templateDistributionHourSchool/templateEtapaHours/${id}/line/${lineId}`,
}


