import React, { useEffect, useState } from "react"
import BugetTable from "../../common/dataTable/BugetTable"
import StaffDropdown from "./StaffDropdown"
import useModal from "../../../utils/hooks/useModal"
import AddStaffModal from "./AddStaffModal"
import "./staffTable.scss"

const StaffTable = ({ 
    loading = false,
    data,
    nameFieldPerson, 
    columns, 
    columnsFooter 
}) => {

    const { isShowing, toggle } = useModal()

    const [ values, setValues ] = useState()
    const [ fullNameFilter, setFullNameFilter ] = useState('')
    const [ rowIndex, setRowIndex ] = useState(null)

    useEffect(() => {
        setValues(data)
    }, [data])

    const openModalToAddStaff = (filter, index) => {
        if (filter) setFullNameFilter(filter)
        setRowIndex(index)
        toggle()
    }

    const updateTableAfterAddPerson = (staff) => {
        const newStaff = [...values]
        newStaff[rowIndex] = {...newStaff[rowIndex], nameFieldPerson: staff}
        setValues(newStaff)
    }

    const editorStaff = (options) => (
        <StaffDropdown
            onHandleAddStaff={openModalToAddStaff} 
            options={options} />
    )  

    const columnsTable = () => {
        if (columns.length > 0) {
            return columns.map((column) => {
                if (column?.field === nameFieldPerson) {
                    return {...column,
                        body: (rowData) => rowData?.person?.basic?.apellidosYNombre,
                        editor: (options) => editorStaff(options)
                    }     
                }
                return column
            })
        }
        return []   
    }

    return (
        <div className="staff-table">
            <BugetTable
                loading={loading}
                tableOptions={{
                    loading,
                    editMode: "cell",
                    value: data,
                }}
                columns={columnsTable()}
                columnsFooter={columnsFooter}
            />
            <AddStaffModal
                isShowing={isShowing}
                toggle={toggle}
                fullNameFilter={fullNameFilter}
                updateTableAfterAddPerson={updateTableAfterAddPerson}
            />
        </div>
    )
}

export default StaffTable