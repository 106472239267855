import React from "react"
import { ControlLabel } from "../label/Label"
import MultiSelectComp from "../dropdown/MultiSelectComp"

const LabeledMultiSelect = ({
    classNameBox,
    idLabel, 
    translate,
    required, 
    name,
    value,
    onChange,
    options,
    optionLabel, 
    optionValue,
    disabled, 
    filter,
    placeholderId
}) => {

    return (
        <div className={`flex-container ${classNameBox} `} >
            <ControlLabel id={idLabel} required={required} translate={translate}/>
            <MultiSelectComp
                filter={filter}
                name={name}
                value={value}
                onChange={onChange}
                options={options}
                optionLabel={optionLabel}
                optionValue={optionValue}
                disabled={disabled}
                placeholder={placeholderId}
            />
        </div>
    )
}

export default LabeledMultiSelect