import React from "react"
import MaestrosListView from '../../../../components/common/MaestrosListView'
import { MAESTROS_ACCOUNTING_CENTER } from "../../../../utils/constants/maestros/maestrosCenters"


const MaestrosAccountingCenter = () => (
    <MaestrosListView 
        title='maestros.accounting.center' 
        maestros={MAESTROS_ACCOUNTING_CENTER} 
    />
)

export default MaestrosAccountingCenter