import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams, useNavigate } from "react-router-dom"
import StaffService from "../../../../services/staff/staff.service"
import Modal from '../../../../components/modal/Modal'
import useModal from '../../../../utils/hooks/useModal'
import useInputs from "../../../../utils/hooks/useInputs"
import StaffProfileTabs from '../../../../components/maestros-labor/staff/staff-profile-tabs/StaffProfileTabs'
import StaffEditForm from '../../../../components/maestros-labor/staff/staff-edit-form/StaffEditForm'
import swalAlert from "../../../../utils/helpers/swalAlert"
import { confirmAction } from "../../../../utils/helpers/common"
import CommonHeader from "../../../../components/common/commonHeader/CommonHeader"
import "./staffProfile.scss"

// TODO: refactorizar no se visualiza la informacion correctamente 
const StaffProfile = () => {
    const { id: profileId } = useParams()

    const {formatMessage: msg} = useIntl()
    const navigate = useNavigate()

    const {isShowing, toggle} = useModal()
    const {inputs, updateInputs, onChangeInputs } = useInputs()

    const [activeIndex, setActiveIndex] = useState(0)
    const [ infoBasic, setBasicInfo ] = useState(null)
    const [ infoSs, setSsInfo ] = useState(null)
    const [ infoWork, setWorkInfo ] = useState(null)
    
    const getInfoIfNeeded = async (info, getter) => info ?? (await getter(profileId))?.resp
    const resetBasicInfoIfNeeded = async () => setBasicInfo(await getInfoIfNeeded(infoBasic, StaffService.getBasicById))
    const resetWorkInfoIfNeeded = async () => setWorkInfo(await getInfoIfNeeded(infoWork, StaffService.getLaboralById))
    const resetSsInfoIfNeeded = async () => setSsInfo(await getInfoIfNeeded(infoSs, StaffService.getSocialsById))

    const getInfo = [
        async () => resetBasicInfoIfNeeded(),
        async () => resetWorkInfoIfNeeded(),
        async () => resetSsInfoIfNeeded()
    ]

    const onChangeTab = ({index}) => {
        setActiveIndex(index)
        getInfo[index]()
    }
    
    useEffect(() => {
        resetBasicInfoIfNeeded()
        resetWorkInfoIfNeeded()
        resetSsInfoIfNeeded()
    }, [])


    const openEditModal = async () => {
        const profileData = {
            infoBasic,
            infoSs,
            infoWork
        }

        updateInputs(profileData)
        toggle()
    }

    const handleSave = async () => {
        const updatedBasic = await StaffService.updateBasicById(profileId, {...inputs?.infoBasic})
        const updatedWork = await StaffService.updateLaboralById(profileId, {labor: Object.values(inputs?.infoWork)})
        const updatedSs = await StaffService.updateSocialsById(profileId, {...inputs?.infoSs})

        if (updatedBasic && updatedWork && updatedSs) {
            await swalAlert.succesWithTimer()
        }

        setBasicInfo(updatedBasic?.resp)
        setWorkInfo(updatedWork?.resp)
        setSsInfo(updatedSs.resp)

        toggle()
    }

    const handleDelete = async () => {
        const res = await confirmAction({
            action: () => StaffService.remove(profileId),
            areYouSureMsg: msg({id: "swal.title.delete"}),
            yesMsg: msg({id: "button.delete"}),
            noMsg: msg({id: "button.cancel"}),
        })

        if (res) {
            navigate(-1)
        }
    }
    const handlerHeaderActions = {
        firstHandlerAction: openEditModal, 
        textFirstAction: "button.edit", 
        firstIconClass: "pi-file-edit",
        secondHandlerAction: handleDelete, 
        textSecondAction: "button.delete",
        secondIconClass: "pi-trash"
    }

    return (
        <div className="main-common-view">
            <div className="staff-profile-salary-table container__with-background">
                <CommonHeader 
                    titleSuffix={infoBasic?.apellidosYNombre} 
                    handlerHeaderActions={handlerHeaderActions}
                />
                <StaffProfileTabs 
                    infoBasic={infoBasic}
                    infoWork={infoWork}
                    infoSs={infoSs}
                    onChangeTab={onChangeTab}
                    isReadOnly
                />
            </div>

            <Modal 
                isShowing={isShowing}
                hide={toggle}
                title={msg({id: 'profile.edit'})}
                width="100%"
                maxWidth="1250px"
                context={(
                    <StaffEditForm
                        inputs={inputs}
                        handleChange={onChangeInputs}
                        handleSave={handleSave}
                        activeTabIndex={activeIndex}
                    />
                )}
            />
        </div>
    )
}

export default StaffProfile
