import { createSlice } from '@reduxjs/toolkit'
import SubtypesService from './subtypes.service'
import createAsyncThunkMiddleware from '../../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../../common/extraReducers'

export const create = createAsyncThunkMiddleware('subtypes/create', SubtypesService.create)

export const getAll = createAsyncThunkMiddleware('subtypes/getAll', SubtypesService.getAll)

export const update = createAsyncThunkMiddleware('subtypes/update', SubtypesService.update)

export const remove = createAsyncThunkMiddleware('subtypes/delete', SubtypesService.remove)

const initialState = []

const subtypesSlice = createSlice({
    name: 'subtypes',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default subtypesSlice.reducer
