
export const CenterBudgetsEndpoints = {
    BUDGETS: (courseId) => `/v1/centers/courses/budgets/course/${courseId}`,
    
    INCOMES: (courseId) => `/v1/centers/courses/budgets/incomes/course/${courseId}`,
    CONCEPTS: (courseId, balanceId) => `/v1/centers/courses/budgets/incomes/others/course/${courseId}/balance/${balanceId}`,
    ACCOUNTS: (courseId, conceptId) => `/v1/centers/courses/budgets/incomes/others/course/${courseId}/concept/${conceptId}`,
    OTHER_CONCEPTS: (courseId, conceptId) => `/v1/centers/courses/budgets/incomes/others/course/${courseId}/concept/${conceptId}`,
    ACCOUNTS_DATA: (courseId, conceptId, accountId) => `/v1/centers/courses/budgets/incomes/others/course/${courseId}/concept/${conceptId}/account/${accountId}`,
    TOTAL_INCOMES: (courseId, conceptId, accountId) => `/v1/centers/courses/budgets/incomes/others/course/${courseId}/concept/${conceptId}/account/${accountId}/totals`,
    TOTAL_INCOMES_AGROUPATIONS: (courseId, conceptId, accountId, agroupationId) => `/v1/centers/courses/budgets/incomes/others/course/${courseId}/concept/${conceptId}/account/${accountId}/agroupation/${agroupationId}`,
    TOTAL_INCOMES_CONFIRM: (courseId, conceptId, accountId) => `/v1/centers/courses/budgets/incomes/others/course/${courseId}/concept/${conceptId}/account/${accountId}/totals/confirm`,

    ACTIVITIES: (courseId, balanceId) => `/v1/centers/courses/budgets/incomes/activities/course/${courseId}/balance/${balanceId}`,
    ACTIVITIES_DATA: (courseId, conceptId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}`,
    ACTIVITIES_DATA_ETAPA: (courseId, conceptId, etapaId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}/etapa/${etapaId}`,
    ACTIVITIES_DATA_ETAPA_GENERAL_TABLE: (courseId, conceptId, etapaId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}/etapa/${etapaId}/fees`,
    ACTIVITIES_DATA_SERVICES_TABLE: (courseId, conceptId, otherServiceId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}/service/${otherServiceId}`,
    ACTIVITIES_DATA_ETAPA_SERVICES_TABLE: (courseId, conceptId, etapaId, serviceId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}/etapa/${etapaId}/service/${serviceId}`,
    ACTIVITIES_DATA_ETAPA_SERVICES_TABLE_CONFIRM: (courseId, conceptId, etapaId, serviceId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}/etapa/${etapaId}/service/${serviceId}/confirm`,

    ACTIVITIES_SUBVENTIONS: (courseId, conceptId) => `/v1/centers/courses/budgets/incomes/activities/subventions/course/${courseId}/concept/${conceptId}`,
    ACTIVITIES_SUBVENTIONS_TABLE: (courseId, conceptId, etapaId) => `/v1/centers/courses/budgets/incomes/activities/subventions/course/${courseId}/concept/${conceptId}/etapa/${etapaId}`,

    ACTIVITIES_DATA_ETAPA_GENERAL_TABLE_CONFIRM: (courseId, conceptId, etapaId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}/etapa/${etapaId}/fees/confirm`,
    ACTIVITIES_DATA_SERVICES_TABLE_CONFIRM: (courseId, conceptId, otherServiceId) => `/v1/centers/courses/budgets/incomes/activities/fees/course/${courseId}/concept/${conceptId}/service/${otherServiceId}/confirm`,


    EXPENSES: (courseId) => `/v1/centers/courses/budgets/expenses/course/${courseId}`,
    EXPENSES_BALANCES: (courseId, balanceId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/balance/${balanceId}`,
    EXPENSES_CONCEPTS: (courseId, conceptId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/concept/${conceptId}`,

    EXPENSES_SUPPLIES_TOTALS_DATA: (courseId, conceptId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/concept/${conceptId}/totals`,
    EXPENSES_SUPPLIES_TOTALS_ACCOUNTS: (courseId, conceptId, accountId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/concept/${conceptId}/totals/account/${accountId}`,
    EXPENSES_SUPPLIES_TOTALS_ACCOUNTS_CONFIRM: (courseId, conceptId, accountId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/concept/${conceptId}/totals/account/${accountId}/confirm`,

    EXPENSES_SUPPLIES_ETAPAS_DATA: (courseId, conceptId, etapaId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/concept/${conceptId}/agroupation/${etapaId}`,
    EXPENSES_SUPPLIES_AGROUPATIONS_ACCOUNTS: (courseId, conceptId, etapaId, accountId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/concept/${conceptId}/agroupation/${etapaId}/account/${accountId}`,
    EXPENSES_SUPPLIES_AGROUPATIONS_ACCOUNTS_CONFIRM: (courseId, conceptId, etapaId, accountId) => `/v1/centers/courses/budgets/expenses/supplies/course/${courseId}/concept/${conceptId}/agroupation/${etapaId}/account/${accountId}/confirm`,

    STAFF_EXPENSES: (courseId) => `/v1/centers/courses/budgets/expenses/staffs/course/${courseId}`,
    STAFF_EXPENSES_ETAPA: (courseId, etapaId) => `/v1/centers/courses/budgets/expenses/staffs/course/${courseId}/etapa/${etapaId}`,
    CONFIRM_STAFF_EXPENSES_ETAPA: (courseId, etapaId) => `/v1/centers/courses/budgets/expenses/staffs/course/${courseId}/etapa/${etapaId}/confirm`,


    RESULTS: (courseId) => `/v1/centers/courses/budgets/results/course/${courseId}`,
    RESULTS_TRANSPOSE: (courseId, transpose) => `/v1/centers/courses/budgets/results/course/${courseId}?transpose=${transpose}`,
    EXPENSES_RESULTS: (courseId) => `/v1/centers/courses/budgets/results/expenses/course/${courseId}`,
    INCOMES_RESULTS: (courseId) => `/v1/centers/courses/budgets/results/incomes/course/${courseId}`,
    EXPENSES_RESULTS_TRANSPOSE: (courseId, transpose) => `/v1/centers/courses/budgets/results/expenses/course/${courseId}?transpose=${transpose}`,
    INCOMES_RESULTS_RANSPOSE: (courseId, transpose) => `/v1/centers/courses/budgets/results/incomes/course/${courseId}?transpose=${transpose}`,
}