import { haveFilterQuery, paginator, haveSort } from "../common/auxFun"


export const endpointsAccounts = {
    GENERIC: '/v1/maestros/accounting/accounts',
    ACCOUNTS: {
        LOAD: ({filter, limit, skip, sort}) => `/v1/maestros/accounting/accounts${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
        BY_ACCOUNT: (id) => `/v1/maestros/accounting/accounts/${id}`,
        UPLOAD_FILE: '/v1/maestros/accounting/accounts/uploadFile',
    },
    SUBACCOUNTS: {
        BY_ACCOUNT_CREATE: (id) => `/v1/maestros/accounting/accounts/${id}/subaccount`,
        BY_ACCOUNT: ({id, filter, limit, skip, sort}) =>
            `/v1/maestros/accounting/accounts/${id}/subaccount${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
        BY_SUBACCOUNT: (id) => `/v1/maestros/accounting/subaccounts/${id}`,
        BY_SUBACCOUNT_ENABLE: (id) => `/v1/maestros/accounting/subaccounts/${id}/enable`,
        BY_SUBACCOUNT_DISABLE: (id) => `/v1/maestros/accounting/subaccounts/${id}/disable`,
        BY_ACCOUNT_AND_SUBACCOUNT: (id, subaccountId) => `/v1/maestros/accounting/accounts/${id}/subaccount/${subaccountId}`,
    }
}