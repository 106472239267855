import React from "react"
import LabeledCalendar from "../../common/form-components/labeled-calendar/LabeledCalendar"
import LabeledFile from "../../common/form-components/labeled-file/LabeledFile"
import LabeledTextarea from "../../common/form-components/labeled-inputs/LabeledTextArea"
import "./collective-agreements-add-fileAndData.scss"
import LabeledNumber from "../../common/form-components/labeled-inputs/LabeledNumber"

const CollectiveAgreementsAddFileAndData = ({
    inputs, 
    onChangeInputs,
    fileRef
}) => {

    return (
        <div className="collective-add-file">
            <div className="label-action">
                <div className="label-action-info">
                    <LabeledCalendar 
                        idLabel='year'
                        required
                        name='year'
                        value={inputs?.year}
                        onChange={onChangeInputs}
                        dateFormat="yy"
                        view='year'
                        showIcon
                    />
                    <LabeledFile 
                        idLabel='file'
                        required
                        name='file'
                        accept='.pdf'
                        chooseLabel='upload'
                        onSelect={onChangeInputs}
                        ref={fileRef}
                    />
                    <LabeledNumber 
                        idLabel='salaryIncrease'
                        name='salaryIncrease'
                        value={inputs?.salaryIncrease}
                        onChange={onChangeInputs}
                        suffix='%'
                        maxFractionDigits={2}
                    />
                </div>
                <LabeledTextarea 
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={onChangeInputs}
                    numRows={1}
                />
            </div>
        </div>
    )
}

export default CollectiveAgreementsAddFileAndData