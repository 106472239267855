import React from 'react'
import { useIntl } from 'react-intl'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from "../../common/buttons/SaveButton"
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import { COST_CENTER_GROUPING } from '../../../utils/constants/grouping'
import './formCentersCostCenter.scss'

const CenterCostCenterForm = ({ 
    inputs, 
    handleChange,
    handleSave
}) => {
    const intl = useIntl()

    const validateForm = validators.hasValue(inputs?.name) 
                    && validators.hasValue(inputs?.typeGrouping)

    return (
        <div className="form-center-cost-modal">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                    <div className="control-wrapper">
                        <LabeledDropdown 
                            idLabel='grouped.by'
                            required
                            name='typeGrouping'
                            optionLabel='name'
                            optionValue='value'
                            value={inputs?.typeGrouping}
                            options={COST_CENTER_GROUPING}
                            showClear
                            placeholder={intl.formatMessage({id: 'placeholder.select.grouping'})} 
                            onChange={(e) => handleChange(e)}
                        />
                    </div>
                    <div className="control-wrapper">
                        <LabeledTextArea 
                            idLabel='description'
                            name='description'
                            value={inputs?.description}
                            onChange={(e) => handleChange(e)}
                            numRows={3}
                            numColumns={15}
                        />
                    </div>
                </div> 
            </div>
            <div className="modal-btn-container">
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default CenterCostCenterForm
