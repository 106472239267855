import { GET, POST, DELETE, PATCH } from "../../common/commonRequest.service"
import { endpointsCenterAccountPlan } from "./endpoints"

const CenterAccountPlanService = {
    create: async (body) => POST(endpointsCenterAccountPlan.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsCenterAccountPlan.LOAD({ filter, limit, skip, sort })),
    update: async ({_id, body}) => PATCH(endpointsCenterAccountPlan.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsCenterAccountPlan.BY_ID(id)),
    getAllAccountPlanjustificationSubvention: async ({ filter, limit, skip, sort }) => GET(endpointsCenterAccountPlan.LOAD_JUSTIFICATE_SUBVENTIONS({ filter, limit, skip, sort })),
    getById: async (id) => GET(endpointsCenterAccountPlan.BY_ID(id))
}

export default CenterAccountPlanService