import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useIntl } from "react-intl"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import EducationOfferService from "../../../../services/centers/courses/education-offer-and-service/educationOffer.service"
import BreadcrumbLink from "../../../../components/common/breadcrumb/BreadcrumbLink"
import useOfferNavigation from "../../../../components/center-courses/education-offer/hooks/useOfferNavigation"
import useLoading from "../../../../utils/hooks/useLoading"

const EducationOffer = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, educationOfferId} = useParams()

    const { previousInEtapa } = useOfferNavigation()

    const [ etapa, setEtapa ] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getEtapa()
    }, [educationOfferId])

    const getEtapa = async () => {
        startLoading()
        const resp = await EducationOfferService.getByEtapaId(courseId, educationOfferId)
        if (resp) {
            setEtapa(resp?.resp)
        }
        stopLoading()
    }

    const goToEtapa = (educationOffer) => {
        return CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, educationOffer)
    }

    const goToTable = (id, item) => {
        if (item?._id === 'studentsclassrooms') {
            return CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_CLASS(centerId, courseId, educationOfferId)
        }
        if (item?._id === 'nee') {
            return CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_NEE(centerId, courseId, educationOfferId)
        }
        return CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_SERVICES(centerId, courseId, educationOfferId, item?.service)
    }

    const additionalItems = [
        {
            name: msg({ id: 'studentsclassrooms' }).toUpperCase(),
            ...etapa?.classroomAndStudents,
            confirmation: etapa?.classroomAndStudentsConfirmation,
            _id: 'studentsclassrooms'
        },
        {
            name: msg({ id: 'nee' }),
            ...etapa?.nee,
            confirmation: etapa?.neeConfirmation,
            _id: 'nee'
        },
        ...etapa?.services?.map(service => ({
            name: service?.name,
            ...service,
            confirmation: etapa?.servicesConfirmation?.find(conf => conf.service === service.service)?.confirmation
        })) || []
    ].filter(Boolean)

    const contentSummary = (
        <h1>Aqui va el resumen</h1>
    )

    const paramsGenerateExcel = {
        fileName: msg({ id: 'educative.offer.and.services'}),
        cols: [
            { title: msg({ id: 'name' }), dataKey: 'name' }
        ],
        resp: async () => {
            const resp = await EducationOfferService.getSummary(courseId)
            return resp?.resp?.associations
        }
    }

    const breadcrumb = [
        {
            label: msg({ id: 'educative.offer.and.services' }),
            template: <BreadcrumbLink
                path={CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId)}
                text={msg({ id: 'educative.offer.and.services' })} />
        },
        ...(etapa?.path || [])
            ?.slice(0, -1)
            ?.map(p => ({
                label: p?.name,
                template: <BreadcrumbLink
                    path={CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, p?._id)}
                    text={p?.name} />
            })),
        {
            label: etapa?.name,
        }
    ]

    const handlePrevious = () => { previousInEtapa() }

    const detailView = {
        list: etapa?.subetapas,
        gotToLink: goToEtapa
    }

    const dashboard = {
        list: additionalItems,
        gotToLink: goToTable
    }

    return (
        etapa?.isEducativeLastLevel ? (
            <CardsOverview
                loading={loading}
                cardsInfo={dashboard}
                breadcrumb={breadcrumb}
                contentTableSummary={contentSummary}
                paramsGenerateExcel={paramsGenerateExcel}
                handlePrevious={handlePrevious}
            />
        ) : (
            <CardsOverview
                loading={loading}
                cardsInfo={detailView}
                breadcrumb={breadcrumb}
                contentTableSummary={contentSummary}
                paramsGenerateExcel={paramsGenerateExcel}
                handlePrevious={handlePrevious}
            />
        )
    )
}

export default EducationOffer