import { createAsyncThunk } from '@reduxjs/toolkit'

/**
 * Create Redux async action
 *
 * @public
 * @param {String} name Action name
 * @param {Function} service API call
 */
const createAsyncThunkMiddleware = (name, service) => createAsyncThunk(
    name,
    async (args, thunkAPI) => {
        try {
            const res = await service(args)
            if (res === null) {
                return thunkAPI.rejectWithValue('Error') 
            }
            return res

        } catch (error) {
            // return custom error message from API if any
            if (error.response && error.response.data.message) {
                return thunkAPI.rejectWithValue(error.response.data.message)
            } 
            return thunkAPI.rejectWithValue(error.message)
        }
    }
)

export default createAsyncThunkMiddleware