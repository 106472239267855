const rowEditor = () => {
    return {
        key: Math.random(),
        className: 'row-edit',
        rowEditor, 
        align: "right", 
        exportable: false
    }
}

export default rowEditor