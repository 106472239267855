import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import useLoading from "../../../../utils/hooks/useLoading"
import JustifyContent from "../../../../components/center-courses/hours-and-staff/JustifyContent"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import DistributionHoursService from "../../../../services/centers/courses/distribution-hours/ditributionHours.service"

const HoursAndStaff = () => {

    const { centerId, courseId } = useParams()
    const navigate = useNavigate()
    
    const [distributionHours, setDistributionHours] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getDistributioHours()
    }, [])

    const getDistributioHours = async () => {
        startLoading()
        const resp = await DistributionHoursService.getById(courseId)
        if (resp) {
            setDistributionHours({
                ...resp?.resp, 
                etapas: resp?.resp?.etapas?.map(r => ({_id: r?.etapa, ...r}))})
        }
        stopLoading()
    }

    const goToEtapa = (id) => {
        if (id === 'pas') return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_PAS(centerId, courseId)
        return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, id)
    }

    const contentSummary = (
        <h1>Aqui va el resumen</h1>
    )

    const justifyContent = (
        <JustifyContent 
            distributionHoursData={distributionHours}
        />
    )

    const offerAndServices = [
        ...distributionHours?.etapas || [],
        ...(distributionHours?.otherServices || []),
        {
            _id: 'pas',
            name: 'pas'.toUpperCase(),
            confirmation: {
                isConfirm: distributionHours?.pasConfirmed,
            }
        }
    ]

    const handlePrevious = () => navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId))

    const handleNext = () => navigate(CENTER_COURSE_DETAILS.BUGET(centerId, courseId))

    const cardList = {
        list: offerAndServices,
        gotToLink: goToEtapa
    }

    return (
        <CardsOverview
            loading={loading}
            title='hours.and.staff'
            cardsInfo={cardList}
            contentTableSummary={contentSummary}
            justifyContent={
                distributionHours?.totalHoursAssigned < distributionHours?.totalHoursToDistribute 
                    ? justifyContent 
                    : undefined
            }
            handlePrevious={handlePrevious}
            handleNext={handleNext}
        />
    )
}

export default HoursAndStaff