import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsPas } from "./endpoints"

const PasService = {
    create: async (body) => POST(endpointsPas.GENERIC, body),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsPas.LOAD({filter, limit, skip, sort})),
    getById: async ({pasId}) => GET(endpointsPas.BY_ID(pasId)),
    update: async ({_id, body}) => PATCH(endpointsPas.BY_ID(_id), body),
    remove: async (neeId) => DELETE(endpointsPas.BY_ID(neeId))
}

export default PasService