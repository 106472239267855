// import API from '../commonAPI'
import { GET, DELETE, POST, PATCH } from "../common/commonRequest.service"
import { endpointsFoundationCourses } from "./endpoints"

export const FoundationCourseService = {
    get: async () => GET(endpointsFoundationCourses.GENERIC),
    create: async (body) => POST(endpointsFoundationCourses.GENERIC, body),
    getById: async (id) => GET(endpointsFoundationCourses.BY_ID(id)),
    delete: async (id) => DELETE(endpointsFoundationCourses.BY_ID(id)),
    update: async (body) => PATCH(endpointsFoundationCourses.GENERIC, body),
}
