import React from "react"
import edit from '../../../assets/imgs/editar.svg'
import "./iconButtonEdit.scss"

const IconButtonEdit = ({onEdit}) => {
    return (
        
        <div className='icon-btn-edite'>
            <span 
                role='button' 
                tabIndex='0'
                className="edit-icon" 
                onClick={onEdit} 
            >
                <img src={edit} alt='edit' />
            </span>
        </div>
    )
}

export default IconButtonEdit