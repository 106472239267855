import { MAESTROS } from './maestros'

const generateKeyRouteMapSingle = (mastersList, targetMap) => mastersList
    ?.reduce((accumulated, master) => {
        const resultingMap = accumulated || targetMap

        if (master.key) {
            resultingMap[master.key] = master.route
        }

        return resultingMap
    }, targetMap)

const generateKeyRouteMap = (mastersLists, targetMap) => mastersLists
    ?.reduce((map, list) => generateKeyRouteMapSingle(list, targetMap), {})

const MAESTROS_KEY_ROUTE_MAP = generateKeyRouteMap([ MAESTROS ], {})

export default MAESTROS_KEY_ROUTE_MAP
