import React, { useEffect, useState, useContext } from "react"
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { LayoutCenterContext } from "../../../../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../../../../utils/hooks/useLoading"
import BugetTable from '../../../../../../../components/common/dataTable/BugetTable'
import Dashboard from '../../../../../../../components/common-courses/view-content/Dashboard'
import BudgetService from '../../../../../../../services/centers/courses/budgets/budgets.service'
import BreadcrumbLink from '../../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../../routes/centers/center-course/pathname'
import editNumberRowColumn from '../../../../../../../components/common/dataTable/editors/editNumberRowColumn'

const GeneralFeesTable = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId, feesId, etapaId} = useParams()

    const [generalTable, setGeneralTable] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getDataGeneralTable()
    }, [])

    const getDataGeneralTable = async () => {
        startLoading()
        const resp = await BudgetService.getActivitiesDataEtapaGeneralTable(courseId, feesId, etapaId)
        if (resp) {
            setGeneralTable(resp?.resp)
        }
        stopLoading()
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await BudgetService.confirmActivitiesDataEtapaGeneralTable(courseId, feesId, etapaId, generalTable)
                : await BudgetService.updateActivitiesDataEtapaGeneralTable(courseId, feesId, etapaId, generalTable)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setGeneralTable(resp?.resp)
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: generalTable?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES(centerId, courseId, generalTable?.balanceTemplate)} 
                text={generalTable?.templateName} /> 
        },
        {
            label: generalTable?.conceptName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES(centerId, courseId, balanceId, generalTable?.concept)} 
                text={generalTable?.conceptName} /> 
        },
        {
            label: generalTable?.etapaName
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleSave,
        handleConfirm
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: generalTable?.fees,
            }}
            columns={[
                { field: 'feeName', header: 'fee'},
                { field: 'numStudents',
                    header: 'students',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: {width: "10%"}
                },
                { field: 'periodicity',
                    header: 'periodicity',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    bodyClassName: 'editRow',
                    style: {width: "10%"},
                    ...editNumberRowColumn({})
                },
                { field: 'income', 
                    header: 'amount',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    bodyClassName: 'editRow',
                    style: {width: "10%"},
                    ...editNumberRowColumn({})
                },
                { field: 'totalIncome',
                    header: 'total.import',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: {width: "10%"}},
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 3, footerStyle: { textAlign: 'left' } },
                { footer: 'sum', field: 'income', className: "p-datatype-number"},
                { footer: 'sum', field: 'totalIncome', className: "p-datatype-number"}
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default GeneralFeesTable