import React from "react"
import { Chips } from 'primereact/chips'
import "./inputComp.scss"
import { useIntl } from "react-intl"

const InputChipsComp = ({
    name,
    value, 
    onChange,
    ...propsChips
}) => {

    const { formatMessage: msg } = useIntl()
    return (
        <Chips 
            name={name}
            value={value}
            onChange={onChange}
            placeholder={msg({id: 'info.options.chip'})}
            {...propsChips}
        />
    )
}

export default InputChipsComp