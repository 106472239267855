import React, { useEffect, useState, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { LayoutCenterContext } from '../../../../../components/common-courses/context/LayoutCenterContex'
import useLoading from '../../../../../utils/hooks/useLoading'
import BreadcrumbLink from '../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../routes/centers/center-course/pathname'
import Dashboard from '../../../../../components/common-courses/view-content/Dashboard'
import DistributionStaffTable from '../../../../../components/common-courses/staff/DistributionStaffTable'
import DistributionHoursService from "../../../../../services/centers/courses/distribution-hours/ditributionHours.service"
import editNumberRowColumn from '../../../../../components/common/dataTable/editors/editNumberRowColumn'
import Button from '../../../../../components/common/buttons'
import Delete from '../../../../../assets/imgs/papelera.svg'

const Staff = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, etapaId } = useParams()

    const navigate = useNavigate()
    
    const [pasData, setPasData] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getPasData()
    }, [])

    const getPasData = async () => {
        startLoading()
        const resp = await DistributionHoursService.getStaff(courseId, etapaId)
        if (resp) {
            const transformedData = resp?.resp?.staffs.map(item => ({ 
                person: {
                    basic: item.staff.basic,
                    _id: item.staff._id
                },
                selectedLabor: item.staff.labor.map(laborItem => ({
                    ...laborItem,
                    selectedLabor: item.staff.selectedLabor
                })),
                schoolPaymentHours: item.schoolPaymentHours,
                delegatedPaymentHours: item.delegatedPaymentHours
            }))
            setPasData(resp?.resp?.staffs.length > 0 ? { ...resp?.resp, staff: transformedData } : { ...resp?.resp, staff: resp?.resp?.staffs })
        }
        stopLoading()
    }

    const handleAddDataLine = () => {
        setPasData(prevState => ({
            ...prevState,
            staff: [
                ...prevState?.staff,
                { _id: Math.random(), person: null, selectedLabor: null, schoolPaymentHours: 0, delegatedPaymentHours: 0 }
            ]
        }))
    }

    const transformData = (data) => {
        const patchedData = data?.filter(item => item!==null && item?.person!==null).map(item => {
            return item?.person && {
                daDni: item?.person?.basic?.daDni,
                coIdenContrato: item?.person?.labor?.[0]?.coIdenContrato || null,
                schoolPaymentHours: item?.schoolPaymentHours || null,
                delegatedPaymentHours: item?.delegatedPaymentHours || null
            }
        })?.filter(Boolean)
        return patchedData
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const transformedData = transformData(pasData?.staff)
            const body = {
                ...pasData,
                staffs: transformedData
            }
            const resp = confirm===true
                ? await DistributionHoursService.confirmStaff(courseId, etapaId, body)
                : await DistributionHoursService.updateStaff(courseId, etapaId, body)
            if (confirm) setFlagConfirm(true)
            if (!confirm && resp) {
                const transformedBackData = resp?.resp?.staffs.map(item => ({ 
                    person: {
                        basic: item.staff.basic,
                        _id: item.staff._id
                    },
                    selectedLabor: item.staff.labor.map(laborItem => ({
                        ...laborItem,
                        selectedLabor: item.staff.selectedLabor
                    })),
                    schoolPaymentHours: item.schoolPaymentHours,
                    delegatedPaymentHours: item.delegatedPaymentHours
                }))
                setPasData(resp?.resp?.staffs.length > 0 ? { ...resp?.resp, staff: transformedBackData } : { ...resp?.resp, staff: resp?.resp?.staffs })
            }
        }, 0)
    }

    const handleDelete = (data) => {
        if (data?.person && data?.person?.basic) {
            setPasData({...pasData, staff: pasData.staff.filter(item => item.person.basic.daDni!==data.person.basic.daDni)})
        } else {
            setPasData({...pasData, staff: pasData.staff.filter(item => item._id!==data._id)})
        }

    }

    const breadcrumb = [
        {
            label: msg({ id: 'hours.and.staff' }),
            template: <BreadcrumbLink
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({ id: 'hours.and.staff' })} /> 
        },
        {
            label: pasData?.etapaName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, pasData?.etapa)} 
                text={pasData?.etapaName} /> 
        },
        {
            label: msg({id: 'distribution.hours'}),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION(centerId, courseId, etapaId)} 
                text={msg({id: 'distribution.hours'})} /> 
        },
        {
            label: msg({id: 'staff'})
        }
    ]

    const handlePrevious = () => navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_PAS_BY_ID(centerId, courseId, etapaId))

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const footerOptions = {
        handlePrevious,
    }

    const addButton = () => (
        <Button
            btnClass="button-white"
            btnText={msg({ id: 'button.add' })}
            btnType="button"
            onHandleClick={handleAddDataLine}
        />
    )

    const removeButton = (rowData) => (
        <span
            className="btn"
            role="button"
            tabIndex="0"
            onClick={() => handleDelete(rowData)}
        >
            <img className="img-item" style={{width: "18px", height: "18px"}} src={Delete} alt="delete" />
        </span>
    
    )

    const getTableColumns = (
        [
            { field: 'person', header: 'staff'},
            { field: 'dcCategoria', header: 'category', style: {width: "15%"}},
            { field: 'totalHours',
                header: 'totalHours',
                headerClassName: "p-align-end",
                className: 'p-datatype-number',
                style: { width: "15%" },
                body: rowData => 0 + rowData.delegatedPaymentHours + rowData.schoolPaymentHours
            },

            { field: 'delegatedPaymentHours',
                className: "p-datatype-number",
                style: { width: "10%"},
                header: 'delegated.payment.hours',
                ...editNumberRowColumn({})
            },
            { field: 'schoolPaymentHours', 
                header: msg({id: "school.payment.hours"}),
                headerClassName: "p-align-end",
                className: 'p-datatype-number',
                style: {width: "15%"},
                noTraslateHeader: true,
                ...editNumberRowColumn({})
            },
            { header: addButton(), className: "none", body: (rowData) => removeButton(rowData), align: "center", noTraslateHeader: true, style: { width: "2%"} }
        ].filter(Boolean)
    )

    const table = (
        <DistributionStaffTable
            loading={loading}
            data={pasData?.staff || []}
            nameFieldPerson='person'
            nameFieldLabor='dcCategoria'
            columns={
                getTableColumns
            }
            columnsFooter={[
                {footer: msg({id: 'total'}), colSpan: 3, footerStyle: { textAlign: 'left' }},
                {footer: 'sum',
                    field: 'delegatedPaymentHours',
                    headerClassname: "p-align-end",
                    className: 'p-datatype-number'
                },
                {footer: 'sum',
                    field: 'schoolPaymentHours',
                    headerClassname: "p-align-end",
                    className: 'p-datatype-number'
                },
                {footer: '', colSpan: pasData?.coveredService ? 2 : 1},
            ]}
        />
    )

    return (
        <Dashboard 
            headerOptions={headerOptions}
            content={table}
            footerOptions={footerOptions}
        />
    )
}

export default Staff