import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from "../../../../../../../utils/hooks/useLoading"
import Dashboard from '../../../../../../../components/common-courses/view-content/Dashboard'
import BreadcrumbLink from '../../../../../../../components/common/breadcrumb/BreadcrumbLink'
import BugetTable from '../../../../../../../components/common/dataTable/BugetTable'
import { CENTER_COURSE_DETAILS } from '../../../../../../../routes/centers/center-course/pathname'
import BudgetService from '../../../../../../../services/centers/courses/budgets/budgets.service'

const SubventionsTable = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId, subventionId, etapaId} = useParams()

    const [dataTable, setDataTable] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getDataTable()
    }, [])

    const getDataTable = async () => {
        startLoading()
        const resp = await BudgetService.getSubventionsTable(courseId, subventionId, etapaId)
        if (resp) {
            setDataTable(resp?.resp)
        }
        stopLoading()
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: dataTable?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES(centerId, courseId, dataTable?.balanceTemplate)} 
                text={dataTable?.templateName} /> 
        },
        {
            label: dataTable?.conceptName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_SUBVENTIONS(centerId, courseId, balanceId, dataTable?.concept)} 
                text={dataTable?.conceptName} /> 
        },
        {
            label: dataTable?.etapaName || dataTable?.otherServiceName
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: dataTable?.subventions,
            }}
            columns={[
                { field: 'subventionName',
                    header: 'subventionName',
                },
                { field: 'totalSubvention',
                    header: 'subsidyQuantification',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    style: { width: "10%" }
                },
                { field: 'subsidyQuantificationName',
                    header: 'subsidyQuantification',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    style: { width: "10%" }
                },
                { field: 'totalIncome',
                    header: 'amount',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number',
                    style: { width: "10%" }
                }
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 1, footerStyle: { textAlign: 'left' } },
                { footer: 'sum',
                    field: 'totalSubvention',
                    className: 'p-datatype-number',
                },
                { footer: '', field: ''},
                { footer: 'sum',
                    field: 'totalIncome',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number'
                }
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default SubventionsTable