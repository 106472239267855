import { createSlice } from '@reduxjs/toolkit'
import CenterTeamsService from './centerTeams.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const createCenterTeam = createAsyncThunkMiddleware(
    'centerTeams/create', 
    CenterTeamsService.createCenterTeam
)

export const getAllCenterTeams = createAsyncThunkMiddleware(
    'centerTeams/getAll', 
    CenterTeamsService.getAll
)

export const updateCenterTeam = createAsyncThunkMiddleware(
    'centerTeams/update', 
    CenterTeamsService.updateCenterTeam
)


export const confirmCenterTeam = createAsyncThunkMiddleware(
    'centerTeams/update', 
    CenterTeamsService.confirmCenterTeam
)


export const removeCenterTeam = createAsyncThunkMiddleware(
    'centerTeams/remove', 
    CenterTeamsService.removeCenterTeam
)


const initialState = {}

const centersTeamsSlice = createSlice({
    name: 'centerTeams',
    initialState,
    reducers: {
    },
    extraReducers: extraReducersCommon(createCenterTeam, updateCenterTeam, getAllCenterTeams, removeCenterTeam)
})

export default centersTeamsSlice.reducer
