import { createSlice } from '@reduxjs/toolkit'
import CollectiveAgreementsService from './collectiveAggreements.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('collectiveAgreements/create', CollectiveAgreementsService.create)

export const getAll = createAsyncThunkMiddleware('collectiveAgreements/getAll', CollectiveAgreementsService.getAll)

export const update = createAsyncThunkMiddleware('collectiveAgreements/update', CollectiveAgreementsService.update)

export const remove = createAsyncThunkMiddleware('collectiveAgreements/delete', CollectiveAgreementsService.remove)

export const updateFile = createAsyncThunkMiddleware('collectiveAgreements/update', CollectiveAgreementsService.updateFile)

export const removeFile = createAsyncThunkMiddleware('collectiveAgreements/update', CollectiveAgreementsService.removeFile)


const initialState = []

const collectiveAgreementsSlice = createSlice({
    name: 'collectiveAgreements',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default collectiveAgreementsSlice.reducer