import React from "react"
import { useIntl } from 'react-intl'
import MainCommonView from '../../../../components/common/MainCommonView'
import useBasicLocalData from '../../../../utils/hooks/base-adapter/useBasicLocalData'
import MaestrosList from "../../../../components/maestros/MaestrosList"
import { MAESTROS_ACCOUNTING_FOUNDATION } from "../../../../utils/constants/maestros/maestrosFoundation"


const MaestrosAccountingFoundation = () => {
    const {formatMessage: msg} = useIntl()

    const dataFacade = useBasicLocalData({
        allData: MAESTROS_ACCOUNTING_FOUNDATION,
        rowsPerPageInitial: 10,
    })

    const headerOptions = {
        title: msg({id: 'maestros.accounting.foundation'}),
        searchOptions: dataFacade?.searchOptions
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: MAESTROS_ACCOUNTING_FOUNDATION.length
    }
    
    return (
        <MainCommonView 
            componentData={(
                <MaestrosList 
                    maestroslist={dataFacade.data}
                />
            )}
            headerOptions={headerOptions}
            pagerOptions={pagerOptions}
        />
    )
}

export default MaestrosAccountingFoundation