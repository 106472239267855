import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const CancelButton = ({
    id,
    cancelTextId,
    onClick, 
    isDisabled,  
    ...propsCancelButton
}) => {
    const { formatMessage: msg } = useIntl()

    return (
        <Button
            id={id|| 'cancel-button'}
            btnText={msg({id: cancelTextId || "button.cancel"})}
            btnClass="button-white button-white__padding__8_15 action-cours-btn"
            isDisabled={false || isDisabled}
            onHandleClick={onClick}
            iconRight
            iconSize="0.8rem"
            {...propsCancelButton}
        />
    )
}

export default CancelButton