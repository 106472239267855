import React, { useEffect, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { LayoutCenterContext } from '../../../../../components/common-courses/context/LayoutCenterContex'
import useLoading from "../../../../../utils/hooks/useLoading"
import Dashboard from "../../../../../components/common-courses/view-content/Dashboard"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import Button from "../../../../../components/common/buttons"
import BugetTable from "../../../../../components/common/dataTable/BugetTable"
import editNumberRowColumn from "../../../../../components/common/dataTable/editors/editNumberRowColumn"
import editTextRowColumn from "../../../../../components/common/dataTable/editors/editTextRowColumn"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import DistributionHoursService from "../../../../../services/centers/courses/distribution-hours/ditributionHours.service"
import Delete from '../../../../../assets/imgs/papelera.svg';

const SchoolTeamsHours = () => {
    
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, etapaId } = useParams({})

    const navigate = useNavigate()

    const [schoolTeamsHours, setSchoolTeamsHours] = useState()
    const [etapa, setEtapa] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getSchooolTeamsHours()
        getLineConfirmation()
    }, [])

    const getSchooolTeamsHours = async () => {
        startLoading()
        const resp = await DistributionHoursService.getSchool(courseId, etapaId)
        if (resp) {
            setSchoolTeamsHours(resp?.resp)
        }
        stopLoading()
    }

    const getLineConfirmation = async () => {
        const resp = await DistributionHoursService.getEtapa(courseId, etapaId)
        if (resp) {
            setEtapa(resp?.resp?.etapa)
        }
    }

    const handleAddSchoolTeamsHoursData = () => {
        setSchoolTeamsHours(prevState => ({
            ...prevState,
            team: {
                ...prevState?.team,
                categories: [
                    ...prevState?.team?.categories,
                    { _id: Math.random(), name: undefined, hours: 0 }
                ]
            }
        }))
    }

    const handleDelete = (data) => {
        if (data?.name) {
            setSchoolTeamsHours({...schoolTeamsHours, team: {...schoolTeamsHours.team, categories: schoolTeamsHours.team.categories.filter(item => item?.name!==data?.name)}})
        } else {
            setSchoolTeamsHours({...schoolTeamsHours})
        }
    }

    const addButton = () => (
        <Button
            btnClass="button-white"
            btnText={msg({ id: 'button.add' })}
            btnType="button"
            onHandleClick={handleAddSchoolTeamsHoursData}
        />
    )

    const removeButton = (rowData) => (
        // TODO: Create component with better button + icon template.
        <span
            className="btn"
            role="button"
            tabIndex="0"
            onClick={() => handleDelete(rowData)}
        >
            <img className="img-item" style={{width: "18px", height: "18px"}} src={Delete} alt="delete" />
        </span>
    
    )

    const handleConfirm = async () => {
        await DistributionHoursService.confirmSchool(courseId, etapaId, {categories: schoolTeamsHours?.team?.categories})
        setFlagConfirm(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await DistributionHoursService.confirmSchool(courseId, etapaId, {categories: schoolTeamsHours?.team?.categories})
                : await DistributionHoursService.updateSchool(courseId, etapaId, {categories: schoolTeamsHours?.team?.categories})
            if (confirm) setFlagConfirm(true)
            if (resp) {
                navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION(centerId, courseId, etapaId))
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'hours.and.staff' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({ id: 'hours.and.staff' })} /> 
        },
        {
            label: schoolTeamsHours?.etapaName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, schoolTeamsHours?.etapa)} 
                text={schoolTeamsHours?.etapaName} /> 
        },
        {
            label: msg({id: 'distribution.hours'}),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION(centerId, courseId, etapaId)} 
                text={msg({id: 'distribution.hours'})} /> 
        },
        {
            label: msg({id: 'hours.teams.school'})
        },
    ]

    const handlePrevious = () => { navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION_FOUNDATION(centerId, courseId, etapaId)) }

    const handleNext = () => { navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_STAFF(centerId, courseId, etapaId)) }

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const footerOptions = {
        handlePrevious,
        handleNext: etapa?.lineDistribution?.confirmation?.isConfirm && handleNext
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: schoolTeamsHours?.team?.categories,
                editMode: 'cell'
            }}
            columns={[
                {
                    field: 'name', 
                    bodyClassName: 'roll-title', 
                    header: 'name',
                    ...editTextRowColumn
                },
                {
                    field: 'hours',
                    style: { width: "10%"},
                    className: "p-datatype-number",
                    headerClassName: "p-align-end",
                    bodyClassName: 'editRow',
                    header: "hours",
                    noTraslateHeader: true,
                    ...editNumberRowColumn({})
                },
                { header: addButton(), className: "none", body: (rowData) => removeButton(rowData), align: "center", noTraslateHeader: true, style: { width: "2%"} }
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 1, footerStyle: { textAlign: 'left' } },
                { footer: 'sum', field: 'hours', className: "p-datatype-number" },
                { footer: '' }
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
            footerOptions={footerOptions}
        />
    )
}

export default SchoolTeamsHours
