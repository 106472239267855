import React from 'react'
import { useSelector } from 'react-redux'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledCheckbox from '../../common/form-components/labeled-checkbox/LabeledCheckbox'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledDropdown from "../../common/form-components/labeled-dropdown/LabeledDropdown"
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import './formCenterTeam.scss'

const FormCenterTeam = (
    { 
        inputs,
        handleChange,
        handleSave
    }
) => {
    const agroupations = (useSelector(state => state?.centerTeamsAgroupations))?.resp

    const validateForm = validators.isEntityValues([inputs?.name, inputs?.association])

    return (
        <div className='form-center-team'> 
            <div className='modal-content-wrapper'> 
                <LabeledText 
                    idLabel='name'
                    required
                    name='name'
                    value={inputs?.name}
                    onChange={(e) => handleChange(e)}
                />
                <div className='control-wrapper'>
                    <LabeledDropdown 
                        idLabel='agroupation'
                        required
                        name='association'
                        value={inputs?.association?._id || inputs?.association}
                        optionValue='_id'
                        options={agroupations}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledCheckbox 
                        idLabel='center.teams.isManagerial'
                        name='isManagerial'
                        onChange={(e) => handleChange(e)}
                        checked={inputs?.isManagerial}
                        tooltip="info.isManagerial"
                    /> 
                </div>
                <LabeledTextArea 
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={(e) => handleChange(e)}
                    numRows={3}
                    numColumns={15}
                />
            </div>
            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={() => handleSave(inputs?._id)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormCenterTeam