import React from "react"
import { useSelector } from 'react-redux'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from 'lodash'
import { createNee, getAllNee, removeNee, updateNee } from "../../../../services/nee/nee.slice"
import useModal from "../../../../utils/hooks/useModal"
import Modal from "../../../../components/modal/Modal"
import FormNee from "../../../../components/maestros-center/nee/FormNee"
import useInputs from "../../../../utils/hooks/useInputs"
import MainCommonView from "../../../../components/common/MainCommonView"
import NEEService from "../../../../services/nee/nee.service"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import editTextRowColumn from "../../../../components/common/dataTable/editors/editTextRowColumn"
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../utils/helpers/data-table/column-types/rowEditor"

const Nee = () => {

    const { formatMessage: msg } = useIntl()

    const nees = useSelector(state => state?.nee)

    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs} = useInputs()

    const dataFacade = useHandlerBasicActions({
        isUseDispatch: true,
        getAll: getAllNee, 
        sortFieldInitial: 'name',
        createAction: createNee,
        removeAction: removeNee, 
        editAction: updateNee
    })

    const handleCreate = async () => {
        await dataFacade.create(inputs)
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({newData}) => {
        const body = _.omit(newData, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const { _id } = newData
        dataFacade.edit({_id, body})
    }

    const headerOptions = {
        title: msg({id: 'nee'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'nee',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: () => NEEService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: nees?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: nees?.resp,
                editMode: "row",
                onRowEditComplete, 
                ...dataFacade?.sortOptions
            }}
            columns={[
                { field: "name",
                    header: 'name',
                    sortable: true,
                    ...editTextRowColumn
                },
                { field: "description",
                    header: 'description', 
                    ...editTextRowColumn
                },
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ]}
        />
    )

    return (
        <div className='nee'>

            <MainCommonView 
                isUseDispatch
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id='nee.new'/>}
                context={(
                    <FormNee 
                        inputs={inputs} 
                        handleSubmit={handleCreate}
                        handleChange={onChangeInputs}
                    />
                )}
            />
        </div>
    )
}

export default Nee