import React, { useEffect, useState, useContext } from "react"
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { LayoutCenterContext } from "../../../../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../../../../utils/hooks/useLoading"
import BugetTable from '../../../../../../../components/common/dataTable/BugetTable'
import Dashboard from '../../../../../../../components/common-courses/view-content/Dashboard'
import BudgetService from '../../../../../../../services/centers/courses/budgets/budgets.service'
import BreadcrumbLink from '../../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../../routes/centers/center-course/pathname'
import editNumberRowColumn from '../../../../../../../components/common/dataTable/editors/editNumberRowColumn'

const FeesTable = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId, feesId, otherServiceId} = useParams()

    const [dataTable, setDataTable] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getDataTable()
    }, [])

    const getDataTable = async () => {
        startLoading()
        const resp = await BudgetService.getActivitiesDataServicesTable(courseId, feesId, otherServiceId)
        if (resp) {
            setDataTable(resp?.resp)
        }
        stopLoading()
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await BudgetService.confirmActivitiesDataServiceTable(courseId, feesId, otherServiceId, dataTable)
                : await BudgetService.updateActivitiesDataServicesTable(courseId, feesId, otherServiceId, dataTable)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setDataTable(resp?.resp)
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: dataTable?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES(centerId, courseId, dataTable?.balanceTemplate)} 
                text={dataTable?.templateName} /> 
        },
        {
            label: dataTable?.conceptName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES(centerId, courseId, balanceId, dataTable?.concept)} 
                text={dataTable?.conceptName} /> 
        },
        {
            label: dataTable?.otherServiceName
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleSave,
        handleConfirm
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: dataTable?.subservices,
            }}
            columns={[
                { field: 'subserviceName', header: 'fee'},
                { field: 'numStudents',
                    header: 'students',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: {width: "10%"}
                },
                { field: 'periodicity',
                    header: 'periodicity',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: {width: "10%"},
                    bodyClassName: 'editRow',
                    ...editNumberRowColumn({})
                },
                { field: 'income', 
                    header: 'amount',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                    style: {width: "10%"},
                    bodyClassName: 'editRow',
                    ...editNumberRowColumn({})
                },
                { field: 'totalIncome', header: 'total.import' },
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 3, footerStyle: { textAlign: 'left' } },
                { footer: 'sum',
                    field: 'income',
                    className: "p-datatype-number",
                },
                { footer: 'sum',
                    field: 'totalIncome',
                    className: "p-datatype-number"
                }
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default FeesTable