import React from "react"
import { maestrosCenters } from "./maestrosCenters.pathname"
// Maestros centro
import Etapas from '../../../views/maestros/maestros-center/etapas/Etapas'
import Etapa from '../../../views/maestros/maestros-center/etapas/Etapa'
import CenterTeams from '../../../views/maestros/maestros-center/center-teams/CenterTeams'
import CenterTeam from '../../../views/maestros/maestros-center/center-teams/CenterTeam'
import Nee from '../../../views/maestros/maestros-center/nee/Nee'
import Typologies from '../../../views/maestros/maestros-center/typologies/Typologies'
import SchoolServices from '../../../views/maestros/maestros-center/school-services/SchoolServices'
import SchoolService from '../../../views/maestros/maestros-center/school-services/SchoolService'
// Maestros contabilidad del centro
import MaestrosAccountingCenter from '../../../views/maestros/maestros-center/accounting/MaestrosAccountingCenter'
import MaestrosAccountingPlan from '../../../views/maestros/maestros-center/accounting/maestros-accounting-plan/MaestrosAccountingPlan'
import Subventions from '../../../views/maestros/maestros-center/accounting/maestros-subventions/subventions/Subventions'
import Subtypes from '../../../views/maestros/maestros-center/accounting/maestros-accounting-plan/subtypes/Subtypes'
import CenterAccountPlans from '../../../views/maestros/maestros-center/accounting/maestros-accounting-plan/center-account-plans/CenterAccountPlans'
import CenterAccountPlan from '../../../views/maestros/maestros-center/accounting/maestros-accounting-plan/center-account-plans/center-account-plan/CenterAccountPlan'
import CentersCostCenter from '../../../views/maestros/maestros-center/accounting/maestros-accounting-plan/center-cost-center/CentersCostCenter'
import MaestrosSubventions from "../../../views/maestros/maestros-center/accounting/maestros-subventions/MaestrosSubventions"
import SubsidyQuantification from "../../../views/maestros/maestros-center/accounting/maestros-subventions/subsidy-quantification/SubsidyQuantification"
import DistributionCriteria from "../../../views/maestros/maestros-center/accounting/maestros-accounting-plan/distribution-criteria/DistributionCriteria"
import FeeTypes from "../../../views/maestros/maestros-center/accounting/maestros-quotes/FeeTypes"
import Fees from "../../../views/maestros/maestros-center/accounting/maestros-quotes/Fees"

export const maestrosCentersRoutes = [
    /**
        * MAESTROS CENTROS
    */
    {
        path: maestrosCenters.TYPOLOGIE,
        name: 'tipologia',
        element: <Typologies />,
    },
    {
        path: maestrosCenters.SERVICES,
        name: 'services',
        element: <SchoolServices />,
    },
    {
        path: maestrosCenters.SERVICES + ':serviceId',
        name: 'service',
        element: <SchoolService />,
    },
    {
        path: maestrosCenters.ETAPAS,
        name: 'etapas',
        element: <Etapas />,
    },
    {
        path: maestrosCenters.ETAPA + ':etapaId',
        name: 'etapa',
        element: <Etapa />,
    },
    {
        path: maestrosCenters.CENTER_TEAMS,
        name: 'centerteams',
        element: <CenterTeams />,
    },
    {
        path: maestrosCenters.CENTER_TEAM(':centerTeamId'),
        name: 'centerteam',
        element: <CenterTeam />,
    },
    {
        path: maestrosCenters.NEE,
        name: 'nee',
        element: <Nee />,
    },
    {
        path: maestrosCenters.MAESTROS_ACCOUNTING_CENTER,
        name: 'accountingCenter',
        element: <MaestrosAccountingCenter />,
    },
    {
        path: maestrosCenters.MAESTROS_ACCOUNTING_CENTER,
        name: 'accountingCenter',
        element: <MaestrosAccountingCenter />,
    },
    // MAESTROS DE CONTABILIDAD DEL CENTRO
    {
        path: maestrosCenters.MAESTROS_ACCOUNTING_PLAN,
        name: 'accountingplan',
        element: <MaestrosAccountingPlan/>,
    },
    {
        path: maestrosCenters.MAESTROS_SUBVENTIONS,
        name: 'subventions',
        element: < MaestrosSubventions/>,
    },
    {
        path: maestrosCenters.MAESTROS_FEES,
        name: 'fees',
        element: < FeeTypes/>,
    },
    {
        path: maestrosCenters.MAESTROS_FEES_ID(':etapaId'),
        name: 'fees.types',
        element: < Fees/>,
    },
    // maestros plan center
    {
        path: maestrosCenters.ACCOUNTING_SCHOOL_COST_CENTER,
        name: 'schoolCostCenter',
        element: <CentersCostCenter />,
    },
    {
        path: maestrosCenters.ACCOUNTING_PLAN_CENTER,
        name: 'accountingplancentre',
        element: <CenterAccountPlans />,
    },
    {
        path: maestrosCenters.ACCOUNTING_PLAN_CENTER_BY_ID(':id'),
        name: 'accountingplancentre',
        element: <CenterAccountPlan />,
    },
    {
        path: maestrosCenters.ACCOUNTING_SUBTYPES,
        name: 'subtypes',
        element: <Subtypes />,
    },
    {
        path: maestrosCenters.ACCOUNTING_DISTRIBUTION_CRITERIA,
        name: 'distributioncriteria',
        element: <DistributionCriteria />,
    },

    // Maestros subventions
    {
        path: maestrosCenters.SUBSIDY_QUANTIFICATION,
        name: 'subventions',
        element: <SubsidyQuantification />,
    },
    {
        path: maestrosCenters.SUBVENTIONS,
        name: 'subventions',
        element: <Subventions />,
    }
]