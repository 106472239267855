import React from 'react'
import { ControlLabel } from '../label/Label'
import EditorComp from '../inputs/EditorComp'

const LabeledEditor = ({ idLabel, required, name, value, onChange, ...rest }) => {
    return (
        <div className='flex-container'>
            <ControlLabel id={idLabel} required={required}/>
            <div className='control-input'>
                <EditorComp 
                    name={name}
                    value={value}
                    onChange={onChange}
                    {...rest}
                />
            </div>
        </div>
    )

}

export default LabeledEditor