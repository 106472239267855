import React, { useState } from "react"
import { useIntl } from "react-intl"
import Modal from "../../components/modal/Modal"
import useModal from "./useModal"
import PdfView from "../../components/pdf-view/PdfView"

// TO DO: BORRAR ESTO 

const usePreviewPdf = () => {
    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()

    const [ previewUrl, setPreviewUrl ] = useState('')
    const [ previewTitle, setPreviewTitle ] = useState('')

    const onclickPreviewPdf = ({ name, env, path }) => {
        const pathComplete = env ? env + path : path
        setPreviewTitle(name)
        setPreviewUrl(pathComplete)
        toggle()
    }

    const modalPdfView = (
        <Modal 
            isShowing={isShowing}
            hide={toggle}
            width="90%"
            maxWidth="1000px"
            title={`${msg({ id: 'preview'})} - ${previewTitle}`}
            context={<PdfView url={previewUrl} />}
        />      
    )
       
    return {
        onclickPreviewPdf, 
        modalPdfView
    }

}

export default usePreviewPdf