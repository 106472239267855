import React, { useState } from 'react'
import { validators } from '../../utils/helpers/validators'
import SaveButton from '../common/buttons/SaveButton'
import LabeledFile from '../common/form-components/labeled-file/LabeledFile'
import './importFileModal.scss'

const ImportFileModal = ({ 
    handleSubmit,
    typefile 
}) => {
    const [file, setFile] = useState()

    const validateForm = validators.isEntityValues([file])

    const handleFileChange = (e) => {
        if (e.files) {
            setFile(e.files)
        }
    }

    return (
        <div className="import-file-modal">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledFile 
                        idLabel='file'
                        required
                        name='file'
                        accept={typefile}
                        chooseLabel='upload'
                        onSelect={handleFileChange}
                    />
                </div>
            </div>
            <div className="modal-btn-container">
                <SaveButton
                    btnText="upload"
                    onClick={() => handleSubmit(file)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default ImportFileModal
