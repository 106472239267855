import { endpointsSalaryTables } from './endpoints'
import { POST, GET, DELETE } from '../common/commonRequest.service'

const SalaryTablesService = {
    upload: async (schoolYearId, agreementId, body) => POST(endpointsSalaryTables.GENERIC(schoolYearId, agreementId), body),
    getAll: async ({ agreementId, filter, limit, skip, sort }) => GET(endpointsSalaryTables.LOAD({ agreementId, filter, limit, skip, sort})),
    getById: async (id) => GET(endpointsSalaryTables.BY_ID(id)),
    remove: async (id) => DELETE(endpointsSalaryTables.BY_ID(id)),
}

export default SalaryTablesService
