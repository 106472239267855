import React, { useEffect, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { LayoutCenterContext } from "../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../utils/hooks/useLoading"
import Dashboard from "../../../../components/common-courses/view-content/Dashboard"
import DataTableCenter from "../../../../components/common/dataTable/DataTableCenter"
import DistributionHoursService from "../../../../services/centers/courses/distribution-hours/ditributionHours.service"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import BreadcrumbLink from "../../../../components/common/breadcrumb/BreadcrumbLink"
import editNumberRowColumn from "../../../../components/common/dataTable/editors/editNumberRowColumn"

const CalculHours = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, etapaId } = useParams({})

    const navigate = useNavigate()

    const [tableData, setTableData] = useState([])
    const [calculHores, setCalculHores] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getLineDistribution()
    }, [])

    const getLineDistribution = async () => {
        startLoading()
        const resp = await DistributionHoursService.getLine(courseId, etapaId)
        if (resp) {
            setCalculHores(resp?.resp)
            setTableData(transformData(resp?.resp))
        }
        stopLoading()
    }

    const transformData = (data) => {
        const isConcerted = data?.templateEtapa && data?.templateEtapa?.activatedConcerted
        
        const dataTable = [
            { name: msg({ id: isConcerted ? 'line.distribution.number.groups' : 'line.distribution.number' }), hours: data?.lineDistribution?.numLines, isTitle: true, editable: false },
            { name: msg({ id: isConcerted ? "line.distribution.concerted.units" : "line.distribution.number.units" }), hours: data?.lineDistribution?.udConcerted, isTitle: true, editable: false },
            { name: msg({ id: 'line.distribution.additional.hours' }), hours: data?.lineDistribution?.additionalHours, editable: true },
            isConcerted && { name: msg({ id: 'line.distribution.concerted.other.endowments' }), isTitle: true, hours: data?.lineDistribution?.otherEndowment },

            isConcerted && { name: msg({ id: 'line.distribution.concerted.payment.hours' }), isTitle: true },
            isConcerted && { name: msg({ id: 'line.distribution.concerted.teaching.hours' }), hours: data?.lineDistribution?.delegatedPayment?.teachingHours, editable: false },
            isConcerted && { name: msg({ id: 'line.distribution.concerted.non.teaching.hours' }), hours: data?.lineDistribution?.delegatedPayment?.nonTeachingHours, editable: false },
            isConcerted && { name: msg({ id: 'line.distribution.concerted.total.hours' }), hours: data?.lineDistribution?.delegatedPayment?.totalHours, isTitle: true, editable: false },

            { name: msg({ id: 'line.distribution.school.hours' }), isTitle: true },
            { name: msg({ id: isConcerted ? 'line.distribution.concerted.teaching.complementary.hours' : 'line.distribution.teaching.hours' }), hours: data?.lineDistribution?.schoolPayment?.teachingHours, editable: false },
            { name: msg({ id: isConcerted ? 'line.distribution.concerted.non.teaching.complementary.hours' : 'line.distribution.non.teaching.hours' }), hours: data?.lineDistribution?.schoolPayment?.nonTeachingHours, editable: false },
            { name: msg({id: 'line.distribution.total.delegated.payment.hours'}), hours: data?.lineDistribution?.schoolPayment?.totalHours, isTitle: true, editable: false },

            { name: msg({id: 'line.distribution.total'}), isTitle: true },

            { name: msg({id: 'line.distribution.total.available.hours'}), hours: data?.lineDistribution?.totalAvailableHours, isTitle: true, editable: false },
            { name: msg({id: 'line.distribution.classroom.hours'}), hours: data?.lineDistribution?.classRoomHours, isTitle: true, editable: false },
            { name: msg({id: 'line.distribution.excess.hours.to.distribute'}), hours: data?.lineDistribution?.hoursToDistribute, isTitle: true, editable: false }
        ].filter(Boolean)

        return dataTable
    }

    const toParams = (data) => {

        const patchedData = {
            numLines: data[0]?.hours || 0,
            udConcerted: data[1]?.hours || 0,
            additionalHours: data[2]?.hours || 0,
            otherEndowment: data[3]?.hours || 0,

            delegatedPayment: {
                teachingHours: data[5]?.hours || 0,
                nonTeachingHours: data[6]?.hours || 0,
                totalHours: (data[5]?.hours + data[6]?.hours) || 0,
            },

            schoolPayment: {
                teachingHours: data[9]?.hours,
                nonTeachingHours: data[10]?.hours,
                totalHours: (data[9]?.hours + data[10]?.hours) || 0
            },

            totalAvailableHours: data[13]?.hours || 0,
            classRoomHours: data[14]?.hours || 0,
            hoursToDistribute: data[15]?.hours || 0,
        }
        return patchedData
    }
    
    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const newData = toParams(tableData)
            const resp = confirm===true
                ? await DistributionHoursService.confirmLine(courseId, etapaId, newData)
                : await DistributionHoursService.updateLine(courseId, etapaId, newData)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                // If we will implement save on blur then
                // setLineDistribution(resp?.resp)
                // setTableData(transformData(resp?.resp))

                navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, etapaId))
            }
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'hours.and.staff' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({ id: 'hours.and.staff' })} /> 
        },
        {
            label: calculHores?.etapaName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, calculHores?.etapa)} 
                text={calculHores?.etapaName} /> 
        },
        {
            label: msg({id: 'calcul.hours'})
        }
    ]

    const handlePrevious = () => { navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID(centerId, courseId, etapaId)) }

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const footerOptions = {
        handlePrevious
    }

    const content = (
        <DataTableCenter
            loading={loading}
            tableOptions={{
                value: tableData,
                editMode: 'cell',
            }}
            columns={[
                {
                    field: 'name',
                    bodyClassName: 'roll-title',
                    header: msg({ id: 'name' }),
                    style: { width: "90%" }
                },
                {
                    field: 'hours',
                    className: "p-datatype-number",
                    bodyClassName: (rowData) => (rowData.editable ? 'editRow' : 'roll-title'),
                    header: msg({ id: 'hours' }),
                    style: { width: "10%" },
                    ...editNumberRowColumn({ propsInput: { minFractionDigits: 0, maxFractionDigits: 6 } }),
                    onBeforeCellEditShow: (options) => !!options?.rowData?.editable
                }
            ]}
        />
    )

    return (
        <Dashboard
            content={content}
            headerOptions={headerOptions}
            footerOptions={footerOptions}
        />
    )
}

export default CalculHours
