import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import MainCommonView from "../../../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../../../components/common/dataTable/MaestrosDataTable'
import CenterCostCenterForm from '../../../../../../components/maestros-center/center-cost-center/CenterCostCenterForm'
import Modal from '../../../../../../components/modal/Modal'
import CentersCostCenterService from '../../../../../../services/center-accounting/center-cost-center/centersCostCenter.service'
import { create, getAll, remove, update } from '../../../../../../services/center-accounting/center-cost-center/centersCostCenter.slice'
import editDropdownRowColumn from '../../../../../../components/common/dataTable/editors/editDropDownRowColumn'
import editTextRowColumn from '../../../../../../utils/helpers/data-table/column-types/editTextRowColumn'
import rowDelete from '../../../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from "../../../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../../../utils/hooks/useInputs'
import useModal from '../../../../../../utils/hooks/useModal'
import { COST_CENTER_GROUPING } from '../../../../../../utils/constants/grouping'

const CentersCostCenter = () => {

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const centersCostCenter = useSelector((state) => state?.centersCostCenter)

    const dataFacade = useHandlerBasicActions({
        isUseDispatch: true,
        sortFieldInitial: 'name',
        getAll, 
        createAction: create,
        removeAction: remove, 
        editAction: update
    })

    const handleCreate = async () => {
        const body = {
            ...inputs,
            typeGrouping: inputs?.typeGrouping?.value
        }
        await dataFacade.create(body)
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({ newData }) => {
        const body = {
            name: newData?.name,
            description: newData?.description,
            typeGrouping: newData?.typeGrouping
        }
        const { _id } = newData
        dataFacade.edit({_id, body})
    }

    const headerOptions = {
        title: msg({id: 'school.cost.center'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const exportService = async () => {
        let resp = (await CentersCostCenterService.getAll({}))?.resp

        resp = resp.map(r => { r.typeGrouping = msg({id: r.typeGrouping}); return r })

        return { resp }
    }

    const importExportFilesOptions = {
        title: 'school.cost.center',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'grouped.by'}), dataKey: 'typeGrouping' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: centersCostCenter?.total
    }

    const dataTable = (

        <MaestrosDataTable 
            tableOptions={{
                value: centersCostCenter?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={[
                { field: "name",
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                { field: 'typeGrouping',
                    body: (rowData) => rowData?.typeGrouping ? msg({id: rowData?.typeGrouping}) : '',
                    header: 'grouped.by',
                    ...editDropdownRowColumn({
                        options: COST_CENTER_GROUPING,
                        name: 'typeGrouping', 
                        optionLabel: 'name',
                        optionValue: 'value'
                    })
                },
                { field: "description",
                    header: 'description',
                    ...editTextRowColumn({sortable: false})
                },
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ]}
        />
    )

    return (
        <div className="centers-cost-center">
            <MainCommonView 
                isUseDispatch
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="500px"
                title={<FormattedMessage id="center.cost.center.new" />}
                context={(
                    <CenterCostCenterForm 
                        inputs={inputs}
                        handleSave={handleCreate}
                        handleChange={onChangeInputs}
                    />
                )}   
            />
        </div>
    )
}
export default CentersCostCenter
