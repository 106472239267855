import React, { useEffect, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { LayoutCenterContext } from "../../../../components/common-courses/context/LayoutCenterContex"
import Dashboard from "../../../../components/common-courses/view-content/Dashboard"
import DataTableCenter from "../../../../components/common/dataTable/DataTableCenter"
import EducationOfferService from "../../../../services/centers/courses/education-offer-and-service/educationOffer.service"
import editNumberRowColumn from "../../../../components/common/dataTable/editors/editNumberRowColumn"
import BreadcrumbLink from "../../../../components/common/breadcrumb/BreadcrumbLink"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import useLoading from "../../../../utils/hooks/useLoading"

const EducationServices = () => {

    const { centerId, courseId, educationOfferId} = useParams()
    const { loading, startLoading, stopLoading } = useLoading(true)
    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()

    const [service, setService] = useState({})

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getService()
    }, [])

    const getService = async () => {
        startLoading()
        const resp = await EducationOfferService.getOtherServices(courseId, educationOfferId)
        if (resp) {
            setService(resp?.resp?.otherService)
        }
        stopLoading()
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const resp = confirm===true
                ? await EducationOfferService.confirmOtherService(courseId, service?.service, service)
                : await EducationOfferService.updateService(courseId, service?.service, service)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setService(resp?.resp)
            }
            navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId))
        }, 0)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'educative.offer.and.services' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId)} 
                text={msg({ id: 'educative.offer.and.services' })} /> 
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const handlePrevious = () => {
        navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId))
    }

    const table = (
        <DataTableCenter
            tableOptions={{
                loading,
                value: service?.subservices,
                editMode: 'cell',
            }}
            columns={[
                {field: 'name', bodyClassName: 'roll-title', header: msg({id: 'name'})},
                {   
                    field: 'numStudents',
                    style: { width: "20%"},
                    headerClassName: "p-align-end",
                    className: 'p-datatype-number',
                    bodyClassName: 'editRow', 
                    header: msg({id: 'students.number'}),
                    ...editNumberRowColumn({})
                },
            ]}
        />
    )

    return (
        <Dashboard
            breadcrumb={breadcrumb}
            content={table}
            headerOptions={headerOptions}
            footerOptions={{handlePrevious}}
        />
    )
}

export default EducationServices