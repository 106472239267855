// import API from '../commonAPI'
import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsFoundation } from "./endpoints"

export const FoundationService = {
    create: async (body) => POST(endpointsFoundation.GENERIC, body),
    get: async () => GET(endpointsFoundation.GENERIC),
    update: async (body) => PATCH(endpointsFoundation.GENERIC, body),
    remove: async (id) => DELETE(endpointsFoundation.GENERIC, id),
    getFoundationLogo: async () => GET(endpointsFoundation.LOGO),
    updateFoundationLogo: async (body) => PATCH(endpointsFoundation.LOGO, body),
}
