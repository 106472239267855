import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl} from 'react-intl'
import PasswordCom from '../../components/common/form-components/password/PasswordComp'
import { pathname } from '../../routes/pathname'
import logo from "../../assets/imgs/logos/logo.svg"
import { validators } from '../../utils/helpers/validators'
import TokenService from '../../services/common/token.service'
import { persistor } from '../../store'
import Button from '../../components/common/buttons'
import AuthService from '../../services/auth/auth.service'
import './updatePassword.scss'

const UpdatePassword = () => {

    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()
    
    const [input, setInput] = useState({
        password: "",
        confirmPassword: ""
    })

    useEffect(() => {
        cleanAndSetNewJWTCache()
    }, [])

    const cleanAndSetNewJWTCache = async () => {
        persistor.purge()
        const queryParams = new URLSearchParams(window.location.search)
        const token = await queryParams.get("token")
        await TokenService.setJWT({accessToken: token})
    }

    const handleChange = ({target: {value, name}}) => { 
        setInput({...input, [name]: value}) 
    }

    const handleSubmit = async (event) => { 
        event.preventDefault()
        const body = {
            password: input?.password
        }
        const resp = await AuthService.resetPassword(body, msg({id: "swal.password-changed"}))
        if (resp) {
            await TokenService.removesetJWT()
            persistor.purge()
            navigate(pathname.LOGIN)
        }
    }

    const validatePasswordFormat = validators.passwordFormat(input?.password)
    const validateConfirmPassword = validators.equals(input?.password, input?.confirmPassword)

    return (
        <div className='change-password'>
            <form className='change-password-form'>
                <div className='change-password-box'>
                    <Link to={pathname.LOGIN} className="logo_link"> 
                        <img className='width__100' src={logo} alt='logo'/> 
                    </Link>
                    <div className="box-password-form">
                        <h3 className="title-form">
                            <FormattedMessage id="password.update" />
                        </h3>

                        <PasswordCom 
                            name="password"
                            value={input?.password} 
                            onChange={handleChange}
                            placeholder="password" 
                            feedback
                        />

                        <PasswordCom
                            name="confirmPassword"
                            value={input?.confirmPassword} 
                            onChange={handleChange} 
                            placeholder="password.confirm" 
                        />

                        <Button
                            id="submitReset"
                            btnText={<FormattedMessage id="button.send"/>}
                            btnType='submit'
                            btnClass="btn__with-bg-type-1"
                            isDisabled={!(validatePasswordFormat && validateConfirmPassword)}
                            onHandleClick={(e) => handleSubmit(e)}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
export default UpdatePassword