import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import MainCommonView from "../../../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../../../components/common/dataTable/MaestrosDataTable'
import DistributionCriteriaForm from '../../../../../../components/maestros-center/distribution-criteria/DistributionCriteriaForm'
import Modal from '../../../../../../components/modal/Modal'
import DistributionCriteriaService from '../../../../../../services/center-accounting/distribution-criteria/distributionCriteria.service'
import auxFunction from '../../../../../../utils/helpers/auxFun'
import editTextRowColumn from '../../../../../../components/common/dataTable/editors/editTextRowColumn'
import rowDelete from '../../../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from "../../../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../../../utils/hooks/useInputs'
import useModal from '../../../../../../utils/hooks/useModal'

const DistributionCriteria = () => {

    const inputsEmptyState = auxFunction.setEmptyState(['name', 'description' ]) 

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs(inputsEmptyState)

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: DistributionCriteriaService.getAll,
        createAction: DistributionCriteriaService.create,
        removeAction: DistributionCriteriaService.remove, 
        editAction: DistributionCriteriaService.update
    })

    const handleCreate = async () => {
        await dataFacade.create(inputs)
        updateInputs(inputsEmptyState)
        toggle()
    }

    const toggleModal = () => {
        toggle()
        updateInputs(inputsEmptyState)
    }

    const onRowEditComplete = async ({newData}) => {
        const body = _.omit(newData, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const { _id } = newData
        dataFacade.edit({_id, body})
    }

    const headerOptions = {
        title: msg({id: 'distribution.criteria'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'distribution.criteria',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: () => DistributionCriteriaService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={([
                { field: "name",
                    sortable: true,
                    header: 'name', 
                    ...editTextRowColumn
                },
                { field: "description",
                    header: 'description',
                    ...editTextRowColumn
                },
                { field: "function", header: 'function' },
                {...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
            ])}
        />
    )

    return (
        <div className='distribution-criteria'>
            <MainCommonView 
                isUseDispatch
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={() => {
                    toggleModal()
                    updateInputs({})
                }}
                width="90%"
                maxWidth="500px"
                title={msg({id: "distribution.criteria.new"})}
                context={(
                    <DistributionCriteriaForm 
                        inputs={inputs} 
                        handleSave={handleCreate} 
                        handleChange={onChangeInputs} 
                    />
                )}
            />

        </div>
    )
}

export default DistributionCriteria
