import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAll } from '../../../services/territorial-services/territorialServices.slice'
import { validators } from '../../../utils/helpers/validators'
import CentersService from '../../../services/centers/centers.service'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import './formEditCenter.scss'

const CenterForm = ({ 
    centerId,
    // inputs,
    updateInputs,
    handleChange, 
    handleSave
}) => {
    
    const dispatch = useDispatch()
    const [ center, setCenter ] = useState()
    const [ inputs, setInputs ] = useState()
    //     const [ patchedInputs, setPatchedInputs ] = useState(inputs)

    const territorialServices = useSelector(state => state.territorialServices)

    const getAllTerritorialsServices = () => dispatch(getAll({}))

    const handleChangeCustom = (e) => {
        console.log(e)
        handleChange("63d26fdc75271d8984dbdaa4")
        // const selectedTerritorialService = territorialServices?.resp?.filter(srv => srv._id === e?.target?.value)[0]
        // console.log(selectedTerritorialService)
        // const newE = {...e, target: { _id: selectedTerritorialService._id, name: selectedTerritorialService.name } }
        // updateInputs({...inputs, territorialService: { _id: selectedTerritorialService._id, name: selectedTerritorialService.name } })
        //handleChange(e)
        //handleChange({...e, target: { value: "63d26fdc75271d8984dbda99"}})
    }

    const handleSaveCustom = (e) => {
        getAllTerritorialsServices()
        const selectedTerritorialService = territorialServices?.resp?.filter(srv => srv._id === inputs.territorialService)[0]
        console.log(e)
        // handleSave({...e, originalEvent: { target: {_id: selectedTerritorialService._id, name: selectedTerritorialService.name}}})
        handleSave(e)
    }

    useEffect(() => {
        getCenterData()
        // console.log(inputs)
        // getAllTerritorialsServices()
        // getCenterData()
        // const selectedTerritorialService = territorialServices?.resp?.filter(srv => srv._id === inputs.territorialService)[0]
        // // console.log('CenterForm', 'useEffect', inputs.territorialService, selectedTerritorialService)
        // // console.log('CenterForm', 'useEffect', selectedTerritorialService)
        // // if (inputs?.territorialService) inputs.territorialService = {_id: inputs.territorialService, name: selectedTerritorialService.name}
    }, [])

    useEffect(() => {
        console.log(center)
        setInputs(center)
    }, [center])

    const getCenterData = async () => {
        console.log(centerId)
        const centerData = (await CentersService.getById(centerId))?.resp
        centerData.territorialService = centerData?.territorialService?._id
        setCenter(centerData)
    }

    const validateForm = validators.isEntityValues([inputs?.reference,
        inputs?.name,
        inputs?.concertedCenterCode,
        inputs?.address,
        inputs?.population,
        inputs?.province,
        inputs?.telf, inputs?.email, inputs?.web]) 
    
    return (
        <div className='form-center'>
            <div className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='reference'
                        required
                        name='reference'
                        value={inputs?.reference}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='reference.concerted'
                        required
                        name='concertedCenterCode'      
                        value={inputs?.concertedCenterCode}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledDropdown
                        idLabel='territorial.service'
                        required
                        name='territorialService'
                        value={inputs?.territorialService}
                        optionLabel='name'
                        optionValue='_id'
                        options={territorialServices?.resp}
                        onChange={(e) => handleChange(e)}
                        filter
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='address'
                        required
                        name='address'
                        value={inputs?.address}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledText 
                        idLabel='population'
                        required
                        name='population'
                        value={inputs?.population}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='province'
                        required
                        name='province'
                        value={inputs?.province}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledText 
                        idLabel='telf'
                        required
                        name='telf'
                        value={inputs?.telf}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='email'
                        required
                        name='email'
                        value={inputs?.email}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledText 
                        idLabel='WEB'
                        required
                        name='web'
                        value={inputs?.web}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className='modal-btn-container '>
                <SaveButton 
                    onClick={() => handleSaveCustom(inputs?._id)}
                    btnClass="btn-save-modal"
                    isDisabled={!validateForm}
                />
            </div>
        </div>
    )
}

export default CenterForm