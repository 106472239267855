import { POST, GET, PATCH, DELETE } from "../common/commonRequest.service"
import { endpointsSubvention } from "./endpoints"

const SubventionService = {
    create: async (body) => POST(endpointsSubvention.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsSubvention.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsSubvention.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsSubvention.BY_ID(id))
}

export default SubventionService