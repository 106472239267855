import React from 'react'
import { maestros } from './maestros.pathname'
import SchoolCourses from '../../views/maestros/school-courses/SchoolCourses'
import MaestrosCenter from '../../views/maestros/maestros-center/MaestrosCenter'
import MaestrosFoundation from '../../views/maestros/maestros-foundation/MaestrosFoundation'
import MaestrosEcoFin from '../../views/maestros/maestros-ecofin/MaestrosEcoFin'
import MaestrosLabor from '../../views/maestros/maestros-labor/MaestrosLabor'
import MaestrosTemplates from '../../views/maestros/template-distribution-hour-school/MaestrosTemplates'
import SearchMasters from '../../views/maestros/maestros-search/SearchMasters'
import MaestrosTemplateImport from '../../views/maestros/maestros-template-import/MaestroTemplateImport'

export const maestrosRoutes = [
    {
        path: maestros.MASTERS_SEARCH,
        name: 'search masters',
        element: <SearchMasters />,
    },
    {
        path: maestros.SCHOOLYEAR,
        name: 'schoolyear',
        element: <SchoolCourses />,
    },
    {
        path: maestros.MAESTROS_CENTER,
        name: 'maestros center',
        element: <MaestrosCenter/>,
    },
    {
        path: maestros.MAESTROS_FOUNDATION,
        name: 'maestros foundation',
        element: <MaestrosFoundation />,
    },
    {
        path: maestros.MAESTROS_ECOFIN,
        name: 'maestros ecofin',
        element: <MaestrosEcoFin />,
    },
    {
        path: maestros.MAESTROS_LABOR,
        name: 'maestros labor',
        element: <MaestrosLabor />,
    },
    {
        path: maestros.MAESTROS_TEMPLATES,
        name: 'maestros templates',
        element: <MaestrosTemplates />,
    },
    {
        path: maestros.MAESTROS_TEMPLATES_IMPORT,
        name: 'maestros templates import',
        element: <MaestrosTemplateImport />,
    },
]