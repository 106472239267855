import React, { useContext } from "react"
import { LayoutCenterContext } from "../../../components/common-courses/context/LayoutCenterContex"
import CenterData from "../../../components/centers/center-data/CenterData"

const CenterCourseSummary = () => {

    const { center } = useContext(LayoutCenterContext) || []
    
    return (
        <CenterData center={center}/>
    )
}

export default CenterCourseSummary