import React from 'react'
import InputNumberComp from './InputNumberComp'
import "./inputComp.scss"

const InputDoubleComp = ({
    name,
    value, 
    onChange,
    useGrouping=false,
    maxFractionDigits=2,
    ...propsInput
}) => (
    <InputNumberComp 
        name={name}
        value={value}
        onChange={onChange}
        useGrouping={useGrouping}
        minFractionDigits={0}
        maxFractionDigits={maxFractionDigits}
        {...propsInput}
    />
)

export default InputDoubleComp