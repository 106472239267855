import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import EducationOfferService from "../../../../services/centers/courses/education-offer-and-service/educationOffer.service"
import useLoading from "../../../../utils/hooks/useLoading"

const EducationOfferAndServices = () => {
    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()

    const { centerId, courseId } = useParams()
    const { loading, startLoading, stopLoading } = useLoading(true)

    const [centerEducativeOffer, setCenterEducativeOffer] = useState({})

    useEffect(() => {
        getEducationOffer()
    }, [])

    const getEducationOffer = async () => {
        startLoading()
        const resp = await EducationOfferService.getById(courseId)
        if (resp) {
            setCenterEducativeOffer(resp?.resp)
        }
        stopLoading()
    }

    const goToEtapa = (educationOfferId, item) => {
        if (item?.service) return CENTER_COURSE_DETAILS.EDUCATION_SERVICE_BY_ID(centerId, courseId, item?.service)
        return CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, educationOfferId)
    }

    // TODO: crear contenido resumen
    const contentSummary = (
        <h1>Aqui va el resumen</h1>
    )

    const paramsGenerateExcel = {
        fileName: msg({ id: 'educative.offer.and.services'}),
        cols: [
            { title: msg({ id: 'name' }), dataKey: 'name' }
        ],
        resp: async () => {
            const resp = await EducationOfferService.getSummary(courseId)
            return resp?.resp?.associations
        }
    }

    const offerAndServices = [
        ...(centerEducativeOffer?.etapas || [])?.map(item => ({
            ...item,
            _id: item?.etapa
        })),
        ...(centerEducativeOffer?.otherServices || [])?.map(item => ({
            ...item,
            _id: item?.service
        }))
    ]

    const handlePrevious = () => navigate(CENTER_COURSE_DETAILS.STATISTICS(centerId, courseId))

    const handleNext = () => navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId))

    const cardsInfo = {
        list: offerAndServices,
        gotToLink: goToEtapa
    }

    return (
        <CardsOverview
            title='educative.offer.and.services'
            cardsInfo={cardsInfo}
            contentTableSummary={contentSummary}
            paramsGenerateExcel={paramsGenerateExcel}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            loading={loading}
        />
    )
}

export default EducationOfferAndServices