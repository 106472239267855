import React, { useEffect, useRef, useState } from 'react'
import { validators } from '../../../utils/helpers/validators'
import useInputs from '../../../utils/hooks/useInputs'
import AddFileButton from '../../common/buttons/AddFileButton'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextarea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import { SubTitle } from '../../common/form-components/label/Label'
import CollectiveAgreementsAddFileAndData from './CollectiveAgreementsAddFileAndData'
import CollectiveAgreementsFilesTable from './CollectiveAgreementsFilesTable'
import auxFunction from '../../../utils/helpers/auxFun'
import "./collective-agreement-form.scss"

// TODO : refactor this component
const CollectiveAgreementForm = ({
    inputs,
    handleChangeInputs,
    handleSaveAgreement,
    handleAddFile,
    handleEditFile,
    handleRemoveFile
}) => {

    const [ filesData, setFilesData ] = useState([])

    const { inputs: inputsFile, onChangeInputs: onChangeInputsFile, updateInputs: updateInputsFiles } = useInputs({})
    const fileRef = useRef()

    useEffect(() => { setFilesData(inputs?.files || []) }, [])

    const addFileDataToTable = async () => {
        const fileDataAux = {...inputsFile, 
            year: auxFunction.getFullYear(inputsFile.year)}

        if (inputs?._id) {
            const resp = await handleAddFile(fileDataAux)
            if (resp) setFilesData(resp?.resp?.files)
            return
        }
        
        setFilesData([...filesData, fileDataAux])
        updateInputsFiles({})
        fileRef?.current?.clear()
    }

    const editFileDataOfTable = async ({ newData, index }) => {
        if (inputs?._id) {
            const resp = await handleEditFile(newData)
            if (resp) setFilesData(resp?.resp?.files)
            return
        }
        const items = [...filesData]
        items[index] = newData
        setFilesData(items)
    }

    const removeFileDataFromTable = async (newData) => {
        if (inputs?._id) {
            const resp = await handleRemoveFile({
                _id: inputs?._id,
                fileId: newData
            })
            if (resp) setFilesData(resp?.resp?.files)
        }
        setFilesData(filesData?.filter((d) => d !== newData))
    }

    const validateForm = validators.isEntityValues([inputs?.name])

    const validateFormFile = validators.isEntityValues([inputsFile?.year, inputsFile?.files]) 

    return (
        <div className="collective-agreement-form">
            <div className="modal-content-wrapper">

                <SubTitle id='general.data'/>
                <div className="control-wrapper">
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChangeInputs(e)}
                    />
                    <LabeledTextarea 
                        idLabel='description'
                        name='description'
                        value={inputs?.description}
                        onChange={(e) => handleChangeInputs(e)}
                        numRows={1}
                    />
                </div>
                <div className='content-label'>
                    <div className="files-data"> 
                        <SubTitle id='file.data' />
                        <CollectiveAgreementsAddFileAndData 
                            inputs={inputsFile}
                            onChangeInputs={onChangeInputsFile}
                            fileRef={fileRef}
                        />
                    </div>
                    <div className='collective-table'>
                        <CollectiveAgreementsFilesTable 
                            data={filesData}
                            removeItem={removeFileDataFromTable}
                            editItem={editFileDataOfTable}
                        />
                    </div>
                </div>
            </div>

            <div className="modal-btn-container actions-wrapper">
                <AddFileButton 
                    onClick={() => addFileDataToTable()}
                    btnAdd='button.add.agreement'
                    isDisabled={validateFormFile}
                    btnClass="button-white"   
                />
                <SaveButton 
                    onClick={() => handleSaveAgreement(filesData)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default CollectiveAgreementForm
