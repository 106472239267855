import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import CommonHeader from "../../../../components/common/commonHeader/CommonHeader"
import MaestrosDataTable from '../../../../components/common/dataTable/MaestrosDataTable'
import ImportFileModal from '../../../../components/import-files/ImportFileModal'
import ExportAccountTypeForm from '../../../../components/maestros-ecofin/accounts/ExportAccountTypeForm'
import FormAccount from '../../../../components/maestros-ecofin/accounts/FormAccount'
import HeaderImportExportAccountFiles from "../../../../components/maestros-ecofin/accounts/HeaderImportExportAccountFiles"
import Modal from '../../../../components/modal/Modal'
import PaginatorComp from "../../../../components/paginator/PaginatorComp"
import { maestrosEcoFin } from '../../../../routes/maestros/maestros-ecofin/maestrosEcoFin.pathname'
import { AccountsService } from '../../../../services/accounts/accounts.service'
import editTextRowColumn from '../../../../utils/helpers/data-table/column-types/editTextRowColumn'
import rowDelete from '../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../utils/helpers/data-table/column-types/rowEditor'
import { uploadFileWithLoading } from '../../../../utils/helpers/uploadFiles'
import useExport from "../../../../utils/hooks/useExport"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'
import rowSeeWithTooltip from '../../../../components/common/dataTable/body/rowSeeWithTooltip'

// TODO: refactorizar todo lo que tenga que ver con la subida de archivo
const FILE_TYPE_PDF = 'pdf'
const FILE_TYPE_XLS = 'xls'
const ACCOUNTS_LIST = 'accounts.list'
const ACCOUNT = 'account'

const Accounts = () => {

    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()
    
    const { isShowing, toggle } = useModal()
    const { isShowing: isShowingImport, toggle: toggleImport } = useModal()
    const { isShowing: isExportShowing, toggle: toggleExport } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const [ exportTitle, setExportTitle ] = useState(ACCOUNTS_LIST)
    const [ accountColumnTitle, setAccountColumnTitle ] = useState(ACCOUNTS_LIST)
    const [ fileType, setFileType ] = useState()

    const tetleText = useMemo(() => `${exportTitle && msg({id: exportTitle})}`, [exportTitle])
    const columns = useMemo(() => [
        { title: msg({ id: accountColumnTitle }), dataKey: ACCOUNT },
        { title: msg({ id: 'description' }), dataKey: 'description' }
    ], [accountColumnTitle])

    const { exportPDF, exportXLS } = useExport({ title: tetleText, columns})

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: ACCOUNT,
        getAll: AccountsService.getAll,
        createAction: AccountsService.create,
        removeAction: AccountsService.remove, 
        editAction: AccountsService.update
    })

    const handleCreate = async () => {
        const resp = await dataFacade.create(inputs)
        if (resp) {
            navigate(maestrosEcoFin.ACCOUNTING_SUBACCOUNTS(resp?.resp._id))
            updateInputs({})
            toggle()
        }
    }

    const onRowEditComplete = async ({newData}) => {
        const body = {
            account: newData?.account,
            description: newData?.description
        }
        dataFacade.edit({_id: newData._id, body})
    }

    const importCreate = async (file) => uploadFileWithLoading({
        file, 
        uploadService: AccountsService.uploadFile, 
        onSuccess: dataFacade.reload,
        onFinally: toggleImport,
        loadingMessage: msg({ id: 'swal.title.loading' })
    })

    const headerOptions = {
        title: msg({id: 'accounts'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const exportAccount = async (exportType) => {
        const exportAccountOnly = exportType === false
        const accountsData = await AccountsService.getAll({})
        const dataToExport = exportAccountOnly
            ? accountsData?.resp
            : accountsData?.resp?.reduce((accumulated, account) => [
                ...accumulated,
                {
                    account: account.account, 
                    description: account.description
                },
                ...account.subaccounts.map(subaccount => { return { 
                    account: `            ${subaccount?.subaccount}`,
                    description: subaccount?.description
                } })
            ], [])


        return { resp: dataToExport }
    }

    const importExportFilesOptions = {
        title: exportTitle,
        columns,
        importService: toggleImport,
        intermediateExportAction: ({type}) => {
            setFileType(type)

            toggleExport()
        }
    }

    const onChangeExportType = ({exportType}) => {
        const exportAccountAndSubaccount = exportType === true
        setExportTitle(exportAccountAndSubaccount ? "accounts.list.with.subaccounts" : ACCOUNT)
        setAccountColumnTitle(exportAccountAndSubaccount ? "account.and.subaccount" : ACCOUNTS_LIST)
    }

    const onDoExport = ({exportType}) => {
        const exportService = () => exportAccount(exportType)
        if (fileType === FILE_TYPE_PDF) {
            exportPDF(exportService)
        } else if (fileType === FILE_TYPE_XLS) {
            exportXLS(exportService)
        }
        toggleExport()
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataControl = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade.sortOptions,
            }}
            columns={([
                { 
                    field: ACCOUNT,
                    header: ACCOUNT,
                    sortable: true,
                    ...editTextRowColumn
                },
                { 
                    field: "description",
                    header: 'description',
                    sortable: true,
                    ...editTextRowColumn, 
                },
                { ...rowDelete({ handleDelete: dataFacade.remove })},
                {...rowEditor()},
                { 
                    className: 'row-delete column-tooltip',
                    field: "masters", 
                    ...rowSeeWithTooltip({
                        tooltip: msg({id: 'info.subaccounts'}),
                        onClick: (rowData) => navigate(maestrosEcoFin.ACCOUNTING_SUBACCOUNTS(rowData?._id))
                    
                    })
                },
            ])}
        />
    )

    return (
        <div className="accounts">
            <div className="main-common-view">
                <div className="box-container">
                    <CommonHeader {...headerOptions} />

                    <div className="component-data">
                        <HeaderImportExportAccountFiles {...importExportFilesOptions} />

                        { dataControl }
                    </div>

                    <PaginatorComp {...pagerOptions}/>
                </div> 
            </div>

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id="account.new" />}
                context={(
                    <FormAccount 
                        inputs={inputs} 
                        handleSubmit={handleCreate} 
                        handleChange={onChangeInputs} 
                    />
                )}
            />

            <Modal
                isShowing={isShowingImport}
                hide={toggleImport}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id="import" />}
                context={(
                    <ImportFileModal 
                        handleSubmit={importCreate} 
                        typefile='.csv'
                    />
                )}
            />

            <Modal
                isShowing={isExportShowing}
                hide={toggleExport}
                width="90%"
                maxWidth="450px"
                title={<FormattedMessage id='export.mode'/>}
                context={(
                    <ExportAccountTypeForm
                        onChangeType={onChangeExportType}
                        handleSubmit={onDoExport}
                    />
                )}
            />
        </div>   
    )
}
export default Accounts
