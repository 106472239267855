import React from 'react' 
import { Route, Routes } from 'react-router-dom' 
import _ from 'lodash' 

import Header from '../header/index'
import ProtectedRoute from './proteted/ProtectedRoute' 
import PrivateRoutes from './proteted/PrivateRoutes' 
import CenterRoutes from './center/CenterRoutes'

const ProtectedLayout = ({ auth }) => (
    <>
        <Header />
        <ProtectedRoute isAllowed={!_.isEmpty(auth)}>
            <Routes>
                {/* Any authenticated user */}
                <Route path="/*" element={<PrivateRoutes />} />
                <Route path="/app/centers/center/:centerId/*" element={<CenterRoutes />} />

                {/* Admin routes 
                <Route path="/admin/*" element={(
                    <ProtectedRoute isAllowed={auth?.user?.role === "admin"}>
                        <AdminRoutes />
                    </ProtectedRoute>
                )} />*/}
            </Routes>
        </ProtectedRoute>
    </>
)

export default ProtectedLayout