import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from "../../../../../../utils/hooks/useLoading"
import BreadcrumbLink from '../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../routes/centers/center-course/pathname'
import BugetTable from '../../../../../../components/common/dataTable/BugetTable'
import BudgetService from '../../../../../../services/centers/courses/budgets/budgets.service'
import Dashboard from '../../../../../../components/common-courses/view-content/Dashboard'
import { parseToTableFormat } from '../../../../../../utils/data-parsers/expensesTableParser'

const ExpensesTableH = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId } = useParams()

    const [expensesTable, setExpensesTable] = useState([])
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getExpensesData()
    }, [])

    const getExpensesData = async () => {
        startLoading()
        const resp = await BudgetService.getExpensesResultsTranspose(courseId, true)
        if (resp && resp.resp) {
            setExpensesTable(parseToTableFormat(resp.resp))
        }
        stopLoading()
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: (
                <BreadcrumbLink 
                    path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                    text={msg({ id: 'budget' })} 
                />
            )
        },
        {
            label: msg({ id: 'result' }),
            template: (
                <BreadcrumbLink 
                    path={CENTER_COURSE_DETAILS.BUGET_RESULTS(centerId, courseId)} 
                    text={msg({ id: 'result' })} 
                />
            )
        },
        {
            label: msg({ id: 'horizontal.result' }),
            template: (
                <BreadcrumbLink 
                    path={CENTER_COURSE_DETAILS.BUDGET_RESULTS_HORIZONTAL(centerId, courseId)} 
                    text={msg({ id: 'horizontal.result' })} 
                />
            )
        },
        {
            label: msg({ id: 'expenses' })
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb
    }

    const getColumns = () => {
        if (!expensesTable || expensesTable.length === 0) return [];
        const sampleItem = expensesTable[0];
        const columns = Object.keys(sampleItem).map(key => {
            if (key === 'concept' || key === 'total') {
                return {
                    field: key,
                    header: key
                }
            }
            return {
                field: key,
                header: sampleItem[key]?.name || key,
                noTraslateHeader: true
            }
        })
        return columns
    }

    const transformTableData = (data) => {
        if (!data || data.length === 0) return []
        return data.map(item => {
            const transformedItem = { ...item }
            Object.keys(transformedItem).forEach(key => {
                if (typeof transformedItem[key] === 'object' && transformedItem[key] !== null) {
                    transformedItem[key] = transformedItem[key].value
                }
            })
            return transformedItem
        })
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: transformTableData(expensesTable)
            }}
            columns={getColumns()}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default ExpensesTableH
