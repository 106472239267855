import foundationTeams from '../../../assets/imgs/maestros/maestros-foundation/equips-fundacio.svg'
import accounting from '../../../assets/imgs/maestros/maestros-accounting/accounting.svg'
import { maestrosFoundation } from '../../../routes/maestros/maestros-foundation/maestrosFoundation.pathname'
import planFoundation from '../../../assets/imgs/maestros/maestros-foundation/plan-contable.fundacion.svg'


export const maestrosFoundationKeys = {
    FOUNDATION_TEAMS: 'foundationTeams',
    ACCOUNTING_PLAN_FUNDATION: 'foundationAccountPlan',
}
export const MAESTROS_FOUNDATION = [
    {
        id: 'foundation.teams',
        name: 'Equipo fundacion',
        route: maestrosFoundation.FOUNDATION_TEAMS,
        src: foundationTeams,
        key: maestrosFoundationKeys.FOUNDATION_TEAMS
    },
    {
        id: 'accounting',
        name: 'cantabilidadad fundacion ',
        route: maestrosFoundation.MAESTROS_ACCOUNTING_FOUNDATION,
        src: accounting,
    },
]

export const MAESTROS_ACCOUNTING_FOUNDATION = [
    {
        id: 'accounting.plan.foundation',
        name: 'placomptablefundacio',
        route: maestrosFoundation.ACCOUNTING_PLAN_FUNDATION,
        src: planFoundation,
        key: maestrosFoundationKeys.ACCOUNTING_PLAN_FUNDATION
    },
]