import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch } from 'react-redux'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import CardList from "../../../../components/card-list/CardList"
import useModal from "../../../../utils/hooks/useModal"
import Modal from "../../../../components/modal/Modal"
import FormCenterTeam from "../../../../components/maestros-center/center-teams/FormCenterTeam"
import { maestrosCenters } from "../../../../routes/maestros/maestros-centers/maestrosCenters.pathname"
import useInputs from "../../../../utils/hooks/useInputs"
import CenterTeamsService from "../../../../services/center-teams/centerTeams.service"
import { getAllCenterTeamsAgroupations } from "../../../../services/center-teams/centerTeamsAgroupations.slice"
import auxFunction from "../../../../utils/helpers/auxFun"

const CenterTeams = () => {
    const dispatch = useDispatch()
    const { formatMessage: msg } = useIntl()

    const {isShowing, toggle} = useModal()  
    const { inputs, updateInputs, onChangeInputs } = useInputs()
    
    useEffect(() => { 
        dispatch(getAllCenterTeamsAgroupations())
    }, [])

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: CenterTeamsService.getAll,
        createAction: CenterTeamsService.createCenterTeam,
        removeAction: CenterTeamsService.removeCenterTeam, 
        editAction: CenterTeamsService.updateCenterTeam
    })

    const handleCreate = () => { 
        dataFacade.create(inputs)
    }

    const handleUpdate = (id) => {
        const body = {
            ...inputs, 
            association: auxFunction.returnObjectId(inputs?.association)
        }
        return dataFacade.edit({ _id: id, body })
    }

    const handleSave = async (_id) => {
        const resp = _id ? await handleUpdate(_id) : await handleCreate()
        closeModal()
        return resp
    }

    const openModal = (centerTeam) => {
        updateInputs(centerTeam)
        toggle()
    }

    const closeModal = () => {
        updateInputs({})
        toggle()
    }

    const goToCardLink = (id) => maestrosCenters.CENTER_TEAM(id)

    const headerOptions = {
        title: msg({id: 'center.teams'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions, 
    }

    const importExportFilesOptions = {
        title: 'center.teams',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: () => CenterTeamsService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataControl = (
        <CardList 
            list={dataFacade?.data?.resp}
            gotToLink={goToCardLink}
            openEditModal={openModal}
            handleDelete={dataFacade.remove}
        />
    )

    const defineModalTitle = () => msg({ id: `center.teams.${inputs._id ? 'edit' : 'new'}`})

    return (
        <div className="center-teams-container">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
                showLegend
            />

            <Modal 
                isShowing={isShowing}
                hide={closeModal}
                width='90%'
                maxWidth='500px'
                title={defineModalTitle()}
                context={(
                    <FormCenterTeam
                        inputs={inputs}
                        handleChange={onChangeInputs}
                        handleSave={handleSave}
                    />
                )}
            />
        </div>
    )
    
}

export default CenterTeams