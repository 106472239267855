/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { OrganizationChart } from "primereact/organizationchart"
import { useIntl } from "react-intl"
import FoundationTeamsService from "../../../services/foundation-teams/foundationTeams.service"
import FoundationStatistiscData from "../../../components/foundation/statistics/FoundationStatisticsData"

const FoundationStatistics = () => {

    const { formatMessage: msg } = useIntl()

    const [teamsTree, setTeamsTree] = useState([])
    const [nodes, setNodes] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showNodeDetails, setShowNodeDetails] = useState(false)
    const [selectedNode, setSelectedNode] = useState(null)

    useEffect(() => {
        getNodes()
    }, [])

    const getNodes = async () => {
        const allNodes = (await FoundationTeamsService.getHierarchy())?.resp
        if (allNodes) {
            const validNodes = allNodes.filter(node => node !== null && typeof node === 'object')
            setTeamsTree(validNodes)
            const filteredTree = getTreeFilteredByLevel(3, validNodes)
            setNodes(filteredTree)
        }
        setIsLoading(false)
    } 

    const getTreeFilteredByLevel = (finalLevel, data) => {
        const rootNode = [{
            label: msg({id: 'foundation'}).toUpperCase(),
            expanded: true,
            firstNode: true,
            children: filteredByLevel(data, 1, finalLevel)
        }]
        return rootNode
    }

    const filteredByLevel = (treeNodes, currentLevel, level) => {
        if (currentLevel > level) {
            return []
        }

        return treeNodes.map(node => ({
            ...node,
            expanded: true,
            children: node?.children ? filteredByLevel(node?.children, currentLevel + 1, level) : undefined
        }))
    }

    const handleSelectNode = (e) => {
        if (!e?.data[0]?.firstNode) {
            setSelectedNode(e?.data[0])
            if (!showNodeDetails) {
                setShowNodeDetails(true)
            }
        }
    }

    return (
        <div>
            <h1>{msg({id: 'foundation.statistics'})}</h1>
            {isLoading ? (
                <p> </p>
            ) : (
                !showNodeDetails ? (
                    <OrganizationChart
                        value={nodes}
                        selectionMode="multiple"
                        onSelectionChange={handleSelectNode}
                    />
                ) : (
                    <FoundationStatistiscData 
                        foundationTeam={selectedNode}
                        teamsTree={teamsTree}
                    />
                )
            )}
        </div>
    )
}

export default FoundationStatistics
