import { ROWS_PER_PAGE_DEFAULT } from "../constants/common"

const auxFunction = {
    returnIds: (array) => (array|| []).map(item => item?._id || item),
    returnId: (object) => object?._id || object,
    returnObjectId: (object) => object?._id || object,
    updateInputs: (name, value, oldValues) => {
        const nameParts = name.split('.') 

        const updatedInputs = { ...oldValues }
        
        let currentObj = updatedInputs
        nameParts.forEach((part, index) => {
            if (!currentObj[part]) {
                currentObj[part] = {}
            }
            if (index === nameParts.length - 1) {
                currentObj[part] = value
            }
            currentObj = currentObj[part]
        })
        return updatedInputs
    },
    checkHasValue: (data) => ( // TODO; review this function and remplace
        data !== null || undefined ? data: ""
    ),
    openPdfFileWithBlob: (file) => {
        if (file) {
            const blob = new Blob([file], { type: "application/pdf" })
            const url = URL.createObjectURL(blob)
            return url
        }
        return null
    },
    truncateWithEllipsis: (str, length) => (
        str.length > length ? str.substring(0, length) + "..." : str
    ),
    getDataCountDisplayPerPage: ({limit, rowsPerPageOptions, ROWS_PER_PAGE = ROWS_PER_PAGE_DEFAULT }) => {
        return limit || (rowsPerPageOptions ? rowsPerPageOptions[1] : ROWS_PER_PAGE[1])
    },
    setEmptyState: (propertiesArry) => ( // TODO: add this to every component with states
        propertiesArry.reduce((acc, property) => {
            acc[property] = ''
            return acc
        }, {})
    ),
    isSubstringPresent: (str, substr) => {
        return str && substr ? str.toLowerCase().includes(substr.toLowerCase().trim()) : null
    },

    contains: (str, substr) => typeof str === 'undefined' || str === null || typeof substr === 'undefined' || substr === null 
        ? null
        : (str + '').toLowerCase().trim().includes((substr + '').toLowerCase().trim()),

    findLastCourse: (courses) => courses?.reduce(
        (last, course) => new Date(last.yearTo) <= new Date(course.yearTo) ? course : last,
        courses[0]
    ),

    getObjectDeepPropertyValue: (object, propertyWithDots) => propertyWithDots
        ?.split('.')
        .reduce((acc, propertyPart) => acc?.[propertyPart], object),

    flattenArrayProperty: (arrayObj, arrayProp, propName) => {
        return arrayObj?.map(obj => { 
            if (obj[arrayProp]) {
                obj[arrayProp] = obj[arrayProp].reduce 
                    ? obj[arrayProp].reduce((acc, propVal) => `${acc}${acc !== '' ? ', ' : ''} ${propVal[propName]}`, '')
                    : obj[arrayProp][propName]
            }

            return obj
        })
    },
}

export default auxFunction