import React from "react"
import FoundationTeams from "../../../views/maestros/maestros-foundation/foundation-teams/FoundationTeams"
import { maestrosFoundation } from "./maestrosFoundation.pathname"
import FoundationTeam from "../../../views/maestros/maestros-foundation/foundation-teams/FoundationTeam"
import MaestrosAccountingFoundation from "../../../views/maestros/maestros-foundation/maestros-accounting-foundation/MaestrosAccountingFoundation"
import FoundationAccountPlan from "../../../views/maestros/maestros-foundation/maestros-accounting-foundation/foundation-acount-plan/FoundationAccountPlan"

export const maestrosFoundationRoutes = [
    {
        path: maestrosFoundation.FOUNDATION_TEAMS,
        name: 'foundationteams',
        element: <FoundationTeams />,
    },
    {
        path: maestrosFoundation.FOUNDATION_TEAM(':teamId'),
        name: 'team',
        element: <FoundationTeam />,
    },
    {
        path: maestrosFoundation.MAESTROS_ACCOUNTING_FOUNDATION,
        name: 'accounting',
        element: < MaestrosAccountingFoundation/>,
    },
    {
        path: maestrosFoundation.ACCOUNTING_PLAN_FUNDATION,
        name: 'accountingplanfoundation',
        element: <FoundationAccountPlan />,
    }
    
]