import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import useLoading from "../../../../../../utils/hooks/useLoading"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"
import CardsOverview from "../../../../../../components/common-courses/view-content/CardsOverview"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"
import BudgetService from "../../../../../../services/centers/courses/budgets/budgets.service"

const OtherIncomesData = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, balanceId, conceptId, accountId } = useParams()

    const [data, setData] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        startLoading()
        const resp = await BudgetService.getOtherIncomesData(courseId, conceptId, accountId)
        if (resp) {
            setData(resp?.resp)
        }
        stopLoading()
    }
 
    const goToTotals = (id, item) => {
        if (item?.agroupation === 'totals') return CENTER_COURSE_DETAILS.BUDGET_INCOMES_DATA_TABLE_TOTALS(centerId, courseId, balanceId, conceptId, accountId)
        return CENTER_COURSE_DETAILS.BUDGET_INCOMES_DATA_TABLE_AGROUPATIONS(centerId, courseId, balanceId, conceptId, accountId, item?.etapa || item?.otherService)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: data?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_BALANCES(centerId, courseId, data?.balanceTemplate)} 
                text={data?.templateName} /> 
        },
        {
            label: data?.conceptName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_CONCEPTS(centerId, courseId, data?.balanceTemplate, data?.concept)} 
                text={data?.conceptName} /> 
        },
        {
            label: data?.numAccount
        }
    ]

    const cardListTotals = {
        list: [
            {
                agroupation: 'totals',
                name: msg({ id: 'totals.anual' }).toUpperCase()
            }
        ],
        gotToLink: goToTotals
    }

    const cardListEtapaServices = {
        list: [
            ...(data?.etapas || []),
            ...(data?.otherServices || [])
        ],
        gotToLink: goToTotals
    }

    const sections = [
        {
            title: msg({ id: 'indicate.total.anual'}) + '*',
            cardsInfo: cardListTotals
        },
        ...(
            !data?.confirmation?.isConfirm ? [{
                title: msg({ id: 'total.distribution'}) + '*',
                cardsInfo: cardListEtapaServices
            }] : []
        )
    ]

    return (
        <CardsOverview
            loading={loading}
            breadcrumb={breadcrumb}
            sections={sections}
        />
    )
}

export default OtherIncomesData
