import React, { useEffect, useState } from "react"
import { useIntl } from 'react-intl'
import { NavLink, useParams } from "react-router-dom"
import favicon from "../../../assets/imgs/logos/icono-logo-modal.svg"
import { centerCourseMenu, newCenterCourseMenu } from "../../../utils/center-courses/menu"
import useLoading from "../../../utils/hooks/useLoading"
import "./menuCourse.scss"

const MenuCourse = ({ activeItemMenu, newCourse = false, loading = false }) => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId} = useParams()
    const [ menu, setMenu ] = useState(centerCourseMenu)
    // const { xloading, startLoading, stopLoading } = useLoading(loading)

    useEffect(() => {
        if (activeItemMenu.structure || newCourse === true) {
            setMenu(newCenterCourseMenu)
        }
    }, [])
    useEffect(() => {
        if (activeItemMenu.structure || newCourse === true) {
            setMenu(newCenterCourseMenu)
        } else {
            setMenu(centerCourseMenu)
        }
    }, [activeItemMenu, newCourse])

    // const menu = centerCourseMenu
    // menu = (activeItemMenu.structure || newCourse === true) ? newCenterCourseMenu : centerCourseMenu

    return (
        <aside className="course-tabs__aside">
            {(newCourse === true && activeItemMenu.structure) && (
                <div className="course-tabs__aside__header">
                    <img className="favicon" src={favicon} alt="favicon" /> 
                    <h2 className="title__subtitle">Crear nou curs</h2>
                </div>
            )}
            <div className="list">
                {menu?.map((nav) => (
                    <NavLink
                        key={Math.random()}
                        to={nav.path(centerId, courseId)}
                        className={({ isActive }) => `item-list ${isActive ? 'active' : ''} 
                        ${activeItemMenu[nav.key] || (activeItemMenu[nav.key] === undefined) ? '' : 'disabled'}`}
                    >
                        {msg({ id: nav.id })}
                    </NavLink>
                ))}
            </div>
        </aside>
    )
}

export default MenuCourse