import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import useLoading from "../../../../utils/hooks/useLoading"
import CardsOverview from "../../../../components/common-courses/view-content/CardsOverview"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"
import DistributionHoursService from "../../../../services/centers/courses/distribution-hours/ditributionHours.service"
import BreadcrumbLink from "../../../../components/common/breadcrumb/BreadcrumbLink"

const HoursAndStaffView = () => {
    
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, etapaId } = useParams({})

    const navigate = useNavigate()

    const [distributionHours, setDistributionHours] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getDistributioHours()
    }, [])

    const getDistributioHours = async () => {
        startLoading()
        const resp = await DistributionHoursService.getEtapa(courseId, etapaId)
        if (resp) {
            setDistributionHours(resp?.resp)
        }
        stopLoading()
    }

    const checkIfAllIsConfirmed = () => {
        return distributionHours?.lineDistribution?.confirmation?.isConfirm
            && distributionHours?.staffDistribution?.confirmation?.isConfirm
            && distributionHours?.teams?.find(team => team?.name === 'FUNDACIÓ')?.confirmation?.isConfirm
            && distributionHours?.teams?.find(team => team?.name === 'ESCOLA')?.confirmation?.isConfirm
    }

    const HoursAndStaffCards = [
        {   
            _id: etapaId,
            name: msg({id: 'calcul.hours'}).toUpperCase(),
            confirmation: distributionHours?.lineDistribution?.confirmation,
            source: 'lineDistribution',
            line: true,
            key: 'calcul-' + etapaId
        },
        {   
            _id: etapaId,
            source: 'distributionHours',
            confirmation: {
                isConfirm: checkIfAllIsConfirmed()
            },
            name: msg({id: 'distribution.hours'}).toUpperCase(),
            key: 'distribucio-' + etapaId
        },
    ]

    const goToList = (id, item) => {
        if (item?.line) {
            return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_CALC(centerId, courseId, id)
        }
        return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION(centerId, courseId, id)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'hours.and.staff' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({ id: 'hours.and.staff' })} /> 
        },
        {
            label: distributionHours?.etapaName
        }
    ]

    const handlePrevious = () => { navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)) }

    const cardList = {
        list: HoursAndStaffCards,
        gotToLink: goToList
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            handlePrevious={handlePrevious}
            breadcrumb={breadcrumb}
        />
    )
}

export default HoursAndStaffView