import { DELETE, GET, PATCH, POST } from "../../common/commonRequest.service"
import { endpointsCourses } from "./endpoints"

const CoursesService = {
    create: async (body) => POST(endpointsCourses.CREATE, body),
    getCourseByCourseId: async (courseId) => GET(endpointsCourses.BY_COURSE_ID(courseId)),
    getCoursesByCenterId: async (centerId) => GET(endpointsCourses.BY_CENTER_ID(centerId)),
    getLastCourseCreated: async (centerId) => GET(endpointsCourses.BY_CENTER_LAST_COURSE_CREATED(centerId)),
    updateCourseByCourseId: async ({ courseId, body}) => PATCH(endpointsCourses.BY_COURSE_ID(courseId, body)),
    removeCourseByCourseId: async (courseId) => DELETE(endpointsCourses.BY_COURSE_ID(courseId)),
    // TO DO: remove
    remove: async (courseId) => DELETE(endpointsCourses.BY_COURSE_ID(courseId)),
}

export default CoursesService