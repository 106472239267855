import { 
    endpointsLinesDistributionHoursExceeded,
    endpointsTemplateCalculateHours
} from './endpoints'
import { DELETE, GET, PATCH, POST } from '../common/commonRequest.service'


export const ExceededHoursDistributionService = {
    create: async (body) => POST(endpointsLinesDistributionHoursExceeded.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsLinesDistributionHoursExceeded.LOAD({filter, limit, skip, sort})),
    update: async ({ _id, body }) => PATCH(endpointsLinesDistributionHoursExceeded.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsLinesDistributionHoursExceeded.BY_ID(id))
}

export const TemplateEtapaHoursService = {
    create: async (body) => POST(endpointsTemplateCalculateHours.LIST, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsTemplateCalculateHours.LOAD({filter, limit, skip, sort})),
    getTemplateHoursById: async (templateHoursId) => GET(endpointsTemplateCalculateHours.BY_ID(templateHoursId)),
    update: async ({ body }) => PATCH(endpointsTemplateCalculateHours.LIST, body),
    remove: async (id) => DELETE(endpointsTemplateCalculateHours.BY_ID(id)),
    getDataById: async (id) => GET(endpointsTemplateCalculateHours.BY_ID(id)),
    createLine: async ({ id, body }) => POST(endpointsTemplateCalculateHours.LINE_POST(id), body),
    updateByLineId: async ({ id, lineId, body }) => PATCH(endpointsTemplateCalculateHours.BY_LINE(id, lineId), body),
    removeByLineId: async ({ id, lineId }) => DELETE(endpointsTemplateCalculateHours.BY_LINE(id, lineId)),
}