import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsStages } from "./endpoints"

const EtapasService = {
    createEtapa: async (body) => POST(endpointsStages.GENERIC, body),
    getAllEtapas: async ({filter, limit, skip, sort}) => GET(endpointsStages.LOAD({filter, limit, skip, sort})),
    getFirstLevel: async ({filter, limit, skip, sort}) => GET(endpointsStages.FIRST_LEVEL({filter, limit, skip, sort})),
    getAllSubetapas: async ({ etapaId, filter, limit, skip, sort}) => GET(endpointsStages.LOAD_SUBETAPAS({etapaId, filter, limit, skip, sort})),
    getDataByEtapaId: async (etapaId) => GET(endpointsStages.BY_ETAPA(etapaId)),
    updateEtapa: async ({_id, body}) => PATCH(endpointsStages.BY_ETAPA(_id), body),
    confirmEtapa: async (etapaId) => PATCH(endpointsStages.CONFIRM(etapaId)),
    removeEtapa: async (etapaId) => DELETE(endpointsStages.BY_ETAPA(etapaId))
}

export default EtapasService