import { createSlice } from "@reduxjs/toolkit";
import createAsyncThunkMiddleware from "../common/createAsyncThunkMiddleware";
import { FoundationService } from "./foundation.service";

export const createFoundation = createAsyncThunkMiddleware(
    'foundation/create',
    FoundationService.create
)

export const getFoundation = createAsyncThunkMiddleware(
    'foundation/get',
    FoundationService.get
)

export const updateFoundation = createAsyncThunkMiddleware(
    'foundation/update',
    FoundationService.update
)

export const removeFoundation = createAsyncThunkMiddleware(
    'foundation/remove',
    FoundationService.remove
)

const initialState = {}

const foundationSlice = createSlice({
    name: 'foundation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFoundation.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(updateFoundation.fulfilled, (state, action) => {
                return action.payload
            })
    }
})

export default foundationSlice.reducer