import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl} from 'react-intl'
import { pathname } from '../../routes/pathname'
import logo from "../../assets/imgs/logos/logo.svg"
import { validators } from '../../utils/helpers/validators'
import Button from '../../components/common/buttons'
import AuthService from '../../services/auth/auth.service'
import InputTextComp from '../../components/common/form-components/inputs/InputTextComp'
import './reset.scss'

const ResetPassword = () => {

    const { formatMessage: msg } = useIntl()
    
    const navigate = useNavigate()

    const [email, setEmail] = useState('')

    const handleChange = (e) => {
        const value = e.target.value
        setEmail(value)
    }

    const handleSubmit = async () => {
        const body = {
            email
        }
        const resp = await AuthService.recoverPassword(body, msg({id: 'swal.send.email.password' }))
        if (resp) {
            navigate(pathname.LANDING)
        }
    }

    const validate = validators.emailFormat(email)

    return (
        <div className='reset'>
            <form className='reset-form'>
                <div className='reset-box'>
                    <Link to={pathname.LOGIN} className="reset-box-link"> 
                        <img className='reset-box-logo' src={logo} alt='logo verduna'/> 
                    </Link>
                    <div className="reset-box-form">
                        <h3 className="title-form"><FormattedMessage id="app.reset.title"/> </h3>
                        <InputTextComp 
                            name='email'
                            value={email}
                            onChange={handleChange}
                            placeholder={msg({id: 'email'})}
                        />
                        <Button
                            id="submitReset"
                            btnText={<FormattedMessage id="button.send"/>}
                            btnType='button'
                            btnClass="btn__with-bg-type-1"
                            isDisabled={!validate}
                            onHandleClick={() => handleSubmit()}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}
export default ResetPassword