import { GET, PATCH } from "../../../common/commonRequest.service"
import { CenterBudgetsEndpoints } from "./endpoints"

const BudgetService = {
    getBudgets: async (courseId) => GET(CenterBudgetsEndpoints.BUDGETS(courseId)),

    getIncomes: async (courseId) => GET(CenterBudgetsEndpoints.INCOMES(courseId)),
    getConcepts: async (courseId, balanceId) => GET(CenterBudgetsEndpoints.CONCEPTS(courseId, balanceId)),
    getAccounts: async (courseId, conceptId) => GET(CenterBudgetsEndpoints.ACCOUNTS(courseId, conceptId)),
    getOtherIncomesData: async (courseId, conceptId, accountId) => GET(CenterBudgetsEndpoints.ACCOUNTS_DATA(courseId, conceptId, accountId)),
    getOtherIncomesTotals: async (courseId, conceptId, accountId) => GET(CenterBudgetsEndpoints.TOTAL_INCOMES(courseId, conceptId, accountId)),
    getOtherIncomesAgroupationsTotals: async (courseId, conceptId, accountId, agroupationId) => GET(CenterBudgetsEndpoints.TOTAL_INCOMES_AGROUPATIONS(courseId, conceptId, accountId, agroupationId)),
    totalIncomesSave: async (courseId, conceptId, accountId, body) => PATCH(CenterBudgetsEndpoints.TOTAL_INCOMES(courseId, conceptId, accountId), body),
    totalIncomesConfirm: async (courseId, conceptId, accountId, body) => PATCH(CenterBudgetsEndpoints.TOTAL_INCOMES_CONFIRM(courseId, conceptId, accountId), body),

    getActivities: async (courseId, balanceId) => GET(CenterBudgetsEndpoints.ACTIVITIES(courseId, balanceId)),
    getActivitiesData: async (courseId, conceptId) => GET(CenterBudgetsEndpoints.ACTIVITIES_DATA(courseId, conceptId)),
    getActivitiesDataEtapa: async (courseId, conceptId, etapaId) => GET(CenterBudgetsEndpoints.ACTIVITIES_DATA_ETAPA(courseId, conceptId, etapaId)),
    getActivitiesDataEtapaGeneralTable: async (courseId, conceptId, etapaId) => GET(CenterBudgetsEndpoints.ACTIVITIES_DATA_ETAPA_GENERAL_TABLE(courseId, conceptId, etapaId)),
    getActivitiesDataEtapaServicesTable: async (courseId, conceptId, etapaId, serviceId) => GET(CenterBudgetsEndpoints.ACTIVITIES_DATA_ETAPA_SERVICES_TABLE(courseId, conceptId, etapaId, serviceId)),
    getActivitiesDataServicesTable: async (courseId, conceptId, otherServiceId) => GET(CenterBudgetsEndpoints.ACTIVITIES_DATA_SERVICES_TABLE(courseId, conceptId, otherServiceId)),
    
    getActivitiesSubventions: async (courseId, conceptId) => GET(CenterBudgetsEndpoints.ACTIVITIES_SUBVENTIONS(courseId, conceptId)),
    getSubventionsTable: async (courseId, conceptId, etapaId) => GET(CenterBudgetsEndpoints.ACTIVITIES_SUBVENTIONS_TABLE(courseId, conceptId, etapaId)),

    updateActivitiesEtapaServicesTable: async (courseId, conceptId, etapaId, serviceId, body) => PATCH(CenterBudgetsEndpoints.ACTIVITIES_DATA_ETAPA_SERVICES_TABLE(courseId, conceptId, etapaId, serviceId), body),
    confirmActivitiesEtapaServicesTable: async (courseId, conceptId, etapaId, serviceId, body) => PATCH(CenterBudgetsEndpoints.ACTIVITIES_DATA_ETAPA_SERVICES_TABLE_CONFIRM(courseId, conceptId, etapaId, serviceId), body),

    updateActivitiesDataEtapaGeneralTable: async (courseId, conceptId, etapaId, body) => PATCH(CenterBudgetsEndpoints.ACTIVITIES_DATA_ETAPA_GENERAL_TABLE(courseId, conceptId, etapaId), body),
    confirmActivitiesDataEtapaGeneralTable: async (courseId, conceptId, etapaId, body) => PATCH(CenterBudgetsEndpoints.ACTIVITIES_DATA_ETAPA_GENERAL_TABLE_CONFIRM(courseId, conceptId, etapaId), body),
    
    updateActivitiesDataServicesTable: async (courseId, conceptId, otherServiceId, body) => PATCH(CenterBudgetsEndpoints.ACTIVITIES_DATA_SERVICES_TABLE(courseId, conceptId, otherServiceId), body),
    confirmActivitiesDataServiceTable: async (courseId, conceptId, otherServiceId, body) => PATCH(CenterBudgetsEndpoints.ACTIVITIES_DATA_SERVICES_TABLE_CONFIRM(courseId, conceptId, otherServiceId), body),

    getExpenses: async (courseId) => GET(CenterBudgetsEndpoints.EXPENSES(courseId)),
    getExpensesBalances: async (courseId, balanceId) => GET(CenterBudgetsEndpoints.EXPENSES_BALANCES(courseId, balanceId)),
    getExpensesConcepts: async (courseId, conceptId) => GET(CenterBudgetsEndpoints.EXPENSES_CONCEPTS(courseId, conceptId)),
    
    getExpensesTotals: async (courseId, conceptId) => GET(CenterBudgetsEndpoints.EXPENSES_SUPPLIES_TOTALS_DATA(courseId, conceptId)),
    getExpensesTotalsData: async (courseId, conceptId, accountId) => GET(CenterBudgetsEndpoints.EXPENSES_SUPPLIES_TOTALS_ACCOUNTS(courseId, conceptId, accountId)),
    confirmExpensesTotalsData: async (courseId, conceptId, accountId, body) => PATCH(CenterBudgetsEndpoints.EXPENSES_SUPPLIES_TOTALS_ACCOUNTS_CONFIRM(courseId, conceptId, accountId), body),
    updateExpensesTotalsData: async (courseId, conceptId, accountId, body) => PATCH(CenterBudgetsEndpoints.EXPENSES_SUPPLIES_TOTALS_ACCOUNTS(courseId, conceptId, accountId), body),

    getExpensesEtapas: async (courseId, conceptId, etapaId) => GET(CenterBudgetsEndpoints.EXPENSES_SUPPLIES_ETAPAS_DATA(courseId, conceptId, etapaId)),
    getExpensesEtapasData: async (courseId, conceptId, etapaId, accountId) => GET(CenterBudgetsEndpoints.EXPENSES_SUPPLIES_AGROUPATIONS_ACCOUNTS(courseId, conceptId, etapaId, accountId)),
    
    getStaffExpenses: async (courseId) => GET(CenterBudgetsEndpoints.STAFF_EXPENSES(courseId)),
    getStaffExpensesEtapa: async (courseId, etapaId) => GET(CenterBudgetsEndpoints.STAFF_EXPENSES_ETAPA(courseId, etapaId)),

    saveStaffExpensesEtapa: async (courseId, etapaId, body) => PATCH(CenterBudgetsEndpoints.STAFF_EXPENSES_ETAPA(courseId, etapaId), body),
    confirmStaffExpensesEtapa: async (courseId, etapaId, body) => PATCH(CenterBudgetsEndpoints.CONFIRM_STAFF_EXPENSES_ETAPA(courseId, etapaId), body),

    getResults: async (courseId) => GET(CenterBudgetsEndpoints.RESULTS(courseId)),
    getResultsTranspose: async (courseId, transpose) => GET(CenterBudgetsEndpoints.RESULTS_TRANSPOSE(courseId, transpose)),
    getExpensesResults: async (courseId, transpose) => GET(CenterBudgetsEndpoints.EXPENSES_RESULTS(courseId, transpose)),
    getIncomesResults: async (courseId, transpose) => GET(CenterBudgetsEndpoints.INCOMES_RESULTS(courseId, transpose)),
    getExpensesResultsTranspose: async (courseId, transpose) => GET(CenterBudgetsEndpoints.EXPENSES_RESULTS_TRANSPOSE(courseId, transpose)),
    getIncomesResultsTranspose: async (courseId, transpose) => GET(CenterBudgetsEndpoints.INCOMES_RESULTS_RANSPOSE(courseId, transpose)),
}

export default BudgetService