import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from "../../../../../../../utils/hooks/useLoading"
import BugetTable from '../../../../../../../components/common/dataTable/BugetTable'
import Dashboard from '../../../../../../../components/common-courses/view-content/Dashboard'
import BudgetService from '../../../../../../../services/centers/courses/budgets/budgets.service'
import BreadcrumbLink from '../../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../../routes/centers/center-course/pathname'

const AgroupationsTable = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId, conceptId, accountId, agroupationId } = useParams()

    const [agroupationsTableTotals, setAgroupationsDataTableTotals] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getAgroupationsDataTableTotals()
    }, [])

    const getAgroupationsDataTableTotals = async () => {
        startLoading()
        const resp = true //await BudgetService.getOtherIncomesAgroupationsTotals(courseId, conceptId, accountId, agroupationId)
        if (resp) {
            setAgroupationsDataTableTotals({
                course: "66aa038807310204f02d45c4",
                balanceTemplate: "668e6c9b19adb6be106c9a72",
                templateName: "1. Ingressos per les activitats",
                concept: "668e6ce319adb6be106c9a79",
                conceptName: " Prestacions de serveis",
                account: "657c09eec3ebe4abb90ce09c",
                numAccount: "724",
                etapa: "643dd3a02651815b3c09c633",
                etapaName: "INFANTIL",
                subaccounts: [
                    {
                        subaccount: "657c09eec3ebe4abb90ce0a0",
                        numSubaccount: "1022",
                        totalIncome: 0,
                        imputationName: "100%"
                    },
                ]
            })
        }
        stopLoading()
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: agroupationsTableTotals?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_BALANCES(centerId, courseId, agroupationsTableTotals?.balanceTemplate)} 
                text={agroupationsTableTotals?.templateName} /> 
        },
        {
            label: agroupationsTableTotals?.conceptName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_CONCEPTS(centerId, courseId, agroupationsTableTotals?.balanceTemplate, agroupationsTableTotals?.concept)} 
                text={agroupationsTableTotals?.conceptName} /> 
        },
        {
            label: agroupationsTableTotals?.numAccount,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_DATA(centerId, courseId, agroupationsTableTotals?.balanceTemplate, agroupationsTableTotals?.concept, accountId)} 
                text={agroupationsTableTotals?.numAccount} /> 
        },
        {
            label: agroupationsTableTotals?.etapaName
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb
    }

    const table = (
        <BugetTable 
            tableOptions={{
                loading,
                value: agroupationsTableTotals?.subaccounts
            }}
            columns={[
                { field: 'numSubaccount', header: 'subaccount'},
                { field: 'descSubaccount', header: 'concept' },
                { field: 'income',
                    header: 'courseImport',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number' },
                { field: 'imputationName',
                    header: 'imputation',
                    headerClassName: 'p-align-end',
                    className: 'p-datatype-number' },
                { field: 'totalIncome',
                    header: 'import',
                    headerClassName: 'p-align-end',
                    className: "p-datatype-number",
                },
            ]}
            columnsFooter={[
                { footer: msg({id: 'total'}), colSpan: 2, footerStyle: { textAlign: 'left' } },
                { footer: 'sum',
                    field: 'income',
                    className: "p-datatype-number",
                },
                { footer: '',
                    field: 'imputationName',
                    className: "p-datatype-number",
                },
                { footer: 'sum',
                    field: 'totalIncome',
                    className: "p-datatype-number",
                }
            ]}
        />
    )

    return (
        <Dashboard 
            content={table}
            headerOptions={headerOptions}
        />
    )
}

export default AgroupationsTable