import React, { useCallback, useEffect, useState } from "react"
import DropdownComp from "../../common/form-components/dropdown/DropdownComp"
import StaffService from "../../../services/staff/staff.service"
import AddButton from "../../common/buttons/AddButton"

const StaffDropdown = ({
    options,
    onHandleAddStaff
}) => {

    const [ filter, setFilter ] = useState('')  
    const [ staff, setStaff ] = useState([])

    useEffect(() => {
        getAllStaf()
    }, [filter]) 

    const getAllStaf = useCallback(async () => {
        const resp = (await StaffService.getAll({filter, sort: "basic.apellidosYNombre"}))?.resp
        if (resp) {
            setStaff(resp)
        }   
    }, [filter])

    const handleAddStaff = () => {
        return onHandleAddStaff(filter, options?.rowIndex)
    }

    const addStaffButton = (
        <AddButton
            onClick={handleAddStaff}
        />
    )

    const handleFilter = async (search) => {
        const resp = (await StaffService.getAll({search}))?.resp
        setFilter(search)
        if (resp) {
            setStaff(resp)
        }   
    }

    const itemTemplateStaff = (item) => (
        <div className={item?.isPersonal ? '' : 'deactivated'}>
            {item?.basic?.apellidosYNombre}
        </div>
    )

    return (
        <DropdownComp
            options={staff}
            showClear
            value={options?.value}
            onChange={({ target: { value } }) => options.editorCallback(value)} 
            optionLabel="basic.apellidosYNombre"
            filter
            filterInputAutoFocus
            filterBy="basic.apellidosYNombre"
            onFilter={(e) => handleFilter(e.filter)}
            panelFooterTemplate={addStaffButton}
            itemTemplate={itemTemplateStaff}
        />
    )
}

export default StaffDropdown