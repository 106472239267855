import { createSlice } from '@reduxjs/toolkit'
import { ProfileService } from './profile.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'

export const getProfile = createAsyncThunkMiddleware('profile/getProfile', ProfileService.getProfile)

export const updateProfile = createAsyncThunkMiddleware('profile/update', ProfileService.updateProfile)

export const uploadImageProfile = createAsyncThunkMiddleware('profile/update', ProfileService.updateImage)

const initialState = {}

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfile.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(updateProfile.fulfilled, (state, action) => {
                return action.payload
            })
    }
})

export default profileSlice.reducer