import React from 'react'
import { useIntl } from 'react-intl'
import CollectiveAgreementsService from '../../../../services/collective-aggreements/collectiveAggreements.service'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import CardList from '../../../../components/card-list/CardList'
import { SALARY_TABLE } from '../../../../routes/maestros/maestros-laboral/maestrosLaboral.pathname'
import './salaryTables.scss'

const SalaryTables = () => {

    const { formatMessage: msg } = useIntl()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: CollectiveAgreementsService.getAll
    })

    const headerOptions = {
        title: msg({id: 'salary.tables'}),
        searchOptions: dataFacade?.searchOptions
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const goToCardLink = (id) => SALARY_TABLE(id)

    const cardList = (
        <CardList list={dataFacade?.data?.resp} gotToLink={goToCardLink}/>
    )

    return (
        <div className="salary-tables">
            <MainCommonView 
                headerOptions={headerOptions}
                pagerOptions={pagerOptions}
                componentData={cardList}
            />
        </div>
    )
}

export default SalaryTables
