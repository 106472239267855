import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import StrucutreLayout from "./StrucutreLayout"
import StageInformationTable from "./StageInformationTable"
import EtapasService from "../../../services/stages/etapas.service"
import useLoading from "../../../utils/hooks/useLoading"

const StageInformation = ({ 
    inputs, 
    updateInputs,
    propsButtons,
    disabledSaveButton,
    isLastScreen,
    isFirstScreen,
}) => {
    const { formatMessage: msg } = useIntl()

    const { loading, startLoading, stopLoading } = useLoading(true)

    const { typology } = inputs
    const [typologyStages, setTypologyStages] = useState(inputs?.educativeOffer?.etapas || [])

    const getTypologyStages = useCallback(async () => {
        startLoading()
        const typologyStagesData = getTypologyStagesData()

        if (typologyStagesData) {
            const resolvedStages = await Promise.all(typologyStagesData).then(stages => stages?.filter(Boolean))
            if (inputs?.educativeOffer?.etapas?.length > 0) {
                const updatedTypologyStages = resolvedStages?.map(stage => {
                    const foundStage = inputs?.educativeOffer?.etapas?.find(x => x?.etapa === stage?._id)
                    return foundStage ? { ...stage, ...foundStage } : stage
                })
                setTypologyStages(updatedTypologyStages)
            } else {
                setTypologyStages(resolvedStages)
            } 
        }
        stopLoading()
    }, [typology])

    useEffect(() => {
        if (typology) {
            getTypologyStages()
        }
    }, [typology, getTypologyStages])

    const getTypologyStagesData = () => {
        const typologyStagesData = typology?.etapas?.map(async stage => {
            // Substeps should only be selected for steps that are not required to be selected
            const foundStage = (await EtapasService.getFirstLevel({})).resp?.find(x => x?._id === stage?._id)
            return foundStage ? { yearFrom: stage?.yearFrom, hasBatxibac: stage?.hasBatxibac, ...foundStage } : null
        })
        return typologyStagesData
    }
    
    const updateEducatifeOffer = (newStages) => {
        updateInputs({
            ...inputs,
            educativeOffer: {
                ...inputs?.educativeOffer,
                etapas: newStages
            }
        })
    }

    const parseData = useMemo(() => (typologyStages?.length > 0 && typologyStages?.map(x => ({ 
        name: x?.name ?? x?.label,
        etapa: x?._id,
        numberLines: x?.numberLines ?? 0,
        activatedConcerted: x?.activatedConcerted ?? true,
        isSubventioned: x?.isSubventioned ?? false,
        subsidizedSubetapas: x?.subsidizedSubetapas ?? [],
        subetapas: x?.subetapas ?? x?.children ?? [],
        yearFrom: x?.yearFrom ?? 0,
        hasBatxibac: x?.hasBatxibac ?? false
        
    }))) || [], [typologyStages])

    const validate = inputs?.educativeOffer?.etapas?.some(e => e?.numberLines > 0)

    return (
        <StrucutreLayout 
            title={msg({ id: "fill.in.the.information.for.each.stage" })}
            {...propsButtons}
            isLastScreen={isLastScreen}
            isFirstScreen={isFirstScreen}
            disabledNextButton={!validate}
            disabledSaveButton={disabledSaveButton}
            context={(
                <StageInformationTable
                    loading={loading} 
                    stages={parseData}
                    updateInputs={updateEducatifeOffer}
                />
            )}
        />
    )
}

export default StageInformation
