import { maestros } from "../../../routes/maestros/maestros.pathname"
import cours from '../../../assets/imgs/maestros/curs-escolar.svg'
import school from '../../../assets/imgs/maestros/maestros-centers/maestro-escuela.svg'
import foundation from '../../../assets/imgs/maestros/maestros-foundation/maestro-fundacion.svg'
import labor from '../../../assets/imgs/maestros/maestros-labor/mestres-laboral.svg'
import maestrostemplates from '../../../assets/imgs/maestros/maestros-templates/maestros-templates.svg'
import ecofin from '../../../assets/imgs/maestros/maestros-ecofin/mestres-ecofin.svg'
import importacion from '../../../assets/imgs/maestros/importacion.svg'

export const maestrosKeys = {
    CENTER: 'schoolsMaster',
    FOUNDATION: 'foundationMaster',
    LABORAL: 'workingMaster',
    ECOFIN: 'accountantMaster'
}

export const MAESTROS = [
    {
        id: 'school.courses',
        position: 1,
        route: maestros.SCHOOLYEAR,
        src: cours,
    },
    {
        id: 'schoolsMaster',
        position: 2,
        route: maestros.MAESTROS_CENTER,
        src: school,
        key: maestrosKeys.CENTER
    },
    {
        id: 'foundationMaster',
        position: 3,
        route: maestros.MAESTROS_FOUNDATION,
        src: foundation,
        key: maestrosKeys.FOUNDATION
    },
    {
        id: 'workingMaster',
        position: 4,
        route: maestros.MAESTROS_LABOR,
        src: labor,
        key: maestrosKeys.LABORAL
    },
    {
        id: 'accountantMaster',
        position: 5,
        route: maestros.MAESTROS_ECOFIN,
        src: ecofin,
        key: maestrosKeys.ECOFIN
    },
    {
        id: 'templates.distribution.hours.school',
        position: 6,
        route: maestros.MAESTROS_TEMPLATES,
        src: maestrostemplates,
    },
    {
        id: 'maestros.templates.import',
        position: 7,
        route: maestros.MAESTROS_TEMPLATES_IMPORT,
        src: importacion,
    }
]