import React from "react"
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import Button from "../common/buttons"
import Search from "../common/search/Search"
import DropdownComp from "../common/form-components/dropdown/DropdownComp"
import './headerView.scss'

// TODO: Investigate if its possible eliminate HeaderView
// and insteads use MainCommonView in pages where HeaderView is used
const HeaderView = ({
    title,
    // props for Search
    search, 
    placeholderSearchId,
    inputText, 
    onChangeText,
    // props for button add 
    handleAdd,
    textAdd,
    iconAdd,
    handleAux, 
    textAux,
    iconAux,
    // props for button confirmed 
    isConfirmed, 
    handleConfirm,
    confirmText,
    subHeaderText,
    // props for sort
    sort,
    optionsSort,
    onChangeSort,
    sortLabel,
    // props for select
    optionsDropdown,
    dropdownValue,
    onChangeDropdown
}) => {
    const navigate = useNavigate()
    const intl = useIntl()

    return (
        <div className='container  headerView'> 
            <div className="headerView-content">
                <div className="left-aligned-part-of-header ">
                    <h2 className="title__container" >{title}</h2>

                    
                </div>

                <div className="right-aligned-part-of-header">
                    { subHeaderText && <h3 className="title__subtitle">{ subHeaderText }</h3> }
                    {
                        sort && (
                            <div className='sort'>
                                <label className='combo-label'>
                                    <FormattedMessage id={sortLabel || "order.by"}/>
                                </label>
                                <div className="dropdown">
                                    <DropdownComp
                                        id='activityType'
                                        name='activityType'
                                        optionLabel='label'
                                        optionValue="value"
                                        value={sort}
                                        options={optionsSort}
                                        filter={!sortLabel}
                                        emptyMessage={intl.formatMessage({id: 'placeholder.not.options'})}
                                        emptyFilterMessage={intl.formatMessage({id: 'placeholder.not.options'})}
                                        onChange={(e) => onChangeSort(e)}
                                        placeholder={intl.formatMessage({id: 'placeholder.select'})}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        search && (
                            <div className="search">
                                <Search 
                                    onChangeText={onChangeText}
                                    inputText={inputText}
                                    placeholder={placeholderSearchId}
                                />
                            </div>
                        )
                    }  

                    {/** Select */}
                    {
                        dropdownValue
                        && (
                            <DropdownComp 
                                id='selectOptions'
                                name='options'
                                value={dropdownValue}
                                options={optionsDropdown}
                                filter
                                emptyMessage={intl.formatMessage({id: 'placeholder.not.options'})}
                                emptyFilterMessage={intl.formatMessage({id: 'placeholder.not.options'})}
                                onChange={(e) => onChangeDropdown(e)}
                                placeholder={intl.formatMessage({id: 'placeholder.select'})}
                            />
                        ) 
                    }
                    {/* Botton back */}                    
                    <Button
                        id="gobackBtn"
                        btnText={<FormattedMessage id="button.back"/>}
                        btnType='button'
                        btnClass="button-white button-white__padding__8_15"
                        isDisabled={false}
                        onHandleClick={() => navigate(-1)}
                        iconRight
                        iconSize='0.9rem'
                        iconClass='pi-replay'
                    />
                    { /* others bottons*/ }
                    {
                        handleAdd && (
                            <Button
                                id="addNew"
                                btnText={textAdd || <FormattedMessage id="button.add"/>}
                                btnType='button'
                                btnClass="button-white button-white__padding__8_15"
                                isDisabled={false}
                                onHandleClick={() => handleAdd()}
                                iconRight
                                iconSize='1rem'
                                iconClass={iconAdd || 'pi-plus'}
                            />
                        )
                        
                    }
                    {
                        handleAux && (
                            <Button
                                id="addNew"
                                btnText={textAux || <FormattedMessage id="button.add"/>}
                                btnType='button'
                                btnClass="button-white button-white__padding__8_10"
                                isDisabled={false}
                                onHandleClick={() => handleAux()}
                                iconRight
                                iconSize='1rem'
                                iconClass={iconAux || 'pi-plus'}
                            />
                        )
                        
                    }
                    {
                        !isConfirmed && handleConfirm && (
                            <Button
                                id="confirmBtn"
                                btnText={confirmText || <FormattedMessage id="button.confirm"/>}
                                btnType='button'
                                btnClass="button-red-border btn__with-bg-type-1 btn__padding__8_15"
                                isDisabled={false}
                                onHandleClick={() => handleConfirm()} 
                                iconRight
                                iconSize='1rem'
                            />
                        )
                    }
                </div>  
            </div>
            
        </div>
    )
}

export default HeaderView