import React from 'react'
import { Link } from 'react-router-dom'
import Edit from '../../assets/imgs/editar.svg'
import Delete from '../../assets/imgs/papelera.svg'
import './card.scss'

const Card = ({ 
    item,
    isConfirm,
    linkTo,
    openEditModal,
    handleDelete 
}) => {    
    const link = linkTo(item?._id)

    return (
        <div className="box-item" key={[item?._id]}>
            <div className={isConfirm ? 'box-content complete' : 'box-content'}>
                <Link className="box-content__text red-text" to={link} disabled={!link}>
                    {item?.name }
                </Link>
                {
                    (handleDelete || openEditModal) && (
                        <div className='box-icon-action'>
                            {
                                handleDelete && (
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        onClick={() => handleDelete(item?._id)}
                                        className="button-item-box delete"
                                    >
                                        <img className="img-item" src={Delete} alt="delete" />
                                    </span>
                                )
                            }
                            {
                                openEditModal && (
                                    <span 
                                        role="button"
                                        tabIndex="0" 
                                        onClick={() => openEditModal(item)} 
                                        className="button-item-box edit">
                                        <img className="img-item" src={Edit} alt="edit" />
                                    </span>
                                )
                            }
                        </div>
                    )
                }
                
            </div>
        </div>
    )
}

export default Card
