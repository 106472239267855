import React from "react"
import { useSelector } from "react-redux"
import defaulPerfil from '../../assets/imgs/menu-icons/usuario.svg'
import env from '../../utils/constants/env-vars'

const ProfileImg = () => {

    const profile = useSelector(state => state.profile)?.resp

    return (
        <img 
            className='width__100 icon-img profile'
            src={profile?.image ? `${env.URL_IMG}${profile?.image}` : defaulPerfil}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null // prevents looping
                currentTarget.src = defaulPerfil
            }} 
            alt="perfil" />
    )

}

export default ProfileImg