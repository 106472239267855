import { haveFilterQuery, paginator, haveSort } from "../common/auxFun"


export const endpointsFoundationTeam = {
    GENERIC: '/v1/maestros/foundation/teams',
    LOAD: ({filter, limit, skip, sort}) => `/v1/maestros/foundation/teams/${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
    CONFIRM: (foundationTeamId) => `/v1/maestros/foundation/teams/${foundationTeamId}/confirm`,
    BY_FOUNDATION_TEAM: (foundationTeamId) => `/v1/maestros/foundation/teams/${foundationTeamId}`,
    BY_FOUNDATION_TEAM_BY_POST: (foundationTeamId, postId) => `/v1/maestros/foundation/teams/${foundationTeamId}/${postId}`,
    HIERARCHY: '/v1/maestros/foundation/teams/hierarchy'
}