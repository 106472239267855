const extraReducersCommon = (create, update, readAll, remove) => {
    return (builder) => {
        builder
            .addCase(readAll.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(create.fulfilled, (state, action) => {
                return { 
                    ...state, 
                    resp: [...state.resp, action.payload?.resp], 
                    total: +state?.total + 1
                }
            })
            .addCase(update.fulfilled, (state, action) => {
                return {
                    ...state,
                    resp: state.resp?.map((s) => {
                        if (s._id === action.payload?.resp?._id) return action.payload?.resp 
                        return s
                    })
                }
            })
            .addCase(remove.fulfilled, (state, action) => {
                return { 
                    ...state, 
                    resp: state?.resp.filter((s) => s._id !== action?.payload?.resp?._id),
                    total: +state?.total - 1
                }
            })
    }
}

export default extraReducersCommon