import React from "react"
import { onCellEditComplete } from "../../../../utils/helpers/data-table/common"
import CheckboxCom from "../../form-components/checkbox/CheckboxComp"

const booleanEditor = (options) => (
    <CheckboxCom
        checked={options?.value}
        onChange={({ target: { value } }) => options.editorCallback(value)}
    />
)

const editBooleanRowColumn = {
    editor: (options) => booleanEditor(options),
    onCellEditComplete, 
}


export default editBooleanRowColumn