import { POST, GET, PATCH, DELETE } from "../common/commonRequest.service"
import { endpointsFoundationTeam } from "./endpoints"

const FoundationTeamsService = {
    create: async (body) => POST(endpointsFoundationTeam.GENERIC, body), 
    getById: async (foundationTeamId) => GET(endpointsFoundationTeam.BY_FOUNDATION_TEAM(foundationTeamId)), 
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsFoundationTeam.LOAD({filter, limit, skip, sort})), 
    update: async ({ _id, body }) => PATCH(endpointsFoundationTeam.BY_FOUNDATION_TEAM(_id), body),
    confirm: async (foundationTeamId) => PATCH(endpointsFoundationTeam.CONFIRM(foundationTeamId)),
    remove: async (foundationTeamId) => DELETE(endpointsFoundationTeam.BY_FOUNDATION_TEAM(foundationTeamId)), 
    createPost: async ({foundationTeamId, body}) => POST(endpointsFoundationTeam.BY_FOUNDATION_TEAM(foundationTeamId), body),
    removePost: async ({foundationTeamId, postId}) => DELETE(endpointsFoundationTeam.BY_FOUNDATION_TEAM_BY_POST(foundationTeamId, postId)),
    getHierarchy: async () => GET(endpointsFoundationTeam.HIERARCHY)
}

export default FoundationTeamsService