import React from "react"
import { MultiSelect } from "primereact/multiselect"
import { useIntl } from "react-intl"
import "./multiSelectComp.scss"

const MultiSelectComp = ({
    value,
    name, 
    onChange, 
    options, 
    optionLabel, 
    placeholderId,
    disabled
}) => {

    const { formatMessage: msg } = useIntl()

    const selectedItems = value?.map(valueItem => 
        options?.find(option => (valueItem?._id || valueItem) === option?._id))
        .filter(option => option !== undefined)
    
    return (
        <MultiSelect
            filter
            name={name}
            value={selectedItems || []}
            onChange={(e) => onChange({ ...e, target: { ...e.target, value: e.target?.value } })}
            options={options || []}
            optionLabel={optionLabel}
            display="chip"
            placeholder={placeholderId ? msg({id: placeholderId}) : msg({id: "placeholder.select"})}
            disabled={disabled}
            pt={{
                item: ({ context }) => ({
                    className: context.selected ? 'item-active' : undefined
                })
            }}
        />
    )
}

export default MultiSelectComp