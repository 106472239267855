const GENERIC = (courseId) => `/v1/centers/courses/statistics/course/${courseId}`

export const endpointsStatistics = {
    BY_ID: (id) => GENERIC(id),
    SUMMARY_BY_ID: (courseId) => `${GENERIC(courseId)}/summary`,
    SUMMARY_BY_ETAPA: (courseId, associationId) => `${GENERIC(courseId)}/association/${associationId}/summary`,
    AGROUPATIONS_BY_COURSE_ID: (courseId) => `${GENERIC(courseId)}/associations`,
    BY_ID_SUMMARY: (courseId) => `${GENERIC(courseId)}/summary`,
    ASSOCIATIONS_BY_COURSE_ID: (courseId) => `${GENERIC(courseId)}/associations`,
    BY_COURSE_ID_AND_AGROUPATION_ID: (courseId, agroupationId) => `${GENERIC(courseId)}/association/${agroupationId}`,
    BY_COURSE_ID_AND_AGROUPATION_ID_SUMMARY: (courseId, agroupationId) => `${GENERIC(courseId)}/association/${agroupationId}`,
    BY_COURSE_ID_AND_TEAM_ID: (courseId, teamId) => `${GENERIC(courseId)}/team/${teamId}`,
    CONFIRM: (courseId, teamId) => `${GENERIC(courseId)}/team/${teamId}/confirm`,
    DISCONFIRM: (courseId, teamId) => `${GENERIC(courseId)}/team/${teamId}/disconfirm`,
}
