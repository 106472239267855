import _ from 'lodash'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../components/common/dataTable/MaestrosDataTable'
import { bodyRenderTypes } from '../../../../components/common/dataTable/body/bodyColumnsTypes'
import CenterTeamCharges from '../../../../components/maestros-center/center-teams/CenterTeamCharges'
import Modal from '../../../../components/modal/Modal'
import CenterTeamsService from '../../../../services/center-teams/centerTeams.service'
import { getAllEtapas } from '../../../../services/stages/etapas.slice'
import auxFunction from '../../../../utils/helpers/auxFun'
import editDropdownRowColumn from '../../../../components/common/dataTable/editors/editDropDownRowColumn'
import editMultiSelectRowColumn from '../../../../components/common/dataTable/editors/editMultiSelectRowColumn'
import editTextRowColumn from '../../../../components/common/dataTable/editors/editTextRowColumn'
import rowDelete from '../../../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../../../utils/helpers/data-table/column-types/rowEditor'
import useBasicLocalData from "../../../../utils/hooks/base-adapter/useBasicLocalData"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'
import { PaymentTypeItems } from '../../../../utils/constants/paymentTypes'

const CenterTeam = () => {
    const { centerTeamId } = useParams() 
    const navigate = useNavigate()
    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()

    const {inputs, updateInputs, onChangeInputs} = useInputs()
    const { isShowing, toggle } = useModal()

    const getTeams = () => CenterTeamsService.getDataByTeamId({ centerTeamId })
    const createTeam = (body) => CenterTeamsService.createCenterTeamCharge({ centerTeamId, body })
    const confirmTeams = () => CenterTeamsService.confirmCenterTeam(centerTeamId)

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'post',
        getAll: getTeams,
        createAction: createTeam,
        removeAction: CenterTeamsService.deleteCenterTeamCharge, 
        editAction: CenterTeamsService.updateCenterTeamCharge
    })

    const localPagingAndFiltering = useBasicLocalData({
        allData: dataFacade?.data?.resp?.posts,
        rowsPerPageInitial: 10,
        sortFieldInitial: 'post',
        fieldFilter: 'post',
        translate: false
    })

    const stages = useSelector(state => state?.etapas)
    const getStages = () => { dispatch(getAllEtapas({})) }
    
    useEffect(getStages, [])

    const handleCreateCharge = async () => {
        const body = { ...inputs, etapas: auxFunction.returnIds(inputs?.etapas) }
        dataFacade.create(body)
        updateInputs({})
        toggle()        
    }

    const handleConfirmCenterTeam = async () => {
        await confirmTeams()
        navigate(-1)
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const onRowEditComplete = async ({newData}) => {
        const data = _.omit(newData, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const etapas = auxFunction.returnIds(data?.etapas)
        const body = { ...data, etapas }
        dataFacade.edit({ _id: newData?._id, body })
    }

    const headerOptions = {
        title: msg({id: 'jobTitle'}),
        titleSuffix: dataFacade?.data?.resp?.name,
        handlerHeaderActions: {
            firstHandlerAction: toggle,
            confirmHandlerAction: handleConfirmCenterTeam,
            isConfirmed: dataFacade?.data?.resp?.confirmation?.isConfirm
        },
        searchOptions: localPagingAndFiltering?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'jobTitle',
        titleSuffix: dataFacade?.data?.resp?.name,
        columns: [
            { title: msg({id: 'name'}), dataKey: 'post' },
            { title: msg({id: 'stages'}), dataKey: 'etapas' },
        ],
        exportService: async () => {
            const resp = (await getTeams())?.resp?.posts

            return { resp: auxFunction.flattenArrayProperty(resp, 'etapas', 'name')}
        }
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: localPagingAndFiltering?.data,
                editMode: 'row',
                onRowEditComplete,
                ...localPagingAndFiltering?.sortOptions
            }}
            columns={[
                { field: 'post',
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                { field: 'etapas',
                    header: 'stages',
                    className: 'items-column',
                    body: (rowData) => bodyRenderTypes(rowData?.etapas),
                    ...editMultiSelectRowColumn({options: stages?.resp, optionValue: '_id', optionLabel: 'name'})
                },
                dataFacade?.data?.resp?.isManagerial && (
                    { field: 'paymentType',
                        header: 'payment.type',
                        ...editDropdownRowColumn({
                            options: PaymentTypeItems || [],
                            name: 'paymentType',
                            optionValue: '_id',
                            optionLabel: 'name'
                        }),
                        body: (rowData) => msg({id: rowData?.paymentType})
                    }
                ),
                {...rowDelete({ handleDelete: dataFacade?.remove })},
                {...rowEditor()},
            ]}
        />
    )

    return (
        <div className='center-team-container'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={localPagingAndFiltering?.pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="500px"
                title={<FormattedMessage id='charge.new'/>}
                context={(
                    <CenterTeamCharges
                        inputs={inputs} 
                        handleSave={handleCreateCharge}
                        handleChange={onChangeInputs}
                        isManagerial={dataFacade?.data?.resp?.isManagerial}
                    />
                )}
            />
            
        </div>
    )
}

export default CenterTeam