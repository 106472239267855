import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import MainCommonView from "../../../../../components/common/MainCommonView"
import MaestrosDataTable from '../../../../../components/common/dataTable/MaestrosDataTable'
import { bodyRenderTypes } from '../../../../../components/common/dataTable/body/bodyColumnsTypes'
import editTreeSelectRowColumn from '../../../../../components/common/dataTable/editors/editTreeSelectRowColumn'
import FoundationAccountPlanService from '../../../../../services/foundation-account-plan/foundationAccountPlan.service'
import FoundationTeamsService from '../../../../../services/foundation-teams/foundationTeams.service'
import { getAll as getAllImputation } from '../../../../../services/imputation/imputation.slice'
import rowEditor from '../../../../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from "../../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import './foundationAccountPlan.scss'

const FoundationAccountPlan = () => {

    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: FoundationAccountPlanService.getAll,
        editAction: FoundationAccountPlanService.update
    })

    const [nodes, setNodes] = useState(null)
    const getAllImp = () => dispatch(getAllImputation({ }))

    useEffect(() => {
        getAllImp()
        getHierarchy()
    }, [])

    const getHierarchy = async () => {
        const resp = await FoundationTeamsService.getHierarchy()
        if (resp) {
            setNodes(resp?.resp)
        }
    }

    const onRowEditComplete = async ({ newData }) => {
        const getTeamsSelected = () => {
            if (_.isArray(newData?.teams) && newData?.teams?.length > 0) {
                return newData?.teams?.map(team => team?._id)
            }
            return Object.keys(newData?.teams)?.map(teams => teams)
        }

        const body = {
            imputation: newData.imputation?._id || newData?.imputation,
            teams: getTeamsSelected() 
        }
        const { _id } = newData

        dataFacade.edit({_id, body})
    }

    const headerOptions = {
        title: msg({id: 'accounting.plan.foundation'}),
        searchOptions: dataFacade?.searchOptions
    }

    const exportService = async () => {
        let resp = (await FoundationAccountPlanService.getAll({}))?.resp

        resp = resp.map(r => { r.description = `${r?.account?.description} - ${r?.subaccount?.description}`; return r })
        resp = resp.map(r => { 
            r.teams = r?.teams && r?.teams?.length > 0 
                ? r?.teams?.map(t => t?.name || t?.subaccount || t)
                : msg({id: 'empty'})
            return r 
        })

        return { resp }
    }

    const importExportFilesOptions = {
        title: 'accounting.plan.foundation',
        columns: [
            { title: msg({id: 'account'}), dataKey: 'numAccount' },
            { title: msg({id: 'subaccount'}), dataKey: 'numSubaccount' },
            { title: msg({id: 'type'}), dataKey: 'description' }, 
            { title: msg({id: 'accounting.foundation.cost.center'}), dataKey: 'teams' },
        ],
        exportService
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                sortField: 'numAccount',
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={[
                {
                    field: 'numAccount',
                    sortable: true,
                    frozoen: true,
                    header: 'account',
                    body: (rowData) => rowData?.numAccount
                },
                {
                    field: 'description',
                    header: 'type.description',
                    body: (rowData) => rowData?.account?.description
                },
                {
                    field: 'numSubaccount',
                    sortable: true,
                    frozoen: true,
                    header: 'subaccount',
                    body: (rowData) => rowData?.numSubaccount
                },
                {
                    field: 'subaccount',
                    header: 'subaccount',
                    body: (rowData) => rowData?.subaccount?.description
                },
                {
                    field: 'teams',
                    header: 'accounting.foundation.cost.center',
                    body: (rowData) => bodyRenderTypes(rowData?.teams),
                    ...editTreeSelectRowColumn({options: nodes})
                },
                {...rowEditor()}
            ]}
        />     
    )

    return (
        <div className='foundation-account-plan'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />
        </div>
    )
}

export default FoundationAccountPlan
