import { endpointsManagementPositions } from "./endpoints"
import { POST, GET, DELETE, PATCH } from '../common/commonRequest.service'

const ManagementPositionsService = {
    create: async (body) => POST(endpointsManagementPositions.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsManagementPositions.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsManagementPositions.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsManagementPositions.BY_ID(id))
}

export default ManagementPositionsService