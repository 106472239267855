import { CENTER } from "../pathname"

export const CENTER_COURSE = (centerId, courseId) => CENTER(centerId) + `/course/${courseId}`

export const CENTER_COURSE_DETAILS = {
    DATA: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/center',


    STRUCTURE: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/structure',


    STATISTICS: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/statistics', 
    STATISTICS_TEAMS_BY_AGROUPATION: (centerId, courseId, agroupationId) => CENTER_COURSE(centerId, courseId) + `/statistics/${agroupationId}`,
    STATISTICS_BY_TEAM_ID: (centerId, courseId, agroupationId, teamId) => CENTER_COURSE(centerId, courseId) + `/statistics/${agroupationId}/team/${teamId}`, 


    EDUCATION_OFFER: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/educationOffer',
    EDUCATION_OFFER_BY_ID: (centerId, courseId, educationOfferId) => CENTER_COURSE(centerId, courseId) + `/educationOffer/${educationOfferId}`,
    EDUCATION_SERVICE_BY_ID: (centerId, courseId, educationOfferId) => CENTER_COURSE(centerId, courseId) + `/educationOffer/${educationOfferId}/service`,
    EDUCATION_OFFER_BY_ID_CLASS: ((centerId, courseId, educationOfferId) => CENTER_COURSE(centerId, courseId) + `/educationOffer/${educationOfferId}/classroomAndStudents`),
    EDUCATION_OFFER_BY_ID_NEE: ((centerId, courseId, educationOfferId) => CENTER_COURSE(centerId, courseId) + `/educationOffer/${educationOfferId}/nee`),
    EDUCATION_OFFER_BY_ID_SERVICES: ((centerId, courseId, educationOfferId, service) => CENTER_COURSE(centerId, courseId) + `/educationOffer/${educationOfferId}/service/${service}`),


    DISTRIBUTION_HOURS_AND_STAFF: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/distributionHours',
    DISTRIBUTION_HOURS_BY_ID_CALC: (centerId, courseId, distributionHoursId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/${distributionHoursId}/calculHours`,
    DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION: (centerId, courseId, distributionHoursId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/${distributionHoursId}/distribution`,
    DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION_FOUNDATION: (centerId, courseId, distributionHoursId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/${distributionHoursId}/distribution/foundation`,
    DISTRIBUTION_HOURS_BY_ID_DISTRIBUTION_SCHOOL: (centerId, courseId, distributionHoursId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/${distributionHoursId}/distribution/school`,
    DISTRIBUTION_HOURS_STAFF: (centerId, courseId, distributionHoursId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/${distributionHoursId}/distribution/staff`,
    DISTRIBUTION_HOURS_BY_ID: (centerId, courseId, distributionHoursId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/${distributionHoursId}`,
    DISTRIBUTION_HOURS__AND_STAFF_BY_ID: (centerId, courseId, staffId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/${staffId}`,


    DISTRIBUTION_HOURS_PAS: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/pas`,
    DISTRIBUTION_HOURS_PAS_BY_ID: (centerId, courseId, pasId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/pas/${pasId}`,
    DISITRIBUTION_HOURS_PAS_BY_ID_TABLE: (centerId, courseId, pasId) => CENTER_COURSE(centerId, courseId) + `/distributionHours/pas/${pasId}/table`,
    
    
    BUGET: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget',
    BUGET_EXPENSES: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/expenses',
    
    BUDGET_EXPENSES_SUPPLIES: (centerId, courseId, supplyId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/supplies/${supplyId}`,
    BUDGET_EXPENSES_SUPPLIES_DATA: (centerId, courseId, supplyId, balanceId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/supplies/${supplyId}/balance/${balanceId}`,

    BUDGET_EXPENSES_SUPPLIES_DATA_TOTALS: (centerId, courseId, supplyId, balanceId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/supplies/${supplyId}/balance/${balanceId}/totals`,
    BUDGET_EXPENSES_SUPPLIES_DATA_AGROUPATIONS: (centerId, courseId, supplyId, balanceId, etapaId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/supplies/${supplyId}/balance/${balanceId}/etapa/${etapaId}`,

    BUDGET_EXPENSES_SUPPLIES_TOTALS_TABLE: (centerId, courseId, supplyId, balanceId, accountId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/supplies/${supplyId}/balance/${balanceId}/totals/${accountId}`,
    BUDGET_EXPENSES_SUPPLIES_TOTALS_TABLE_AGRUPATIONS: (centerId, courseId, supplyId, balanceId, accountId, etapaId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/supplies/${supplyId}/balance/${balanceId}/etapa/${etapaId}/${accountId}`,

    BUDGET_EXPENSES_STAFFS: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/staffs`,
    BUDGET_EXPENSES_STAFFS_ETAPA_TABLE: (centerId, courseId, etapaId) => CENTER_COURSE(centerId, courseId) + `/budget/expenses/staffs/etapa/${etapaId}`,
    
    
    BUGET_INCOMES: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/incomes',
    BUDGET_INCOMES_BALANCES: (centerId, courseId, balanceId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/balances/${balanceId}`,
    BUDGET_INCOMES_CONCEPTS: (centerId, courseId, balanceId, conceptId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/balances/${balanceId}/concepts/${conceptId}`,
    BUDGET_INCOMES_DATA: (centerId, courseId, balanceId, conceptId, accountId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/balances/${balanceId}/concepts/${conceptId}/accounts/${accountId}`,
    BUDGET_INCOMES_DATA_TABLE_TOTALS: (centerId, courseId, balanceId, conceptId, accountId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/balances/${balanceId}/concepts/${conceptId}/accounts/${accountId}/data`,
    BUDGET_INCOMES_DATA_TABLE_AGROUPATIONS: (centerId, courseId, balanceId, conceptId, accountId, agroupationId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/balances/${balanceId}/concepts/${conceptId}/accounts/${accountId}/agroupations/${agroupationId}`,
    
    BUDGET_INCOMES_ACTIVITIES: (centerId, courseId, activityId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}`,
    
    BUDGET_INCOMES_ACTIVITIES_FEES: (centerId, courseId, activityId, feesId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}/fees/${feesId}`,
    BUDGET_INCOMES_ACTIVITIES_FEES_DATA: (centerId, courseId, activityId, feesId, etapaId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}/fees/${feesId}/etapa/${etapaId}`,

    BUDGET_INCOMES_ACTIVITIES_FEES_SERVICES_TABLE: (centerId, courseId, activityId, feesId, etapaId, serviceId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}/fees/${feesId}/etapa/${etapaId}/services/${serviceId}/data`,
    BUDGET_INCOMES_ACTIVITIES_FEES_SERVICES_GENERAL_TABLE: (centerId, courseId, activityId, feesId, etapaId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}/fees/${feesId}/etapa/${etapaId}/general`,
    BUDGET_INCOMES_ACTIVITIES_FEES_SERVICES_SERVICES_TABLE: (centerId, courseId, activityId, feesId, otherServiceId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}/fees/${feesId}/service/${otherServiceId}`,

    BUDGET_INCOMES_ACTIVITIES_SUBVENTIONS: (centerId, courseId, activityId, subventionId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}/subventions/${subventionId}`,
    BUDGET_INCOMES_ACTIVITIES_SUBVENTIONS_TABLE: (centerId, courseId, activityId, subventionId, etapaId) => CENTER_COURSE(centerId, courseId) + `/budget/incomes/activities/${activityId}/subventions/${subventionId}/etapa/${etapaId}`,

    BUGET_RESULTS: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results',
    BUDGET_RESULTS_VERTICAL: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/vertical',
    BUDGET_RESULTS_VERTICAL_EXPENSES: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/vertical/expenses',
    BUDGET_RESULTS_VERTICAL_INCOMES: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/vertical/incomes',
    BUDGET_RESULTS_VERTICAL_TOTALS: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/vertical/totals',

    BUDGET_RESULTS_HORIZONTAL: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/horizontal',
    BUDGET_RESULTS_HORIZONTAL_EXPENSES: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/horizontal/expenses',
    BUDGET_RESULTS_HORIZONTAL_INCOMES: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/horizontal/incomes',
    BUDGET_RESULTS_HORIZONTAL_TOTALS: (centerId, courseId) => CENTER_COURSE(centerId, courseId) + '/budget/results/horizontal/totals'
}