import { endpointsStadiums } from "./endpoints"
import { POST, GET, DELETE, PATCH } from '../common/commonRequest.service'

const StadiumsService = {
    create: async (body) => POST(endpointsStadiums.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsStadiums.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsStadiums.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsStadiums.BY_ID(id))
}

export default StadiumsService