import { endpointsContractType } from './endpoints'
import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"

const ContractTypeService = {
    create: async (body) => POST(endpointsContractType.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort}) => GET(endpointsContractType.LOAD({filter, limit, skip, sort})),
    update: async ({ _id, body }) => PATCH(endpointsContractType.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsContractType.BY_ID(id)),
}

export default ContractTypeService
