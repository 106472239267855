import { POST, GET, PATCH, DELETE } from "../common/commonRequest.service"
import { endpointsSubsidyQuantification } from "./endpoints"

const SubsidyQuantificationService = {
    create: async (body) => POST(endpointsSubsidyQuantification.GENERIC, body),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsSubsidyQuantification.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsSubsidyQuantification.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsSubsidyQuantification.BY_ID(id))
}

export default SubsidyQuantificationService