import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsFees } from "./endpoints"

const FeeServices = {
    getAll: async ({id, filter, limit, skip, sort}) => GET(endpointsFees.GET_ALL({id, filter, limit, skip, sort})),
    create: async (id, body) => POST(endpointsFees.FEE(id), body),
    update: async (id, body) => PATCH(endpointsFees.FEE(id), body),
    remove: async (neeId) => DELETE(endpointsFees.FEE(neeId))
}

export default FeeServices