import { createSlice } from '@reduxjs/toolkit'
import FoundationAccountPlanService from './foundationAccountPlan.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('foundationAccountPlan/create', FoundationAccountPlanService.create)

export const getAll = createAsyncThunkMiddleware('foundationAccountPlan/getAll', FoundationAccountPlanService.getAll)

export const update = createAsyncThunkMiddleware('foundationAccountPlan/update', FoundationAccountPlanService.update)

export const remove = createAsyncThunkMiddleware('foundationAccountPlan/delete', FoundationAccountPlanService.remove)

const initialState = []

const foundationAccountPlanSlice = createSlice({
    name: 'foundationAccountPlans',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default foundationAccountPlanSlice.reducer