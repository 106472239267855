import React, { forwardRef } from "react"
import { useIntl } from "react-intl"
import { FileUpload } from "primereact/fileupload"
import { ControlLabel } from "../label/Label"
import "./labeledFile.scss"

const LabeledFile = forwardRef((props, ref) => {
    const { 
        idLabel,
        labelValues,
        name,
        required,
        accept,
        multiple,
        labelButton,
        maxFileSize, 
        onSelect,
    } = props
    
    const { formatMessage: msg } = useIntl()

    return (
        <div className="flex-container">
            <ControlLabel id={idLabel} labelValues={labelValues} required={required}/>
            <div className="control-input">
                <FileUpload
                    name={name}
                    mode="basic"
                    accept={accept}
                    multiple={multiple}
                    maxFileSize={maxFileSize || 10000000}
                    chooseLabel={msg({id: labelButton || 'upload'})}
                    onSelect={(e) => onSelect({files: multiple ? e?.files : e?.files[0] })}
                    ref={ref}
                />   
            </div>
        </div>
    )
})

export default LabeledFile
