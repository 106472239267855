import { DELETE, GET, PATCH, POST } from "../../common/commonRequest.service";
import { endpointsTemplatesBalance } from "./endpoints";


const TemplatesBalanceService = {
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsTemplatesBalance.GENERIC({ filter, limit, skip, sort })),
    getById: async (id) => GET(endpointsTemplatesBalance.BY_ID(id)),
    
    create: async (body) => POST(endpointsTemplatesBalance.GENERIC({}), body),
    update: async (id, body) => PATCH(endpointsTemplatesBalance.BY_ID(id), body),
    delete: async (id) => DELETE(endpointsTemplatesBalance.BY_ID(id)),
    
    createConcept: async (id, body) => POST(endpointsTemplatesBalance.CONCEPT(id), body),
    updateConcept: async ({ _id, body }) => PATCH(endpointsTemplatesBalance.CONCEPT(_id), body),
    removeConcept: async (id) => DELETE(endpointsTemplatesBalance.CONCEPT_BY_ID(id))
}

export default TemplatesBalanceService