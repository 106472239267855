import React from 'react'
import { useSelector } from 'react-redux'
import { validators } from '../../../utils/helpers/validators'
import LabeledCheckbox from '../../common/form-components/labeled-checkbox/LabeledCheckbox'
import LabeledMultiSelect from '../../common/form-components/labeled-dropdown/LabeledMultiSelect'
import LabeledDouble from '../../common/form-components/labeled-inputs/LabeledDouble'
import SaveButton from '../../common/buttons/SaveButton'
import "./templateEtapaHoursForm.scss"

const TemplateEtapaHoursForm = ({ 
    inputs, 
    handleChange,
    handleSave
}) => {

    const alletapas = useSelector((state) => state?.etapas)

    const validateForm = validators.isEntityValues([inputs?.etapas, inputs?.coeficientNonTeachingHours]) 

    return (
        <div className="form-staff-hours">
            <div className="modal-content-wrapper">
                <LabeledMultiSelect 
                    idLabel='stages'
                    name='etapas'
                    value={inputs?.etapas} 
                    onChange={handleChange}
                    options={alletapas?.resp}
                    optionLabel="name"
                    optionValue="_id"
                />
                <div className='control-wrapper'>
                    <LabeledDouble 
                        idLabel='not.teaching.hours.coefficient'
                        required
                        name='coeficientNonTeachingHours'
                        value={inputs?.coeficientNonTeachingHours}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledCheckbox 
                        idLabel='otherEndowment'
                        name='otherEndowment'
                        checked={inputs?.otherEndowment}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
            </div>

            <div className="modal-btn-container actions-wrapper">
                <SaveButton
                    onClick={() => handleSave(inputs?._id)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default TemplateEtapaHoursForm
