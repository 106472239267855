import React from "react"
import { useIntl } from 'react-intl'
import MainCommonView from '../../../../../components/common/MainCommonView'
import useBasicLocalData from '../../../../../utils/hooks/base-adapter/useBasicLocalData'
import MaestrosList from "../../../../../components/maestros/MaestrosList"
import { MAESTROS_ACCOUNTING_PLAN_CENTER } from "../../../../../utils/constants/maestros/maestrosCenters"

const MaestrosAccountingPlan = () => {

    const { formatMessage: msg } = useIntl()

    const dataFacade = useBasicLocalData({
        sortFieldInitial: 'order',
        allData: MAESTROS_ACCOUNTING_PLAN_CENTER,
        rowsPerPageInitial: 10,
    })

    const headerOptions = {
        title: msg({id: 'maestros.accounting.plan'}),
        searchOptions: dataFacade?.searchOptions
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: MAESTROS_ACCOUNTING_PLAN_CENTER.length
    }

    return (
        <MainCommonView 
            componentData={(
                <MaestrosList 
                    maestroslist={dataFacade.data}
                />
            )}
            headerOptions={headerOptions}
            pagerOptions={pagerOptions}
        />
    )
}

export default MaestrosAccountingPlan