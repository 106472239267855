import React from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { Card } from 'primereact/card'
import DistributionHoursService from "../../../services/centers/courses/distribution-hours/ditributionHours.service"
import { validators } from "../../../utils/helpers/validators"
import useInputs from "../../../utils/hooks/useInputs"
import SaveButton from "../../common/buttons/SaveButton"
import LabeledTextArea from "../../common/form-components/labeled-inputs/LabeledTextArea"

const JustifyContent = ({
    distributionHoursData
}) => {
    
    const { courseId } = useParams()
    const { inputs, onChangeInputs } = useInputs()

    const { formatMessage: msg } = useIntl()

    const handleSend = async () => {
        await DistributionHoursService.justify(courseId, {justification: inputs?.proof})
    }

    const disableSaveButton = validators.isEntityValues([inputs?.proof])

    return (
        <>
            <>
                <Card>
                    {msg({id: 'hours.distribute.total'}).toUpperCase()}: {distributionHoursData?.totalHoursToDistribute}
                </Card>
                <Card>
                    {msg({id: 'hours.assigned.total'}).toUpperCase()}: {distributionHoursData?.totalHoursAssigned}
                </Card>
                <Card>
                    {msg({id: 'hours.distribute.pending.total'}).toUpperCase()}: {distributionHoursData?.totalHoursToDistribute 
                                                                                - distributionHoursData?.totalHoursAssigned}
                </Card>
            </>
            <LabeledTextArea
                idLabel='proof'
                required
                name='proof'
                value={inputs?.description}
                onChange={(e) => onChangeInputs(e)}
                numRows={3}
                numColumns={15} 
            />
            <SaveButton 
                onClick={handleSend} 
                isDisabled={!disableSaveButton}
            />
        </>
    )
}

export default JustifyContent