import React from "react"
import { backoffice, BACKOFFICE } from "./backoffice.pathname"
import Maestros from '../views/maestros/Maestros'
import Centers from '../views/centers/Centers'
import Users from '../views/users/Users'
import BackOffice from "../views/backoffice/BackOffice"
import ProcessMap from "../views/processmap/ProcessMap"

export const backcofficeRoutes = [
    {
        path: BACKOFFICE,
        name: 'backoffice',
        element: <BackOffice />,
    },
    {
        path: backoffice.CENTERS,
        name: 'centers',
        element: <Centers />,
    },
    {
        path: backoffice.MAESTROS,
        name: 'maestros',
        element: <Maestros />,
    },
    {
        path: backoffice.USERS,
        name: 'users',
        element: <Users />,
    },
    {
        path: backoffice.PROCESSMAP,
        name: 'processmap',
        element: <ProcessMap />,
    }
]