import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import CardsOverview from "../../../../../components/common-courses/view-content/CardsOverview"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import BudgetService from "../../../../../services/centers/courses/budgets/budgets.service"
import useLoading from "../../../../../utils/hooks/useLoading"

const Incomes = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId} = useParams()
    
    const [incomes, setIncomes] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getIncomes()
    }, [])

    const getIncomes = async () => {
        startLoading()
        const resp = await BudgetService.getIncomes(courseId)
        if (resp) {
            setIncomes(resp?.resp)
        }
        stopLoading()
    }

    const goToList = (id, item) => {
        if (item?.source === 'activity') {
            return CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES(centerId, courseId, item?.balanceTemplate)
        }
        return CENTER_COURSE_DETAILS.BUDGET_INCOMES_BALANCES(centerId, courseId, item?.balanceTemplate)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' })
        }
    ]

    const incomesCards = [
        ...(incomes?.activities || []).map(item => ({
            ...item,
            name: item?.templateName,
            source: 'activity'
        })),
        ...(incomes?.others || []).map(item => ({
            ...item,
            name: item?.templateName,
            source: 'others'
        })),
    ]

    const cardList = {
        list: incomesCards,
        gotToLink: goToList
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default Incomes