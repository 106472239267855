import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { privateRoutes } from '../../../routes/common/private.routes'
import { maestrosCentersRoutes } from '../../../routes/maestros/maestros-centers/maestrosCenters.routes'
import { maestrosRoutes } from '../../../routes/maestros/maestros.routes'
import { backcofficeRoutes } from '../../../routes/backoffice.routes'
import { maestrosEcoFinRoutes } from '../../../routes/maestros/maestros-ecofin/maestrosEcoFin.routes'
import { maestrosFoundationRoutes } from '../../../routes/maestros/maestros-foundation/maestrosFoundation.routes'
import { maestrosLaboralRoutes } from '../../../routes/maestros/maestros-laboral/maestrosLaboral.routes'
import { maestrosTemplatesRoutes } from '../../../routes/maestros/maestros-templates/maestrosTemplates.routes'
import { schoolYearRoutes } from '../../../routes/maestros/school-year/schoolYear.routes'

import { getProfile } from '../../../services/users/profile.slice'
import { foundationRoutes } from '../../../routes/foundation/foudation.routes'
import FoundationMainCourses from '../../foundation/FoundationMainCourses'

const PrivateRoutes = () => {

    const dispatch = useDispatch()

    const getProfileData = () => dispatch(getProfile())

    useEffect(() => {
        getProfileData()
    }, [])

    const elements = [
        ...privateRoutes,
        ...backcofficeRoutes,
        ...maestrosCentersRoutes,
        ...maestrosRoutes,
        ...maestrosEcoFinRoutes,
        ...maestrosFoundationRoutes,
        ...maestrosLaboralRoutes,
        ...maestrosTemplatesRoutes,
        ...schoolYearRoutes
    ]
    
    return (
        <Routes>
            {elements.map(({ path, element, name }) => (
                <Route key={name} path={path} element={element} />
            ))}
           
            {foundationRoutes.map(({ path, element, name }) => (
                <Route key={name} path={path} element={(
                    <>
                        <FoundationMainCourses/>
                        {element}
                    </>
                )} />
                
            ))}
        </Routes>
    )
}

export default PrivateRoutes
