import React, { useEffect, useState } from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import { CenterServices } from '../../../services/center-services/centerServices.service'
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import './formPas.scss'

const FormNee = ({
    inputs, 
    handleChange,
    handleSubmit 
}) => {

    const [services, setServices] = useState([])

    useEffect(() => {
        getServices()
    }, [])

    const getServices = async () => {
        const resp = await CenterServices.getAllServices({})
        if (resp) {
            setServices(resp?.resp)
        }
    }

    const validateForm = validators.isEntityValues([inputs?.name])

    return (
        <div className='form-pas'>
            <div className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledTextArea 
                        idLabel='description'
                        name='description'
                        value={inputs?.description}
                        onChange={(e) => handleChange(e)}
                        numRows={3}
                        numColumns={15}
                    />
                </div>
                <LabeledDropdown 
                    idLabel='coveredService'
                    name='coveredService'
                    value={inputs?.coveredService?._id || inputs?.coveredService}
                    optionLabel='name'
                    optionValue='_id'
                    options={services || []}
                    onChange={(e) => handleChange(e)}
                />
            </div>
            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={() => handleSubmit(inputs?._id)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormNee