import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import HeaderView from '../../components/header-view/HeaderView'
import Modal from '../../components/modal/Modal'
import useModal from '../../utils/hooks/useModal'
import ProfileForm from '../../components/profile/ProfileForm'
import edit from '../../assets/imgs/editar.svg'
import UploadImage from '../../components/upload/UploadImage'
import { updateProfile, uploadImageProfile } from '../../services/users/profile.slice'
import ProfileImg from '../../components/profile/ProfileImg'
import LabeledText from '../../components/common/form-components/labeled-inputs/LabeledText'
import "./profile.scss"
import MainCommonView from '../../components/common/MainCommonView'
import Search from '../../components/common/search/Search'

const Profile = () => {
    const dispatch = useDispatch()

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()

    const profile = useSelector(state => state.profile)?.resp
    
    const update = (body) => dispatch(updateProfile(body))

    const handleUpdateProfile = async (data) => {
        await update(data)
        toggle()
    }

    const headerOptions = {
        title: msg({id: 'profile'}),
        searchOptions: false
    }

    const profileContent = (
        <div className='profile-auth container__with-background'>
            <div className='profile-auth-wrapper container'>
                <div className='center-data__container--actions'>
                    <span 
                        role='button' 
                        tabIndex='0'
                        className="edit-icon" 
                        onClick={() => toggle()} 
                    >
                        <img src={edit} alt='edit' />
                    </span>
                </div>

                <div className='profile-auth-wrapper-info'>
                    <div className='profile-auth-wrapper-upload'>
                        <div className='profile-img '>
                            <ProfileImg />
                        </div>
                        <div className='upload-btn'>
                            <UploadImage 
                                handleUpdateImage={(image) => dispatch(uploadImageProfile(image))}
                            />
                        </div>
                        
                    </div>
                    
                    <div className='data-profile'>
                        <LabeledText 
                            idLabel='name'
                            name='name'
                            value={profile?.name}
                            disabled
                        />
                      
                        <LabeledText
                            idLabel='email'
                            name='email'
                            value={profile?.email}
                            disabled 
                        />
                  
                        <LabeledText 
                            idLabel='lang'
                            name='lang'
                            value={profile?.lang}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <Modal 
                isShowing={isShowing}
                hide={toggle}
                width='100%'
                maxWidth='750px'
                title={msg({id: 'profile.edit'})}
                context={(
                    <ProfileForm
                        data={profile}
                        handleUpdateProfile={handleUpdateProfile}
                        isReadOnly={false}
                    />
                )}
            />

        </div>
    )


    return (
        <MainCommonView 
            headerOptions={headerOptions}
            componentData={profileContent}
        />
    )

}

export default Profile