import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import swalAlert from '../../../../utils/helpers/swalAlert'
import FoundationTeamsService from '../../../../services/foundation-teams/foundationTeams.service'
import { confirm } from '../../../../services/foundation-teams/foundationTeams.slice'
import Posts from '../../../../components/maestros-foundation/foundation-teams/Posts'
import useModal from '../../../../utils/hooks/useModal'
import Modal from '../../../../components/modal/Modal'
import FormPost from '../../../../components/maestros-foundation/foundation-teams/FormPost'
import FoundationTeamForm from '../../../../components/maestros-foundation/foundation-teams/FoundationTeamForm'
import CardList from '../../../../components/card-list/CardList'
import { maestrosFoundation } from '../../../../routes/maestros/maestros-foundation/maestrosFoundation.pathname'
import HeaderView from '../../../../components/header-view/HeaderView'
import Legend from '../../../../components/common/legend/Legend'
import './foundationTeam.scss'

const FoundationTeam = () => {
    const { teamId } = useParams()

    const initialStateChildren = {
        name: '',
        description: '',
        parent: teamId
    }
    const initialStateCharge = {
        name: ''
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const intl = useIntl()

    const {isShowing, toggle} = useModal()
    const {isShowing: isShowingEdit, toggle: toggleEdit} = useModal()
    const {isShowing: isShowingAdd, toggle: toggleAdd} = useModal()

    const [team, setTeam] = useState({})
    const [children, setChildren] = useState([])
    const [inputsTeam, setInputsTeam] = useState(initialStateChildren)
    const [inputsCharge, setInputsCharge] = useState(initialStateCharge)

    const confirmTeam = (confirmteamId) => dispatch(confirm(confirmteamId))
    
    useEffect(() => {
        getById()
    }, [teamId])

    const getById = async () => {
        const resp = await FoundationTeamsService.getById(teamId)
        if (resp) {
            setTeam(resp?.resp?.foundationTeam)
            setChildren(resp?.resp?.childrenFoundationTeam)
        }
    }

    const onInputChargeChange = ({target: {value, name}}) => { 
        setInputsCharge({...inputsCharge, [name]: value}) 
    }

    const onInputTeamChange = ({target: {value, name}}) => { 
        setInputsTeam({...inputsTeam, [name]: value}) 
    }

    const handleConfirm = async () => {
        const confirmation = await swalAlert.warning(
            intl.formatMessage({ id: 'swal.title.confirm' }) + team?.name + '?',
            intl.formatMessage({ id: 'button.cancel' }),
            intl.formatMessage({ id: 'button.confirm' })
        )
        if (confirmation) {
            confirmTeam(teamId)
            navigate(-1)
        }
    }

    const handleUpdateChildren = async (foundationTeamId) => {
        const body = {
            name: inputsTeam?.name,
            description: inputsTeam?.description
        }
        await FoundationTeamsService.update({_id: foundationTeamId, body})
        setInputsTeam(initialStateChildren)
        getById()
        toggleEdit()
    }

    const handleDeleteTeam = async (childrenId) => {
        const confirmation = await swalAlert.warning(
            intl.formatMessage({ id: 'swal.title.delete' }),
            intl.formatMessage({ id: 'button.cancel' }),
            intl.formatMessage({ id: 'button.delete' })
        )
        if (confirmation) {
            await FoundationTeamsService.remove(childrenId)
            getById()
        }
    }

    const handleCreateChildren = async () => {
        await FoundationTeamsService.create(inputsTeam)
        setInputsTeam(initialStateChildren)
        getById()
        toggleAdd()
    }

    const handleCreateCharager = async () => {
        await FoundationTeamsService.createPost({ foundationTeamId: teamId, body: inputsCharge })
        setInputsCharge(initialStateCharge)
        getById()
        toggle()
    }

    const handleDeleteCharge = async (postId) => {
        const confirmation = await swalAlert.warning( 
            intl.formatMessage({id: "swal.title.delete"}),
            intl.formatMessage({id: "button.cancel"}),
            intl.formatMessage({id: "button.delete"})
        )
        if (confirmation) {
            await FoundationTeamsService.removePost({foundationTeamId: teamId, postId})
            getById()
        }
    }

    // others 
    const closeTeamModal = () => {
        setInputsTeam({})
        toggle()
    }

    const closeEditChildrenModal = () => {
        setInputsTeam(initialStateChildren)
        toggleEdit()
    }

    const openEditModalChildren = (childrenTeam) => {
        setInputsTeam(childrenTeam)
        toggleEdit()
    }

    const goToPath = (id) => {
        return maestrosFoundation.FOUNDATION_TEAM(id)
    }
    
    return (
        <div className="foundation-team-container">
            <div className={`
                team__container
                container__with-legend 
                ${children?.length > 0 ? '' : 'no-items'}`
            }>
                <HeaderView
                    title={team?.name} 
                    handleAdd={() => toggle()}
                    textAdd={intl.formatMessage({ id: 'charge.add' })}
                    handleAux={() => toggleAdd()}
                    textAux={intl.formatMessage({ id: 'subteams.add' })}
                    handleConfirm={() => handleConfirm()}
                    isConfirmed={team?.confirmation?.isConfirm}
                /> 
            
                <div className='team-sub-container'>
                    <Posts 
                        posts={team?.posts} 
                        handleDeleteCharge={handleDeleteCharge} 
                    />
                    
                    <div className='team-wrapper-container '>
                        
                        <div className='team-header'>
                            <h3 className="title__container">
                                <FormattedMessage id="subteams" />
                            </h3>
                        </div>
                        
                        <CardList
                            list={children}
                            gotToLink={goToPath}
                            openEditModal={openEditModalChildren}
                            handleDelete={handleDeleteTeam}
                        />
                        
                        { children?.length > 0 && <Legend /> }
                    </div>
                </div>
            </div>
            
            <Modal 
                isShowing={isShowing}
                hide={closeTeamModal}
                width='90%'
                maxWidth='450px'
                title={<FormattedMessage id='charge.new'/>}
                context={(
                    <FormPost
                        inputs={inputsCharge}
                        handleChange={onInputChargeChange}
                        handleSave={handleCreateCharager}
                    />
                )}
            />

            <Modal 
                isShowing={isShowingAdd}
                hide={toggleAdd}
                width='90%'
                maxWidth='450px'
                title={<FormattedMessage id='foundation.teams.new'/>}
                context={(
                    <FoundationTeamForm
                        inputs={inputsTeam}
                        handleChange={onInputTeamChange}
                        handleSave={handleCreateChildren}
                    />
                )}
            />

            <Modal 
                isShowing={isShowingEdit}
                hide={closeEditChildrenModal}
                width='90%'
                maxWidth='450px'
                title={<FormattedMessage id='center.teams.edit'/>}
                context={(
                    <FoundationTeamForm
                        inputs={inputsTeam}
                        handleChange={onInputTeamChange}
                        handleSave={handleUpdateChildren}
                    />
                )}
            />
        </div>
    )
}
export default FoundationTeam
