import React from 'react'
import { Link } from 'react-router-dom'

const BreadcrumbLink = ({ path, text }) => (
    <Link key={Math.random()} to={path} className="text-primary font-semibold">
        <span>{text || '...'}</span>
    </Link>
)

export default BreadcrumbLink
