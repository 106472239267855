import React from 'react'
import { validators } from '../../utils/helpers/validators'
import SaveButton from '../common/buttons/SaveButton'
import LabeledText from '../common/form-components/labeled-inputs/LabeledText'
import LabeledTextarea from '../common/form-components/labeled-inputs/LabeledTextArea'
import LabeledFile from '../common/form-components/labeled-file/LabeledFile'
import "./maestros- template-import-form.scss"

export const FILE_TYPE = ".xlsx, .csv"

const MaestrosTemplateImportForm = ({ 
    inputs, 
    handleChange, 
    handleSave, 
    handleDeleteFile 
}) => {
    
    const validateForm = validators.isEntityValues([inputs?.name, inputs?.file || inputs?.files]) 
    
    return (
        <div className="maestros-template-import-form">
            <div className="modal-content-wrapper">
                <div className='content-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={handleChange}
                    />
                    <LabeledFile
                        idLabel='import.file.type' 
                        labelValues={{type: FILE_TYPE}}
                        value={inputs?.file}
                        handleChange={handleChange}
                        handleDelete={handleDeleteFile}
                        accept={FILE_TYPE} 
                        onSelect={handleChange}
                    />
                </div>
                <LabeledTextarea 
                    idLabel="description"
                    name='description'
                    value={inputs?.description} 
                    onChange={handleChange}
                />
            </div>
            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={handleSave}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div> 
        </div>
    )
}

export default MaestrosTemplateImportForm
