import React from "react"

const ButtonWithTooltip = ({
    textTooltip, 
    button
}) => {
    return (
        <div className='table-tooltip'>
            <span className='table-tooltip-context'>{ textTooltip }</span>
            { button }
        </div>
    )
}

export default ButtonWithTooltip