import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import Modal from "../../modal/Modal"
import SaveButton from "../../common/buttons/SaveButton"
import StaffService from "../../../services/staff/staff.service"
import useInputs from "../../../utils/hooks/useInputs"
import LabeledText from "../../common/form-components/labeled-inputs/LabeledText"
import { validators } from "../../../utils/helpers/validators"
import LabeledCheckbox from "../../common/form-components/labeled-checkbox/LabeledCheckbox"
import LabeledEditor from "../../common/form-components/labeled-inputs/LabeledEditor"
import "./addStaffModal.scss"

const AddStaffModal = ({
    isShowing,
    toggle,
    fullNameFilter,
    updateTableAfterAddPerson 
}) => {

    const { formatMessage: msg } = useIntl()

    const {inputs, onChangeInputs, updateInputs } = useInputs({
        daDni: '', 
        apellidosYNombre: fullNameFilter ?? '',
        remark: '',
        isIntern: true
    })

    useEffect(() => {
        if (fullNameFilter) {
            updateInputs({
                daDni: '', 
                apellidosYNombre: fullNameFilter ?? '',
                remark: '',
                isIntern: true
            })
        }
    }, [])

    const handleAddStaff = async () => {
        const resp = (await StaffService.createPending(inputs))?.resp 
        if (resp) {
            updateTableAfterAddPerson(resp)
            toggle()
        }
    }

    const validateForm = validators.isEntityValues([
        inputs?.daDni, inputs?.apellidosYNombre]) 

    return (
        <Modal
            isShowing={isShowing}
            hide={toggle}  
            title={msg({id: "request.register.personnel"})}
            width="90%"
            maxWidth="600px"
            context={(
                <div className="add-satff-form">
                    <div className='modal-content-wrapper'>
                        <LabeledText 
                            idLabel="dni" 
                            required
                            name="daDni"
                            value={inputs?.daDni} 
                            onChange={onChangeInputs}   
                        />
                        <div className="content-wrapped">
                            <LabeledText 
                                idLabel="first.last.name" 
                                required
                                name="apellidosYNombre"
                                value={inputs?.apellidosYNombre || fullNameFilter} 
                                onChange={onChangeInputs}
                            />  
                            <LabeledCheckbox 
                                idLabel='isIntern'
                                name='isIntern'
                                checked={inputs?.isIntern}
                                onChange={onChangeInputs}
                            />  

                        </div>
                        
                        <LabeledEditor 
                            idLabel="remarks"
                            required
                            name="remark"
                            value={inputs?.remark}
                            onChange={onChangeInputs}
                            min-height={40}
                        />
                        
                    </div>
                    <div className="modal-btn-container">
                        <SaveButton     
                            onClick={handleAddStaff}
                            isDisabled={!validateForm}
                        />
                    </div>
                </div>
            )}  
        />
    )
}

export default AddStaffModal