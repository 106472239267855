import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'

const SubsidyQuantificationForm = ({
    inputs, 
    handleChange,
    handleSubmit
}) => {
    const validateForm = validators.isEntityValues(inputs?.name, inputs?.code)
    return (
        <div className='subsidy-quantificationForm new-uantification-modal'>
            <div className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='code'
                        required
                        name='code'
                        value={inputs?.code}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className='control-wrapper'>
                    <LabeledTextArea 
                        idLabel='description'
                        name='description'
                        value={inputs?.description}
                        onChange={(e) => handleChange(e)}
                        numRows={3}
                        numColumns={15}
                    />
                </div>
            </div>
            <div className='modal-btn-container actions-wrapper'>
                <SaveButton 
                    onClick={() => handleSubmit()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"    
                />
            </div>
        </div>
    )
}

export default SubsidyQuantificationForm