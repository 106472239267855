import React from 'react'
import Home from '../../views/home/Home'
import Profile from '../../views/profile/Profile'
import privatePathname from './private.pathname'

export const privateRoutes = [
    {
        path: privatePathname.HOME,
        name: 'home',
        element: <Home />,
    },
    {
        path: privatePathname.PROFILE,
        name: 'profile',
        element: <Profile />,
    },
]