import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import useModal from "../../../utils/hooks/useModal"
import BugetTable from "../../common/dataTable/BugetTable"
import AddStaffModal from "./AddStaffModal"
import StaffDropdown from "./StaffDropdown"
import "./staffTable.scss"
import LaborDropdown from "./LaborDropdown"

const DistributionStaffTable = ({
    loading = false,
    data,
    nameFieldPerson,
    nameFieldLabor, 
    columns, 
    columnsFooter 
}) => {
    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()

    const [ values, setValues ] = useState([])
    const [ fullNameFilter, setFullNameFilter ] = useState('')
    const [ rowIndex, setRowIndex ] = useState(null)

    useEffect(() => {
        setValues(data)
    }, [data])

    const openModalToAddStaff = (filter, index) => {
        if (filter) setFullNameFilter(filter)
        setRowIndex(index)
        toggle()
    }

    const updateTableAfterAddPerson = (staff) => {
        const newValues = [...values]
        newValues[rowIndex] = {...newValues[rowIndex], [nameFieldPerson]: staff}
        setValues(newValues)
    }

    const editorStaff = (options) => (
        <StaffDropdown 
            onHandleAddStaff={openModalToAddStaff}
            options={options}
        />
    )

    const editorLabor = (options) => (
        <LaborDropdown 
            options={options}
            values={values}
        />
    )

    const columnsTable = () => {
        if (columns.length > 0) {
            return columns.map((column) => {
                if (column?.field === nameFieldPerson) {
                    return {...column,
                        body: (rowData) => rowData?.[nameFieldPerson]?.basic?.apellidosYNombre || msg({id: 'placeholder.no.staff.data'}),
                        editor: (options) => editorStaff(options)
                    }   
                }
                if (column?.field === nameFieldLabor) { 
                    return {...column,
                        body: (rowData) => rowData?.selectedLabor?.dcCategoria
                                            || rowData?.dcCategoria?.dcCategoria
                                            || rowData?.selectedLabor?.[0]?.dcCategoria,
                        editor: (options) => editorLabor(options)
                    }
                }
                return column
            })
        }
        return []   
    }

    return (
        <div className="staff-table">
            <BugetTable
                loading={loading}
                tableOptions={{
                    value: values,
                }}
                columns={columnsTable()}
                columnsFooter={columnsFooter}
            />
            <AddStaffModal
                isShowing={isShowing}
                toggle={toggle}
                fullNameFilter={fullNameFilter}
                updateTableAfterAddPerson={updateTableAfterAddPerson}
            />
        </div>
    )
}

export default DistributionStaffTable

