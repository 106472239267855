import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import MainCommonView from "../../../../components/common/MainCommonView"
import FormPas from '../../../../components/maestros-labor/pas/FormPas'
import Modal from '../../../../components/modal/Modal'
import PasService from '../../../../services/pas/pas.service'
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from '../../../../utils/hooks/useInputs'
import useModal from '../../../../utils/hooks/useModal'
import CardList from '../../../../components/card-list/CardList'
import { maestrosLaboral } from '../../../../routes/maestros/maestros-laboral/maestrosLaboral.pathname'
import auxFunction from '../../../../utils/helpers/auxFun'

const Pas = () => {

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: PasService.getAll,
        createAction: PasService.create,
        removeAction: PasService.remove,
        editAction: PasService.update
    })

    const handleCreatePas = () => {
        const body = {
            ...inputs,
            coveredService: auxFunction.returnObjectId(inputs?.coveredService)
        }
        dataFacade.create(body)
    }

    const handleUpdatePas = async (id) => {
        const body = {
            ...inputs,
            coveredService: auxFunction.returnObjectId(inputs?.coveredService)
        }
        dataFacade.edit({_id: id, body})
    } 

    const handleSave = async (_id) => {
        const resp = _id ? await handleUpdatePas(_id) : await handleCreatePas()
        toggleModal()
        return resp
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const openEditModal = (pas) => {
        updateInputs(pas)
        toggle()
    }

    const headerOptions = {
        title: msg({id: 'pas'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'pas',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'description'}), dataKey: 'description' },
        ],
        exportService: () => PasService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const goToLink = (id) => maestrosLaboral.PAS_ID + id
    
    const dataControl = (
        <CardList 
            list={dataFacade?.data?.resp}
            gotToLink={goToLink}
            handleDelete={dataFacade?.remove}
            openEditModal={openEditModal} 
        />
    )

    return (
        <div className="contract-types">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
            />

            <Modal
                isShowing={isShowing}
                hide={toggleModal}
                width="90%"
                maxWidth="500px"
                title={<FormattedMessage id="pas.new" />}
                context={(
                    <FormPas
                        inputs={inputs} 
                        handleSubmit={handleSave}
                        handleChange={onChangeInputs}
                    />
                )}
            />
        </div>
    )
}

export default Pas
