import React from "react"
import { onCellEditComplete } from "../../../../utils/helpers/data-table/common"
import MultiSelectComp from '../../form-components/dropdown/MultiSelectComp'

const multiSelectEditor = ({ optionsEditor, options, optionLabel }) => {
    return (
        <MultiSelectComp 
            filter
            name="options"
            options={options}
            optionLabel={optionLabel || 'name'}
            value={optionsEditor?.value}
            onChange={(e) => optionsEditor?.editorCallback(e.value)}
        />
    )
}

const editMultiSelectRowColumn = ({
    options, onChange, optionLabel
}) => {

    return {
        editor: (optionsEditor) => multiSelectEditor({optionsEditor, options, optionLabel, onChange}),
        onCellEditComplete
    }
}

export default editMultiSelectRowColumn