import React from "react"
import { onCellEditComplete } from "./common"
import DropdownComp from "../../form-components/dropdown/DropdownComp"

const dropdownEditor = ({ 
    optionsEditor,
    options,
    optionLabel,
    optionValue,
    filter 
}) => {
    return (
        <DropdownComp
            value={optionsEditor?.value?._id || optionsEditor?.value}
            options={options || []}
            onChange={({ target: { value } }) => optionsEditor.editorCallback(value)} 
            optionLabel={optionLabel}
            optionValue={optionValue}
            filter={filter}
        />
    )
}

const editDropdownRowColumn = ({ 
    options,
    optionLabel = 'name', 
    optionValue = '_id',
    filter=false

}) => {
    return {
        editor: (optionsEditor) => dropdownEditor({ 
            optionsEditor, options, optionLabel, optionValue, filter}),
        onCellEditComplete, 
    }
}

export default editDropdownRowColumn