import React, { useEffect } from 'react'
import { DataTable } from 'primereact/datatable'
import { useIntl } from 'react-intl'
import { Column } from 'primereact/column'
import { DataTableFun } from '../../../utils/helpers/dataTableFun'
import './dataTableCenter.scss'

const DataTableCenter = ({
    loading = false,
    tableOptions,
    columns
}) => {

    const { formatMessage: msg } = useIntl()
    
    useEffect(() => {
        // BEGIN: DataTable Responsive Pattern
        window.addEventListener("resize", DataTableFun.responsiveResize)
        return () => {
            window.removeEventListener("resize", DataTableFun.responsiveResize)
        }
        // END: DataTable Responsive Pattern
    }, [])

    return (
        <DataTable
            loading={loading}
            /* className={`${tableOptions?.className ?? ''}`}*/
            className="center-data-table"
            responsiveLayout={tableOptions?.responsiveLayout || 'scroll'}
            scrollable={tableOptions?.scrollable ?? true}
            scrollHeight={DataTableFun.responsiveHeight()}
            emptyMessage={msg({ id: 'placeholder.not.data' })}
            {...tableOptions}
        >
            {columns.map((column) => (
                <Column key={column?.field || Math.random()} {...column} />
            ))}
        </DataTable>
    )
}

export default DataTableCenter
