import React from "react"
import { InputText } from "primereact/inputtext"
import { FormattedMessage } from "react-intl"
import { InputNumber } from "primereact/inputnumber"
import { MultiSelect } from "primereact/multiselect"
import { InputSwitch } from "primereact/inputswitch"
import _, { isNumber, isObject } from "lodash"
import { FileUpload } from "primereact/fileupload"
import Delete from "../../assets/imgs/papelera.svg"
import ViewButton from "../../components/common/buttons/ViewButton"
import EditButton from "../../components/common/buttons/EditButton"
import FileEditor from "../../components/import-files/FileEditor"
import auxFunction from "./auxFun"
import CalendarComp from "../../components/common/calendar/CalendarComp"

// TODO: borrar este archivo despues de refactorizar tablas

export const DataTableFun = {
    onCellEditComplete: (e) => {
        const { rowData, newValue, field, originalEvent: event } = e
        if (newValue !== null) rowData[field] = newValue
        else event.preventDefault()
    },
    textEditor: (options) => {
        return (
            <InputText
                type="text"
                value={options.value}
                onChange={({ target: { value } }) =>
                    options.editorCallback(value)
                }
            />
        )
    },
    fileEditor: (options) => {
        return <FileEditor options={options} />
    },
    numberEditor: (op) => {
        return (
            <InputNumber
                value={op.value}
                onValueChange={(e) => op.editorCallback(e.value)}
            />
        )
    },
    doubleEditor: (op) => {
        return (
            <InputNumber
                name={op.name}
                value={op.value}
                onValueChange={(e) => op.editorCallback(e.value)}
                minFractionDigits={2}
                maxFractionDigits={2}
            />
        )
    },
    calendarYearEditor: (op, minDate) => {
        const parseYear = (value) => {
            if (isNumber(value)) {
                return auxFunction.convertStringToDate(value)
            }
            if (isObject(value)) {
                return new Date(value?.getFullYear().toString(), 0, 1)
            }
            return value ? new Date(value, 0, 1) : ""
        }

        return (
            <CalendarComp
                id="year"
                name="year"
                value={parseYear(op?.value)}
                onChange={(e) => op.editorCallback(e.value)}
                view="year"
                dateFormat="yy"
                minDate={parseYear(minDate)}
                showIcon
                required
            />
        )
    },
    booleanEditor: (options) => {
        return (
            <InputSwitch
                checked={options.value}
                onChange={(e) => options.editorCallback(e.value)}
            />
        )
    },
    buttonDelete: (rowData, handleDelete) => (
        <button
            type="button"
            onClick={() => handleDelete(rowData?._id)}
            className="btn-icon"
        >
            <img width="18" height="18" src={Delete} alt="delete" />
        </button>
    ),
    buttonDeleteItem: (rowData, handleDelete) => (
        <button
            type="button"
            onClick={() => handleDelete(rowData)}
            className="btn-icon"
        >
            <img width="18" height="18" src={Delete} alt="delete" />
        </button>
    ),
    // TODO: rename buttons
    buttonDeleteCustom: (rowData, service) => (
        <button
            type="button"
            onClick={() => service(rowData)}
            className="btn-icon"
        >
            <img width="18" height="18" src={Delete} alt="delete" />
        </button>
    ),
    linkFile: ({ label, onClick }) => (
        <a onClick={() => onClick()}>
            {label} <i className="pi pi-eye" style={{ fontSize: "1.2rem" }} />
        </a>
    ),
    buttonUpload: ({
        options,
        inputId,
        accept,
        labelButton,
        multiple = false,
        maxFileSize,
    }) => {
        return (
            <FileUpload
                name={inputId}
                mode="basic"
                accept={accept}
                multiple
                maxFileSize={maxFileSize || 10000000}
                chooseLabel={<FormattedMessage id={labelButton || "upload"} />}
                onSelect={(e) =>
                    options.editorCallback(multiple ? e?.files : e?.files[0])
                }
            />
        )
    },
    buttonDownload: (label, path) =>
        label && (
            <a href={path} download style={{ paddingRight: "1em" }}>
                {label}{" "}
                <i className="pi pi-download" style={{ fontSize: "1.2rem" }} />
            </a>
        ),
    buttonSeeMore: (rowData, handleSeeMore) => (
        <ViewButton onClick={() => handleSeeMore(rowData)} />
    ),
    buttonEdit: (rowData, handleEdit) => (
        <EditButton onClick={() => handleEdit(rowData)} />
    ),
    renderItems: (items) => {
        return items && items.length > 0 ? (
            <div className="items-column">
                {items.map((item) => {
                    return (
                        <span key={item?._id} className="item">
                            {item?.name || item?.subaccount || item}
                        </span>
                    )
                })}
            </div>
        ) : (
            <span className="sense-items">
                <FormattedMessage id="empty" />
            </span>
        )
    },

    multiSelectEditor: ({
        options,
        optionsEditor,
        values = optionsEditor.value,
        placeholder,
    }) => {
        // en version 10.02 hay un bug con optionValue
        const getValues = () => {
            return (
                values?.map((x) => {
                    /*if (x?._id) return x?._id
                return x*/
                    if (x?._id) return _.find(options, ["_id", x?._id])
                    return _.find(options, ["_id", x])
                }) || []
            )
        }

        return (
            <MultiSelect
                filter
                name="options"
                value={getValues()}
                onChange={({ target: { value } }) =>
                    optionsEditor.editorCallback(value?.map((val) => val?._id))
                }
                options={options}
                optionLabel="name"
                //optionValue='_id'
                display="chip"
                inputId="multiselect"
                placeholder={placeholder}
                pt={{
                    item: ({ context }) => ({
                        className: context.selected
                            ? "selected-item "
                            : undefined,
                    }),
                }}
            />
        )
    },

    getBodyRowdataSubtype: (rowData, subtypeId) => {
        const subtyesRowData = rowData?.subtypes
        if (subtyesRowData.length < 0) return null
        const subTypeInCol = _.find(subtyesRowData, ["id", subtypeId])
        if (subTypeInCol) return subTypeInCol?.groups
        return null
    },

    responsiveHeight: () => {
        // Element heights that are relevant
        const headerHeight = document.querySelector('header.header')?.offsetHeight || 0
        const headerCoursesHeight = document.querySelector('div.header-courses')?.offsetHeight || 0
        const mainviewHeight = document.querySelector('div.main-view-header')?.offsetHeight || 0
        const generalEditorHeight = document.querySelector('div.p-editor-container')?.offsetHeight || 0
        const customOffset = 100
        // console.log('DataTableFun', 'responsiveHeight', 'heights', headerHeight, headerCoursesHeight, mainviewHeight, generalEditorHeight)
        if (window.innerWidth > 992) {
            const height = (window.innerHeight) - (headerHeight + headerCoursesHeight + mainviewHeight + generalEditorHeight + customOffset)
            // console.log('DataTableFun', 'responsiveHeight', window.innerHeight, height)
            return height ? String(height).concat("px") : null
        }
        return "100%"
    },

    responsiveResize: () => {
        // Limit to current datatable (by id/name)???
        // console.log('DataTableFun', 'responsiveResize')
        document.querySelectorAll(".p-datatable-wrapper").forEach((dt) => {
            dt.style["max-height"] = DataTableFun.responsiveHeight()
        })
        document.querySelectorAll(".p-datatable-body").forEach((dt) => {
            dt.style["max-height"] = DataTableFun.responsiveHeight()
        })
        
    }

}
