import React, { useEffect, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { LayoutCenterContext } from "../../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../../utils/hooks/useLoading"
import Dashboard from "../../../../../components/common-courses/view-content/Dashboard"
import EducationOfferService from "../../../../../services/centers/courses/education-offer-and-service/educationOffer.service"
import editNumberRowColumn from "../../../../../components/common/dataTable/editors/editNumberRowColumn"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import useOfferNavigation from "../../../../../components/center-courses/education-offer/hooks/useOfferNavigation"
import BugetTable from "../../../../../components/common/dataTable/BugetTable"
import "./service.scss"

const Service = () => {

    const {centerId, courseId, educationOfferId, service} = useParams()
    const { formatMessage: msg } = useIntl()
    const {previousInService, nextInService} = useOfferNavigation()

    const [offerService, setOfferService] = useState({})
    const [refactoredOfferService, setRefactoredOfferService] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getEtapaServices()
    }, [service])

    const getEtapaServices = async () => {
        startLoading()
        const resp = await EducationOfferService.getEtapaServiceById(courseId, educationOfferId, service)
        if (resp) {
            if (resp?.resp?.hasUnits && resp?.resp?.isEducativeLastLevel) {
                // console.log(resp?.resp)
                const llarData = getDataLlar(resp?.resp)
                // console.log(llarData)
                setOfferService(llarData)
                setRefactoredOfferService(setOfferServiceTable(llarData))
                // console.log(refactoredOfferService)
            }
            else {
                setOfferService(resp?.resp)
                setRefactoredOfferService(setOfferServiceTable(resp?.resp))
            }
        }
        stopLoading()
    }

    const getDataLlar = (data) => {
        return {
            ...data,
            subetapas: [{
                isLebelOfDistribution: false,
                name: data?.etapaName,
                numStudents: data?.numStudents,
                subetapa: data?.etapa
            }],
            subservices: data?.subservices.map(subservice => {
                return {
                    ...subservice,
                    subetapas: [{
                        subetapa: data?.etapa,
                        name: data?.etapaName,
                        numStudents: subservice?.numStudents
                    }]
                }
            }),
        }
    }

    const setOfferServiceTable = (data) => (
        {
            etapa: data?.etapa,
            course: data?.course,
            service: data?.service,
            name: data?.name,
            subservices: parseServiceToTable(data?.subservices),
            numStudents: data?.numStudents,
            otherServicesConfirm: data?.otherServicesConfirm,
            _id: data?._id,
        }
    )

    const parseServiceToTable = (data) => {
        if (data?.length === 0) return []
        return data?.map(subservice => {
            const obj = {
                subservice: subservice?.subservice,
                name: subservice?.name,
                numStudents: subservice?.numStudents,
                _id: subservice?._id
            }
            if (subservice?.subetapas) {
                const subetapasObj = Object.assign(
                    {},
                    ...subservice?.subetapas?.map(s => ({ [s.subetapa]: s?.numStudents }))
                )
                const subetapasNames = subservice?.subetapas.reduce((acc, s) => {
                    acc[s?.subetapa] = s?.name
                    return acc
                }, {})
                obj.subetapas = subetapasObj
                obj.subetapasNames = subetapasNames
            }
            if (subservice?.specialties) {
                const specialtiesObj = Object.assign(
                    {},
                    ...subservice?.specialties?.map(s => ({ [s.specialty]: s?.numStudents }))
                )
                const specialtiesNames = subservice?.specialties.reduce((acc, s) => {
                    acc[s?.specialty] = s?.name
                    return acc
                }, {})
                obj.specialties = specialtiesObj
                obj.specialtiesNames = specialtiesNames
            }
            return obj
        })
    }

    const transformBackData = (data) => {
        return {
            etapa: data?.etapa,
            course: data?.course,
            service: data?.service,
            name: data?.name,
            subservices: parseOfferServiceBack(data?.subservices),
            numStudents: data?.numStudents,
            otherServicesConfirm: data?.otherServicesConfirm,
            _id: data?._id,
            servicesConfirmation: data?.servicesConfirmation
        }
    }

    const parseOfferServiceBack = (data) => {
        if (data?.length === 0) return []
        return data?.map(subservice => {
            const obj = {
                subservice: subservice?.subservice,
                name: subservice?.name,
                numStudents: subservice?.numStudents,
                _id: subservice?._id
            }
            if (subservice?.subetapas) {
                const subetapasArray = Object.entries(subservice?.subetapas).map(([subetapa, numStudents]) => ({
                    subetapa,
                    numStudents,
                    name: subservice?.subetapasNames
                }))
                obj.subetapas = subetapasArray
                // WARNING: Ugly Patch!
                const patchedNumStudents = subservice?.subetapas[Object.keys(subservice?.subetapasNames)[0]]
                obj.numStudents = patchedNumStudents
            }
            if (subservice?.specialties) {
                obj.specialties = Object.entries(subservice?.specialties).map(([specialty, numStudents]) => ({
                    specialty,
                    numStudents,
                    name: subservice?.specialtiesNames
                }))
            }
            return obj
        })
    }

    const getColumns = () => {
        const firstServiceItem = offerService?.subservices?.[0]
        if (firstServiceItem && firstServiceItem?.specialties) {
            return firstServiceItem.specialties.map(specialty => ({
                field: 'specialties.' + specialty?.specialty,
                className: 'p-datatype-number',
                headerClassName: "p-align-end",
                noTraslateHeader: true,
                bodyClassName: 'editRow',
                header: specialty?.name,
                style: { width: "10%"},
                ...editNumberRowColumn({})
            }))
        }
        if (firstServiceItem && firstServiceItem?.subetapas) {
            return firstServiceItem.subetapas.map(subetapa => ({
                field: 'subetapas.' + subetapa?.subetapa,
                className: 'p-datatype-number',
                headerClassName: "p-align-end",
                noTraslateHeader: true,
                bodyClassName: 'editRow',
                header: subetapa?.name,
                style: { width: "10%"},
                ...editNumberRowColumn({})
            }))
        }
        return []
    }

    const getColumnsFooter = () => {
        const getDataColumns = getColumns()?.map(column => ({
            footer: 'sum',
            field: column.field,
            className: 'p-datatype-number',
        }))
        return [
            { footer: msg({id: 'total'}), colSpan: 1, footerStyle: { textAlign: 'left' }},
            ...getDataColumns
        ]
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const body = transformBackData(refactoredOfferService)
            const resp = confirm===true
                ? await EducationOfferService.confirmService(courseId, educationOfferId, service, body)
                : await EducationOfferService.updateEtapaService(courseId, educationOfferId, service, body)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setOfferService(resp?.resp)
                setRefactoredOfferService(setOfferServiceTable(resp?.resp))
                nextInService()
            }
        }, 0)
    }

    const handlePrevious = () => { previousInService() }

    const handleNext = () => { nextInService() }

    const breadcrumb = [
        {
            label: msg({ id: 'educative.offer.and.services' }),
            template: <BreadcrumbLink
                path={CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId)}
                text={msg({ id: 'educative.offer.and.services' })} />
        },
        ...(offerService?.path || [])
            ?.map(p => ({
                label: p?.name,
                template: <BreadcrumbLink
                    path={CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, p?._id)}
                    text={p?.name} />
            })),
        {
            label: offerService?.name
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const table = (
        <div className="education-offer-service">
            <BugetTable
                tableOptions={{
                    loading,
                    value: refactoredOfferService?.subservices,
                    editMode: 'cell',
                }}
                columns={[
                    {field: 'name', bodyClassName: 'roll-title', header: 'name'},
                    ...getColumns()
                ]}
                columnsFooter={getColumnsFooter()}

            />
        </div>

    )

    return (
        <Dashboard
            content={table}
            headerOptions={headerOptions}
            footerOptions={{
                handlePrevious,
                handleNext
            }}
        />
    )
}

export default Service