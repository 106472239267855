import { DELETE, PATCH, POST, GET } from "../common/commonRequest.service"
import { endpointsTypologies } from "./endpoints"

const TypologiesService = {
    create: async (body) => POST(endpointsTypologies.GENERIC, body),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsTypologies.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsTypologies.BY_TYPOLOGY(_id), body),
    remove: async (tipologiaId) => DELETE(endpointsTypologies.BY_TYPOLOGY(tipologiaId))
}

export default TypologiesService