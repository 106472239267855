import swalAlert from './swalAlert'

// TODO: DELETE

export const execActionWithLoading = async ({action, onSuccess, onFinally, loadingMessage}) => {
    let swalWindow

    if (loadingMessage) {
        swalWindow = swalAlert.loadinng(loadingMessage)
    }

    const resp = await action()

    if (resp) {
        swalWindow?.close()

        if (typeof onSuccess === 'function') {
            onSuccess()
        }
    }

    if (typeof onFinally === 'function') {
        onFinally()
    }
}


export const confirmAction = async ({action, areYouSureMsg, yesMsg, noMsg}) => {
    const confirm = await swalAlert.warning(areYouSureMsg, noMsg, yesMsg)
    let resp

    if (confirm?.isConfirmed) {
        resp = await action()
        return resp
    } 
    return null
}

export const getAllAndSetState = async ({ request, setState}) => {
    const resp = await request({})

    if (resp) setState(resp)
}


export const isIterable = (obj) => {
    if (obj == null) {
        return false
    }

    return typeof obj[Symbol.iterator] === 'function'
}
