import React from "react"
import { Paginator } from 'primereact/paginator'
import auxFunction from "../../utils/helpers/auxFun"
import { ROWS_PER_PAGE_DEFAULT } from "../../utils/constants/common"
import './paginatorComp.scss'

const PaginatorComp = ({
    firstRow,
    limit,
    totalRecords,
    onPageChange,
    rowsPerPageOptions
}) => {


    return (totalRecords > 0) && (
        <div className='paginator-container'>
            <Paginator
                first={firstRow} // Zero-relative number of the first row to be displayed
                rows={auxFunction.getDataCountDisplayPerPage({limit, rowsPerPageOptions})} // Data count to display per page
                totalRecords={totalRecords || 0} //Number of total records.
                rowsPerPageOptions={rowsPerPageOptions || ROWS_PER_PAGE_DEFAULT} //Array of integer values to display inside rows per page dropdown
                onPageChange={onPageChange} //Callback to invoke when page changes, the event object contains information about the new state.
                pt={{
                    pageButton: ({ context }) => ({
                        className: context.active ? 'page-active' : undefined
                    })
                }}
            />
        </div>
    )
}

export default PaginatorComp