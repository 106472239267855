import React, { useState } from 'react'
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import InputTextComp from "../../components/common/form-components/inputs/InputTextComp"
import { appPathname, pathname } from '../../routes/pathname'
import { login } from '../../services/auth/auth.slice'
import { validators } from '../../utils/helpers/validators'
import logo from "../../assets/imgs/logos/logo.svg"
import Button from '../../components/common/buttons'
import PasswordComp from '../../components/common/form-components/password/PasswordComp'
import AppInfo from '../../components/common/appinfo/AppInfo'
import "./login.scss"

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const { formatMessage: msg } = useIntl()
    
    const [inputs, setInputs] = useState({
        email: '',
        password: ''
    })

    const handleChange = (event) => {
        const value = event.target.value
        const name = event.target.name
        setInputs(input => ({ ...input, [name]: value }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const resp = await dispatch(login(inputs))
        if (resp?.type === 'auth/login/fulfilled') {
            navigate(appPathname.HOME)
        } else {
            setInputs(input => ({ ...input, password: '' })) 
        }
    }

    const validate = validators.emailFormat(inputs?.email) && validators.hasValue(inputs?.password)

    return (
        <div className='login'>
            <form className='login-form'>
                <div className="login-box">
                    <img className="login-box__img" src={logo} alt="vedruna"/>
                    <div className="box-login-form">
                        <h3 className="title-form">
                            <FormattedMessage id="auth.login"/>
                        </h3>

                        <InputTextComp 
                            name='email'
                            value={inputs?.email}
                            onChange={handleChange}
                            placeholder={msg({id: 'email'})}
                        />
              
                        <PasswordComp 
                            name="password" 
                            value={inputs?.password} 
                            onChange={handleChange} 
                        />

                        <Button
                            id="submitLogin"
                            btnText={<FormattedMessage id="button.enter"/>}
                            btnType='submit'
                            btnClass="btn__with-bg-type-1"
                            isDisabled={!validate}
                            onHandleClick={(e) => handleSubmit(e)}
                        />
                        <a className="reset-password__link" href={pathname.RESET}> 
                            <FormattedMessage id="auth.forgot-password"/> 
                        </a>
                    </div>
                </div>
            </form>
            <AppInfo />
        </div>
    )
}
export default Login