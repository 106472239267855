import React from "react"
import useInputs from "../../../utils/hooks/useInputs"
import { validators } from "../../../utils/helpers/validators"
import LabeledNumber from "../../common/form-components/labeled-inputs/LabeledNumber"
import SaveButton from "../../common/buttons/SaveButton"
import LabeledDouble from "../../common/form-components/labeled-inputs/LabeledDouble"
import "./templateLineHoursOfStage.scss"

const TemplateLineHoursOfStage = ({
    handleSave
}) => {
    const { inputs, onChangeInputs} = useInputs({})

    const validateForm = validators.isEntityValues([
        inputs?.numLines, inputs?.udConcerted, inputs?.ratio, inputs?.numStudents])

    return (
        <div className=" template-line-hour-stage-form">
            <div className="modal-content-wrapper">
                <div className="content-wrapped">
                    <LabeledNumber
                        idLabel='number.lines'
                        required
                        name='numLines'
                        value={inputs?.line}
                        onChange={(e) => onChangeInputs(e)}
                    />
                    <LabeledNumber
                        idLabel='concerted.units'
                        required
                        name='udConcerted'
                        value={inputs?.concertedUd}
                        onChange={(e) => onChangeInputs(e)}
                    />
                    <LabeledDouble
                        idLabel='ratio'
                        required
                        name='ratio'
                        value={inputs?.ratio}
                        maxFractionDigits={11}
                        onChange={(e) => onChangeInputs(e)}
                    />
                    <LabeledNumber
                        idLabel='numStudents.group'
                        required
                        name='numStudents'
                        value={inputs?.numStudents}
                        onChange={(e) => onChangeInputs(e)}
                    />
                </div>
            </div>

            <div className="modal-btn-container actions-wrapper">
                <SaveButton
                    btnClass="btn-save-modal"
                    isDisabled={!validateForm}
                    onClick={() => handleSave(inputs)} 
                />
            </div>
        </div>
    )
}

export default TemplateLineHoursOfStage