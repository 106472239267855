import React from 'react';
import './appinfo.scss'

const packageJson = require('../../../../package.json')

const AppInfo = () => {
    return (
        <div className="app-info">
            <ul>
                <li><strong>{packageJson.name}</strong></li>
                <li>Build: {packageJson.version}</li>
                <li>React version: {React.version}</li>
                <li>PrimeReact version: {packageJson.dependencies.primereact}</li>
            </ul>
        </div>
    )
}

export default AppInfo;
