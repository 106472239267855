import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl';
import useLoading from '../../../../../utils/hooks/useLoading';
import CardsOverview from '../../../../../components/common-courses/view-content/CardsOverview'
import { CENTER_COURSE_DETAILS } from '../../../../../routes/centers/center-course/pathname'
import { CenterPasService } from '../../../../../services/center-pas/centerServices.service'
import BreadcrumbLink from '../../../../../components/common/breadcrumb/BreadcrumbLink'

const Pas = () => {
    const { formatMessage: msg } = useIntl()
    const { centerId, courseId } = useParams()

    const navigate = useNavigate()

    const [pas, setPas] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getPas()
    }, [])

    const getPas = async () => {
        startLoading()
        const resp = await CenterPasService.getCoursePas(courseId)
        if (resp) {
            setPas(resp?.resp)
        }
        stopLoading()
    }

    const goToPas = (id, item) => {
        if (item?.isLastLevel) return CENTER_COURSE_DETAILS.DISITRIBUTION_HOURS_PAS_BY_ID_TABLE(centerId, courseId, item?.pasId)
        return CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_PAS_BY_ID(centerId, courseId, item?.pasId)
    }

    const breadcrumb = [
        {
            label: msg({id: 'hours.and.staff'}),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId)} 
                text={msg({id: 'hours.and.staff'})} /> 
        },
        {
            label: 'PAS'
        }
    ]

    const contentSummary = (
        <h1>In progress...</h1>
    )

    const handlePrevious = () => navigate(CENTER_COURSE_DETAILS.DISTRIBUTION_HOURS_AND_STAFF(centerId, courseId))

    const cardList = {
        list: pas?.pas,
        gotToLink: goToPas
    }

    return (
        <CardsOverview
            loading={loading}
            breadcrumb={breadcrumb}
            cardsInfo={cardList}
            contentTableSummary={contentSummary}
            handlePrevious={handlePrevious}
        />
    )
}

export default Pas