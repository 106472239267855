import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsUsers } from "./endpoints"

export const UserService = {
    create: async (body) => POST(endpointsUsers.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsUsers.LOAD({filter, limit, skip, sort})),
    update: async ({ _id, body }) => PATCH(endpointsUsers.BY_USER(_id), body),
    remove: async (_id) => DELETE(endpointsUsers.BY_USER(_id))
}

export default UserService