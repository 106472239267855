import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import rowSeeWithTooltip from "../../../../components/common/dataTable/body/rowSeeWithTooltip"
import editTextRowColumn from "../../../../components/common/dataTable/editors/editTextRowColumn"
import TemplateBalanceForm from "../../../../components/maestros-center/template-balance/TemplateBalanceForm"
import Modal from "../../../../components/modal/Modal"
import { maestrosEcoFin } from "../../../../routes/maestros/maestros-ecofin/maestrosEcoFin.pathname"
import TemplatesBalanceService from "../../../../services/center-accounting/templates_balance/templateBalance.service"
import auxFunction from "../../../../utils/helpers/auxFun"
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../utils/helpers/data-table/column-types/rowEditor"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useInputs from "../../../../utils/hooks/useInputs"
import useModal from "../../../../utils/hooks/useModal"

const Balance = () => {
    
    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs()
    const navigate = useNavigate()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: TemplatesBalanceService.getAll,
        createAction: TemplatesBalanceService.create,
        removeAction: TemplatesBalanceService.delete
    })

    const handleCreate = async () => {
        const resp = await dataFacade.create(inputs)
        if (resp) {
            updateInputs({})
            toggle()
        }
    }

    const onRowEditComplete = async (data) => {
        const balanceData = {
            ...data?.newData,
            concepts: auxFunction.returnIds(data?.newData?.concepts),
        }
        const resp = await TemplatesBalanceService.update(data?.data?._id, balanceData)
        if (resp) {
            dataFacade.reload()
        }
    }

    const headerOptions = {
        title: msg({id: 'balance'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'balance',
        columns: [
            { title: msg({ id: 'name' }), dataKey: 'name' },
            { title: msg({ id: 'description' }), dataKey: 'description' }
        ],
        exportService: async () => {
            const resp = await TemplatesBalanceService.getAll({})
            return resp
        }
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const table = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete
            }}
            columns={([
                { field: 'name',
                    header: 'name',
                    sortable: true,
                    ...editTextRowColumn
                },
                { field: "description",
                    header: 'description',
                    ...editTextRowColumn
                },
                {...rowDelete({ handleDelete: dataFacade.remove }) },
                {...rowEditor()},
                { className: 'row-delete column-tooltip',
                    field: "masters",
                    ...rowSeeWithTooltip({ 
                        textTooltip: msg({id: 'info.see.concepts'}),
                        onClick: (rowData) => navigate(maestrosEcoFin.BALANCE(rowData?._id))})
                }
            ])}
        />
    )

    return (
        <div>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={table}
            />

            <Modal 
                isShowing={isShowing}
                hide={toggle}
                width='90%'
                maxWidth='450px'
                title={<FormattedMessage id='template.balance.new'/>}
                context={(
                    <TemplateBalanceForm 
                        inputs={inputs}
                        handleSubmit={handleCreate}
                        handleChange={onChangeInputs}
                    />
                )}
            />

        </div>
    )

}

export default Balance