import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { updateSchoolYearMaster } from '../../services/schoolYears/schoolYears.slice'
import auxFunction from '../helpers/auxFun'

// TODO: convert to simple function
const useConfirmLastCourse = ({ masterKey }) => {

    const dispatch = useDispatch()

    const schoolYears = useSelector(state => state.schoolYears)?.resp

    const [lastCourse, setLastCourse] = useState()
    const [isConfirmed, setConfirmed] = useState(false)

    const confirmHandlerAction = () => updateCourseMaster(lastCourse?._id, masterKey)

    const defineLastCourseConfirmed = () => {
        const year = auxFunction.findLastCourse(schoolYears)
        setLastCourse(year)

        const confirmed = areMastersConfirmedForCourse(year, masterKey)
        setConfirmed(confirmed)
    }

    const areMastersConfirmedForCourse = (course, master) => {
        const courseMasters = course?.masters?.filter(c => c.master === master)

        return courseMasters?.length === 1 ? courseMasters[0]?.status : null
    }

    const updateCourseMaster = (schoolYearId, master) => {
        dispatch(updateSchoolYearMaster({schoolYearId, master}))
        setConfirmed(true)
    }

    return {
        defineLastCourseConfirmed,
        auxInfoText: lastCourse?.name, 
        handlerHeaderActions: {
            isConfirmed,
            confirmHandlerAction
        }
    }

}

export default useConfirmLastCourse