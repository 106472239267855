import React from "react"
import { useIntl } from "react-intl"
import LabeledCalendar from "../../../../common/form-components/labeled-calendar/LabeledCalendar"
import LabeledDropdown from "../../../../common/form-components/labeled-dropdown/LabeledDropdown"
import LabeledNumber from "../../../../common/form-components/labeled-inputs/LabeledNumber"
import LabeledText from "../../../../common/form-components/labeled-inputs/LabeledText"
import "./staffProfileBasic.scss"
 
const StaffProfileBasic = ({
    info,
    handleInputsChange,
    isReadOnly,
}) => {
    const {formatMessage: msg} = useIntl()

    const changeOptions = {
        isReadOnly, 
        disabled: isReadOnly,
        onChange: handleInputsChange
    }

    const GENDERS = [
        { name: msg({id: "woman"}), _id: "0" },
        { name: msg({id: "man"}), _id: "1" }
    ]

    return (
        <div className="basic-staff-profile">
            <div className="staff-info-card">
                <LabeledText {...changeOptions} idLabel="dni" required name="infoBasic.daDni" value={info?.daDni} />
                <LabeledText {...changeOptions} idLabel="first.last.name" required name="infoBasic.apellidosYNombre" value={info?.apellidosYNombre} />
                <LabeledDropdown
                    idLabel='sex'
                    name="infoBasic.clSexo"
                    value={info?.clSexo} 
                    options={GENDERS}
                    optionLabel='name'
                    optionValue='_id'
                    {...changeOptions}
                />
                <LabeledCalendar {...changeOptions} idLabel="date.birth" name="infoBasic.feNacimiento" value={info?.feNacimiento} dateFormat="dd/mm/yy"/>
                <LabeledNumber {...changeOptions} idLabel="nationality.code" name="infoBasic.coNacionalidad" value={info?.coNacionalidad} />
                <LabeledText {...changeOptions} idLabel="nationality.description" name="infoBasic.dcNacionalidad" value={info?.dcNacionalidad} />
                <LabeledText {...changeOptions} idLabel="iban" name="infoBasic.iban1" value={info?.iban1} />
                <LabeledNumber {...changeOptions} idLabel="disability.code" name="infoBasic.clMinusvalia" value={info?.clMinusvalia} />

                <LabeledNumber {...changeOptions} idLabel="phone.vedruna" name="infoBasic.telefonVedruna" value={info?.telefonVedruna} />
                <LabeledNumber {...changeOptions} idLabel="phone" name="infoBasic.telefon" value={info?.telefon} />
                <LabeledText {...changeOptions} idLabel="email.vedruna" name="infoBasic.emailVedruna" value={info?.emailVedruna} />
                <LabeledText {...changeOptions} idLabel="email" name="infoBasic.email" value={info?.email} />
                <LabeledText {...changeOptions} idLabel="naf" name="infoBasic.naf" value={info?.naf} />
                <LabeledText {...changeOptions} idLabel="lang" name="infoBasic.idioma" value={info?.idioma} />
            </div>
            <div className="staff-info-card">
                <LabeledText {...changeOptions} idLabel="deduction" name="infoBasic.caConPorIrpf" value={info?.caConPorIrpf} />
                <LabeledText {...changeOptions} idLabel="center" name="infoBasic.daCentro" value={info?.daCentro} />
                <LabeledText {...changeOptions} idLabel="thirdparty.help" name="infoBasic.dcAyudaTerceros" value={info?.dcAyudaTerceros} />
            </div>
            <div className="staff-info-card wrapped-code">
                <LabeledNumber {...changeOptions} idLabel="zip.code" name="infoBasic.teIdenPostalAc" value={info?.teIdenPostalAc} />
                <LabeledNumber {...changeOptions} idLabel="country" name="infoBasic.clIdenPaisAc" value={info?.clIdenPaisAc} />
                <LabeledText {...changeOptions} idLabel="country.description" name="infoBasic.dcIdenPaisAc" value={info?.dcIdenPaisAc} />
                <LabeledText {...changeOptions} idLabel="autonomy.code" name="infoBasic.clIdenAutonomiaAc" value={info?.clIdenAutonomiaAc} />
                <LabeledNumber {...changeOptions} idLabel="municipy.code" name="infoBasic.clIdenMunicipioAc" value={info?.clIdenMunicipioAc} />
                <LabeledText {...changeOptions} idLabel="municipy.description" name="infoBasic.dcIdenMunicipioAc" value={info?.dcIdenMunicipioAc} />
                <LabeledNumber {...changeOptions} idLabel="province.code" name="infoBasic.clIdenProvinciaAc" value={info?.clIdenProvinciaAc} />
                <LabeledText {...changeOptions} idLabel="province.description" name="infoBasic.dcIdenProvinciaAc" value={info?.dcIdenProvinciaAc} />
                <LabeledText {...changeOptions} idLabel="road.code" name="infoBasic.clIdenViaPub" value={info?.clIdenViaPub} />
                <LabeledText {...changeOptions} idLabel="road.name" name="infoBasic.teIdenCalle" value={info?.teIdenCalle} />
                <LabeledText {...changeOptions} idLabel="road.name.value" name="infoBasic.teIdenNum" value={info?.teIdenNum} />
                <LabeledText {...changeOptions} idLabel="block" name="infoBasic.teIdenBloque" value={info?.teIdenBloque} />
                <LabeledText {...changeOptions} idLabel="stairs" name="infoBasic.teIdenEscal" value={info?.teIdenEscal} />
                <LabeledText {...changeOptions} idLabel="floor" name="infoBasic.teIdenPiso" value={info?.teIdenPiso} />
            </div>
        </div>
    )
}
 
export default StaffProfileBasic