import { createSlice } from '@reduxjs/toolkit'
import FoundationTeamsService from './foundationTeams.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

/**
 * body - {...}
 */
export const create = createAsyncThunkMiddleware('foundationTeams/create', FoundationTeamsService.create)

/**
 * body - {...}
 */
export const createChildren = createAsyncThunkMiddleware('foundationTeams/update', FoundationTeamsService.create)

/**
 * ()
 */
export const getAll = createAsyncThunkMiddleware('foundationTeams/getAll', FoundationTeamsService.getAll)

/**
 * foundationTeamId, body - {...}
 */
export const update = createAsyncThunkMiddleware('foundationTeams/update', FoundationTeamsService.update)

/**
 * foundationTeamId 
 */
export const confirm = createAsyncThunkMiddleware('foundationTeams/update', FoundationTeamsService.confirm)

/**
 * foundationTeamId
 */
export const remove = createAsyncThunkMiddleware('foundationTeams/remove', FoundationTeamsService.remove)

/**
 * foundationTeamId
 */
export const removeChildren = createAsyncThunkMiddleware('foundationTeams/update', FoundationTeamsService.remove)



const initialState = {}

const funsationTeamsSlice = createSlice({
    name: 'foundationTeams',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default funsationTeamsSlice.reducer