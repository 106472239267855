import { useState } from "react"

const useSort = (field, sortInit) => {

    const [ sortOrder, setSortOrder ] = useState(sortInit || 1)
    const [ sortField, setSortField ] = useState(field)

    const onSort = (e) => {
        setSortField(e.sortField)
        setSortOrder(e.sortOrder)
    }

    const onSortDropdown = (e) => {
        setSortField(e.target.value)
    }

    const sort = sortOrder === -1 ? '-' + sortField : sortField

    return { sortOrder, sortField, onSort, onSortDropdown, sort }
}

export default useSort