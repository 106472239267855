import React from "react"
import CheckboxCom from "../checkbox/CheckboxComp"
import { LabelCheck } from "../label/Label"
import "./labeledCheckbox.scss"

const LabeledCheckbox = ({
    idLabel, 
    required, 
    specificClass,
    name, 
    onChange, 
    checked, 
    disabled,
    tooltip
}) => {
    return (
        <div className="flex-container">
            <div className="control-input-check">
                <LabelCheck id={idLabel} required={required} specificClass={specificClass}/>
                <div className="control-input">
                    <CheckboxCom
                        name={name}
                        onChange={onChange}
                        checked={checked}
                        disabled={disabled}
                        tooltip={tooltip}
                    />
                </div>
            </div>
        </div>
    )

}

export default LabeledCheckbox