const re = (expression) => {
    return new RegExp(expression)
}

export const validators = {
    emailFormat: (email) => {
        return re(/^.+@.+\..+$/).test(email)
    },
    passwordFormat: (password) => {
        return re(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*?&]{6,}$/).test(password)
    },
    equals: (first, second) => {
        return first === second
    },
    hasValue: (text) => {
        let value = text

        if (value === null || value === undefined) return false

        if (typeof value !== 'string') value = value.toString()

        value = value.trim()
        return re(/^.+/).test(value)
    },

    isEntityValues: (values) => {
        return values?.every((value) => value !== undefined && value?.toString().trim() !== '')
    },
    isNumeric: (value) => {
        return re(/^\d+$/).test(value)
    },
    isDni(value) {
        if (!value) return false
        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
        const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i
        const str = value?.toString().toUpperCase()

        if (!nifRexp.test(str) && !nieRexp.test(str)) return false

        const nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2')

        const letter = str.substr(-1)
        const charIndex = parseInt(nie.slice(0, 8), 10) % 23

        return validChars[charIndex] === letter
    }
}
