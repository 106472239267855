import React from "react"
import { useIntl } from 'react-intl'
import "./info-card.scss"

const InfoCard = ({
    item
}) => {

    const { formatMessage: msg } = useIntl()

    return (
        <div className="info-card">
            {item?.src && <img className="info-card__img" src={item.src} alt={item?.name} width={item.width} height={item.height} />}
            <div className="title__subtitle">{item?.id && msg({ id: item.id })}</div>
            <div className="info-card__description">{item?.subtitle}</div>
        </div> 
    )
}

export default InfoCard