import React from 'react'
import { adminPathname, pathname } from '../pathname'
import Landing from '../../views/landing/index'
import Login from '../../views/login'
import ResetPassword from '../../views/reset'

import BackOffice from '../../views/backoffice/BackOffice'

import UpdatePassword from '../../views/update-password/UpdatePass'

const routes = {
    // Rutas publicas
    public: [
        {
            path: pathname.LANDING,
            name: 'langing',
            element: <Landing />,
        },
        {
            path: pathname.LOGIN,
            name: 'login',
            element: <Login />,
        },
        {
            path: pathname.RESET,
            name: 'resetPassword',
            element: <ResetPassword />,
        },
        {
            path: pathname.UPDATE_PASSWORD,
            name: 'updatePassword',
            element: <UpdatePassword />,
        },
    ]
}

export default routes
