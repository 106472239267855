import React from "react"
import useExport from "../../../utils/hooks/useExport"
import useModal from "../../../utils/hooks/useModal"
import CardList from "../../card-list/CardList"
import DownloadButton from "../../common/buttons/DownloadButton"
import Legend from "../../common/legend/Legend"
import Modal from "../../modal/Modal"
import Footer from "../footer/Footer"
import OverviewHeader from "../header/OverviewHeader"
import ProgressSpinnerComp from "../../common/spinner/ProgressSpinnerComp"
import swalAlert from "../../../utils/helpers/swalAlert"
import "./cardsOverview.scss"

// Vista general con cards
const CardsOverview = ({
    title,
    breadcrumb,
    cardsInfo,
    sections,
    contentTableSummary, 
    justifyContent,
    paramsGenerateExcel, 
    handleSummary, 
    handlePrevious,
    handleNext,
    loading
}) => {

    const { isShowing: isSummaryShowing, toggle: toggleSummary } = useModal()
    const { isShowing: isJustifyShowing, toggle: toggleJustify } = useModal()
    const { generateExcel } = useExport()

    const exportXLS = async () => {
        const data = await paramsGenerateExcel.resp()
        generateExcel({...paramsGenerateExcel, data})
    }

    const handleSummaryClick = async () => {
        try {
            await handleSummary()
            toggleSummary()
        } catch (err) {
            swalAlert.error(err)
        }
    }

    const summaryData = (
        <div className="summary-data">
            <div className="table">
                {contentTableSummary}
            </div>
            <div className="summary-footer">
                <DownloadButton 
                    btnText='download' 
                    onClickDownload={() => exportXLS()} 
                />
            </div>
        </div>
    )

    const justifyData = (
        <div className="justify-data">
            <div className="table">
                {justifyContent}
            </div>
        </div>
    )

    return (
        <div className="main-view-content">
            <OverviewHeader 
                title={title} 
                breadcrumb={breadcrumb}
                toggleModal={contentTableSummary ? handleSummaryClick : undefined}
                toggleJustify={justifyContent ? toggleJustify : undefined}
            />
            {
                !loading
                    ? (
                        <>
                            <div className="context-main-view">
                                {sections ? (
                                    sections.map((section) => (
                                        <div className="card-section">
                                            <p>{section.title}</p>
                                            <CardList {...section.cardsInfo} />
                                        </div>
                                    ))
                                ) : (
                                    <CardList {...cardsInfo} />
                                )}
                            </div>
                            <Legend />
                            <Footer 
                                handlePrevious={handlePrevious}
                                handleNext={handleNext}
                            />
                        </>
                    )
                    : <ProgressSpinnerComp loading={loading}/>
            }
         
            <Modal
                isShowing={isSummaryShowing}
                hide={toggleSummary}
                context={summaryData}
                width="90%"
                height="90%"
                maxWidth="1550px"
            />
            <Modal
                isShowing={isJustifyShowing}
                hide={toggleJustify}
                context={justifyData}
                width="90%"
                maxWidth="1550px"
            />
        </div>
    )
}

export default CardsOverview
