import { GET, PATCH } from "../common/commonRequest.service"
import { endpointsCenterPas } from "./endpoints"

export const CenterPasService = {
    getCoursePas: async (id) => GET(endpointsCenterPas.PAS(id)),
    getCenterPasSummary: async (id) => GET(endpointsCenterPas.PAS_SUMARY(id)),

    getPasById: async (id, pasId) => GET(endpointsCenterPas.PAS_ID(id, pasId)),
    updatePasId: async (id, pasId, body) => PATCH(endpointsCenterPas.PAS_ID(id, pasId), body),
    getPasSummary: async (id, pasId) => GET(endpointsCenterPas.PAS_ID_SUMARY(id, pasId)),

    confirmPas: async (id, pasId, body) => PATCH(endpointsCenterPas.CONFIRM(id, pasId), body),
    disconfirmPas: async (id, pasId) => PATCH(endpointsCenterPas.DISCONFIRM(id, pasId)),
}
