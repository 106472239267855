import React from 'react'
import { FormattedMessage } from 'react-intl'
import Card from '../../card/Card'
import { maestrosCenters } from '../../../routes/maestros/maestros-centers/maestrosCenters.pathname'
import './etapasList.scss'

const EtapaList = ({
    etapas,
    handleDelete,
    openEditModal,
    canEtapaBeEntered
}) => {

    const yearEtapa = (yearFrom, yearTo) => {
        let years = ''
        if (yearFrom && yearTo) {
            years = `${yearFrom}-${yearTo}`
        }
        if (yearFrom && !yearTo) {
            years = `> ${yearFrom}`
        }
        return ` ${years}`
    }

    const goToEtapa = (id) => maestrosCenters?.ETAPA + id

    const linkTo = (id, etapa) => (!canEtapaBeEntered || canEtapaBeEntered(etapa)) ? goToEtapa(id) : null

    return (
        <div className='etapas'>
            <div className='etapas-list box-list'>
                {etapas?.length > 0 && etapas.map((etapa) => (
                    <Card 
                        key={etapa._id}
                        item={{...etapa, name: etapa?.name + yearEtapa(etapa?.yearFrom, etapa?.yearTo) }}
                        isConfirm={etapa?.confirmation?.isConfirm}
                        linkTo={(id) => linkTo(id, etapa)}
                        openEditModal={openEditModal}
                        handleDelete={handleDelete}
                    />
                ))}
            </div>
            {
                etapas?.length === 0 && (
                    <div className='container text-not-data'>
                        <FormattedMessage id='placeholder.not.data'/> 
                    </div>
                )
            }
           
        </div>
    )
} 
export default EtapaList