import React from "react"
import Footer from "../footer/Footer"
import OverviewHeader from "../header/OverviewHeader"
import "./dashboard.scss"

const Dashboard = ({
    content,
    headerOptions = {},
    footerOptions = {}
}) => {

    return (
        <div className="content-data">
            <OverviewHeader {...headerOptions} />
            {content}
            <Footer {...footerOptions} />
        </div>
    )

}

export default Dashboard