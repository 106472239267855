import React from "react"
import { Editor } from "primereact/editor"
import "./editorComp.scss"

const EditorComp = ({ name, value, onChange, height }) => {

    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold" />
                <button className="ql-italic" aria-label="Italic" />
                <button className="ql-underline" aria-label="Underline" />
            </span>
        )
    }

    return (
        <div className="general-editor">
            <Editor 
                name={name}
                headerTemplate={renderHeader()}
                value={value}
                onTextChange={(e) => onChange({ target: {value: e.htmlValue, name}})} 
                style={{height}}
            />
        </div>
        
    )
}

export default EditorComp