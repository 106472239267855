import { haveFilterQuery, paginator, haveSort } from "../common/auxFun"


export const endpointsCenterTeams = {
    GENERIC: '/v1/maestros/school/teams',
    LOAD: ({ filter, limit, skip, sort }) => `/v1/maestros/school/teams/${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
    LOAD_ALL_MANAGERS: `/v1/maestros/school/teams/managers`,
    BY_TEAM: (centerTeamId) => `/v1/maestros/school/teams/${centerTeamId}`,
    CONFIRM: (centerTeamId) => `/v1/maestros/school/teams/${centerTeamId}/confirm`,
    TEAM_POST: (centerTeamId) => `/v1/maestros/school/teams/post/${centerTeamId}`,
    TEAM_BY_POST: (postId) => `/v1/maestros/school/teams/post/${postId}`,
    AGROUPATIONS: '/v1/maestros/school/associations'
}