import React from "react"
import { FormattedMessage } from "react-intl"
import Card from "../card/Card"
import './cardList.scss'

const CardList = ({
    list, 
    gotToLink,
    openEditModal,
    handleDelete
}) => {
    return (
        <div className='card-items'>
            <div className="items-list">
                {list?.length > 0 ? (
                    list.map((item) => (
                        <Card
                            key={item?.key || item?.subetapa || item?._id || item?.etapa?._id || item?.service}
                            item={item}
                            isConfirm={item?.confirmation?.isConfirm || item?.otherServicesConfirm?.isConfirm}
                            linkTo={() => gotToLink(item?.subetapa || item?._id || item?.etapa?._id, item)}
                            openEditModal={openEditModal}
                            handleDelete={handleDelete}
                        />
                    ))
                ) : (
                    // TODO: Show this ONLY if NOT loading
                    <div className='container text-not-data'>
                        <FormattedMessage id='placeholder.not.data'/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CardList