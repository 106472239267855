import { createSlice } from '@reduxjs/toolkit'
import ImputationService from './imputation.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('imputation/create', ImputationService.create)

export const getAll = createAsyncThunkMiddleware('imputation/getAll', ImputationService.getAll)

export const update = createAsyncThunkMiddleware('imputation/update', ImputationService.update)

export const remove = createAsyncThunkMiddleware('imputation/delete', ImputationService.remove)

const initialState = []

const imputationSlice = createSlice({
    name: 'imputations',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default imputationSlice.reducer