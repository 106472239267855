import React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from "react-router-dom"
import MainCommonView from "../../../../../../components/common/MainCommonView"
import EditButton from '../../../../../../components/common/buttons/EditButton'
import MaestrosDataTable from '../../../../../../components/common/dataTable/MaestrosDataTable'
import { bodyRenderTypes } from '../../../../../../components/common/dataTable/body/bodyColumnsTypes'
import { maestrosCenters } from "../../../../../../routes/maestros/maestros-centers/maestrosCenters.pathname"
import CenterAccountPlanService from '../../../../../../services/center-accounting/center-account-plan/centerAccountPlan.service'
import useHandlerBasicActions from "../../../../../../utils/hooks/base-adapter/useHandlerBasicActions"

const CenterAccountPlans = () => {

    const navigate = useNavigate()
    const { formatMessage: msg } = useIntl()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'numAccount',
        rowsPerPageInitial: 10,
        getAll: CenterAccountPlanService.getAll, 
        editAction: CenterAccountPlanService.update
    })
    
    const headerOptions = {
        title: msg({id: 'accounting.plan.center'}),
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'accounting.plan.center',
        columns: [
            { title: msg({id: 'account'}), dataKey: 'numAccount' },
            { title: msg({id: 'type.description'}), dataKey: 'account.description' },
            { title: msg({id: 'subaccount'}), dataKey: 'numSubaccount' },
            { title: msg({id: 'type.description'}), dataKey: 'subaccount.description' },
        ],
        exportService: () => CenterAccountPlanService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const handleEdit = ({_id}) => navigate(maestrosCenters.ACCOUNTING_PLAN_CENTER_BY_ID(_id))

    const getAcountingPlanDetails = (rowData) => (
        <div className='table-tooltip'>
            <span className='table-tooltip-context'>{msg({id: 'see.personal.file'})}</span>
            <EditButton onClick={() => handleEdit(rowData)} />
        </div>
    )

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                ...dataFacade?.sortOptions
            }}
            columns={[
                {
                    field: "numAccount",
                    sortable: true,
                    frozen: true,
                    header: 'account',
                    body: (rowData) => rowData?.numAccount
                },
                {
                    header: 'type.description',
                    body: (rowData) => rowData?.account?.description
                },
                {
                    field: "numSubaccount",
                    sortable: true,
                    frozen: true,
                    header: 'subaccount',
                    body: (rowData) => rowData?.numSubaccount
                },
                {
                    header: 'type.description',
                    body: (rowData) => rowData?.subaccount?.description
                },
                {
                    field: "costCenter",
                    sortable: true,
                    header: 'school.cost.center',
                    body: (rowData) => rowData.costCenter?.name
                },
                {
                    field: "agroupation",
                    header: msg({ id: 'groupings' }),
                    body: (rowData) => bodyRenderTypes(rowData?.agroupation)
                },
                {
                    field: "imputation",
                    sortable: true,
                    header: 'imputation',
                    body: (rowData) => rowData?.imputation?.name
                },
                {
                    className: 'items-column', 
                    field: "subventions",
                    sortable: true,
                    header: 'subventions',
                    body: (rowData) => rowData?.justificationSubvention 
                        ? bodyRenderTypes(rowData?.subventions) : ''
                },
                {
                    className: 'row-edit',
                    style: {minWidth: '6rem'},
                    align: "right",
                    body: getAcountingPlanDetails,
                    exportable: false
                }
            ]}
        />
    )

    return (
        <div className='center-account-plan'>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />
        </div>
    )
}

export default CenterAccountPlans
