import React from 'react'

import { useIntl, FormattedMessage } from 'react-intl'
import { Splitter, SplitterPanel } from 'primereact/splitter'
import { Panel } from 'primereact/panel'
import { Divider } from 'primereact/divider'
import Button from '../../components/common/buttons'
import MainCommonView from '../../components/common/MainCommonView'

import CancelButton from '../../components/common/buttons/CancelButton'
import SummaryButton from '../../components/common/buttons/SummaryButton'
import "./processmap.scss"

const ProcessMap = () => {

    const {formatMessage: msg} = useIntl()

    const headerOptions = {
        title: msg({id: 'processmap'}),
        searchOptions: false,
    }

    const demoButtons = (
        <>
            <Button
                id='confirm'
                btnClass='button-white'
                btnText="A"
            />
            <Button
                id='confirm'
                btnClass='button-white'
                btnText="B"
            />
            <Button
                id='confirm'
                btnClass='button-white'
                iconClass="pi-download"
                iconSize="0.8rem"
                iconRight
                btnText="C"
            />
        </>
    )


    const headerTemplate = (options) => {
        const className = `${options.className}`

        console.log(options)

        return (
            <div className={className}>
                <span className="p-panel-title">{options.props.header}</span>
                <div className="header-actions">
                    {demoButtons}
                </div>
                {options.togglerElement}
            </div>
        )
    }

    const processmapContent = (
        <div className='processmap-container'>
            <div className='processmap-container-sidebar' />
            <div className='processmap-container-content'>
                <Splitter style={{ height: '100%' }}>
                    <SplitterPanel className="flex align-items-center justify-content-center" size={75} minSize={50}>
                        <Panel header="Calendar" headerTemplate={headerTemplate} />
                    </SplitterPanel>
                    <SplitterPanel className="flex align-items-center justify-content-center" size={25} minSize={25}>
                        <Splitter layout="vertical">
                            <SplitterPanel className="flex align-items-center justify-content-center" size={50} minSize={25}>
                                <Panel header="Tasques" headerTemplate={headerTemplate}/>
                            </SplitterPanel>
                            <SplitterPanel className="flex align-items-center justify-content-center" size={50} minSize={25}>
                                <Panel header="Notificacions" headerTemplate={headerTemplate} />
                            </SplitterPanel>
                        </Splitter>
                    </SplitterPanel>
                </Splitter>
            </div>
        </div>        
    )

    return (
        <MainCommonView
            headerOptions={headerOptions}
            componentData={processmapContent}
        />
    )
}
export default ProcessMap
