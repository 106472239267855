import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import EducationOfferService from "../../../../services/centers/courses/education-offer-and-service/educationOffer.service"
import { CENTER_COURSE_DETAILS } from "../../../../routes/centers/center-course/pathname"


const useOfferNavigation = () => {
    const {centerId, courseId, educationOfferId, service} = useParams()
    const navigate = useNavigate()

    const [educationOffer, setEducationOffer] = useState()
    
    useEffect(() => {
        getEducativeOffer()
    }, [])

    const getEducativeOffer = async () => {
        const resp = await EducationOfferService.getByEtapaId(courseId, educationOfferId)
        if (resp) {
            setEducationOffer(resp?.resp)
        }
    }

    const nextInNee = () => {
        if (educationOffer?.services.length > 0) {
            navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_SERVICES(
                centerId,
                courseId,
                educationOfferId,
                educationOffer?.services?.[0]?.service
            ))
        } else {
            navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, educationOfferId))
        }
    }

    const previousInService = () => {
        const currentIndex = educationOffer?.services.findIndex(i => i?.service === service)
        if (currentIndex > 0) {
            navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_SERVICES(
                centerId, 
                courseId, 
                educationOfferId,
                educationOffer?.services?.[currentIndex - 1]?.service
            ))
            return
        }
        navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_NEE(
            centerId,
            courseId,
            educationOfferId
        ))
    }

    const nextInService = () => {
        const currentIndex = educationOffer?.services.findIndex(i => i?.service === service)
        if (currentIndex === educationOffer?.services?.length - 1) {
            navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(
                centerId, 
                courseId, 
                educationOfferId
            ))
            return
        }
        navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_SERVICES(
            centerId, 
            courseId, 
            educationOfferId,
            educationOffer?.services?.[currentIndex + 1]?.service
        ))
    }

    const previousInEtapa = () => {
        //Revisar
    }

    return {
        nextInNee,
        previousInService,
        nextInService,
        previousInEtapa
    }

}

export default useOfferNavigation