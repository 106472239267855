import React from "react"
import {BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import _ from "lodash"
import { pathname } from "../routes/pathname"

import "../assets/styles/common.scss"
import 'primeicons/primeicons.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primereact/resources/primereact.css'
import ProtectedLayout from "./routing/ProtectedLayout"
import routes from "../routes/public/routes"

const App = () => {

    const auth = useSelector(state => state.auth)

    return (
        <BrowserRouter>
            <Routes>
                {/* Public Routes */}
                {routes.public.map(({path, element, name}) => (
                    <Route exact key={name} path={path} element={element} />
                ))}

                {/* Private Routes */}
                <Route
                    path="/*"
                    element={
                        !_.isEmpty(auth) ? (
                            <ProtectedLayout auth={auth} />
                        ) : (
                            <Navigate to={pathname.LOGIN} />
                        )
                    }
                />
            </Routes>
        </BrowserRouter>
    )
}

export default App
