export const endpointsDistributionHours = {
    ETAPAS_BY_ID: (courseId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}`,
    ETAPA: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}`,
    JUSTIFY: (courseId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/justificate`,

    LINE: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/lineDistribution`,
    LINE_CONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/lineDistribution/confirm`,
    LINE_DISCONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/lineDistribution/disconfirm`,

    FOUNDATION: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/foundationTeam`,
    FOUNDATION_CONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/foundationTeam/confirm`,
    FOUNDATION_DISCONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/foundationTeam/disconfirm`,

    SCHOOL: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/schoolTeam`,
    SCHOOL_CONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/schoolTeam/confirm`,
    SCHOOL_DISCONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/etapas/course/${courseId}/etapa/${etapaId}/schoolTeam/disconfirm`,

    STAFF: (courseId, etapaId) => `/v1/centers/courses/distributionHours/staffs/course/${courseId}/etapa/${etapaId}`,
    STAFF_CONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/staffs/course/${courseId}/etapa/${etapaId}/confirm`,
    STAFF_DISCONFIRM: (courseId, etapaId) => `/v1/centers/courses/distributionHours/staffs/course/${courseId}/etapa/${etapaId}/disconfirm`
}