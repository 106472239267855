import React from "react"
import { ControlLabel } from "../label/Label"
import InputTextComp from "../inputs/InputTextComp"
import "./labeledInput.scss"

const LabeledText = ({
    classNameBox,
    idLabel, 
    required, 
    name,
    value,
    disabled, 
    onChange
}) => {

    return (
        <div className={`flex-container ${classNameBox}`}>
            <ControlLabel id={idLabel} required={required}/>
            <div className="control-input">
                <InputTextComp
                    name={name}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                />
            </div>
        </div>
    )

}

export default LabeledText
