import React from "react"
import { useIntl } from "react-intl"
import Button from "."


const DownloadButton = ({
    btnText,
    onClickDownload,
    isDisabled 
}) => {

    const { formatMessage: msg } = useIntl()

    return (
        <Button 
            btnText={msg({ id: btnText })}
            btnType="button"
            btnClass="button-white btn-export"
            iconRight
            iconClass="pi-download"
            isDisabled={isDisabled}
            onHandleClick={() => onClickDownload()}
        />
    )
}

export default DownloadButton