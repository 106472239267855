import { maestrosCenters } from "../../../routes/maestros/maestros-centers/maestrosCenters.pathname";
import etapas from '../../../assets/imgs/maestros/maestros-centers/etapes.svg'
import teams from '../../../assets/imgs/maestros/maestros-centers/equips.svg'
import nee from '../../../assets/imgs/maestros/maestros-centers/nee.svg'
import services from '../../../assets/imgs/maestros/maestros-centers/serveis.svg'
import tipologies from '../../../assets/imgs/maestros/maestros-centers/tipologia.svg'
import accounting from '../../../assets/imgs/maestros/maestros-accounting/accounting.svg'
import accountinPlanCentre from '../../../assets/imgs/maestros/maestros-accounting/maestro-plan-cuentas-centros.svg'
import accountinPlan from '../../../assets/imgs/maestros/maestros-centers/plan-contable.centro.svg'
import subtypes from '../../../assets/imgs/maestros/maestros-centers/subtipos.svg'
import distributionCriteria from '../../../assets/imgs/maestros/maestros-centers/criterio-reparticion.svg'
import costCenter from '../../../assets/imgs/maestros/maestros-centers/maestro-centro-coste.svg'
        
export const maestrosCentersKeys = {
    STAGES: 'etapas',
    TEAMS: 'centerTeams',
    NEE: 'nee',
    SERVICES: 'services',
    tipology: 'tipology',
    ACCOUNTING_PLAN: 'centerAccountPlan',
    SUBVENTIONS: 'subventions'   
}

export const MAESTROS_CENTER = [
    {
        id: 'tipologies',
        position: 1,
        route: maestrosCenters.TYPOLOGIE,
        src: tipologies,
        key: maestrosCentersKeys.tipology,
    },
    {
        id: 'stages',
        position: 2,
        route: maestrosCenters.ETAPAS,
        src: etapas,
        key: maestrosCentersKeys.STAGES,
    },
    {
        id: 'services',
        position: 3,
        route: maestrosCenters.SERVICES,
        src: services,
        key: maestrosCentersKeys.SERVICES,
    },
    {
        id: 'nee',
        position: 4,
        route: maestrosCenters.NEE,
        src: nee,
        key: maestrosCentersKeys.NEE,
    },
    {
        id: 'center.teams',
        position: 5,
        route: maestrosCenters.CENTER_TEAMS,
        src: teams,
        key: maestrosCentersKeys.TEAMS,
    },
    {
        id: 'accounting',
        position: 6,
        route: maestrosCenters.MAESTROS_ACCOUNTING_CENTER,
        src: accounting,
    }
]

export const MAESTROS_ACCOUNTING_CENTER = [
    {
        id: 'accounting.plan.center',
        name: 'subventions',
        route: maestrosCenters.MAESTROS_ACCOUNTING_PLAN,
        src: accountinPlanCentre,
    },
    {
        id: 'maestros.subventions',
        name: 'subventions',
        route: maestrosCenters.MAESTROS_SUBVENTIONS,
        src: accounting,
    },
    {
        id: 'maestros.fees',
        name: 'fees',
        route: maestrosCenters.MAESTROS_FEES,
        src: accounting,
    }
]

export const MAESTROS_ACCOUNTING_PLAN_CENTER = [
    {
        order: 1,
        id: 'school.cost.center',
        name: 'centredecostescola',
        route: maestrosCenters.ACCOUNTING_SCHOOL_COST_CENTER,
        src: costCenter,
    },
    {
        order: 2,
        id: 'subtypes',
        name: 'subtypes',
        route: maestrosCenters.ACCOUNTING_SUBTYPES,
        src: subtypes,
    },
    {
        order: 3,
        id: 'distribution.criteria',
        name: 'distributioncriteria',
        route: maestrosCenters.ACCOUNTING_DISTRIBUTION_CRITERIA,
        src: distributionCriteria,
    },
    {
        order: 4,
        id: 'accounting.plan.center',
        name: 'placomptablecentre',
        route: maestrosCenters.ACCOUNTING_PLAN_CENTER,
        src: accountinPlan,
        key: maestrosCentersKeys.ACCOUNTING_PLAN,
    }
]

export const MAESTROS_SUBVENTIONS = [
    {
        id: 'subsidy.criteria',
        name: 'subsidy.criteria',
        route: maestrosCenters.SUBSIDY_QUANTIFICATION,
        src: subtypes,
    },
    {
        id: 'subventions',
        name: 'subventions',
        route: maestrosCenters.SUBVENTIONS,
        src: costCenter,
        key: maestrosCentersKeys.SUBVENTIONS,
    },
]

