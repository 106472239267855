import React, { useState } from 'react'
import { useSelector } from "react-redux"
import { validators } from '../../utils/helpers/validators'
import SaveButton from '../common/buttons/SaveButton'
import LabeledDropdown from '../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledFile from '../common/form-components/labeled-file/LabeledFile'
import './importFileModal.scss'

const ImportSalaryTableModal = ({ 
    inputs, 
    handleSubmit,
    onChangeInputs,
}) => {
    const [file, setFile] = useState()

    const schoolYears = useSelector(state => state?.schoolYears)

    const validateForm = validators.isEntityValues([file])

    const handleFileChange = (e) => {
        if (e?.files) {
            setFile(e.files)
        }
    }

    return (
        <div className="import-file-modal">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledDropdown
                        idLabel='year'
                        required
                        name='schoolYear'
                        value={inputs?.schoolYear}
                        optionLabel='name'
                        optionValue='_id'
                        options={schoolYears?.resp}
                        onChange={(e) => onChangeInputs(e)}
                        filter
                    />
                    <LabeledFile 
                        idLabel='file'
                        required
                        name='file'
                        accept='.xlsx'
                        chooseLabel='upload'
                        onSelect={handleFileChange}
                    />
                </div>
            </div>
            <div className="modal-btn-container">
                <SaveButton
                    btnText="upload"
                    onClick={() => handleSubmit(file)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default ImportSalaryTableModal
