import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsNEE } from "./endpoints"

const NEEService = {
    createNEE: async (body) => POST(endpointsNEE.GENERIC, body),
    getAll: async ({filter, limit, skip, sort}) => GET(endpointsNEE.LOAD({filter, limit, skip, sort})),
    updateNEE: async ({_id, body}) => PATCH(endpointsNEE.BY_NEE(_id), body),
    removeNEE: async (neeId) => DELETE(endpointsNEE.BY_NEE(neeId))
}

export default NEEService