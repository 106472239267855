import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import MainCommonView from "../../../../../components/common/MainCommonView"
import { getAllEtapas } from "../../../../../services/stages/etapas.slice"
import CardList from "../../../../../components/card-list/CardList"
import { maestrosCenters } from "../../../../../routes/maestros/maestros-centers/maestrosCenters.pathname"

const FeeTypes = () => {
    const { formatMessage: msg } = useIntl()

    const dispatch = useDispatch()

    const etapas = useSelector(state => state?.etapas)?.resp

    const getEtapas = () => dispatch(getAllEtapas({}))

    useEffect(() => {
        getEtapas()
    }, [])

    const headerOptions = {
        title: msg({id: 'maestros.fees'}),
    }

    const goToLink = (id) => {
        return maestrosCenters.MAESTROS_FEES + id
    }
    
    const getEtapasSimplified = () => (etapas?.map(etapa => ({
        _id: etapa._id,
        name: etapa.name
    })))

    const data = (
        <CardList
            list={getEtapasSimplified()}
            gotToLink={goToLink}
        />
    )

    return (
        <MainCommonView
            headerOptions={headerOptions}
            componentData={data}
        />
    )
}

export default FeeTypes