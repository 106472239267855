import { backoffice } from "../backoffice.pathname";

export const maestros = {
    MASTERS_SEARCH: backoffice.MAESTROS +'/search',
    SCHOOLYEAR: backoffice.MAESTROS + '/schoolyear/',
    MAESTROS_CENTER: backoffice.MAESTROS +'/maestroscenter',
    MAESTROS_FOUNDATION: backoffice.MAESTROS +'/maestrosfoundation',
    MAESTROS_LABOR: backoffice.MAESTROS +'/labor',
    MAESTROS_ECOFIN: backoffice.MAESTROS +'/maestrosecofin',
    MAESTROS_TEMPLATES: backoffice.MAESTROS +'/maestrostemplates',
    MAESTROS_TEMPLATES_IMPORT: backoffice.MAESTROS +'/templates/import',
}