import React from 'react'
import { useIntl } from 'react-intl'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import { CenterServices } from '../../../../services/center-services/centerServices.service'
import useModal from '../../../../utils/hooks/useModal'
import Modal from '../../../../components/modal/Modal'
import FormSchoolService from '../../../../components/maestros-center/school-services/FormSchoolService'
import CardList from '../../../../components/card-list/CardList'
import { maestrosCenters } from '../../../../routes/maestros/maestros-centers/maestrosCenters.pathname'
import useInputs from '../../../../utils/hooks/useInputs'

const SchoolServices = () => {
    
    const {formatMessage: msg} = useIntl()
    const { isShowing, toggle} = useModal()
    const { inputs, updateInputs, onChangeInputs} = useInputs()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: CenterServices.getAllServices,
        createAction: CenterServices.createService,
        removeAction: CenterServices.removeService, 
        editAction: CenterServices.updateService
    })

    const goToCardLink = (id) => maestrosCenters?.SERVICES + id

    const handleCreate = async () => dataFacade.create(inputs)

    const handleUpdate = async (id) => {
        const data = {
            name: inputs?.name,
            isByEtapa: inputs?.isByEtapa
        }

        return dataFacade.edit({_id: id, body: data})
    }

    const handleSave = async (_id) => {
        const resp = _id ? await handleUpdate(_id) : await handleCreate()
        closeModal()
        return resp
    }

    const openModal = (schoolService) => {
        updateInputs(schoolService)
        toggle()
    }

    const closeModal = () => {
        updateInputs({})
        toggle()
    }

    const headerOptions = {
        title: msg({id: 'services'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'services',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' }
        ],
        exportService: () => CenterServices.getAllServices({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const dataControl = (
        <CardList 
            list={dataFacade?.data?.resp}
            gotToLink={goToCardLink}
            openEditModal={openModal}
            handleDelete={dataFacade.remove}
        />
    )

    const defineModalTitle = () => msg({ id: `service.${inputs?._id ? 'edit' : 'new'}`})

    return (
       
        <div className="school-services">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
                showLegend
            />

            <Modal 
                isShowing={isShowing}
                hide={closeModal}
                width='90%'
                maxWidth='450px'
                title={defineModalTitle()}
                context={(
                    <FormSchoolService
                        inputs={inputs}
                        handleChange={onChangeInputs}
                        handleSave={handleSave}
                    />
                )}
            />
        </div>
    )
}

export default SchoolServices