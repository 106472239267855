import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import EtapasService from '../../../services/stages/etapas.service'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledTreeSelect from '../../common/form-components/labeled-dropdown/LabeledTreeSelect'
import LabeledNumber from '../../common/form-components/labeled-inputs/LabeledNumber'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import './formSubventions.scss'

const FormSubvention = ({ inputs, handleChange, handleSave }) => {
    const intl = useIntl()

    const schoolYears = useSelector(state => state?.schoolYears)
    const subsidyQuantification = useSelector(state => state?.subsidyQuantification)

    const [etapas, setEtapas] = useState([])

    useEffect(() => {
        getFirstLevelStage()
    }, [])

    const getFirstLevelStage = async () => {
        const resp = await EtapasService.getFirstLevel({})
        if (resp) {
            setEtapas(resp?.resp)
        }
    }

    const validateForm = validators.isEntityValues([
        inputs?.name, inputs?.schoolYear, inputs?.total, inputs?.subsidyQuantification]) 
    && Object.keys(inputs?.etapas).length > 0
    
    return (
        <div className="form-subvention">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledDropdown 
                        idLabel='schoolYear'
                        required
                        name='schoolYear'
                        value={inputs?.schoolYear}
                        optionLabel='name'
                        optioValue='_id'
                        options={schoolYears?.resp}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
              
                <LabeledTreeSelect 
                    idLabel='stages.groups'
                    required
                    name='etapas'
                    value={inputs?.etapas || null}
                    onChange={(e) => handleChange(e)}
                    options={etapas}
                    filter
                    metaKeySelection={false}
                    selectionMode="checkbox"
                    display="chip"
                    partialChecked
                    placeholder={intl.formatMessage({id: 'placeholder.select'})}
                />
                
                <div className="control-wrapper">
                    <LabeledNumber 
                        classNameBox='combo-wrapper'
                        idLabel='subsidyQuantification'
                        required
                        name='total'
                        value={inputs?.total}
                        onChange={(e) => handleChange(e)}
                        maxFractionDigits={2}
                    />
                    <LabeledDropdown 
                        idLabel='quantification.criterion'
                        required
                        name='subsidyQuantification'
                        value={inputs?.subsidyQuantification}
                        optionLabel='name'
                        optioValue='_id'
                        options={subsidyQuantification?.resp}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                
                <LabeledTextArea 
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={(e) => handleChange(e)}
                    numRows={3}
                    numColumns={15}
                />
            </div>
            <div className="modal-btn-container actions-wrapper">
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormSubvention
