import React from "react"
import { useIntl } from "react-intl"
import Button from "./index"

const SaveButton = ({
    onClick, 
    isDisabled, 
    btnText, 
    btnClass
}) => {
    const { formatMessage: msg } = useIntl()

    return (
        <Button 
            btnText={msg({id: btnText || "button.save"})}
            btnType="button"
            btnClass={btnClass ?? 'btn-red btn-red__padding__5_8'}
            isDisabled={isDisabled}
            onHandleClick={onClick}
        />
    )
}

export default SaveButton