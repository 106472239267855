import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import useStatePrev from "../../../utils/hooks/useStatePrev"
import SummaryButton from "../../common/buttons/SummaryButton"
import BreadcrumbComp from "../../common/breadcrumb/BreadCrumbComp"
import ConfirmButton from "../../common/buttons/ConfirmButton"
import SaveButton from "../../common/buttons/SaveButton"
import "./overviewHeader.scss"
import JustifyButton from "../../common/buttons/JustifyButton"

const OverviewHeader = ({
    title,
    breadcrumb,
    toggleModal, 
    handleConfirm,
    disabledConfirm, 
    handleSave, 
    disabledSave,
    toggleJustify
}) => {

    const { formatMessage: msg } = useIntl()

    return (
        <div className="main-view-header bg-structure-box">
            <div className="title">
                { title && msg({ id: title }) }
                { breadcrumb && <BreadcrumbComp model={breadcrumb} /> }
            </div>
            <div className="buttons">
                { toggleJustify 
                    && <JustifyButton onClick={toggleJustify}/>}
                { toggleModal 
                    && <SummaryButton onClick={toggleModal}/>}
                { handleConfirm 
                    && <ConfirmButton onClick={handleConfirm} isDisabled={disabledConfirm}/>}
                { handleSave 
                    && <SaveButton onClick={handleSave} isDisabled={disabledSave}/>}
            </div>

        </div>
    )

}

export default OverviewHeader
