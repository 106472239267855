/* eslint-disable react/destructuring-assignment */
import React from "react"
import {IntlProvider} from 'react-intl'
import Catalan from '../../i18n/ca.json'

const lang = Catalan


const Language = (props) => {
    
    return (
        <IntlProvider messages={lang} locale="ca">
            { props.children }
        </IntlProvider>
    )

}

export default Language