import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from "react-router-dom"
import { useIntl} from 'react-intl'
import logo from '../../assets/imgs/logos/logo.svg'
import home from '../../assets/imgs/menu-icons/inicio.svg'
//import presupuesto from '../../assets/imgs/menu-icons/presupuestos.svg'
import backoffice from '../../assets/imgs/menu-icons/backoffice.svg'
import foundation from '../../assets/imgs/menu-icons/foundation.svg'
import imgLogout from '../../assets/imgs/menu-icons/logout.svg'
import search from '../../assets/imgs/menu-icons/centres-cercar 1.svg'

import { appPathname, pathname } from '../../routes/pathname'
import { maestros } from '../../routes/maestros/maestros.pathname'
import { logout } from '../../services/auth/auth.slice'
import swalAlert from '../../utils/helpers/swalAlert'
import { getAllSchoolYear } from '../../services/schoolYears/schoolYears.slice'

import './header.scss'
import ProfileImg from '../profile/ProfileImg'
import { foundation as foundationPath} from '../../routes/foundation/pathname'
import { BACKOFFICE } from '../../routes/backoffice.pathname'

const Header = () => {

    const intl = useIntl()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const Logout = async () => dispatch(logout())
    const getSchoolYears = () => dispatch(getAllSchoolYear({}))

    const handleLogout = async () => { 
        const confirm = await swalAlert.warning( 
            intl.formatMessage({id: "swal.title.logout"}),
            intl.formatMessage({id: "button.cancel"}),
            intl.formatMessage({id: "button.exit"})
        )
        if (confirm.isConfirmed) {
            await Logout()
            navigate(pathname.LOGIN)
        }
    }

    useEffect(() => {
        getSchoolYears()
    }, [])

    return (
        <header className='header'>
            <div className='header-container'>
                <div className='header-container__logo'> 
                    <Link className='header-container__logo--link' to={appPathname.HOME}> 
                        <img className='width__100 logo-img' src={logo} alt="vedruna" />
                    </Link>
                </div>
                <div className='header-container__icons icons-header-container'>
                    <div className='icon-wrapper' >
                        <Link className='icon-wrapper__link with-tooltip' to={appPathname.HOME} data-tooltip={intl.formatMessage({id: "home"})}>
                            <img className='home width__100 icon-img' src={home} alt="home" />
                        </Link>
                    </div> 
                    
                    <div className='icon-wrapper' >
                        <Link className='icon-wrapper__link with-tooltip' to={foundationPath.FOUNDATION} data-tooltip={intl.formatMessage({id: "foundation"})}>
                            <img className='width__100 icon-img center' src={foundation} alt="foundation" />
                        </Link>
                    </div>
                    { /*  <div className='icon-wrapper' >
                        <Link className='icon-wrapper__link with-tooltip' to={appPathname.HOME} data-tooltip={intl.formatMessage({id: "budget"})}>
                            <img className='width__100 icon-img budget' src={presupuesto} alt="presupuesto"/> 
                        </Link>
                    </div>
                    */}
                    <div className='icon-wrapper' >
                        <Link className='icon-wrapper__link with-tooltip' to={maestros.MASTERS_SEARCH} data-tooltip={intl.formatMessage({id: "searcher"})}>
                            <img className='width__100 icon-img settings' src={search} alt="searcher"/>
                        </Link>
                    </div>
                    <div className='icon-wrapper' >
                        <Link className='icon-wrapper__link with-tooltip' to={BACKOFFICE} data-tooltip={intl.formatMessage({id: "backoffice"})}>
                            <img className='width__100 icon-img settings' src={backoffice} alt="ajustes"/> 
                        </Link>
                    </div>
                    <div className='icon-wrapper ' >
                        <Link className='icon-wrapper__link with-tooltip' to={appPathname.PROFILE} data-tooltip={intl.formatMessage({id: "profile"})}>
                            <div className='profile-img'>
                                <ProfileImg />
                            </div>
                        </Link>
                    </div>

                    <div className='icon-wrapper' >
                        <Link className='icon-wrapper__link with-tooltip' onClick={handleLogout} data-tooltip={intl.formatMessage({id: "logout"})}>
                            <img className='width__100 icon-img close' src={imgLogout} alt="salir" />
                        </Link>
                    </div>

                </div>
            </div>
        </header>
    )
}
export default Header