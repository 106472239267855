import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import StrucutreLayout from "./StrucutreLayout"
import LabeledDropdown from "../../common/form-components/labeled-dropdown/LabeledDropdown"
import LabeledMultiSelect from "../../common/form-components/labeled-dropdown/LabeledMultiSelect"
import TypologiesService from "../../../services/typologies/typologies.service"
import SchoolYearService from "../../../services/schoolYears/schoolYears.service"
import { CenterServices } from "../../../services/center-services/centerServices.service"
import { validators } from "../../../utils/helpers/validators"
import "./generalStructure.scss"

const GeneralStructure = ({
    inputs,
    onChangeInputs,
    updateInputs,
    propsButtons,
    disabledSaveButton,
    isLastScreen,
    isFirstScreen,
}) => {

    const { formatMessage: msg } = useIntl()
    const { courseId } = useParams()

    const [ typologies, setTypologies ] = useState([])
    const [ schoolYears, setSchoolYears ] = useState([])
    const [ services, setServices ] = useState([])

    const selectedServices = inputs?.educativeOffer?.services?.map(service => {
        const { subservices, ...rest } = service
        return rest
    })

    const getSelectedServices = () => {
        const filteredServices = services?.filter(service => {
            return selectedServices?.some(selectedService => selectedService?.service === service?._id)
        })
        return filteredServices
    }

    useEffect(() => { 
        getTipologies()
        getSchoolYears()
        getServices()
    }, [])

    const getTipologies = async () => {
        const resp = await TypologiesService.getAll({})
        if (resp) {
            setTypologies(resp?.resp || [])
        }
    }

    const getSchoolYears = async () => { 
        const resp = await SchoolYearService.getAll({sort: "-name"})
        if (resp) {

            // TODO: Deshabilitar los cursos que ya estén creados en el Centro.
            // Actualmente no es posible listar los cursos de un Centro, porque el sistema NO está preparado para Centros con más de un Curso.
            const respSchoolYears = resp?.resp?.map(x => ({ name: x?.name, _id: x?._id}))
            setSchoolYears(respSchoolYears || [])

            if (respSchoolYears.length > 0) {
                const last = respSchoolYears[respSchoolYears.length - 1]
                //TODO: llamar al endpoint de get last course y actulizar los inputs
                // updateInputs({...inputs, schoolYear: "undefined" })
                updateInputs({...inputs, schoolYear: last })
            }
        }

    }

    const getServices = async () => { 
        const resp = await CenterServices.getAllServices({})
        if (resp) {
            setServices(resp?.resp || [])
        }
    }

    const validate = validators.isEntityValues([inputs?.schoolYear, inputs?.typology]) 

    const disabled = courseId !== 'undefined'

    return (
        <StrucutreLayout 
            title={msg({ id: 'structure.choose' })}
            disabledNextButton={!validate}
            {...propsButtons}
            isLastScreen={isLastScreen}
            isFirstScreen={isFirstScreen}
            disabledSaveButton={disabledSaveButton}
            context={(
                <div className="general-structure"> 
                    <div className="center-structure-form bg-structure-box">
                        <div className="control-wrapper">
                            <LabeledDropdown 
                                idLabel="schoolYear" 
                                name="schoolYear"
                                required
                                filter
                                options={schoolYears}
                                value={inputs?.schoolYear}
                                optionValue={typeof inputs?.schoolYear === 'string' ? '_id' : null}
                                onChange={onChangeInputs}
                                disabled={disabled}
                            />
                            <LabeledDropdown 
                                idLabel="typology"
                                required
                                filter
                                name="typology"
                                options={typologies}
                                value={inputs?.typology}
                                optionValue={typeof inputs?.typology === 'string' ? '_id' : null}
                                onChange={onChangeInputs}
                                disabled={disabled}
                            />
                        </div>  
                        
                        <LabeledMultiSelect 
                            idLabel='service.choose'
                            filter
                            optionLabel="name"
                            name='educativeOffer.services'
                            options={services}
                            //TO DO: habria que cambiar esto
                            value={disabled ? getSelectedServices(): selectedServices}
                            onChange={onChangeInputs}
                            disabled={disabled}
                        />
                        
                    </div>
                    
                </div>
            )}
        />
    )
}

export default GeneralStructure