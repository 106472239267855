import React from 'react'
import { useIntl } from 'react-intl'
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import useInputs from '../../../utils/hooks/useInputs'
import SaveButton from '../../common/buttons/SaveButton'

//TODO: Refactor
const ExportAccountTypeForm = ({handleSubmit, onChangeType}) => {
    const {inputs, onChangeInputs } = useInputs({exportType: false})
    const intl = useIntl()

    const values = [{
        _id: false,
        name: intl.formatMessage({id: 'export.mode.by.account'}),
    }, {
        _id: true,
        name: intl.formatMessage({id: 'export.mode.by.account.and.subaccount'}),
    }]

    const onChange = (e) => {
        onChangeInputs(e)
        onChangeType({exportType: e.value})
    }

    return (
        <div className='form-imputation new-nee-modal'>
            <div className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <LabeledDropdown
                        labelId="export.mode.choose" 
                        id="exportType"
                        value={inputs?.exportType} 
                        values={values}
                        onChange={onChange}
                    />
                </div>
            </div>

            <div className='modal-btn-container actions-wrapper'>
                <SaveButton 
                    onClick={() => handleSubmit(inputs)}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default ExportAccountTypeForm