import { Calendar } from "primereact/calendar"
import React from "react"

const CalendarComp = ({
    name, 
    value,
    minDate,
    maxDate, 
    onChange, 
    view, 
    dateFormat,
    showIcon= true, 
    required,
    disabled
}) => {

    return (
        <Calendar
            name={name}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onChange} 
            view={view}
            dateFormat={dateFormat || "dd/mm/yy"}
            showIcon={showIcon}
            required={required}
            disabled={disabled}
        />
    )

}

export default CalendarComp