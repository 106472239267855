import { Accordion, AccordionTab } from "primereact/accordion"
import { TabPanel, TabView } from "primereact/tabview"
import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import BugetTable from "../../../common/dataTable/BugetTable"

const ResumeStatistics = ({
    summaryData,
    onlyTables
}) => {
    const fieldPerson = 'person.basic.apellidosYNombre'
    const translateStaff = 'staff'

    const { formatMessage: msg } = useIntl()

    const [ data, setData ] = useState()

    useEffect(() => {
        setFilteredData()
    }, [])

    const setFilteredData = () => {
        if (summaryData?.associations) {
            const { associations, ...rest } = summaryData
            setData({
                ...rest,
                firstLevel: associations,
            })
        }
        if (summaryData?.teams) {
            const { teams, ...rest } = summaryData
            setData({
                ...rest,
                firstLevel: teams,
            })
        }
    }

    const getColumns = (dataColumns) => {
        if (!dataColumns || dataColumns?.length === 0) return []
        const keys = Object.keys(dataColumns[0])
        return keys.map(key => key === '_id' ? null : {
            field: key ==='person' ? fieldPerson : key,
            header: key ==='person' ? translateStaff : key,
        }).filter(item => item !== null)
    }

    return (
        <TabView>
            {data?.firstLevel?.map((item) => (
                <TabPanel key={item?._id} header={msg({id: item?.name})}>
                    {!onlyTables ? (
                        <Accordion>
                            {item?.teams?.map((pos) => (
                                <AccordionTab key={pos?._id} header={pos?.name}>
                                    <BugetTable
                                        tableOptions={{
                                            value: pos?.positions || []
                                        }}
                                        columns={getColumns(pos?.positions || [])}
                                    />
                                </AccordionTab>
                            ))}
                        </Accordion>
                    ) : (
                        <BugetTable
                            tableOptions={{
                                value: item?.positions || []
                            }}
                            columns={getColumns(item?.positions || [])}
                        />
                    )}
                </TabPanel>
            ))}
        </TabView>
    )
}

export default ResumeStatistics