import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"
import CardsOverview from "../../../../../../components/common-courses/view-content/CardsOverview"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"
import BudgetService from "../../../../../../services/centers/courses/budgets/budgets.service"

const BudgetConcepts = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId} = useParams()

    const [concepts, setConcepts] = useState()

    useEffect(() => {
        getConcepts()
    }, [])

    const getConcepts = async () => {
        const resp = await BudgetService.getConcepts(courseId, balanceId)
        if (resp) {
            setConcepts(resp?.resp)
        }
    }

    const goToConcepts = (id, item) => {
        return CENTER_COURSE_DETAILS.BUDGET_INCOMES_CONCEPTS(centerId, courseId, balanceId, item?.concept) 
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: concepts?.templateName
        }
    ]

    const cardList = {
        list: concepts?.concepts,
        gotToLink: goToConcepts,
    }

    return (
        <CardsOverview 
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default BudgetConcepts