import React, { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import CancelButton from "../../common/buttons/CancelButton"
import SaveButton from "../../common/buttons/SaveButton"
import Footer from "../../common-courses/footer/Footer"
import { CENTER, CENTER_COURSE } from "../../../routes/centers/pathname"
import { LayoutCenterContext } from "../../common-courses/context/LayoutCenterContex"
import "./structureLayout.scss"

const StrucutreLayout = ({
    title,
    context,
    handleSave,
    isLastScreen,
    handlePrevious,
    handleNext,
    disabledSaveButton,
    disabledNextButton,
    isFirstScreen,
}) => {
    const navigate = useNavigate()
    const { centerId, courseId } = useParams()

    const { setFlagCreateCourse, setFlagConfirm, setCourseSeletedDropdown } = useContext(LayoutCenterContext)

    const handleConcelCreateCourse = () => {
        setFlagCreateCourse(false)
        // Not working
        // navigate(CENTER(centerId))

        // Workaround
        // const getLastCourseId = courseOptions.slice(-1)[0]?._id
        setFlagCreateCourse(false)
        navigate(-3)

    }

    return (
        <div className="section-structure-wrapped">
            <div className="structure-header bg-structure-box"> 
                <div className="structure-header__title"> 
                    {title}
                </div>
                <div className="structure-header__buttons">
                    {courseId === 'undefined' && <CancelButton onClick={handleConcelCreateCourse}/> }
                    {isLastScreen && <SaveButton onClick={handleSave} isDisabled={disabledSaveButton}/> }
                </div>
            </div>

            <div className="context-structure">
                { context }
            </div>
           
            <Footer
                handlePrevious={isFirstScreen ? null : handlePrevious}
                handleNext={isLastScreen ? null : handleNext}
                isDisabledNext={disabledNextButton}
            />
        </div>
    )
}

export default StrucutreLayout