import { createSlice } from '@reduxjs/toolkit'
import SubsidyQuantificationService from './subsidyQuantification.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('subsidyQuantification/create', SubsidyQuantificationService.create)

export const getAll = createAsyncThunkMiddleware('subsidyQuantification/getAll', SubsidyQuantificationService.getAll)

export const update = createAsyncThunkMiddleware('subsidyQuantification/update', SubsidyQuantificationService.update)

export const remove = createAsyncThunkMiddleware('subsidyQuantification/delete', SubsidyQuantificationService.remove)

const initialState = []

const subsidyQuantificationSlice = createSlice({
    name: 'subsidyQuantification',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove),
})

export default subsidyQuantificationSlice.reducer
