import { useState } from "react"
import { ROWS_PER_PAGE_DEFAULT } from "../../constants/common"

const usePaginator = (limitInitial) => {

    const [limit, setLimit] = useState(limitInitial || ROWS_PER_PAGE_DEFAULT[1])
    const [skip, setSkip] = useState(0)
    const [firstRow, setFirst] = useState(0) // Zero-relative number of the first row to be displayed

    const onPageChange = ({ page, first, rows }) => {
        if (page !== undefined) setSkip(page)
        if (first !== undefined) setFirst(first)
        if (rows !== undefined) setLimit(rows)
    }

    return {
        onPageChange, firstRow, skip, limit
    }

}

export default usePaginator