import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Config } from '../../utils/constants/config'
import auxFunction from '../../utils/helpers/auxFun'
import MainCommonView from '../../components/common/MainCommonView'
import './backOffice.scss'

const BackOffice = () => {

    const {formatMessage: msg} = useIntl()

    const [search, setSearch ] = useState("")
    const [filter, setFilter ] = useState(Config)

    const updateFilter = (e) => { 
        const text = e.target.value
        setSearch(text) 

        if (text === '') {
            setFilter(Config)
        } else {
            const result = Config.filter((element) => auxFunction.contains(element.name, text))
            setFilter(result)
        }
    }

    const headerOptions = {
        title: msg({id: 'backoffice'}),
        searchOptions: false,
        inputText: search,
        onChangeText: updateFilter,
        placeholderSearchId: 'search.by.name'
    }

    const backofficeContent = (
        <div className='backoffice-container container__with-background'>
            <div className='box-list container container__without-padding-top'>
                {filter.map((card, i) => {
                    return (
                        <div className='box-item-icon' key={[i]}>
                            <Link className='white-text' to={card.route} >
                                <img className="card-img" src={card.src} alt={card.name} />
                                <FormattedMessage id={card.id} />
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )

    return (

        <MainCommonView
            headerOptions={headerOptions}
            componentData={backofficeContent} />
        
        
    // <div className='backoffice-container container__with-background'>

    //     <HeaderView 
    //         title={<FormattedMessage id='backoffice'/>}
    //         search
    //         inputText={search}
    //         onChangeText={updateFilter}
    //         placeholderSearchId='search.by.name'
    //     />

    //     <div className='box-list container container__without-padding-top'>
    //         {filter.map((card, i) => {
    //             return (
    //                 <div className='box-item-icon' key={[i]}>
    //                     <Link className='white-text' to={card.route} >
    //                         <img className="card-img" src={card.src} alt={card.name} />
    //                         <FormattedMessage id={card.id} />
    //                     </Link>
    //                 </div>
    //             )
    //         })}
    //     </div>
    // </div>
    )
}
export default BackOffice
