import { createSlice } from '@reduxjs/toolkit'
import { CenterServices} from './centerServices.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const createService = createAsyncThunkMiddleware('services/create', CenterServices.createService)

export const getAllServices = createAsyncThunkMiddleware('services/getAll', CenterServices.getAllServices)

export const updateService = createAsyncThunkMiddleware('services/update', CenterServices.updateService)

export const confimService = createAsyncThunkMiddleware('services/confirm', CenterServices.confirmService)

export const removeService = createAsyncThunkMiddleware('services/remove', CenterServices.removeService)

const initialState = {}

const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(createService, updateService, getAllServices, removeService)
})

export default servicesSlice.reducer