import { createSlice } from '@reduxjs/toolkit'
import EtapasService from './etapas.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'

export const createEtapa = createAsyncThunkMiddleware('etapas/create', EtapasService.createEtapa)

export const getAllEtapas = createAsyncThunkMiddleware('etapas/getAll', EtapasService.getAllEtapas)

export const updateEtapa = createAsyncThunkMiddleware('etapas/update', EtapasService.updateEtapa)

export const confirmEtapa = createAsyncThunkMiddleware('etapas/confirm', EtapasService.confirmEtapa)

export const removeEtapa = createAsyncThunkMiddleware('etapas/remove', EtapasService.removeEtapa)

const initialState = {
    resp: []
}

const etapasSlice = createSlice({
    name: 'etapas',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createEtapa.fulfilled, (state, action) => {
                return {...state, resp: [...state.resp, action.payload.resp]}
            })
            .addCase(getAllEtapas.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(updateEtapa.fulfilled, (state, action) => {
                const updateRes = state.resp.map((s) => {
                    if (s._id === action.payload.resp._id) return action.payload.resp
                    return s
                })
                return {...state, resp: updateRes}
            })
            .addCase(confirmEtapa.fulfilled, (state, action) => {
                return action.payload
            })
            .addCase(removeEtapa.fulfilled, (state, action) => {
                return {...state, resp: state.resp.filter((s) => s._id !== action.payload.resp._id)}
            })
    }
})

export default etapasSlice.reducer