import React from 'react'
import { useSelector } from "react-redux"
import logo from "../../assets/imgs/logos/logo.svg"
import enterApp from "../../assets/imgs/entrar-app.svg"
import { appPathname, pathname } from '../../routes/pathname'
import AppInfo from '../../components/common/appinfo/AppInfo'
import './landing.scss'

const Landing = () => {
    
    const auth = useSelector(state => state.auth)

    const path = auth?.user !== null ? appPathname.HOME : pathname.LOGIN

    return (
        <div className='landing'>
            <div className='landing-wrapper__border'>
                <div className='logo-wrapper'>
                    <img className="width__100" src={logo} alt="vedruna" />
                </div>
                <a href={path}>
                    <img className='btn-enter' src={enterApp} alt="entrar" />
                </a>
            </div>
            <AppInfo />
        </div>
    )
}
export default Landing