import { createSlice } from '@reduxjs/toolkit'
import AuthService from './auth.service'
import TokenService from '../common/token.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'

export const login = createAsyncThunkMiddleware('auth/login', AuthService.login)

export const logout = createAsyncThunkMiddleware('auth/logout', AuthService.logout)

const initialState = null

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {
                if (action.payload?.resp) TokenService.setJWT(action.payload?.resp?.tokens)
                return action.payload?.resp
            })
            .addCase(logout.fulfilled, () => {
                TokenService.removesetJWT()
                return initialState
            })
            .addCase(login.rejected, () => {
                return initialState
            })
            .addCase(logout.rejected, () => {
                return initialState
            })
    }
})

export default authSlice.reducer


  