import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledCalendar from '../../common/form-components/labeled-calendar/LabeledCalendar'
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledNumber from "../../common/form-components/labeled-inputs/LabeledNumber"
import { SubTitle } from '../../common/form-components/label/Label'
import "./formManagementPositions.scss"

// TODO : refactor this component
const FormManagementPositions = ({ 
    inputs, 
    handleChange, 
    handleSave, 
    managerialTeams, 
    collectiveAgreements, 
    typologies 
}) => {

    const validateForm = validators.isEntityValues([inputs?.year, inputs?.agreement, inputs?.typology])

    return (
        <div className="form-management-position">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledCalendar 
                        idLabel="year"
                        required
                        name="year"
                        value={inputs?.year}
                        onChange={(e) => handleChange(e)}
                        dateFormat="yy"
                        view="year"
                    />
                    <LabeledDropdown 
                        idLabel="agreement"
                        required
                        name="agreement"
                        value={inputs?.agreement}
                        optionLabel="name"
                        optionValue="_id"
                        options={collectiveAgreements || []}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledDropdown 
                        idLabel="typology"
                        required
                        name="typology"
                        value={inputs?.typology}
                        optionLabel="name"
                        optionValue="_id"
                        options={typologies || []}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <SubTitle id="delegatedPayment" />
                <div className="control-wrapper-grid">
                    { 
                        managerialTeams?.delegatedPayment?.map((managerialTeam) => (
                            <LabeledNumber 
                                idLabel={managerialTeam?.name}
                                name={`delegatedPayment.${managerialTeam._id}`}
                                value={inputs?.delegatedPayment?.value}
                                key={managerialTeam?._id}
                                onChange={(e) => handleChange(e)}
                                minFractionDigits={2}
                            /> 
                        )) 
                    }
                </div>
                <SubTitle id="directSchoolPayment" />
                <div className="control-wrapper-grid">
                    { 
                        managerialTeams?.directSchoolPayment?.map((managerialTeam) => (
                            <LabeledNumber 
                                idLabel={managerialTeam?.name}
                                name={`directSchoolPayment.${managerialTeam._id}`}
                                value={inputs?.directSchoolPayment?.value}
                                key={managerialTeam?._id}
                                onChange={(e) => handleChange(e)}
                                minFractionDigits={2}
                            />
                        )) 
                    }
                </div> 
            </div>
            <div className="modal-btn-container">
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormManagementPositions
