import { createSlice } from '@reduxjs/toolkit'
import NEEService from './nee.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const createNee = createAsyncThunkMiddleware('nee/create', NEEService.createNEE)

export const getAllNee = createAsyncThunkMiddleware('nee/getAll', NEEService.getAll)

export const updateNee = createAsyncThunkMiddleware('nee/update', NEEService.updateNEE)

export const removeNee = createAsyncThunkMiddleware('nee/delete', NEEService.removeNEE)

const initialState = []

const neeSlice = createSlice({
    name: 'nee',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(createNee, updateNee, getAllNee, removeNee)
})

export default neeSlice.reducer