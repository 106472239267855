import React from "react"
import { useIntl } from "react-intl"
import InputTextComp from "../form-components/inputs/InputTextComp"
import './search.scss'

const Search = ({
    placeholder,
    filter, 
    updateFilter 
}) => {

    const {formatMessage: msg} = useIntl()

    return (
        <div className='search-component'>
            <span className="p-input-icon-left width__100">
                <i className="pi pi-search" />
                <InputTextComp 
                    name="search"
                    value={filter}
                    onChange={updateFilter}
                    placeholder={placeholder ? msg({ id: placeholder}) : msg({ id: 'search'})}
                />
            </span>
        </div>
    )
}

export default Search