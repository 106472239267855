import React from "react"
import Accounts from "../../../views/maestros/maestros-ecofin/accounts/Accounts"
import { maestrosEcoFin } from "./maestrosEcoFin.pathname"
import Account from "../../../views/maestros/maestros-ecofin/accounts/Account"
import Imputation from "../../../views/maestros/maestros-ecofin/imputation/Imputation"
import Balance from "../../../views/maestros/maestros-ecofin/balance/Balance"
import Concepts from "../../../views/maestros/maestros-ecofin/balance/Concepts"

export const maestrosEcoFinRoutes = [

    {
        path: maestrosEcoFin.ACCOUNTING_ACCOUNTS,
        name: 'accounts',
        element: <Accounts />,
    },
    {
        path: maestrosEcoFin.ACCOUNTING_SUBACCOUNTS(':accountId'),
        name: 'subaccounts',
        element: <Account />,
    },
    {
        path: maestrosEcoFin.IMPUTATION,
        name: 'imputation',
        element: <Imputation />,
    },
    {
        path: maestrosEcoFin.TEMPLATE_BALANCE,
        name: 'template-balance',
        element: <Balance />,
    },
    {
        path: maestrosEcoFin.BALANCE(':balanceId'),
        name: 'template-balance',
        element: <Concepts />,
    }
]