// MAESTROS LABORAL 
import contractTypes from '../../../assets/imgs/maestros/maestros-labor/contrato.svg'
import categories from '../../../assets/imgs/maestros/maestros-labor/convenio.svg'
import management from '../../../assets/imgs/maestros/maestros-labor/cargos-directivos.svg'
import salaryTables from '../../../assets/imgs/maestros/maestros-labor/sueldo-segun-categoria.svg'
import estadis from '../../../assets/imgs/maestros/maestros-labor/estadios.svg'
import staff from '../../../assets/imgs/maestros/maestros-labor/personal.svg'
// TODO: pas icon
import pas from '../../../assets/imgs/maestros/maestros-centers/equips.svg'
import { maestrosLaboral } from '../../../routes/maestros/maestros-laboral/maestrosLaboral.pathname'


export const MAESTROS_LABORAL = [
    {
        id: 'contract.types',
        name: 'contract types',
        route: maestrosLaboral.CONTRACT_TYPES,
        src: contractTypes,
    },
    {
        id: 'collectiveAgreements',
        name: 'collectiveAggreements',
        route: maestrosLaboral.COLLECTIVE_AGREEMENTS,
        src: categories,
    },
    {
        id: 'management.positions',
        name: 'Càrrecs directiu',
        route: maestrosLaboral.MANAGEMENT_POSITIONS,
        src: management,
    },
    {
        id: 'stadiums',
        name: 'Estadis',
        route: maestrosLaboral.STADIUMS,
        src: estadis,
    },
    {
        id: 'salary.tables',
        name: 'salary tables',
        route: maestrosLaboral.SALARY_TABLES,
        src: salaryTables,
    },
    {
        id: 'staff',
        name: 'staff',
        route: maestrosLaboral.STAFF,
        src: staff,
    },
    {
        id: 'pas',
        name: 'pas',
        route: maestrosLaboral.PAS,
        src: pas,
    },
]