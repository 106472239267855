import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import './formAccount.scss'

const ACCOUNT = 'account'
const SUBACCOUNT = 'subaccount'

const FormAccount = ({ 
    inputs, 
    handleChange, 
    handleSubmit, 
    isSubaccount 
}) => {

    const type = isSubaccount ? SUBACCOUNT : ACCOUNT

    const validateForm = validators.isEntityValues([inputs?.[type]]) 

    return (
        <div className="form-account ">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledText
                        idLabel={type}
                        required
                        name={type}
                        value={inputs?.[type]}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                <div className="control-wrapper">
                    <LabeledTextArea 
                        idLabel='description'
                        name='description'
                        value={inputs?.description}
                        onChange={(e) => handleChange(e)}
                        numRows={3}
                        numColumns={15}
                    />
                </div>
            </div>
            <div className="modal-btn-container">
                <SaveButton 
                    onClick={() => handleSubmit()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormAccount
