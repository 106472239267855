import React, { useEffect, useState} from "react"
import { useSelector } from "react-redux"
import InputDoubleComp from "../../common/form-components/inputs/InputDoubleComp"
import { validators } from "../../../utils/helpers/validators"
import useInputs from "../../../utils/hooks/useInputs"
import SaveButton from "../../common/buttons/SaveButton"
import MaestrosDataTable from "../../common/dataTable/MaestrosDataTable"
import LabeledText from "../../common/form-components/labeled-inputs/LabeledText"
import LabeledDropdown from "../../common/form-components/labeled-dropdown/LabeledDropdown"
import CenterTeamsService from '../../../services/center-teams/centerTeams.service'
import "./exceededHoursDistributionForm.scss"

const initialState = { categoryName: '', description: '', tipologies: [] }

const ExceededHoursDistributionForm = ({
    handleSave,
    dateEdit
}) => {

    const { inputs, onChangeInputs, updateInputs } = useInputs(dateEdit || initialState)

    const [centerTeams, setCenterTeams] = useState([])
    
    const typologies = (useSelector(state => state.typologies))?.resp

    const typologiesTables = [ 
        typologies?.slice(0, Math.floor(typologies?.length / 2)),
        typologies?.slice(Math.floor(typologies?.length / 2), typologies?.length)
    ]

    useEffect(() => { 
        loadSchooTeams() 
    }, [])

    const loadSchooTeams = async () => {
        const resp = await CenterTeamsService.getAll({})
        if (resp?.resp) setCenterTeams(resp?.resp)
    }

    const handleChange = (e, options) => {
        const keyword = options.field
        let updatedTipologies = [...(inputs?.tipologies || [])]

        const index = inputs?.tipologies?.findIndex(
            tipology => tipology.typologyId === options?.rowData?._id
        )

        if (index !== -1) {
            updatedTipologies[index] = { 
                ...updatedTipologies[index],
                [keyword]: e?.target?.value }
        } else {
            updatedTipologies = [...updatedTipologies, 
                { typologyId: options?.rowData?._id,
                    [keyword]: e?.target?.value }]
        }

        updateInputs({
            ...inputs,
            tipologies: updatedTipologies,
        })
    }

    const numberEditor = (options) => (
        <InputDoubleComp 
            value={options.value || hoursBody(options?.rowData, options)} 
            onValueChange={(e) => handleChange(e, options)} 
        />
    )

    const hoursBody = (rowData, options) => inputs?.tipologies?.find(t => 
        t?.typologyId === rowData._id)?.[options.field]

    const tableTypologies = (typologyData) => (
        <MaestrosDataTable 
            tableOptions={{
                value: typologyData,
                editMode: 'cell'
            }}
            columns={([
                { field: "name",
                    header: 'typology',
                },
                { field: "minHours",
                    header: 'min',
                    body: hoursBody,
                    editor: numberEditor
                },
                { 
                    field: "maxHours",
                    header: 'max',
                    body: hoursBody,
                    editor: numberEditor
                }
            ])}
        />
    )

    const validateForm = validators.isEntityValues([inputs?.categoryName, inputs?.centerTeam]) 
    
    return (
        <div className="hours-exceded-form">
            <div className="modal-content-wrapper hours-exceded-form-wrapper">
                <div className="content-wrapper">
                    <LabeledText 
                        idLabel='category'
                        required
                        name='categoryName'
                        value={inputs?.categoryName}
                        onChange={onChangeInputs}
                    />
                    <LabeledDropdown
                        required
                        idLabel="center.team"
                        name="centerTeam"
                        value={inputs?.centerTeam?._id || inputs?.centerTeam}  
                        options={centerTeams || []}
                        optionLabel='name'
                        optionValue='_id'
                        filter
                        onChange={onChangeInputs}
                    />
                </div>
                <LabeledText 
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={onChangeInputs}
                /> 
                <div className="table-wrapper">
                    { typologiesTables.map((typologyData) => (
                        <div className="flex-wrapper" key={Math.random()}>
                            {tableTypologies(typologyData)}
                        </div>
                    ))}
                </div>
            </div>

            <div className="modal-btn-container">
                <SaveButton 
                    onClick={() => handleSave(inputs)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default ExceededHoursDistributionForm