import React from "react"
import { FormattedMessage } from 'react-intl'
import './legend.scss'

const Legend = () => {
    return (
        <div className='legend'>
            <div className='legend-wrapper'>
                <ul className='legend-list'>
                    <li className='legend-list__item'>
                        <span className='icon' />
                        <span><FormattedMessage id="complete"/></span>
                    </li>
                    <li className='legend-list__item'>
                        <span><FormattedMessage id="unfilled"/></span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Legend