import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import useLoading from "../../../../../../utils/hooks/useLoading"
import CardsOverview from "../../../../../../components/common-courses/view-content/CardsOverview"
import BudgetService from "../../../../../../services/centers/courses/budgets/budgets.service"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"

const Supplies = () => {

    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, suppliesId} = useParams()
    
    const [supplies, setSupplies] = useState([])
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getSupplies()
    }, [])

    const getSupplies = async () => {
        startLoading()
        const resp = await BudgetService.getExpensesBalances(courseId, suppliesId)
        if (resp) {
            setSupplies(resp?.resp)
        }
        stopLoading()
    }

    const goToList = (id, item) => {
        return CENTER_COURSE_DETAILS.BUDGET_EXPENSES_SUPPLIES_DATA(centerId, courseId, suppliesId, item?.concept)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'expenses' }),
            template: <BreadcrumbLink    
                path={CENTER_COURSE_DETAILS.BUGET_EXPENSES(centerId, courseId)} 
                text={msg({ id: 'expenses' })} /> 
        },
        {
            label: supplies?.templateName
        }
    ]

    const cardList = {
        list: [
            ...(supplies?.concepts || []).map(item => ({
                ...item,
                source: 'concepts',
            })),
        ],
        gotToLink: goToList
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default Supplies