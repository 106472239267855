import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { DataTableFun } from '../../../utils/helpers/dataTableFun'
import './posts.scss'

const Posts = ({
    posts,
    handleDeleteCharge,
}) => {
    const intl = useIntl()

    return (
        <div className='posts'>

            <div className='posts__header-wrapper  '>
                <h3 className='title__container sub-title'>
                    <FormattedMessage id='jobTitle'/>
                </h3>
            </div>
            <DataTable 
                value={posts} 
                className="table-component no-padding"
                emptyMessage={intl.formatMessage({id: "placeholder.not.data"})} 
                responsiveLayout="scroll"
                scrollable 
            >
                <Column 
                    field="post" 
                    sortable
                    header={intl.formatMessage({id: 'name'})}
                    style={{minWidth: '180px'}}
                />
                <Column body={(rowData) => DataTableFun.buttonDelete(rowData, handleDeleteCharge)} 
                    style={{maxWidth: '80px'}} 
                    align='right' 
                    exportable={false} 
                />
            </DataTable>
        </div>
    )
}
export default Posts