import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import MainCommonView from "../../../../components/common/MainCommonView"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useModal from '../../../../utils/hooks/useModal'
import Modal from '../../../../components/modal/Modal'
import useInputs from '../../../../utils/hooks/useInputs'
import { TemplateEtapaHoursService } from '../../../../services/templates-distribution-hours-school/templateDistributionHoursSchool'
import { getAllEtapas } from '../../../../services/stages/etapas.slice'
import TemplateEtapaHoursForm from '../../../../components/maestros-template/templates-hours/TemplateEtapaHoursForm'
import CardList from '../../../../components/card-list/CardList'
import { TEMPLATES_ETAPA_HOURS } from '../../../../routes/maestros/maestros-templates/maestrosTemplates.pathname'
import auxFunction from '../../../../utils/helpers/auxFun'
import "./templates-hours.scss"

const TemplateEtapasHours = () => {

    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()

    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs({})

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: TemplateEtapaHoursService.getAll,
        createAction: TemplateEtapaHoursService.create,
        removeAction: TemplateEtapaHoursService.remove, 
        editAction: TemplateEtapaHoursService.update
    })

    useEffect(() => {
        dispatch(getAllEtapas({}))
    }, [ ])

    const handleSave = async (_id) => {
        inputs.etapas = auxFunction.returnIds(inputs?.etapas)
        const { etapa, lines, etapaName, createdAt, updatedAt, active, ...body } = inputs
        const resp = _id ? await dataFacade.edit({ _id, body }): await dataFacade.create(body)
        if (resp) closeModal()
        return resp
    }

    const openModal = (data) => {
        updateInputs(data)
        toggle()
    }

    const closeModal = () => {
        updateInputs({})
        toggle()
    }

    const defineModalTitle = msg({ id: `template.${inputs._id ? 'edit' : 'new'}`})

    const headerOptions = {
        title: msg({id: 'templates.and.hours'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }
    
    const goToCardLink = (id) => TEMPLATES_ETAPA_HOURS(id)

    const sortedData = _.orderBy(
        dataFacade.data?.resp?.map(item => ({ ...item, name: item?.etapa?.name })),
        [item => item?.etapa?.order],
        ['asc']
    )

    const listComponent = (
        <CardList 
            list={sortedData}
            gotToLink={goToCardLink}
            openEditModal={openModal}
            handleDelete={dataFacade.remove}
        />
    )

    return (
        <div>
            <MainCommonView 
                headerOptions={headerOptions}
                pagerOptions={pagerOptions}
                componentData={listComponent}
            />

            <Modal
                isShowing={isShowing}
                hide={closeModal}
                width="90%"
                maxWidth="700px"
                title={defineModalTitle}
                context={(
                    <TemplateEtapaHoursForm 
                        inputs={inputs} 
                        handleChange={onChangeInputs} 
                        handleSave={handleSave} 
                    />
                )}
            />

        </div>
    )
}
export default TemplateEtapasHours
