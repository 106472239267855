import { createSlice } from '@reduxjs/toolkit'
import TypologiesService from './typologies.service'
import createAsyncThunkMiddleware from '../common/createAsyncThunkMiddleware'
import extraReducersCommon from '../common/extraReducers'

export const create = createAsyncThunkMiddleware('tipology/create', TypologiesService.create)

export const getAll = createAsyncThunkMiddleware('tipology/getAll', TypologiesService.getAll)

export const update = createAsyncThunkMiddleware('tipology/update', TypologiesService.update)

export const remove = createAsyncThunkMiddleware('tipology/delete', TypologiesService.remove)

const initialState = {}

const typologiesSlice = createSlice({
    name: 'typologies',
    initialState,
    reducers: {},
    extraReducers: extraReducersCommon(create, update, getAll, remove)
})

export default typologiesSlice.reducer