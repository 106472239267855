import React, { useEffect, useState } from "react"
import { OrganizationChart } from "primereact/organizationchart"
import FoundationTeamsService from "../../../services/foundation-teams/foundationTeams.service"
import editTextRowColumn from "../../common/dataTable/editors/editTextRowColumn"
import useInputs from "../../../utils/hooks/useInputs"
import SaveButton from "../../common/buttons/SaveButton"
import StaffTable from "../../common-courses/staff/StaffTable"

const FoundationStatistiscData = ({
    foundationTeam,
    teamsTree,
}) => {

    const { inputs, updateInputs } = useInputs()

    const [teamSelected, setTeamSelected] = useState(null)
    const [selectedTree, setSelectedTree] = useState([{}])

    useEffect(() => { 
        getTeamById() 
    }, [])

    const getTeamById = async () => {
        const team = (await FoundationTeamsService.getById(foundationTeam?._id))?.resp
        if (team) {
            setTeamSelected(team?.foundationTeam)
            updateInputs(team?.foundationTeam)
            getSelectedTree(teamsTree, team?.foundationTeam?._id)
        }
    }

    const getSelectedTree = (tree, id) => {
        const searchTree = (nodes) => {
            if (!nodes || nodes?.length === 0) return null
            const foundNode = nodes.find(node => node?._id === id)
            if (foundNode) {
                setSelectedTree(foundNode)
                return foundNode
            }
            return nodes.reduce((acc, node) => {
                return acc || (node?.children ? searchTree(node?.children) : null)
            }, null)
        }
        return searchTree(tree)
    }

    const changeSelectedNode = (e) => {
        if (e?.data[0]) {
            setTeamSelected({ ...e.data[0] })
        }
    }

    const handleSave = () => {
        //Faltan canvios en los endpoints 
    }

    return (
        <>
            <h1>{teamSelected?.name}</h1>
            <div className="team-panel-container"> 
                <StaffTable 
                    data={inputs?.posts || []}
                    nameFieldPerson='person'
                    columns={[
                        { field: 'post', header: 'jobTitle' },
                        { field: 'person', header: 'staff', bodyClassName: 'editRow' },
                        { field: 'email', header: 'email', bodyClassName: 'editRow', ...editTextRowColumn },
                        { field: 'phone', header: 'phone', bodyClassName: 'editRow', ...editTextRowColumn },
                        { field: 'extension', header: 'extension', bodyClassName: 'editRow', ...editTextRowColumn },
                        { field: 'responsibleTeam', header: 'responsibleTeam', bodyClassName: 'editRow', ...editTextRowColumn },
                    ]}
                />

                <SaveButton
                    onClick={handleSave}
                    isDisabled
                    btnClass="btn-save-modal"
                />

                <OrganizationChart
                    value={[{...selectedTree}]}
                    selectionMode="multiple"
                    onSelectionChange={changeSelectedNode}
                    className="chart"
                />   
            </div>
        </>
    )
}

export default FoundationStatistiscData