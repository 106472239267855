import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import CenterAccountPlanForm from "../../../../../../../components/maestros-center/center-account-plan-form/CenterAccountPlanForm"
import CenterAccountPlanService from '../../../../../../../services/center-accounting/center-account-plan/centerAccountPlan.service'
import MainCommonView from '../../../../../../../components/common/MainCommonView'
import useInputs from "../../../../../../../utils/hooks/useInputs"
import { validators } from '../../../../../../../utils/helpers/validators'
import auxFunction from '../../../../../../../utils/helpers/auxFun'
import "./centerAccountPlan.scss"

const CenterAccountPlan = () => {

    const {id} = useParams()
    const { formatMessage: msg } = useIntl()

    const { inputs, updateInputs, onChangeInputs } = useInputs()

    useEffect(() => {
        getCenterPlanAccountById()     
    }, [])

    const getCenterPlanAccountById = async () => {
        const resp = await CenterAccountPlanService.getById(id)
        if (resp) updateInputs(resp?.resp)
    }

    const handleSave = async () => {
        const body = parseInputs(inputs)
        const resp = (await CenterAccountPlanService.update({_id: inputs?._id, body}))?.resp
        if (resp) updateInputs(resp)
    }
    
    const parseInputs = () => ({
        costCenter: auxFunction.returnObjectId(inputs?.costCenter),
        agroupation: auxFunction.returnIds(inputs?.agroupation),
        subtypes: inputs?.subtypes,
        justificationSubvention: auxFunction.returnObjectId(inputs?.justificationSubvention),
        subventions: inputs?.justificationSubvention
            ? auxFunction.returnIds(inputs?.subventions) 
            : [],
        imputation: auxFunction.returnObjectId(inputs?.imputation),
        distributionCriteriaList: inputs?.distributionCriteriaList?.map(x => (
            { distributionCriteria: x?.distributionCriteria, value: x?.value}))
    })

    const areInputsValid = validators.isEntityValues([inputs?.costCenter])

    const headerOptions = {
        title: msg({id: 'accounting.plan.center'}),
        handlerHeaderActions: {
            firstHandlerAction: handleSave, 
            textFirstAction: "button.save", 
            firstIconClass: "pi-save",
            firstActionDisabled: !areInputsValid
        }
    }

    const data = (
        <CenterAccountPlanForm
            inputs={inputs}
            onChangeInputs={onChangeInputs}
        />
    )

    return (
        <div className="main-common-view">
            <MainCommonView 
                headerOptions={headerOptions}
                componentData={data}
            />
        </div>
    )
}

export default CenterAccountPlan
