import _ from 'lodash'
import React from "react"
import TreeSelectComp from "../../form-components/dropdown/TreeSelectComp"
import { onCellEditComplete } from "../../../../utils/helpers/data-table/common"

const treeSelectEditor = (optionsEditor, options) => {

    const getValues = () => {
        if (optionsEditor.value.length > 0) {
            // convertir en objetos
            let teamsObject = {}
            optionsEditor.value.forEach((team) => {
                if (_.has(team, '_id')) {
                    const { _id } = team
                    teamsObject = {...teamsObject, [_id]: { checked: true, partialChecked: true }}
                } else {
                    teamsObject = {...teamsObject, ...team }
                }
            })
            return teamsObject
        }
        return optionsEditor.value   
    }

    return (
        <TreeSelectComp
            filter
            name="options"
            value={getValues()}
            onChange={({ target: { value } }) => optionsEditor.editorCallback(value)}
            options={options}
            display="chip"
            metaKeySelection={false}
            inputId="multiselect"
            selectionMode="checkbox"
            partialChecked
        />
    )
   
}

const editTreeSelectRowColumn = ({
    options,
}) => {
    return {
        editor: (optionsEditor) => treeSelectEditor(optionsEditor, options),
        onCellEditComplete, 
        key: Math.random()
    }
}

export default editTreeSelectRowColumn