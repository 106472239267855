import React from 'react'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledChip from "../../common/form-components/labeled-inputs/LabeledChips"
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'
import './subtypeForm.scss'

const SubtypeForm = ({ 
    inputs, 
    handleChange,
    handleSave 
}) => {

    const validateForm = validators.isEntityValues([inputs?.name]) 
        && (inputs?.group?.length > 0)

    return (
        <div className="subtypes-form">
            <div className="modal-content-wrapper">
                <div className="control-wrapper">
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                    <LabeledChip 
                        idLabel='options'
                        required
                        name='group'
                        value={inputs?.group}
                        onChange={(e) => handleChange(e)}
                        separator=','
                    />
                    <LabeledTextArea 
                        idLabel='description'
                        name='description'
                        value={inputs?.description}
                        onChange={(e) => handleChange(e)}
                        numRows={3}
                        numColumns={15}
                    />
                </div>
            </div>
            <div className="modal-btn-container">
                <SaveButton 
                    onClick={() => handleSave(inputs?._id)}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default SubtypeForm
