import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from '../../../../../../utils/hooks/useLoading'
import CardsOverview from '../../../../../../components/common-courses/view-content/CardsOverview'
import BudgetService from '../../../../../../services/centers/courses/budgets/budgets.service'
import BreadcrumbLink from '../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../routes/centers/center-course/pathname'

const ActivityIncomes = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId} = useParams()

    const [activities, setActivities] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getActivityIncomes()
    }, [])

    const getActivityIncomes = async () => {
        startLoading()
        const resp = await BudgetService.getActivities(courseId, balanceId)
        if (resp) {
            setActivities(resp?.resp)
        }
        stopLoading()
    }

    const goToLink = (id, item) => {
        if (item?.source === 'fees') return CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES(centerId, courseId, balanceId, item?.concept)
        return CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_SUBVENTIONS(centerId, courseId, balanceId, item?.concept)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: "Ingressos per les activitats"
        }
    ]

    const cardList = {
        list: [
            ...(activities?.fees || []).map(item => ({
                ...item,
                name: item?.conceptName,
                source: 'fees'
            })),
            ...(activities?.subventions || []).map(item => ({
                ...item,
                source: 'subventions'
            })),
        ],
        gotToLink: goToLink,
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default ActivityIncomes