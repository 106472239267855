import React from "react"
import { validators } from "../../../utils/helpers/validators"
import SaveButton from "../../common/buttons/SaveButton"
import LabeledMultiSelect from "../../common/form-components/labeled-dropdown/LabeledMultiSelect"
import LabeledText from "../../common/form-components/labeled-inputs/LabeledText"

const FeesForm = ({
    inputs,
    handleChange,
    handleSubmit,
    subetapas
}) => {
    const validateForm = validators.isEntityValues([inputs?.name]) 

    return (
        <>
            <div>
                <LabeledText
                    idLabel='name'
                    required
                    name='name'
                    value={inputs?.name}
                    onChange={(e) => handleChange(e)} 
                />
                <LabeledText
                    idLabel='description'
                    name='description'
                    value={inputs?.description}
                    onChange={(e) => handleChange(e)} 
                />
                <LabeledMultiSelect 
                    idLabel='substages'
                    name='subetapas'
                    value={inputs?.subetapas}
                    onChange={(e) => handleChange(e)}
                    options={subetapas}
                    optionLabel="name"
                    optionValue="_id"
                />
            </div>
            <div className="modal-btn-container">
                <SaveButton
                    onClick={() => handleSubmit()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal" 
                />
            </div>
        </>
    )
}

export default FeesForm