import { haveFilterQuery, paginator, haveSort } from "../common/auxFun"

export const endpointsStaff = {
    GENERIC: '/v1/maestros/working/staff',
    PENDING: '/v1/maestros/working/staff/noPersonal',
    LOAD: ({filter, limit, skip, sort}) => `/v1/maestros/working/staff/${haveFilterQuery(filter)}${paginator(limit, skip)}${haveSort(sort)}`,
    BY_ID: (id) => `/v1/maestros/working/staff/${id}`,
    BASIC_BY_ID: (id) => `/v1/maestros/working/staff/${id}/basic`,
    LABORAL_BY_ID: (id) => `/v1/maestros/working/staff/${id}/labor`,
    SOCIALS_BY_ID: (id) => `/v1/maestros/working/staff/${id}/socialSecurity`
}