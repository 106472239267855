import React from "react"
import Button from "./index"

const ViewButton = ({
    onClick
}) => {
    return (
        <Button 
            type="button"
            btnClass="btn-icon"
            iconRight
            iconSize="1.1rem"
            iconClass="pi-eye"
            onHandleClick={onClick}
        />
    )

}

export default ViewButton