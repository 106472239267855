import React from "react"
import Delete from '../../../assets/imgs/papelera.svg'

const DeleteButtonImg = ({
    onClick
}) => {
    return (
        <button type="button" onClick={onClick} className="btn-icon">
            <img width="18" height="18" src={Delete} alt="delete" />
        </button>
    )

}

export default DeleteButtonImg