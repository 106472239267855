import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import MainCommonView from '../../components/common/MainCommonView'
import MaestrosDataTable from '../../components/common/dataTable/MaestrosDataTable'
import Modal from '../../components/modal/Modal'
import FormUser from '../../components/users/FormUser'
import UserService from '../../services/users/users.service'
import { roles } from '../../utils/constants/roles'
import { bodyParseDate } from '../../components/common/dataTable/body/bodyColumnsTypes'
import editDropdownRowColumn from '../../components/common/dataTable/editors/editDropDownRowColumn'
import editTextRowColumn from '../../utils/helpers/data-table/column-types/editTextRowColumn'
import rowDelete from '../../utils/helpers/data-table/column-types/rowDelete'
import rowEditor from '../../utils/helpers/data-table/column-types/rowEditor'
import useHandlerBasicActions from '../../utils/hooks/base-adapter/useHandlerBasicActions'
import useInputs from '../../utils/hooks/useInputs'
import useModal from '../../utils/hooks/useModal'
import auxFunction from '../../utils/helpers/auxFun'

const Users = () => {

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs } = useInputs({})

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        getAll: UserService.getAll,
        createAction: UserService.create,
        removeAction: UserService.remove,
        editAction: UserService.update
    })

    const handleCreate = async () => {
        const body = {
            ...inputs,
            role: auxFunction.returnObjectId(inputs?.role)
        }
        const resp = await dataFacade.create(body)
        if (resp) {
            updateInputs({})
            toggle()
        }
    }

    const toggleModal = () => {
        toggle()
        updateInputs({})
    }

    const onRowEditComplete = async ({newData}) => {
        const body = _.omit({ ...newData, role: auxFunction.returnObjectId(newData?.role)}, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const { _id } = newData
        dataFacade.edit({_id, body})
    }
    
    const headerOptions = {
        title: msg({id: 'users'}),
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'users',
        columns: [
            { title: msg({id: 'name'}), dataKey: 'name' },
            { title: msg({id: 'email'}), dataKey: 'email' },
            { title: msg({id: 'role'}), dataKey: 'role' },
        ],
        exportService: () => UserService.getAll({})
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }
    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: dataFacade?.data?.resp,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacade?.sortOptions
            }}
            columns={([
                {
                    field: 'name',
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                {
                    field: 'email',
                    sortable: true,
                    header: 'email',
                    ...editTextRowColumn
                },
                {
                    field: "role",
                    sortable: true,
                    header: 'role',
                    style: { width: "20%"},
                    ...editDropdownRowColumn({
                        options: roles,
                        name: 'role'
                    })
                },
                {
                    field: 'createAt',
                    sortable: true,
                    header: 'date.create',
                    headerClassName: 'p-align-end',
                    className: 'p-align-end',
                    style: { width: "20%"},
                    body: (rowData) => bodyParseDate({date: rowData?.createdAt})
                },
                {...rowDelete({ handleDelete: dataFacade?.remove }), style: { width: "2%"} },
                {...rowEditor(), style: { width: "10%", minWidth: "100px"}},
            ])}
        />
    )

    return (
        <div className='users-container container__with-background with-pagination'> 
            <MainCommonView
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataTable}
            />

            <Modal
                isShowing={isShowing}
                hide={() => {
                    toggleModal()
                    updateInputs({})
                }}
                width="90%"
                maxWidth="550px"
                title={msg({id: 'user.new'})}
                context={(
                    <FormUser 
                        inputs={inputs} 
                        handleSave={handleCreate}
                        handleChange={onChangeInputs} 
                    />
                )}
            />
        </div>
    )
}
export default Users