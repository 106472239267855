import React, { useRef } from 'react'
import { FileUpload } from 'primereact/fileupload'
import { FormattedMessage, useIntl } from 'react-intl'
import { defineUploadButtonLabelForFile } from "../../utils/helpers/uploadFiles"

const FILE_LABEL_LENGTH = 25

// TODO: Refactor taking to account FileYearItem
const FileEditor = ({options}) => {
    const {formatMessage: msg} = useIntl()

    const fileControlLabel = defineUploadButtonLabel(options?.value, FILE_LABEL_LENGTH)
    const fileEditor = useRef()

    const selectHandler = (changeEvent) => {
        const files = changeEvent?.files

        if (files?.length > 0 && files[0]) {
            if (typeof options?.editorCallback === "function") {
                options.editorCallback(files[0])
            }

            if (typeof options?.onHandleChange === "function") {
                options.onHandleChange({target: {name: options?.name, files}})
            }
        }
    }

    const deleteHandler = async () => { 
        if (typeof options?.onDelete === 'function') {
            const result = await options.onDelete(options.id)

            if (result?.payload?.resp && !result?.payload?.resp?.file) {
                fileEditor?.current?.clear();

                if (options?.editorCallback === "function") {
                    options.editorCallback(null)
                }
            }
        }
    }

    function defineUploadButtonLabel(fileValueObject, maxLabelLength) {
        return defineUploadButtonLabelForFile(fileValueObject, maxLabelLength, msg({id: "change"}), msg({id: "upload"}))
    }

    return (
        <div style={{display: "flex", justifyContent: 'center'}}>
            <FileUpload
                name={options?.name}
                mode="basic"
                accept={options?.accept}
                maxFileSize={1000000}
                chooseLabel={fileControlLabel}
                ref={fileEditor}
                onSelect={selectHandler}
                style={{flexGrow: "1"}}
            />
            { !!(options?.value?.path) && (typeof options?.onDelete === "function")
                && (
                    <button type="button" 
                        onClick={deleteHandler}
                        className="btn button-white button-white__padding__8_15" 
                        style={{flexGrow: "0.7", marginLeft: "0.5em"}}>
                        <FormattedMessage id="button.delete" />
                    </button>
                )
            }
        </div>
    )
}

export default FileEditor