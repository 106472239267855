import { maestrosEcoFin } from '../../../routes/maestros/maestros-ecofin/maestrosEcoFin.pathname'
import imputacion from '../../../assets/imgs/maestros/maestros-ecofin/imputacion.svg'
import account from '../../../assets/imgs/maestros/maestros-accounting/cuentas.svg'
import balance from '../../../assets/imgs/maestros/maestros-ecofin/balance-icon.svg'

export const maestrosEcofinKeys = {
    ACCOUNTS: 'accounts'
}

export const MAESTROS_ECOFIN = [
    {
        id: 'accounts',
        name: 'comptes',
        route: maestrosEcoFin.ACCOUNTING_ACCOUNTS,
        src: account,
        key: maestrosEcofinKeys.ACCOUNTS
    },
    {
        id: 'imputation',
        name: 'imputation',
        route: maestrosEcoFin.IMPUTATION,
        src: imputacion,
    },
    {
        id: 'template.balance',
        name: 'template.balance',
        route: maestrosEcoFin.TEMPLATE_BALANCE,
        src: balance,
    }
]