import React, { useMemo } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import MainCommonView from "../../../../components/common/MainCommonView"
import ImportFileModal from "../../../../components/import-files/ImportFileModal"
import Modal from "../../../../components/modal/Modal"
import { STAFF_PROFILE } from "../../../../routes/maestros/maestros-laboral/maestrosLaboral.pathname"
import StaffService from "../../../../services/staff/staff.service"
import { uploadFileWithLoading } from "../../../../utils/helpers/uploadFiles"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import useModal from "../../../../utils/hooks/useModal"
import { bodyRenderBooleanYesNo} from "../../../../components/common/dataTable/body/bodyColumnsTypes"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import './staff.scss'
import rowSeeWithTooltip from "../../../../components/common/dataTable/body/rowSeeWithTooltip"

const Staff = () => {
    const {formatMessage: msg} = useIntl()
    const navigate = useNavigate()

    const { isShowing, toggle } = useModal()

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'basic.apellidosYNombre',
        getAll: StaffService.getAll,
    })

    const importCreate = async (file) => uploadFileWithLoading({
        file, 
        uploadService: StaffService.uploadFile, 
        onSuccess: dataFacade.reload,
        onFinally: toggle
    })

    const headerOptions = {
        title: msg({id: 'staff'}),
        searchOptions: dataFacade?.searchOptions
    }

    const importExportFilesOptions = {
        title: 'accounts.list',
        columns: [
            { title: msg({ id: 'center.code' }), dataKey: 'labor.coCentro' },
            { title: msg({ id: 'center.description' }), dataKey: 'labor.dcCentro' },
            { title: msg({ id: 'first.last.name' }), dataKey: 'basic.apellidosYNombre' },
            { title: msg({ id: 'category.description' }), dataKey: 'labor.dcCategoria' },
            { title: msg({ id: 'antiquity.date.1' }), dataKey: 'labor.feAntiguedad1' },
            { title: msg({ id: 'contract.type.description' }), dataKey: 'labor.dcTipoContrato' },
            { title: msg({ id: 'ftp' }), dataKey: 'labor.daCoEfTp' }
        ],
        exportService: () => StaffService.getAll({}),
        importService: toggle
    }

    const pagerOptions = {
        ...dataFacade?.pagerOptions,
        totalRecords: dataFacade?.data?.total
    }

    const transformData = (resp) => {
        if (!resp) return []
        const transformed = resp?.reduce((accumulated, res) => {
            const laboralWithOtherDuplicated = res?.labor?.map(labor => { return {...res, labor} })
            return [...accumulated, ...laboralWithOtherDuplicated]
        }, [])
    
        return transformed
    }

    const transformedData = useMemo(() => transformData(dataFacade?.data?.resp), [dataFacade?.data?.resp])

    const dataControl = (
        <MaestrosDataTable 
            tableOptions={{
                value: transformedData,
                editMode: 'row',
                ...dataFacade?.sortOptions,
                rowGroupMode: "rowspan",
                groupRowsBy: ["basic.apellidosYNombre", "basic.daDni"]

            }}
            columns={([
                {
                    field: "basic.apellidosYNombre",
                    sortable: true,
                    header: 'first.last.name'
                },
                {
                    field: "basic.daDni",
                    header: 'dni' 
                },
                {
                    field: "isPersonal",
                    header: 'confirmed',
                    body: (rowData) => bodyRenderBooleanYesNo({value: rowData.isPersonal})
                },
                {
                    field: "isIntern",
                    header: 'internal',
                    body: (rowData) => bodyRenderBooleanYesNo({value: rowData.isPersonal})
                },
                {
                    field: "labor.coCentro",
                    sortable: true,
                    header: 'center.code'
                },
                {
                    field: "labor.dcCentro",
                    header: 'center.description' 
                },
                {
                    field: "labor.dcCategoria",
                    header: 'category.description' 
                },
                {
                    field: "labor.feAntiguedad1",
                    header: 'antiquity.date.1' 
                },
                {
                    field: "labor.dcTipoContrato",
                    header: 'contract.type.description'
                },
                {
                    field: "labor.daCoEfTp",
                    header: 'ftp' 
                },
                {
                    className: 'row-delete column-tooltip',
                    field: "masters",
                    ...rowSeeWithTooltip({
                        textTooltip: msg({id: 'info.staff'}),
                        onClick: (rowData) => navigate(STAFF_PROFILE(rowData?._id))
                    })
                },
            ])}
        />
    )

    return (
        <div className="staff container__with-background">
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                pagerOptions={pagerOptions}
                componentData={dataControl}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="350px"
                title={msg({id: 'import.staff' })}
                context={(
                    <ImportFileModal
                        handleSubmit={importCreate} 
                        typefile='.xlsx'
                    />
                )}
            />
        </div>
    )
}

export default Staff