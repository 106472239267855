import React from "react"
import { validators } from "../../../utils/helpers/validators"
import LabeledText from "../../common/form-components/labeled-inputs/LabeledText"
import SaveButton from "../../common/buttons/SaveButton"
import './formPost.scss'

const FormPost = ({inputs, handleChange, handleSave}) => {

    const validateForm = validators.isEntityValues([inputs?.name])

    return (
        <div className='new-post-foundation-team'>
            <div className='modal-content-wrapper'>
                <div className='control-wrapper'>
                    <LabeledText
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)} 
                    />
                </div>

            </div>
            <div className='modal-btn-container'>
             
                <SaveButton 
                    onClick={handleSave}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )

}

export default FormPost