
import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsImputation } from "./endpoints"

const ImputationService = {
    create: async (body) => POST(endpointsImputation.GENERIC, body),
    getAll: async ({ filter, limit, skip, sort }) => GET(endpointsImputation.LOAD({filter, limit, skip, sort})),
    update: async ({_id, body}) => PATCH(endpointsImputation.BY_ID(_id), body),
    remove: async (id) => DELETE(endpointsImputation.BY_ID(id)) 
}

export default ImputationService