import React, { useContext, useState, useEffect } from "react"
import CenterLogo from "../../centers/center-logo/CenterLogo"
import AddButton from "../../common/buttons/AddButton"
import DeleteButton from "../../common/buttons/DeleteButton"
import DropdownComp from "../../common/form-components/dropdown/DropdownComp"
import { LayoutCenterContext } from "../../common-courses/context/LayoutCenterContex"
import "./centerCoursesHeader.scss"

const CenterCoursesHeader = ({
    onChangeCourse,
    handleCreateCourse,
    onDelete,
}) => {

    const { 
        center,
        courses,
        courseSeletedDropdawn,
        flagCreateCourse,
    } = useContext(LayoutCenterContext)

    const [ course, setCourse ] = useState({})

    useEffect(() => {
        setCourse(courses.filter(crs => crs._id === courseSeletedDropdawn)[0])
    }, [courseSeletedDropdawn])
    
    return (

        <div className='header-courses'>
            
            <div className="title-course-header">
                <h2 className="title__subtitle">{ center?.name }</h2>
                { /** TODO: cambiar componente */
                    center && (
                        <CenterLogo 
                            centerId={center?._id}
                            centerImgURL={center?.Img}
                        />
                    )
                }  
            </div>
            <div className="actions-course-header">
                {!flagCreateCourse && courses?.length > 0 && (
                    <DropdownComp
                        options={courses}
                        value={course}
                        onChange={(e) => onChangeCourse(e)}
                        optionLabel='name'
                    />
                )}
                
                {!flagCreateCourse && (
                    <AddButton
                        btnAdd="button.new.course"
                        onClick={() => handleCreateCourse()}
                    />
                )}

                {!flagCreateCourse && (
                    courseSeletedDropdawn && courses?.length>0 && (
                        <DeleteButton
                            onClick={() => onDelete(courseSeletedDropdawn)}
                        />
                    )
                )}
            </div>
            
        </div>
    )
}

export default CenterCoursesHeader