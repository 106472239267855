import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import useLoading from "../../../../../../utils/hooks/useLoading"
import { CENTER_COURSE_DETAILS } from "../../../../../../routes/centers/center-course/pathname"
import CardsOverview from "../../../../../../components/common-courses/view-content/CardsOverview"
import BreadcrumbLink from "../../../../../../components/common/breadcrumb/BreadcrumbLink"
import BudgetService from "../../../../../../services/centers/courses/budgets/budgets.service"

const ConceptAccounts = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId, conceptId} = useParams()

    const [accounts, setAccounts] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getConcepts()
    }, [])

    const getConcepts = async () => {
        startLoading()
        const resp = await BudgetService.getAccounts(courseId, conceptId)
        if (resp) {
            setAccounts(resp?.resp)
        }
        stopLoading()
    }

    const goToConcepts = (id, item) => { 
        return CENTER_COURSE_DETAILS.BUDGET_INCOMES_DATA(centerId, courseId, balanceId, conceptId, item?.account)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: accounts?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_BALANCES(centerId, courseId, accounts?.balanceTemplate)} 
                text={accounts?.templateName} /> 
        },
        {
            label: accounts?.conceptName
        }
    ]

    const cardList = {
        list: accounts?.accounts,
        gotToLink: goToConcepts,
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default ConceptAccounts