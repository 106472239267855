import React from 'react'
import { useSelector } from 'react-redux'
import { validators } from '../../../utils/helpers/validators'
import SaveButton from '../../common/buttons/SaveButton'
import LabeledMultiSelect from '../../common/form-components/labeled-dropdown/LabeledMultiSelect'
import LabeledText from '../../common/form-components/labeled-inputs/LabeledText'
import LabeledTextArea from '../../common/form-components/labeled-inputs/LabeledTextArea'

const FormTipology = (
    { 
        inputs, 
        handleChange,
        handleSave 
    }
) => {

    const alletapas = useSelector(state => state?.etapas)
    
    const validateForm = validators.isEntityValues([inputs?.name])

    return (
        <div className='form-tipologia'>
            <div className='modal-content-wrapper'> 
                <div className='control-wrapper'>
                    <LabeledText 
                        idLabel='name'
                        required
                        name='name'
                        value={inputs?.name}
                        onChange={(e) => handleChange(e)}
                    />
                </div>
                
                <LabeledMultiSelect 
                    idLabel='etapas'
                    name='etapas'
                    value={inputs?.etapas}
                    onChange={(e) => handleChange(e)}
                    options={alletapas?.resp}
                    optionLabel="name"
                    optionValue="_id"
                />

                <LabeledTextArea 
                    idLabel='description'
                    name='description'
                    value={inputs?.values}
                    onChange={(e) => handleChange(e)}
                    numRows={3}
                    numColumns={15}
                />
            </div>
        
            <div className='modal-btn-container'>
                <SaveButton 
                    onClick={() => handleSave()}
                    isDisabled={!validateForm}
                    btnClass="btn-save-modal"
                />
            </div>
        </div>
    )
}

export default FormTipology