import React from "react"
import { Dropdown } from "primereact/dropdown"
import { useIntl } from "react-intl"
import './dropdownComp.scss'

const DropdownComp = ({
    name,
    value,
    options,
    idPlaceHolder, 
    emptyMessage, 
    emptyFilterMessage, 
    ...propsDropdown
}) => {

    const { formatMessage: msg } = useIntl()

    return (
        <Dropdown
            name={name}
            value={value}
            options={options || []}
            placeholder={msg({ id: (idPlaceHolder || 'placeholder.select') })}
            emptyMessage={emptyMessage || msg({id: 'placeholder.not.options'})}
            emptyFilterMessage={emptyFilterMessage || msg({id: 'placeholder.not.options'})}
            optionLabel={propsDropdown?.optionLabel || 'name'} //|| 'name' 
            pt={{
                item: ({ context }) => ({
                    className: context.selected ? 'item-active' : undefined
                })
            }}
            {...propsDropdown}
        />
    )
}

export default DropdownComp