import React, { useEffect, useState, useContext } from "react"
import { useIntl } from "react-intl"
import { useNavigate, useParams } from "react-router-dom"
import { LayoutCenterContext } from "../../../../../components/common-courses/context/LayoutCenterContex"
import useLoading from "../../../../../utils/hooks/useLoading"
import Dashboard from "../../../../../components/common-courses/view-content/Dashboard"
import EducationOfferService from "../../../../../services/centers/courses/education-offer-and-service/educationOffer.service"
import editNumberRowColumn from "../../../../../components/common/dataTable/editors/editNumberRowColumn"
import BreadcrumbLink from "../../../../../components/common/breadcrumb/BreadcrumbLink"
import { CENTER_COURSE_DETAILS } from "../../../../../routes/centers/center-course/pathname"
import useOfferNavigation from "../../../../../components/center-courses/education-offer/hooks/useOfferNavigation"
import BugetTable from "../../../../../components/common/dataTable/BugetTable"
import "./nee.scss"

const Nee = () => {

    const { centerId, courseId, educationOfferId } = useParams()
    const { formatMessage: msg } = useIntl()
    const navigate = useNavigate()

    const { nextInNee } = useOfferNavigation()

    const [nee, setNee] = useState({})
    const [ refactoredNee, setRefactoredNee] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(true)

    const { setFlagConfirm } = useContext(LayoutCenterContext)

    useEffect(() => {
        getNee()
    }, [educationOfferId])

    const getNee = async () => {
        startLoading()
        const resp = await EducationOfferService.getNee(courseId, educationOfferId)
        if (resp) {
            setNee(resp?.resp)
            setRefactoredNee(setNeeDataTable(resp?.resp))
        }
        stopLoading()
    }

    const setNeeDataTable = (neeData) => {
        return {
            etapa: neeData?.etapa,
            course: neeData?.course,
            nee: parseNeeToTable(neeData?.nee),
            neeConfirmation: neeData?.neeConfirmation
        }
    }

    const parseNeeToTable = (dateNee) => {
        if (dateNee?.length === 0) return []
        return dateNee?.map(n => {
            const obj = {
                nee: n?.nee,
                name: n?.name,
                num: n?.num,
                _id: n?._id
            }
            if (n?.specialties) {
                obj.specialties = Object.assign(
                    {},
                    ...n?.specialties?.map(s => ({ [s?.specialty]: s?.num }))
                )
                obj.specialtiesNames = n?.specialties?.reduce((acc, s) => {
                    acc[s?.specialty] = s?.name
                    return acc
                }, {})
            }
            if (n?.subetapas) {
                obj.subetapas = Object.assign(
                    {},
                    ...n?.subetapas?.map(s => ({ [s?.subetapa]: s.num }))
                )
                obj.subetapasNames = n?.subetapas?.reduce((acc, s) => {
                    acc[s?.subetapa] = s?.name
                    return acc
                }, {})
            }
            return obj
        })
    }

    const transformBackData = (data) => {
        return {
            etapa: data?.etapa,
            course: data?.course,
            nee: parseNeeToBack(data?.nee),
            neeConfirmation: data?.neeConfirmation
        }
    }

    const parseNeeToBack = (data) => {
        if (data?.length === 0) return []
        return data?.map(n => {
            const obj = {
                nee: n?.nee,
                name: n?.name,
                num: n?.num,
                _id: n?._id
            }
            if (n?.specialties) {
                obj.specialties = Object.entries(n?.specialties).map(([specialty, num]) => ({
                    specialty,
                    num,
                    name: n?.specialtiesNames[specialty]
                }))
            }
            if (n?.subetapas) {
                obj.subetapas = Object.entries(n?.subetapas).map(([subetapa, num]) => ({
                    subetapa,
                    num,
                    name: n?.subetapasNames[subetapa]
                }))
            }
            return obj
        })
    }

    const getColumns = () => {
        const firstNeeItem = nee?.nee?.[0]
        if (firstNeeItem && firstNeeItem?.specialties) {
            return firstNeeItem?.specialties.map(specialty => ({
                field: 'specialties.' + specialty?.specialty,
                className: 'p-datatype-number',
                headerClassName: "p-align-end",
                bodyClassName: 'editRow',
                header: specialty?.name,
                noTraslateHeader: true,
                style: { width: "10%"},
                ...editNumberRowColumn({})
            }))
        }
        if (firstNeeItem && firstNeeItem?.subetapas) {
            return firstNeeItem.subetapas.map(subetapa => ({
                field: 'subetapas.' + subetapa?.subetapa,
                className: 'p-datatype-number',
                headerClassName: "p-align-end",
                bodyClassName: 'editRow',
                header: subetapa?.name,
                noTraslateHeader: true,
                style: { width: "10%"},
                ...editNumberRowColumn({})
            }))
        }
        return []
    }

    const getColumnsFooter = () => {
        const getDataColumns = getColumns()?.map(column => ({
            footer: 'sum',
            field: column.field,
            className: 'p-datatype-number',
        }))
        return [
            { footer: msg({id: 'total'}), colSpan: 1, footerStyle: { textAlign: 'left' } },
            ...getDataColumns
        ]
    }

    const handleConfirm = async () => {
        handleSave(true)
    }

    const handleSave = async (confirm = false) => {
        setTimeout(async () => {
            const data = transformBackData(refactoredNee)
            const resp = confirm===true
                ? await EducationOfferService.confirmEtapaNee(courseId, educationOfferId, data)
                : await EducationOfferService.updateNee(courseId, educationOfferId, data)
            if (confirm) setFlagConfirm(true)
            if (resp) {
                setNee(resp?.resp)
                setRefactoredNee(setNeeDataTable(resp?.resp))
                nextInNee()
            }
        }, 0)
    }

    const handlePrevious = () => {
        navigate(CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID_CLASS(centerId, courseId, educationOfferId))
    }

    const handleNext = () => {
        nextInNee()
    }

    const breadcrumb = [
        {
            label: msg({ id: 'educative.offer.and.services' }),
            template: <BreadcrumbLink
                path={CENTER_COURSE_DETAILS.EDUCATION_OFFER(centerId, courseId)}
                text={msg({ id: 'educative.offer.and.services' })} />
        },
        ...(nee?.path || [])
            ?.map(p => ({
                label: p?.name,
                template: <BreadcrumbLink
                    path={CENTER_COURSE_DETAILS.EDUCATION_OFFER_BY_ID(centerId, courseId, p?._id)}
                    text={p?.name} />
            })),
        {
            label: msg({ id: 'nee'}).toUpperCase()
        }
    ]

    const headerOptions = {
        loading,
        breadcrumb,
        handleConfirm,
        handleSave,
    }

    const table = (
        <div className="education-offer-nee">
            <BugetTable
                tableOptions={{
                    loading,
                    value: refactoredNee?.nee,
                    editMode: 'cell',
                }}
                columns={[
                    { field: 'name', bodyClassName: 'roll-title', header: msg({ id: 'name' }), noTraslateHeader: true },
                    ...getColumns()
                ]}
                columnsFooter={getColumnsFooter()}
            />
        </div>

    )

    return (
        <Dashboard
            content={table}
            headerOptions={headerOptions}
            footerOptions={{
                handlePrevious,
                handleNext
            }}
        />
    )
}

export default Nee
