import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import LabeledNumber from "../../common/form-components/labeled-inputs/LabeledNumber"
import LabeledDropdown from '../../common/form-components/labeled-dropdown/LabeledDropdown'
import LabeledMultiSelect from '../../common/form-components/labeled-dropdown/LabeledMultiSelect'
import { getAll as getAllImputation } from '../../../services/imputation/imputation.slice'
import { getAll as getAllSubtypes } from '../../../services/center-accounting/subtypes/subtypes.slice'
import { getAll as getAllCostCenter } from '../../../services/center-accounting/center-cost-center/centersCostCenter.slice'
import { getAll as getAllSubventions } from '../../../services/center-subvention/subvention.slice'
import { getAllEtapas } from '../../../services/stages/etapas.slice'
import { getAllServices } from '../../../services/center-services/centerServices.slice'
import { getAll as getAllCriteriaDistribution } from '../../../services/center-accounting/distribution-criteria/distributionCriteria.slice'
import "./centerAccountPlanForm.scss"

const CenterAccountPlanForm = ({ 
    inputs,
    onChangeInputs
}) => {

    const { formatMessage: msg } = useIntl()
    const dispatch = useDispatch()

    const [ groupingOptions, setgroupingOptions ] = useState([])

    const costCenters = (useSelector(state => state?.centersCostCenter))?.resp
    const stages = (useSelector((state) => state?.etapas))?.resp
    const services = (useSelector((state) => state?.services))?.resp
    const subtypes = (useSelector(state => state?.subtypes))?.resp
    
    const imputations = (useSelector(state => state?.imputations))?.resp
    const subventions = (useSelector(state => state?.subventions))?.resp
    const distributionCriteria = (useSelector(state => state?.distributionCriteria))?.resp

    useEffect(() => {
        getAllImp()
        getAllStages()
        getServices()
        getSubtypes()
        getCostCenter()
        getSubventions()
        getDistributions() 
    }, [])

    useEffect(() => {
        setgroupingOptions(getOptionsByType())
    }, [inputs?.costCenter])

    const getAllImp = () => dispatch(getAllImputation({}))
    const getSubtypes = () => dispatch(getAllSubtypes({}))
    const getCostCenter = () => dispatch(getAllCostCenter({}))
    const getAllStages = () => dispatch(getAllEtapas({}))
    const getServices = () => dispatch(getAllServices({}))
    const getSubventions = () => dispatch(getAllSubventions({}))
    const getDistributions = () => dispatch(getAllCriteriaDistribution({}))

    const getOptionsByType = () => {
        if (inputs?.costCenter?.description === 'Per etapes i serveis') {
            return [...services, ...stages] 
        } 
        if (inputs?.costCenter?.typeGrouping === 'services') {
            return services
        }
        if (inputs?.costCenter?.typeGrouping === 'stages') {
            return stages
        }        
        return []
    }

    const optionsJustificationSubvention = [
        {_id: true, name: msg({id: 'yes'})}, 
        {_id: false, name: msg({id: 'no'})}
    ]

    const isJustificationSubvention = ({
        _id: inputs?.justificationSubvention,
        name: msg({id: inputs?.justificationSubvention ? 'yes' : 'no'})
    })

    const onChangeSubtype = (e) => {
        const { name, value } = e?.target
        const searchInputSubtype = inputs?.subtypes?.length > 0 ? inputs?.subtypes?.map(x => {
            if (x?.id === name) {
                return {
                    id: name, 
                    groups: value?.name 
                }
            }
            return x
        }) : [{id: name, groups: value?.name}]
        onChangeInputs({target: {name: 'subtypes', value: searchInputSubtype}})
    }

    const onChangeDistributionCriteria = (e) => {
        const { name, value } = e.target
        const distributionCriteriaList = inputs?.distributionCriteriaList || []
        const existingCriteriaIndex = distributionCriteriaList.findIndex(
            (x) => x.distributionCriteria === name
        )

        if (value === '' || value === null || value === undefined) {
            if (existingCriteriaIndex !== -1) {
                const updatedCriteriaList = [
                    ...distributionCriteriaList.slice(0, existingCriteriaIndex),
                    ...distributionCriteriaList.slice(existingCriteriaIndex + 1),
                ]
                onChangeInputs({
                    target: { name: 'distributionCriteriaList', value: updatedCriteriaList },
                })
            }
            return
        }

        let updatedCriteriaList = []
        if (existingCriteriaIndex !== -1) {
            updatedCriteriaList = [...distributionCriteriaList]
            updatedCriteriaList[existingCriteriaIndex] = {
                distributionCriteria: name,
                value,
            }
        } else {
            updatedCriteriaList = [
                ...distributionCriteriaList,
                { distributionCriteria: name, value },
            ]
        }

        onChangeInputs({
            target: { name: 'distributionCriteriaList', value: updatedCriteriaList },
        })
    }

    return (
        <div className="accounting-plan-details">
            <div className='header-accounting-plan'>
                <div className='header-accounting-plan__content'>
                    <h2 className='title__subtitle'>{inputs?.numAccount}</h2>
                    <div className='title__red-subtitle'>
                        { inputs?.account?.description}
                    </div>
                </div>
                <div className='header-accounting-plan__content'>
                    <h2 className='title__subtitle'>{inputs?.numSubaccount}</h2>
                    <div className='title__red-subtitle'>
                        { inputs?.subaccount?.description }
                    </div>
                </div>
            </div>
            <div className="box-container-style">             
                <LabeledDropdown 
                    idLabel="costCenter"
                    required
                    name='costCenter'
                    value={inputs?.costCenter}
                    options={costCenters}
                    showClear={false}
                    filter
                    onChange={(e) => onChangeInputs(e)}
                />                   
            
                <LabeledMultiSelect
                    idLabel="groupings"
                    name="agroupation"
                    value={inputs?.agroupation}
                    optionLabel="name"
                    options={groupingOptions}
                    onChange={(e) => onChangeInputs(e)}
                />
                
            </div>
            <div className="box-container-style">
                
                <LabeledDropdown 
                    idLabel="imputation"
                    name='imputation'
                    value={inputs?.imputation}
                    options={imputations?.filter(x => x.grouping !== 'foundation')}
                    showClear={false}
                    onChange={(e) => onChangeInputs(e)}
                />
                
                <div className='box-container-content'>
                    <LabeledDropdown 
                        idLabel="justification.subvention"
                        name='justificationSubvention'
                        value={isJustificationSubvention}
                        options={optionsJustificationSubvention}
                        onChange={(e) => onChangeInputs({target: {...e?.target, value: e?.target?.value?._id }})}
                        showClear={false}
                    />
                    {inputs?.justificationSubvention && (
                        <LabeledMultiSelect
                            idLabel="subventions"
                            name="subventions"
                            value={inputs?.subventions}
                            optionLabel='name'
                            optioValue='_id'
                            options={subventions}
                            onChange={(e) => onChangeInputs(e)}
                        />
                    )}
                </div>
            </div>
            <div className="box-container-style">
                {/** Maestro subtipos  */}
                {subtypes?.length > 0 && (
                    <div className='type-activity'>
                        {subtypes.map((subtype) => (
                            <LabeledDropdown 
                                idLabel={subtype?.name}
                                name={subtype?._id}
                                value={inputs?.subtypes?.length > 0 ?{ name: inputs?.subtypes?.find(x => x.id)?.groups } : ''}
                                options={subtype?.group.map(x => ({ name: x }))} 
                                onChange={(e) => onChangeSubtype(e)}
                            />
                        ))}
                    </div> 
                )}

                
                {/** Maestro criterio de distribucion */}
                <div className='type-activity-list'>
                    { distributionCriteria?.map((distribution) => (
                        <LabeledNumber 
                            idLabel={distribution?.name}
                            name={distribution?._id} 
                            value={inputs?.distributionCriteriaList?.find(x => x?.distributionCriteria === distribution?._id)?.value}  
                            key={distribution?._id}
                            onChange={(e) => onChangeDistributionCriteria(e)}
                            suffix='%'
                            minFractionDigits={2}
                        />
                    )) }
                </div>
                
                
            </div>
        </div>
    )
}

export default CenterAccountPlanForm