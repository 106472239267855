import React from "react"
import { useParams } from "react-router-dom"
import { useIntl } from 'react-intl'
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import { TemplateEtapaHoursService } from "../../../../services/templates-distribution-hours-school/templateDistributionHoursSchool"
import useBasicLocalData from "../../../../utils/hooks/base-adapter/useBasicLocalData"
import MainCommonView from "../../../../components/common/MainCommonView"
import useModal from "../../../../utils/hooks/useModal"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import editNumberRowColumn from "../../../../components/common/dataTable/editors/editNumberRowColumn"
import Modal from "../../../../components/modal/Modal"
import TemplateLineHoursOfStage from "../../../../components/maestros-template/templates-hours/TemplateLineHoursOfStage"
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../utils/helpers/data-table/column-types/rowEditor"

const TemplateEtapaHours = () => {

    const { id } = useParams() 
    const { formatMessage: msg } = useIntl()

    const { isShowing, toggle } = useModal()

    const getTemplateHoursByEtapa = () => TemplateEtapaHoursService.getTemplateHoursById(id)
    const createTemplateHourLine = (body) => TemplateEtapaHoursService.createLine({ id, body })
    const updateTemplateHourLine = ({_id, body}) => TemplateEtapaHoursService.updateByLineId({id, lineId: _id, body}) 
    const removeTemplateHourLine = (_id) => TemplateEtapaHoursService.removeByLineId({id, lineId: _id})
    
    const dataFacade = useHandlerBasicActions({
        getAll: getTemplateHoursByEtapa, 
        createAction: createTemplateHourLine,
        editAction: updateTemplateHourLine, 
        removeAction: removeTemplateHourLine
    })

    const localPagingAndFiltering = useBasicLocalData({
        allData: dataFacade?.data?.resp?.lines,
        rowsPerPageInitial: 10,
        sortFieldInitial: 'numLines',
        fieldFilter: 'delegatedPayment.totalHours',
        translate: false
    })

    const onRowEditComplete = async ({ newData }) => {
        const { _id } = newData
        dataFacade.edit({_id, body: newData})
    }

    const addTemplateLine = async (newData) => {
        const resp = await dataFacade.create(newData)
        if (resp) toggle()
    }

    const headerOptions = {
        title: msg({id: 'templates.and.hours'}),
        titleSuffix: dataFacade?.data?.resp?.etapaName,
        handlerHeaderActions: {
            firstHandlerAction: toggle,
        },
        searchOptions: localPagingAndFiltering?.searchOptions
    }

    const dataTable = (
        <MaestrosDataTable 
            tableOptions={{
                value: localPagingAndFiltering?.data, 
                editMode: "row",
                onRowEditComplete, 
                rowGroupMode: "rowspan",
                groupRowsBy: "numLines",
                size: 'small',
                ...localPagingAndFiltering?.sortOptions
            }}
            columns={[
                { field: "numLines", sortable: true, header: 'number.lines', ...editNumberRowColumn({}) },
                { field: "udConcerted", sortable: true, header: 'concerted.units', ...editNumberRowColumn({}) },
                { field: "ratio", sortable: true, header: 'ratio', ...editNumberRowColumn({ propsInput: { minFractionDigits: 0, maxFractionDigits: 11 } }) },
                { field: "numStudents", sortable: true, header: 'numStudents.group', ...editNumberRowColumn({}) },
                { field: "delegatedPayment.totalHours", sortable: true, header: 'hours' },
                { ...rowDelete({ handleDelete: dataFacade.remove }) },
                { ...rowEditor()},
            ]}
        />
    )

    return (
        <>
            <MainCommonView
                headerOptions={headerOptions}
                pagerOptions={localPagingAndFiltering?.pagerOptions}
                componentData={dataTable} />
            
            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="810px"
                title={msg({ id: "template.new" }) + ' ' + dataFacade?.data?.resp?.etapaName}
                context={(
                    <TemplateLineHoursOfStage
                        handleSave={addTemplateLine} />
                )} />
        </>
    )

}

export default TemplateEtapaHours