import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useParams } from "react-router-dom"
import _ from 'lodash'
import MainCommonView from "../../../../components/common/MainCommonView"
import MaestrosDataTable from "../../../../components/common/dataTable/MaestrosDataTable"
import { bodyRenderTypes } from "../../../../components/common/dataTable/body/bodyColumnsTypes"
import editMultiSelectRowColumn from '../../../../components/common/dataTable/editors/editMultiSelectRowColumn'
import BalanceForm from "../../../../components/maestros-center/template-balance/BalanceForm"
import Modal from "../../../../components/modal/Modal"
import { AccountsService } from "../../../../services/accounts/accounts.service"
import TemplatesBalanceService from "../../../../services/center-accounting/templates_balance/templateBalance.service"
import editTextRowColumn from "../../../../components/common/dataTable/editors/editTextRowColumn"
import rowDelete from "../../../../utils/helpers/data-table/column-types/rowDelete"
import rowEditor from "../../../../utils/helpers/data-table/column-types/rowEditor"
import useInputs from "../../../../utils/hooks/useInputs"
import useModal from "../../../../utils/hooks/useModal"
import auxFunction from "../../../../utils/helpers/auxFun"
import useBasicLocalData from "../../../../utils/hooks/base-adapter/useBasicLocalData"
import useHandlerBasicActions from "../../../../utils/hooks/base-adapter/useHandlerBasicActions"
import editDropdownRowColumn from "../../../../components/common/dataTable/editors/editDropDownRowColumn"

const Concepts = () => {

    const { balanceId } = useParams()

    const { formatMessage: msg } = useIntl()
    const { isShowing, toggle } = useModal()
    const { inputs, updateInputs, onChangeInputs} = useInputs()
    const [ accounts, setAccount ] = useState()

    const getConcepts = () => TemplatesBalanceService.getById(balanceId)
    const createConcept = (body) => TemplatesBalanceService.createConcept(balanceId, body)

    const dataFacade = useHandlerBasicActions({
        sortFieldInitial: 'name',
        rowsPerPageInitial: 10,
        getAll: getConcepts,
        createAction: createConcept,
        removeAction: TemplatesBalanceService.removeConcept, 
        editAction: TemplatesBalanceService.updateConcept
    })

    const dataFacadeLocalConcepts = useBasicLocalData({
        allData: dataFacade?.data?.resp?.concepts,
        rowsPerPageInitial: 10,
        sortFieldInitial: 'name',
        fieldFilter: 'name',
        translate: false
    })

    useEffect(() => {
        getAccounts()
    }, [])

    const getAccounts = async () => {
        const resp = await AccountsService.getAll({})
        if (resp) {
            setAccount(resp?.resp)
        }
    }

    const handleCreate = async () => {
        const body = {
            ...inputs,
            accounts: auxFunction.returnIds(inputs?.accounts),
            type: auxFunction.returnId(inputs?.type),
            balanceTemplate: balanceId
        }
    
        dataFacade.create(body)
        updateInputs({})
        toggle()
    }

    const onRowEditComplete = async ({newData}) => {
        const newDataOmited = _.omit(newData, '_id', 'updatedAt', 'createdAt', 'active', '__v')
        const data = {
            ...newDataOmited,
            accounts: auxFunction.returnIds(newData?.accounts),
            type: auxFunction.returnId(newData?.type),
        }
        const etapas = auxFunction.returnIds(data?.etapas)
        const body = { ...data, etapas }
        dataFacade.edit({ _id: newData?._id, body })
    }

    // TODO: I18n
    const types = [
        {
            _id: 'fee',
            name: 'Quota'
        },
        {
            _id: 'subvention',
            name: 'Subvenció'
        }
    ]

    const headerOptions = {
        title: dataFacade?.data?.resp?.name,
        handlerHeaderActions: {
            firstHandlerAction: toggle
        },
        searchOptions: dataFacadeLocalConcepts?.searchOptions
    }

    const importExportFilesOptions = {
        title: dataFacade?.data?.resp?.name,
        columns: [
            { title: msg({ id: 'name' }), dataKey: 'name' },
            { title: msg({ id: 'description' }), dataKey: 'description' },
            { title: msg({ id: 'accounts' }), dataKey: 'accounts' }
        ],
        exportService: async () => {
            const resp = await TemplatesBalanceService.getById(balanceId)
            return resp
        }
    }

    const dataTable = (
        <MaestrosDataTable
            tableOptions={{
                value: dataFacadeLocalConcepts?.data,
                editMode: 'row',
                onRowEditComplete,
                ...dataFacadeLocalConcepts?.sortOptions
            }}
            columns={([
                {
                    field: 'name',
                    sortable: true,
                    header: 'name',
                    ...editTextRowColumn
                },
                {
                    field: 'description',
                    sortable: true,
                    header: 'description',
                    ...editTextRowColumn
                },
                {
                    field: 'accounts',
                    header: 'accounts',
                    body: (rowData) => bodyRenderTypes(rowData?.accounts),
                    ...editMultiSelectRowColumn({
                        options: accounts,
                        optionLabel: 'account'
                    })
                },
                {
                    field: 'type',
                    header: 'type',
                    body: (rowData) => (types.find(type => type._id === rowData?.type))?.name,
                    ...editDropdownRowColumn({
                        options: types,
                        name: 'type',
                        optionValue: '_id',
                        optionLabel: 'name'
                    })
                },
                { ...rowDelete({handleDelete: dataFacade?.remove }) },
                { ...rowEditor() },
            ])}
        />
    )

    return (
        <div>
            <MainCommonView 
                headerOptions={headerOptions}
                importExportFilesOptions={importExportFilesOptions}
                componentData={dataTable}
                pagerOptions={dataFacadeLocalConcepts?.pagerOptions}
            />

            <Modal
                isShowing={isShowing}
                hide={toggle}
                width="90%"
                maxWidth="600px"
                title={<FormattedMessage id='subaccount.new'/>}
                context={(
                    <BalanceForm 
                        inputs={inputs} 
                        handleSubmit={handleCreate}
                        handleChange={onChangeInputs}
                        accounts={accounts || []}
                        types={types || []}
                    />
                )}
            />
        </div>
    )
}

export default Concepts