// TO DO: mover a su carpeta correspondiente
const pathname = {
    LANDING: '/',
    LOGIN: 'login',
    RESET: '/reset',
    UPDATE_PASSWORD: '/updatePassword',
    NOTFOUND: '*',
}

const appPathname = {
    PROFILE: '/app/profile',
    HOME: '/app/home',
    BACKOFFICE: '/app/backoffice',
    CENTER: (idCentro) => `/app/centro/${idCentro}`,
    FOUNDATION: '/app/foundation/',
}

const adminPathname = {
    BACKOFFICE: '/backoffice',
}

export { pathname, appPathname, adminPathname }
