import React from 'react'
import { FormattedMessage } from 'react-intl'
import CardIcono from '../card/CardIcono'
import './maestrosList.scss'

const MaestrosList = ({
    maestroslist,
    showNumbers
}) => {

    return (
        <div className='maestros'>
            <div className='box-list'>
                {maestroslist?.length > 0 && maestroslist?.map((card, index) => (
                    <CardIcono 
                        item={card} 
                        key={card?._id || Math.random()}
                        number={showNumbers ? (index + 1) : null}
                    />
                ))}
            </div>
            {
                maestroslist?.length === 0 && (
                    <div className='container text-not-data'>
                        <FormattedMessage id='placeholder.not.data'/> 
                    </div>
                )
            }
        </div>
    )
}
export default MaestrosList