import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import useLoading from '../../../../../../utils/hooks/useLoading'
import CardsOverview from '../../../../../../components/common-courses/view-content/CardsOverview'
import BreadcrumbLink from '../../../../../../components/common/breadcrumb/BreadcrumbLink'
import { CENTER_COURSE_DETAILS } from '../../../../../../routes/centers/center-course/pathname'
import BudgetService from '../../../../../../services/centers/courses/budgets/budgets.service'

const FeesIncomes = () => {
    const { formatMessage: msg } = useIntl()
    const {centerId, courseId, balanceId, feesId} = useParams()

    const [activitiesData, setActivitiesData] = useState()
    const { loading, startLoading, stopLoading } = useLoading(true)

    useEffect(() => {
        getActivityIncomes()
    }, [])

    const getActivityIncomes = async () => {
        startLoading()
        const resp = await BudgetService.getActivitiesData(courseId, feesId)
        if (resp) {
            setActivitiesData(resp?.resp)
        }
        stopLoading()
    }

    const goToLink = (id, item) => {
        if (item?.source === 'etapa') return CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES_DATA(centerId, courseId, balanceId, feesId, item?.etapa)
        return CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES_FEES_SERVICES_SERVICES_TABLE(centerId, courseId, balanceId, feesId, item?.otherService)
    }

    const breadcrumb = [
        {
            label: msg({ id: 'budget' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET(centerId, courseId)} 
                text={msg({ id: 'budget' })} /> 
        },
        {
            label: msg({ id: 'income' }),
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUGET_INCOMES(centerId, courseId)} 
                text={msg({ id: 'income' })} /> 
        },
        {
            label: activitiesData?.templateName,
            template: <BreadcrumbLink 
                path={CENTER_COURSE_DETAILS.BUDGET_INCOMES_ACTIVITIES(centerId, courseId, activitiesData?.balanceTemplate)} 
                text={activitiesData?.templateName} /> 
        },
        {
            label: activitiesData?.conceptName
        }
    ]

    const cardList = {
        list: [
            ...(activitiesData?.etapas || []).map(item => ({
                ...item,
                source: 'etapa'
            })),
            ...(activitiesData?.otherServices || []).map(item => ({
                ...item,
                source: 'service'
            })),
        ],
        gotToLink: goToLink,
    }

    return (
        <CardsOverview
            loading={loading}
            cardsInfo={cardList}
            breadcrumb={breadcrumb}
        />
    )
}

export default FeesIncomes