import { DELETE, GET, PATCH, POST } from "../common/commonRequest.service"
import { endpointsCenterServices } from "./endpoints"

export const CenterServices = {
    createService: async (body) => POST(endpointsCenterServices.GENERIC, body),
    getAllServices: async ({ filter, limit, skip, sort }) => GET(endpointsCenterServices.SERVICES.LOAD({filter, limit, skip, sort})),
    getServiceById: async ({serviceId}) => GET(endpointsCenterServices.SERVICES.BY_SERVICE(serviceId)),
    updateService: async ({ _id, body }) => PATCH(endpointsCenterServices.SERVICES.BY_SERVICE(_id), body),
    confirmService: async (servicioId) => PATCH(endpointsCenterServices.SERVICES.CONFIRM(servicioId)),
    removeService: async (servicioId) => DELETE(endpointsCenterServices.SERVICES.BY_SERVICE(servicioId))
}

export const CenterServicesTypes = {
    createServiceType: async ({serviceId, body}) => POST(endpointsCenterServices.SERVICES_TYPE.BY_SERVICE_TYPE(serviceId), body),
    updateServiceType: async ({_id, body}) => PATCH(endpointsCenterServices.SERVICES_TYPE.BY_SERVICE_TYPE(_id), body),
    removeServiceType: async (serviceTypeId) => DELETE(endpointsCenterServices.SERVICES_TYPE.BY_SERVICE_TYPE(serviceTypeId))
}
